import { Box, Grid, Skeleton, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { dateInsightArrowClick } from '../../analytics/analytics';
import { IDetailedInformation, Insight } from '../../services/interfaces';
import { NavigationLink } from '../core/link';
import { TextHighlight } from '../sentiment-highlights';

interface IProps {
    insights: Insight[] | null;
    data: IDetailedInformation | null | undefined;
    topicSubscriptionId: string | undefined;
    renderDetails?: (
        _topicSubscriptionId: string,
        _date: string,
        _id: string,
        _summaryId: number
    ) => void;
}

export const DateInsights = (props: IProps) => {
    const { insights, data, topicSubscriptionId } = props;
    return (
        <Grid spacing={5} container>
            {insights?.map((row: Insight, index: number) => (
                <Grid
                    key={index}
                    xs={12}
                    sm={insights.length == 1 ? 12 : 6}
                    item
                >
                    <Box py={3} display="flex">
                        <Box>
                            <Tooltip arrow title="View sources" placement="top">
                                <Typography
                                    to={`/insight/article/${topicSubscriptionId}/${data?.date}/${data?.id}/${row.summaryId}`}
                                    onClick={() => {
                                        if (
                                            props.renderDetails &&
                                            topicSubscriptionId &&
                                            data &&
                                            row
                                        ) {
                                            props.renderDetails(
                                                topicSubscriptionId,
                                                data?.date,
                                                data?.id,
                                                row.summaryId
                                            );
                                        }
                                        if (row && data && topicSubscriptionId)
                                            dateInsightArrowClick(
                                                row.summaryId.toString(),
                                                data.id,
                                                topicSubscriptionId,
                                                '',
                                                `/insight/article/${topicSubscriptionId}/${data?.date}/${data?.id}/${row.summaryId}`
                                            );
                                    }}
                                    component={
                                        props.renderDetails
                                            ? Box
                                            : NavigationLink
                                    }
                                >
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M10.4 8L11.8104 9.39785C12.9351 10.5126 13.4975 11.07 13.5841 11.7493C13.6053 11.9158 13.6053 12.0842 13.5841 12.2507C13.4975 12.93 12.9351 13.4874 11.8104 14.6022L10.4 16M20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z"
                                            stroke="#6e3cfb"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                        />
                                    </svg>
                                </Typography>
                            </Tooltip>
                        </Box>
                        <Box pl={2}>
                            <Typography
                                text={row.summary}
                                highlight={row.highlightIndexes}
                                component={TextHighlight}
                                sx={{ fontWeight: 300 }}
                                variant="subtitle1"
                            >
                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        textDecoration: 'none',
                                        color: '#6e3cfb',
                                        fontWeight: 300,
                                    }}
                                    to="/insight"
                                    component={NavigationLink}
                                >
                                    More Details
                                </Typography>
                            </Typography>
                            <Typography color="#4B4B4C" variant="caption">
                                Sources: [{row.sources.join(', ')}]
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            ))}
            {!insights && (
                <Grid xs={12} sm={12} item>
                    <Box py={3} display="flex">
                        <Box>
                            <Typography>
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M10.4 8L11.8104 9.39785C12.9351 10.5126 13.4975 11.07 13.5841 11.7493C13.6053 11.9158 13.6053 12.0842 13.5841 12.2507C13.4975 12.93 12.9351 13.4874 11.8104 14.6022L10.4 16M20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z"
                                        stroke="#6e3cfb"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                    />
                                </svg>
                            </Typography>
                        </Box>
                        <Box width="100%" pl={2}>
                            <Typography
                                sx={{ fontWeight: 300 }}
                                variant="subtitle1"
                            >
                                <Skeleton variant="text" />
                                <Skeleton variant="text" />
                                <Skeleton variant="text" />
                                <Skeleton variant="text" />
                            </Typography>
                            <br />
                            <Typography color="#4B4B4C" variant="caption">
                                Sources:
                                <Skeleton variant="text" />
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            )}
        </Grid>
    );
};
