import React from 'react';

interface IProps {
    color: string;
}
export const QuestionLight = (props: IProps) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_11369_158660)">
                <path
                    d="M12 18L13.872 13.896L18 12L13.872 10.128L12 6L10.116 10.128L6 12L10.116 13.896L12 18ZM7.404 0.912C8.8621 0.311938 10.4233 0.00214983 12 0C13.572 0 15.132 0.312 16.596 0.912C18.048 1.512 19.368 2.4 20.484 3.516C21.6 4.632 22.488 5.952 23.088 7.404C23.688 8.868 24 10.428 24 12C24 15.18 22.74 18.24 20.484 20.484C19.371 21.5998 18.0486 22.4847 16.5926 23.0881C15.1367 23.6915 13.576 24.0014 12 24C10.4233 23.9978 8.8621 23.6881 7.404 23.088C5.94931 22.4842 4.62807 21.5993 3.516 20.484C2.40024 19.371 1.51528 18.0486 0.911906 16.5926C0.308535 15.1367 -0.00136085 13.576 4.49197e-06 12C4.49197e-06 8.82 1.26 5.76 3.516 3.516C4.632 2.4 5.952 1.512 7.404 0.912ZM5.208 18.792C7.008 20.592 9.456 21.6 12 21.6C14.544 21.6 16.992 20.592 18.792 18.792C20.592 16.992 21.6 14.544 21.6 12C21.6 9.456 20.592 7.008 18.792 5.208C16.9895 3.409 14.5466 2.39905 12 2.4C9.456 2.4 7.008 3.408 5.208 5.208C3.40901 7.01049 2.39906 9.45336 2.4 12C2.4 14.544 3.408 16.992 5.208 18.792Z"
                    fill="black"
                />
            </g>
            <defs>
                <clipPath id="clip0_11369_158660">
                    <rect width="24" height="24" fill={props.color} />
                </clipPath>
            </defs>
        </svg>
    );
};
