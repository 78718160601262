import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    IconButton,
    ListItemButton,
    Switch,
    Tabs,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Input } from '../../../../components/core/input';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { useSnackbar } from 'notistack';
import DeleteIcon from '@mui/icons-material/Delete';
import LanguageIcon from '@mui/icons-material/Language';
import CloseIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
//@ts-ignore
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { DeleteImg } from '../../../../components/icons/delete-img';
import { CustomTab } from '.';
import {
    IChatWidgetMessageSource,
    IChatbotConfig,
    IChatbotVisitorInfo,
    IChatbotVisitorInfoField,
    IChatbotWelcomeMessageConfig,
} from '../../../../services/interfaces';
import SingleSelect from '../../../../components/core/input/single-select';
import { ChatbotFilters } from './chatbotFilters';
import { useChatWidgetService } from '../../../../services/chat-widget.service';
import { ConfirmationDialog } from '../../../../components/dialog/confirm';
import { useNavigate } from 'react-router-dom';
import { Add } from '@mui/icons-material';
import { FilterUnchecked } from '../../../../components/icons/filter-unchecked';
import { FilterChecked } from '../../../../components/icons/filter-checked';
import { Delete } from '../../../../components/icons/delete';
import GPTSelect from '../../../../components/GTP-model-select';
import { AppContext } from '../../../../providers/app-provider';
import { usePermissions } from '../../../../providers/permissions-provider';
import { FeatureName, SubFeatureName } from '../../../../services/interfaces';
import { Authorize } from '../../../../components/authorize';

interface IProps {
    config: IChatbotConfig;
    dataSourcesCount: number | null;
    updateConfig: (config: IChatbotConfig, skipPush?: boolean) => void;
    updateWelcomeMessage: (config: IChatbotConfig) => void;
}

const AUTO_OPEN_BEHAVIOURS = [
    {
        id: 'ONCE_PER_VISIT',
        value: 'Once per visit',
    },
    {
        id: 'EVERYTIME',
        value: 'Everytime',
    },
];

const QUESTION: IChatbotWelcomeMessageConfig = {
    order: 0,
    message: {
        messageID: '12341234',
        vote: 'neutral',
        isBookmarked: false,
        messageType: 'OUTGOING',
        data: {
            query: `What is the focus of Forgepoint?`,
        },
    },
};

const BRIEF_ANSWER: IChatbotWelcomeMessageConfig = {
    order: 1,
    message: {
        messageID: '123412341234',
        vote: 'neutral',
        isBookmarked: false,
        messageType: 'INCOMING',
        data: {
            answer: `<p>The focus of Forgepoint is to convert chaotic data into useful intelligence. They analyze data from public and private sources and extract important insights that help our clients make better decisions and save time [1].</p>`,
            sources: [
                {
                    id: 0,
                    summary:
                        'Forgepoint is a quality focused company that convert chaotic data into useful intelligence, revealing pertinent knowledge and data for commerce, business, and finance. At Photon, we analyse data from public and private sources, extract important insights that help our clients make better decisions and save time.',
                    pageNo: 5,
                    fileName: 'Photon_Document.pdf',
                    answerSource: 'USER_DATA',
                    userFileId: 'UF397619812465446792',
                    uploadedOn: '2023-05-22T17:54:29',
                    fileType: 'DOCUMENTS',
                } as IChatWidgetMessageSource,
            ],
        },
    },
};

const DESCRIPTIVE_ANSWER: IChatbotWelcomeMessageConfig = {
    order: 1,
    message: {
        messageID: '123412341234',
        vote: 'neutral',
        isBookmarked: false,
        messageType: 'INCOMING',
        data: {
            answer: `<p>The focus of Forgepoint is to convert chaotic data into useful intelligence. They analyze data from public and private sources and extract important insights that help our clients make better decisions and save time [1]. Their main goal is to simplify and amplify the power of data analysis for businesses, commerce, and finance [2]. They achieve this by utilizing massive language models and cutting-edge machine learning techniques. From text data, Forgepoint can provide a variety of insights such as succinct summaries, sentiments, potential red flags, keywords, and more [2].</p>`,
            sources: [
                {
                    id: 0,
                    citationIndex: 1,
                    summary:
                        'Forgepoint is a quality focused company that convert chaotic data into useful intelligence, revealing pertinent knowledge and data for commerce, business, and finance. At Photon, we analyse data from public and private sources, extract important insights that help our clients make better decisions and save time.',
                    pageNo: 5,
                    fileName: 'Photon_Document.pdf',
                    answerSource: 'USER_DATA',
                    userFileId: 'UF397619812465446792',
                    uploadedOn: '2023-05-22T17:54:29',
                    fileType: 'DOCUMENTS',
                } as IChatWidgetMessageSource,
                {
                    id: 1,
                    citationIndex: 2,
                    summary:
                        'Forgepoint help you simplify and summarize complex information, delivering pertinent insights that drive your business forward. We do this by utilising massive language models and cutting-edge machine learning techniques. From text data, Forgepoint can glean a variety of insights, such as succinct summaries, sentiments, potential red flags, keywords, and more.',
                    pageNo: 8,
                    fileName: 'Photon_Document.pdf',
                    answerSource: 'USER_DATA',
                    userFileId: 'UF397619812465446792',
                    uploadedOn: '2023-05-22T17:54:29',
                    fileType: 'DOCUMENTS',
                } as IChatWidgetMessageSource,
            ],
        },
    },
};

const ENHANCED_ANSWERS: IChatbotWelcomeMessageConfig = {
    order: 1,
    message: {
        messageID: '12341234234',
        vote: 'neutral',
        isBookmarked: false,
        messageType: 'INCOMING',
        data: {
            answer: `<p>The focus of Forgepoint is to convert chaotic data into useful intelligence. They analyze data from public and private sources and extract important insights that help our clients make better decisions and save time [1]. Their main goal is to simplify and amplify the power of data analysis for businesses, commerce, and finance [2]. They achieve this by utilizing massive language models and cutting-edge machine learning techniques. From text data, Forgepoint can provide a variety of insights such as succinct summaries, sentiments, potential red flags, keywords, and more [2].</p>`,
            sources: [
                {
                    id: 1,
                    citationIndex: 2,
                    answerSource: 'ARTICLE',
                    url: 'https://www.bbc.com/news/world-us-canada-68223906',
                    source: 'BBC',
                    title: "Actress Gina Carano sues Disney and Lucasfilm with Elon Musk's help",
                    datetime: '2024-02-07T17:57:17.747713',
                    icon: 'https://encrypted-tbn2.gstatic.com/faviconV2?url=https://www.bbc.com&client=STREAM&size=128&type=FAVICON&fallback_opts=TYPE,SIZE,URL',
                    image: 'https://ichef.bbci.co.uk/news/1024/branded_news/8E23/production/_132578363_gettyimages-1194400200.jpg',
                    summary:
                        'Actress Gina Carano has sued Disney and Lucasfilm after she was fired in 2021 over a social media post equating being a Republican to being a Jew during the Holocaust. The lawsuit is being funded by Elon Musk, who made an open call on his platform X for others to join the suit. Carano is seeking $75,000 in damages and is also asking the court to force Lucasfilm to recast her.',
                } as IChatWidgetMessageSource,
            ],
        },
    },
};

export const ChatBotConfigurationSettings = (props: IProps) => {
    const { updateChatbotStatus, deleteChatbot } = useChatWidgetService();
    const { isFeatureAuthorized, isSubFeatureAuthorized } = usePermissions();
    const [openDisable, setOpenDisable] = useState(false);
    const appContext = useContext(AppContext);
    const [domainInput, setDomainInput] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const [openDelete, setOpenDelete] = useState(false);
    const navigate = useNavigate();
    const [value, setValue] = React.useState(
        props.config.dataSourceConfig.answerFormat == 'BRIEF' ? 0 : 1
    );

    const [includeAllFiles, setIncludeAllFiles] = useState(
        props.config.dataSourceConfig.customData.fileTypes.length > 0 ||
            props.config.dataSourceConfig.customData.userTagIds.length > 0
    );

    useEffect(() => {
        setTimeout(() => {
            props.updateWelcomeMessage({
                ...props.config,
                designConfig: {
                    ...props.config.designConfig,
                    data: {
                        ...props.config.designConfig.data,
                        config: {
                            ...props.config.designConfig.data.config,
                            text: {
                                ...props.config.designConfig.data.config.text,
                                welcomeMessages: [
                                    QUESTION,
                                    value == 0
                                        ? BRIEF_ANSWER
                                        : DESCRIPTIVE_ANSWER,
                                ],
                            },
                        },
                    },
                },
            });
        }, 100);
    }, []);

    const updateDelay = (newValue: number) => {
        props.updateConfig(
            {
                ...props.config,
                designConfig: {
                    ...props.config.designConfig,
                    data: {
                        ...props.config.designConfig.data,
                        config: {
                            ...props.config.designConfig.data.config,
                            behaviour: {
                                ...props.config.designConfig.data.config
                                    .behaviour,
                                autoOpen: {
                                    ...props.config.designConfig.data.config
                                        .behaviour.autoOpen,
                                    delay: newValue,
                                    frequency:
                                        newValue == 0
                                            ? 'ONCE_PER_VISIT'
                                            : props.config.designConfig.data
                                                  .config.behaviour.autoOpen
                                                  .frequency,
                                },
                            },
                        },
                    },
                },
            },
            true
        );
    };

    const updateRateLimit = (newValue: number) => {
        props.updateConfig(
            {
                ...props.config,
                rateLimit: newValue,
            },

            true
        );
    };

    const updateFilters = (newFilters: {
        fileTypes: string[];
        userTagIds: string[];
    }) => {
        props.updateConfig(
            {
                ...props.config,
                dataSourceConfig: {
                    ...props.config.dataSourceConfig,
                    customData: {
                        ...props.config.dataSourceConfig.customData,
                        fileTypes: newFilters.fileTypes,
                        userTagIds: newFilters.userTagIds,
                    },
                },
            },
            true
        );
    };

    const updateIncludeWeb = (include: boolean) => {
        props.updateConfig(
            {
                ...props.config,
                dataSourceConfig: {
                    ...props.config.dataSourceConfig,
                    customData: {
                        ...props.config.dataSourceConfig.customData,
                        searchTheWeb: include,
                    },
                },
            },
            true
        );
        // props.updateWelcomeMessage({
        //     ...props.config,
        //     designConfig: {
        //         ...props.config.designConfig,
        //         config: {
        //             ...props.config.designConfig.data.config,
        //             text: {
        //                 ...props.config.designConfig.data.config.text,
        //                 welcomeMessages: [
        //                     QUESTION,
        //                     include
        //                         ? ENHANCED_ANSWERS
        //                         : props.config.dataSourceConfig.answerFormat ==
        //                           'BRIEF'
        //                         ? BRIEF_ANSWER
        //                         : DESCRIPTIVE_ANSWER,
        //                 ],
        //             },
        //         },
        //     },
        // });
    };

    const updateAutoOpenBehaviour = (behaviour: string) => {
        props.updateConfig(
            {
                ...props.config,
                designConfig: {
                    ...props.config.designConfig,
                    data: {
                        ...props.config.designConfig.data,
                        config: {
                            ...props.config.designConfig.data.config,
                            behaviour: {
                                ...props.config.designConfig.data.config
                                    .behaviour,
                                autoOpen: {
                                    ...props.config.designConfig.data.config
                                        .behaviour.autoOpen,
                                    frequency:
                                        behaviour == 'EVERYTIME'
                                            ? 'EVERYTIME'
                                            : 'ONCE_PER_VISIT',
                                },
                            },
                        },
                    },
                },
            },
            true
        );
    };

    const updateStatus = async (active: boolean) => {
        if (!props.config.widgetId) return;
        if (active) {
            setOpenDisable(true);
        } else {
            let res = await updateChatbotStatus(
                props.config.widgetId,
                'ACTIVE'
            );
            if (res) {
                enqueueSnackbar('Widget enabled sucessfully', {
                    variant: 'success',
                });
                props.updateConfig(
                    {
                        ...props.config,
                        status: 'ACTIVE',
                    },
                    true
                );
            }
        }
    };

    const handleDisableConfirm = async () => {
        if (!props.config.widgetId) return;
        let res = await updateChatbotStatus(props.config.widgetId, 'ARCHIVE');
        if (res) {
            enqueueSnackbar('Widget disabled sucessfully', {
                variant: 'success',
            });
            props.updateConfig(
                {
                    ...props.config,
                    status: 'ARCHIVE',
                },
                true
            );
        }
    };

    const addDomain = () => {
        if (domainInput) {
            try {
                let url = new URL(domainInput);
                if (!props.config.whiteListedDomains.includes(url.origin)) {
                    props.updateConfig(
                        {
                            ...props.config,
                            whiteListedDomains: [
                                url.origin,
                                ...props.config.whiteListedDomains,
                            ],
                        },
                        true
                    );
                }
                setDomainInput('');
            } catch (error) {
                enqueueSnackbar('invalid domain', {
                    variant: 'error',
                });
            }
        }
    };

    const updateSelectedServerModel = (server: string) => {
        props.updateConfig(
            {
                ...props.config,
                dataSourceConfig: {
                    ...props.config.dataSourceConfig,
                    server: server,
                },
            },
            true
        );
    };

    const removeDomain = (domain: string) => {
        props.updateConfig(
            {
                ...props.config,
                whiteListedDomains: [
                    ...props.config.whiteListedDomains.filter(
                        (d: string) => d != domain
                    ),
                ],
            },
            true
        );
    };

    const handleDeleteConfirm = async () => {
        if (!props.config.widgetId) return;
        let res = await deleteChatbot(props.config.widgetId);
        if (res) {
            enqueueSnackbar('Widget deleted sucessfully', {
                variant: 'success',
            });
            navigate('/chat-widget');
        }
    };

    const updateVsitorInfo = (info?: IChatbotVisitorInfo) => {
        props.updateConfig({
            ...props.config,
            visitorInfo: info,
        });
    };

    const updateVisitorInfoMessage = (message: string) => {
        if (props.config.visitorInfo) {
            props.updateConfig({
                ...props.config,
                visitorInfo: {
                    ...props.config.visitorInfo,
                    message,
                },
            });
        }
    };

    const updateMandatoryFieldFlag = (isInfoRequired: boolean) => {
        if (props.config.visitorInfo) {
            props.updateConfig({
                ...props.config,
                visitorInfo: {
                    ...props.config.visitorInfo,
                    isInfoRequired,
                },
            });
        }
    };

    const updateCustomField = (field: IChatbotVisitorInfoField) => {
        if (props.config.visitorInfo) {
            const optionalFileds =
                props.config.visitorInfo.optionalFields || [];
            if (props.config.visitorInfo) {
                props.updateConfig({
                    ...props.config,
                    visitorInfo: {
                        ...props.config.visitorInfo,
                        optionalFields: optionalFileds.map((f) => {
                            if (f.order == field.order) {
                                return field;
                            }
                            return f;
                        }),
                    },
                });
            }
        }
    };

    const addVisitorInfoCustomFields = () => {
        if (props.config.visitorInfo) {
            const optionalFileds =
                props.config.visitorInfo.optionalFields || [];

            props.updateConfig({
                ...props.config,
                visitorInfo: {
                    ...props.config.visitorInfo,
                    optionalFields: [
                        ...optionalFileds,
                        {
                            order: optionalFileds.length + 1,
                            title: `Field ${optionalFileds.length + 1}`,
                            dataType: 'text',
                            required: true,
                            multiline: false,
                        },
                    ],
                },
            });
        }
    };
    const removeVisitorInfoCustomFields = (order: number) => {
        if (props.config.visitorInfo) {
            const optionalFileds =
                props.config.visitorInfo.optionalFields || [];

            props.updateConfig({
                ...props.config,
                visitorInfo: {
                    ...props.config.visitorInfo,
                    optionalFields: [
                        ...optionalFileds
                            .filter((f) => f.order != order)
                            .map((nf, index) => {
                                nf.order = index + 1;
                                return nf;
                            }),
                    ],
                },
            });
        }
    };

    const updateCitation = (updatedMode: boolean) => {
        props.updateConfig({
            ...props.config,
            withCitations: updatedMode,
            dataSourceConfig: {
                ...props.config.dataSourceConfig,
                withCitations: updatedMode,
            },
        });
        setTimeout(() => {
            props.updateWelcomeMessage({
                ...props.config,
                withCitations: updatedMode,
                designConfig: {
                    ...props.config.designConfig,
                    data: {
                        ...props.config.designConfig.data,
                        config: {
                            ...props.config.designConfig.data.config,
                            text: {
                                ...props.config.designConfig.data.config.text,
                                welcomeMessages: [
                                    QUESTION,
                                    value == 0
                                        ? BRIEF_ANSWER
                                        : DESCRIPTIVE_ANSWER,
                                ],
                            },
                        },
                    },
                },
            });
        }, 0);
    };

    const handleAnswerFormatChange = (
        event: React.SyntheticEvent,
        newValue: number
    ) => {
        if (newValue == undefined) return;
        setValue(newValue);
        props.updateConfig(
            {
                ...props.config,
                dataSourceConfig: {
                    ...props.config.dataSourceConfig,
                    answerFormat: newValue == 0 ? 'BRIEF' : 'DESCRIPTIVE',
                },
            },
            true
        );
        setTimeout(() => {
            props.updateWelcomeMessage({
                ...props.config,
                dataSourceConfig: {
                    ...props.config.dataSourceConfig,
                    answerFormat: newValue == 0 ? 'BRIEF' : 'DESCRIPTIVE',
                },
                designConfig: {
                    ...props.config.designConfig,
                    data: {
                        ...props.config.designConfig.data,
                        config: {
                            ...props.config.designConfig.data.config,
                            text: {
                                ...props.config.designConfig.data.config.text,
                                welcomeMessages: [
                                    QUESTION,
                                    newValue == 0
                                        ? BRIEF_ANSWER
                                        : DESCRIPTIVE_ANSWER,
                                ],
                            },
                        },
                    },
                },
            });
        }, 0);
    };
    return (
        <Box>
            <Box>
                <Typography my={2} variant="h4">
                    Data Source
                </Typography>

                <Box py={4} display="flex" justifyContent="space-between">
                    <Box>
                        <Typography color="#003" variant="h6">
                            Include all files
                        </Typography>
                        <Typography color="#8D8D8D" variant="subtitle2">
                            Total files {props.dataSourcesCount}
                        </Typography>
                    </Box>
                    <Box>
                        <Switch
                            onChange={(e) => {
                                setIncludeAllFiles(!e.target.checked);
                                if (e.target.checked) {
                                    updateFilters({
                                        fileTypes: [],
                                        userTagIds: [],
                                    });
                                }
                            }}
                            checked={
                                !(
                                    includeAllFiles ||
                                    props.config.dataSourceConfig.customData
                                        .fileTypes.length > 0 ||
                                    props.config.dataSourceConfig.customData
                                        .userTagIds.length > 0
                                )
                            }
                        />
                    </Box>
                </Box>
                {(props.config.dataSourceConfig.customData.fileTypes.length >
                    0 ||
                    props.config.dataSourceConfig.customData.userTagIds.length >
                        0 ||
                    includeAllFiles) && (
                    <Box pb={4}>
                        <ChatbotFilters
                            updateFilters={updateFilters}
                            datasourceConfig={
                                props.config.dataSourceConfig.customData
                            }
                        />
                    </Box>
                )}
                <Divider />
                <Authorize
                    featureName={FeatureName.Chatbot}
                    subFeatureName={SubFeatureName.ModelSelection}
                >
                    <Box py={4} display="flex" justifyContent="space-between">
                        <Box>
                            <Typography
                                color={
                                    isSubFeatureAuthorized(
                                        FeatureName.Chatbot,
                                        SubFeatureName.ModelSelection
                                    )
                                        ? '#003'
                                        : '#ccc'
                                }
                                variant="h6"
                            >
                                Select AI Model
                            </Typography>
                            <Typography
                                color={
                                    isSubFeatureAuthorized(
                                        FeatureName.Chatbot,
                                        SubFeatureName.ModelSelection
                                    )
                                        ? '#8D8D8D'
                                        : '#ccc'
                                }
                                variant="subtitle2"
                            >
                                {appContext?.servers.filter(
                                    (s) =>
                                        s.name ==
                                        props.config.dataSourceConfig.server
                                )[0]
                                    ? `Consumes ${
                                          appContext?.servers.filter(
                                              (s) =>
                                                  s.name ==
                                                  props.config.dataSourceConfig
                                                      .server
                                          )[0].quotaConsumptionAmount
                                      } Credit per response`
                                    : ''}
                            </Typography>
                        </Box>

                        <Box>
                            <GPTSelect
                                disabled={
                                    !isSubFeatureAuthorized(
                                        FeatureName.Chatbot,
                                        SubFeatureName.ModelSelection
                                    )
                                }
                                onChange={updateSelectedServerModel}
                                selectedValue={
                                    props.config.dataSourceConfig.server
                                }
                            />
                        </Box>
                    </Box>
                </Authorize>
                <Divider />
                <Box py={4}>
                    <Authorize
                        featureName={FeatureName.PhotonChat}
                        subFeatureName={SubFeatureName.CaptureLeads}
                    >
                        <Box display="flex" justifyContent="space-between">
                            <Box>
                                <Typography
                                    color={
                                        isSubFeatureAuthorized(
                                            FeatureName.PhotonChat,
                                            SubFeatureName.CaptureLeads
                                        )
                                            ? '#003'
                                            : '#ccc'
                                    }
                                    pt={1}
                                    variant="h6"
                                >
                                    Collect Visitor Information
                                </Typography>
                            </Box>
                            <Box>
                                <Switch
                                    disabled={
                                        !isSubFeatureAuthorized(
                                            FeatureName.PhotonChat,
                                            SubFeatureName.CaptureLeads
                                        )
                                    }
                                    onChange={(e) =>
                                        updateVsitorInfo(
                                            props.config.visitorInfo
                                                ? undefined
                                                : {
                                                      message:
                                                          'Please fill the details.',
                                                      mendatoryFields: [
                                                          {
                                                              order: 0,
                                                              title: 'Email',
                                                              dataType: 'text',
                                                              required: true,
                                                              multiline: false,
                                                          },
                                                      ],
                                                  }
                                        )
                                    }
                                    checked={
                                        isSubFeatureAuthorized(
                                            FeatureName.PhotonChat,
                                            SubFeatureName.CaptureLeads
                                        ) && !!props.config.visitorInfo
                                    }
                                />
                            </Box>
                        </Box>
                    </Authorize>
                    {props.config.visitorInfo &&
                        isSubFeatureAuthorized(
                            FeatureName.PhotonChat,
                            SubFeatureName.CaptureLeads
                        ) && (
                            <Box>
                                <Typography fontSize={14} fontWeight={600}>
                                    Message
                                </Typography>
                                <Box pt={2}>
                                    <Input
                                        mt={0}
                                        pt={0}
                                        pb={0}
                                        backgroundColor="#fff"
                                        type="text"
                                        onChange={(e) => {
                                            updateVisitorInfoMessage(
                                                e.target.value.slice(0, 100)
                                            );
                                        }}
                                        value={props.config.visitorInfo.message}
                                        placeholder="Chatbot 1"
                                    />
                                </Box>
                                <Box pt={2}>
                                    <Box
                                        display="flex"
                                        justifyContent={'space-between'}
                                    >
                                        <Box width={250}>
                                            <Typography fontSize={12}>
                                                Select the fields to display
                                            </Typography>
                                        </Box>
                                        <Typography fontSize={12}>
                                            Make it mandatory
                                        </Typography>
                                        {/* <Box
                                        display="flex"
                                        width={'calc(100% - 250px)'}
                                        justifyContent={'space-between'}
                                    >
                                        <Typography fontSize={12}>
                                            Make it mandatory
                                        </Typography>
                                         <Typography fontSize={12}>
                                            Numeric Data Type
                                        </Typography>
                                        <Typography fontSize={12}>
                                            Add Multiple Lines
                                        </Typography> 
                                    </Box> */}
                                    </Box>
                                    {props.config.visitorInfo.mendatoryFields.map(
                                        (field, index) => (
                                            <Box
                                                py={2}
                                                display="flex"
                                                justifyContent={'space-between'}
                                            >
                                                <Box width={200}>
                                                    <Typography fontSize={14}>
                                                        {field.title}
                                                    </Typography>
                                                </Box>
                                                <Typography
                                                    sx={{
                                                        color: '#ccc',
                                                        cursor: 'not-allowed',
                                                        px: 1,
                                                        mr: 8,
                                                    }}
                                                    fontSize={12}
                                                >
                                                    <CheckBoxIcon
                                                        sx={{ fontSize: 22 }}
                                                    />
                                                </Typography>
                                                {/* <Box
                                                display="flex"
                                                width={'calc(100% - 200px)'}
                                                justifyContent={'space-around'}
                                            >
                                                <Typography
                                                    sx={{
                                                        color: '#ccc',
                                                        cursor: 'not-allowed',
                                                        px: 1,
                                                    }}
                                                    fontSize={12}
                                                >
                                                    <CheckBoxIcon
                                                        sx={{ fontSize: 22 }}
                                                    />
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        color: '#ccc',
                                                        cursor: 'not-allowed',
                                                        px: 1,
                                                    }}
                                                    fontSize={12}
                                                >
                                                    <CheckBoxOutlineBlankIcon
                                                        sx={{ fontSize: 22 }}
                                                    />
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        color: '#ccc',
                                                        cursor: 'not-allowed',
                                                        px: 1,
                                                    }}
                                                    fontSize={10}
                                                >
                                                    <CheckBoxOutlineBlankIcon
                                                        sx={{ fontSize: 22 }}
                                                    />
                                                </Typography>
                                            </Box> */}
                                            </Box>
                                        )
                                    )}
                                    {props.config.visitorInfo.optionalFields?.map(
                                        (field, index) => (
                                            <Box
                                                py={2}
                                                display="flex"
                                                justifyContent={'space-between'}
                                            >
                                                <Box width={200}>
                                                    <Input
                                                        mt={0}
                                                        pt={0}
                                                        pb={0}
                                                        inputPadding="5px 5px 5px 0px"
                                                        backgroundColor="#fff"
                                                        noBorder
                                                        fontSize={14}
                                                        type="text"
                                                        onChange={(e) => {
                                                            updateCustomField({
                                                                ...field,
                                                                title: e.target
                                                                    .value,
                                                            });
                                                        }}
                                                        value={field.title}
                                                        placeholder="Chatbot 1"
                                                    />
                                                </Box>
                                                <Box display="flex">
                                                    <Checkbox
                                                        size="small"
                                                        icon={FilterUnchecked}
                                                        onClick={() => {
                                                            updateCustomField({
                                                                ...field,
                                                                required:
                                                                    !field.required,
                                                            });
                                                        }}
                                                        checked={field.required}
                                                        checkedIcon={
                                                            FilterChecked
                                                        }
                                                    />
                                                    {/* <Box
                                                display="flex"
                                                width={'calc(100% - 200px)'}
                                                justifyContent={'space-around'}
                                            >
                                                <Checkbox
                                                    size="small"
                                                    icon={FilterUnchecked}
                                                    onClick={() => {
                                                        updateCustomField({
                                                            ...field,
                                                            required:
                                                                !field.required,
                                                        });
                                                    }}
                                                    checked={field.required}
                                                    checkedIcon={FilterChecked}
                                                />
                                                <Checkbox
                                                    size="small"
                                                    icon={FilterUnchecked}
                                                    onClick={() => {
                                                        updateCustomField({
                                                            ...field,
                                                            dataType:
                                                                field.dataType ==
                                                                'number'
                                                                    ? 'text'
                                                                    : 'number',
                                                        });
                                                    }}
                                                    checked={
                                                        field.dataType ==
                                                        'number'
                                                    }
                                                    checkedIcon={FilterChecked}
                                                />{' '}
                                                <Checkbox
                                                    size="small"
                                                    icon={FilterUnchecked}
                                                    onClick={() => {
                                                        updateCustomField({
                                                            ...field,
                                                            multiline:
                                                                !field.multiline,
                                                        });
                                                    }}
                                                    checked={field.multiline}
                                                    checkedIcon={FilterChecked}
                                                />
                                            </Box> */}
                                                    <IconButton
                                                        onClick={() =>
                                                            removeVisitorInfoCustomFields(
                                                                field.order
                                                            )
                                                        }
                                                        sx={{
                                                            mt: 2,
                                                            p: 0,
                                                            ml: 3,
                                                            '&.Mui-disabled': {
                                                                fillOpacity: 0.35,
                                                            },
                                                        }}
                                                    >
                                                        <Delete
                                                            color="#D82F44"
                                                            size={18}
                                                        />
                                                    </IconButton>
                                                </Box>
                                            </Box>
                                        )
                                    )}
                                    <Button
                                        onClick={addVisitorInfoCustomFields}
                                        variant="text"
                                    >
                                        {' '}
                                        <Add />
                                        Add Custom
                                    </Button>
                                </Box>
                                <Box
                                    mt={2}
                                    pt={1}
                                    borderTop={'1px dashed #ccc'}
                                >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={(e) => {
                                                    updateMandatoryFieldFlag(
                                                        !props.config
                                                            .visitorInfo
                                                            ?.isInfoRequired
                                                    );
                                                }}
                                                checked={
                                                    props.config.visitorInfo
                                                        ?.isInfoRequired
                                                }
                                                size="small"
                                            />
                                        }
                                        label="Make it mandatory to fill out all the fields"
                                    />
                                </Box>
                            </Box>
                        )}
                </Box>
                <Divider />
                <Box pt={4}>
                    <Box py={4} display="flex" justifyContent="space-between">
                        <Box>
                            <Typography color="#003" variant="h6">
                                Enhance your answers
                            </Typography>
                            <Typography color="#8D8D8D" variant="subtitle2">
                                Augment your answers from the web and your data
                                sources.
                            </Typography>
                        </Box>
                        <Box>
                            <Switch
                                onChange={(e) =>
                                    updateIncludeWeb(e.target.checked)
                                }
                                checked={
                                    props.config.dataSourceConfig.customData
                                        .searchTheWeb
                                }
                            />
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                        <Box>
                            <Typography color="#003" variant="h6">
                                Response Citation with Sources
                            </Typography>
                            <Typography color="#8D8D8D" variant="subtitle2">
                                Give transparency and credibility to the
                                responses by citing the data sources
                            </Typography>
                        </Box>
                        <Box>
                            <Switch
                                onChange={(e) =>
                                    updateCitation(e.target.checked)
                                }
                                checked={
                                    props.config.dataSourceConfig.withCitations
                                }
                            />
                        </Box>
                    </Box>
                    <Typography mt={2} variant="subtitle2">
                        Note: If enabled, your customers can see your data
                        sources from which the response is generated. Enable it
                        only if the data sources are public.
                    </Typography>
                </Box>

                <Box py={4} display="flex" justifyContent="space-between">
                    <Box>
                        <Typography color="#003" variant="h6">
                            Answer Format
                        </Typography>
                        <Typography color="#8D8D8D" variant="subtitle2">
                            Select the length of the answer, generated by Photon
                            AI
                        </Typography>
                    </Box>
                    <Box>
                        <Tabs
                            value={value}
                            onChange={handleAnswerFormatChange}
                            sx={{
                                '& .MuiTabs-indicator': { display: 'none' },
                                '& .MuiTab-root:first-of-type': {
                                    borderTopLeftRadius: '100px !important',
                                    borderBottomLeftRadius: '100px !important',
                                },
                                '& .MuiTab-root:last-of-type': {
                                    borderTopRightRadius: '100px !important',
                                    borderBottomRightRadius: '100px !important',
                                },
                            }}
                            aria-label="basic tabs example"
                        >
                            <CustomTab label="Brief" />
                            <CustomTab label="Descriptive" />
                        </Tabs>
                    </Box>
                </Box>

                <Box py={4} display="flex" justifyContent="space-between">
                    <Box>
                        <Typography color="#003" variant="h6">
                            Rate Limiting
                        </Typography>
                        <Typography color="#8D8D8D" variant="subtitle2">
                            Number of question allowed to ask by user in an hour
                        </Typography>
                    </Box>
                    <Box sx={{ width: 100 }}>
                        <TextField
                            fullWidth
                            type="number"
                            onKeyDown={(evt) => {
                                if (
                                    !['ArrowDown', 'ArrowUp'].includes(evt.code)
                                )
                                    evt.preventDefault();
                            }}
                            value={props.config.rateLimit}
                            onChange={(evt) =>
                                updateRateLimit(parseInt(evt.target.value))
                            }
                            InputProps={{
                                inputProps: {
                                    max: 300,
                                    min: 30,
                                },
                            }}
                        />
                    </Box>
                </Box>
                <Divider />
                <Box>
                    <Typography mt={2} variant="h6">
                        Whitelisted Domains
                    </Typography>

                    <Typography mb={2} color="#8D8D8D" variant="subtitle2">
                        Approved domains for secure and authorized chat widget
                        integration.
                    </Typography>
                    <Box>
                        <Input
                            value={domainInput}
                            onEnterPress={addDomain}
                            pb={0}
                            pt={0}
                            onChange={(e) => setDomainInput(e.target.value)}
                            endAdornment={
                                <Button
                                    onClick={addDomain}
                                    sx={{
                                        pt: 1,
                                        pb: 1,
                                    }}
                                    disabled={!domainInput}
                                    variant="contained"
                                >
                                    add
                                </Button>
                            }
                            placeholder={''}
                            type={''}
                        />
                    </Box>
                    <List>
                        {props.config.whiteListedDomains.map(
                            (domain: string) => (
                                <ListItem
                                    sx={{
                                        border: '1px solid #D7D9EC',
                                        borderRadius: 4,
                                        mb: 2,
                                    }}
                                    secondaryAction={
                                        <IconButton
                                            sx={{ mr: 2 }}
                                            edge="end"
                                            onClick={() => removeDomain(domain)}
                                            aria-label="delete"
                                        >
                                            <CloseIcon color="error" />
                                        </IconButton>
                                    }
                                >
                                    <ListItemIcon>
                                        <LanguageIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        sx={{ pl: 2 }}
                                        primary={domain}
                                    />
                                </ListItem>
                            )
                        )}
                    </List>
                </Box>
                <Divider />
                <Box
                    pt={4}
                    pb={
                        props.config.designConfig.data.config.behaviour.autoOpen
                            .delay >= 0
                            ? 0
                            : 4
                    }
                    display="flex"
                    justifyContent="space-between"
                >
                    <Box pt={1.5}>
                        <Typography color="#003" variant="h6">
                            Auto open Chat
                        </Typography>
                    </Box>
                    <Box>
                        <Switch
                            onChange={() => {
                                updateDelay(
                                    props.config.designConfig.data.config
                                        .behaviour.autoOpen.delay >= 0
                                        ? -1
                                        : 0
                                );
                            }}
                            checked={
                                props.config.designConfig.data.config.behaviour
                                    .autoOpen.delay >= 0
                            }
                        />
                    </Box>
                </Box>
                {props.config.designConfig.data.config.behaviour.autoOpen
                    .delay >= 0 && (
                    <Box pb={4}>
                        <Box
                            py={1}
                            display="flex"
                            justifyContent="space-between"
                        >
                            <Box>
                                <Typography color="#003" variant="h6">
                                    Behavior
                                </Typography>
                                <Typography color="#8D8D8D" variant="subtitle2">
                                    How you want your chatbot to open
                                </Typography>
                            </Box>
                            <Box sx={{ width: 200 }}>
                                <SingleSelect
                                    getOptionText={(v: any) =>
                                        v ? v.value : ''
                                    }
                                    placeholder="Auto Open Behaviour"
                                    pt={3}
                                    pb={3}
                                    mt={0}
                                    options={AUTO_OPEN_BEHAVIOURS}
                                    selectedValue={
                                        AUTO_OPEN_BEHAVIOURS.filter(
                                            (v) =>
                                                v.id ==
                                                props.config.designConfig.data
                                                    .config.behaviour.autoOpen
                                                    .frequency
                                        )[0]
                                    }
                                    onChange={(v: any) => {
                                        updateAutoOpenBehaviour(v.id);
                                    }}
                                />
                            </Box>
                        </Box>

                        <Box
                            py={1}
                            display="flex"
                            justifyContent="space-between"
                        >
                            <Box>
                                <Typography color="#003" variant="h6">
                                    Delay duration
                                </Typography>
                                <Typography color="#8D8D8D" variant="subtitle2">
                                    Duration in seconds to wait before the chat
                                    shows up.
                                </Typography>
                            </Box>
                            <Box sx={{ width: 100 }}>
                                <TextField
                                    fullWidth
                                    type="number"
                                    onKeyDown={(evt) => {
                                        if (
                                            !['ArrowDown', 'ArrowUp'].includes(
                                                evt.code
                                            )
                                        )
                                            evt.preventDefault();
                                    }}
                                    value={
                                        props.config.designConfig.data.config
                                            .behaviour.autoOpen.delay
                                    }
                                    onChange={(evt) =>
                                        updateDelay(parseInt(evt.target.value))
                                    }
                                    InputProps={{
                                        inputProps: {
                                            max: 30,
                                            min: 0,
                                        },
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                )}
                <Divider />
            </Box>
            {props.config.widgetId && props.config.widgetId != 'new' && (
                <Box>
                    <Typography my={2} variant="h4">
                        Danger Zone
                    </Typography>

                    <Box py={4} display="flex" justifyContent="space-between">
                        <Box>
                            <Typography color="#003" variant="h6">
                                Disable
                            </Typography>
                            <Typography color="#8D8D8D" variant="subtitle2">
                                Temporary disable chatbot widget from your
                                website
                            </Typography>
                        </Box>
                        <Box>
                            <Switch
                                color="error"
                                onChange={(e) => updateStatus(e.target.checked)}
                                checked={props.config.status !== 'ACTIVE'}
                            />
                        </Box>
                    </Box>
                    <ConfirmationDialog
                        title={
                            'Chatbot will be disabled from your website temporarily!'
                        }
                        description=""
                        open={openDisable}
                        actionTitle="Disable"
                        onClose={() => setOpenDisable(false)}
                        onActionButtonClick={handleDisableConfirm}
                    />
                    <Box py={4} display="flex" justifyContent="space-between">
                        <Box>
                            <Typography color="#003" variant="h6">
                                Delete
                            </Typography>
                            <Typography color="#8D8D8D" variant="subtitle2">
                                Once you delete this chatbot there is no going
                                back. Please be certain
                            </Typography>
                        </Box>
                        <Box>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={() => setOpenDelete(true)}
                                startIcon={<DeleteIcon />}
                            >
                                Delete
                            </Button>
                        </Box>
                    </Box>
                    <ConfirmationDialog
                        title={
                            'Would you like to permanently delete your chatbot'
                        }
                        description="Once you delete this chatbot there is no going back. Please be certain. You can  “Disable” to remove your chatbot temporarily from your website."
                        open={openDelete}
                        icon={DeleteImg}
                        actionTitle="Delete"
                        onClose={() => setOpenDelete(false)}
                        onActionButtonClick={handleDeleteConfirm}
                    />
                </Box>
            )}
        </Box>
    );
};
