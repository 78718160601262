import React, { useEffect, useState } from 'react';

import {
    BarLineChart,
    BarLineChartProps,
} from '../../../../components/charts/bar-line-chart';
import { Box, Grid, Typography } from '@mui/material';
import {
    ReportModule,
    SentimentArticle,
} from '../../../../services/interfaces';
import { UtilsService } from '../../../../services/utils.service';
import { NavigationLink } from '../../../../components/core/link';
import { Circle } from '../../../../components/icons/circle';

interface IArticleProps {
    article: SentimentArticle;
}

export const Article = (props: IArticleProps) => {
    const [showMore, setShowMore] = useState(false);
    return (
        <Box>
            <Typography
                fontSize={14}
                fontWeight={600}
                sx={{ color: '#6e3cfb !important' }}
                target="_blank"
                variant="subtitle2"
                to={props.article.url}
                component={NavigationLink}
            >
                {props.article.title}
            </Typography>
            <Typography
                mt={1}
                className={showMore ? '' : 'clamp-line-4'}
                color="#4a4b4c"
            >
                {props.article.summary}
            </Typography>
            <Box justifyContent={'space-between'} display={'flex'}>
                <span
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <img
                        style={{
                            maxWidth: 14,
                            maxHeight: 14,
                            marginRight: 4,
                        }}
                        src={props.article.icon}
                    />
                    {props.article.sourceName}
                </span>
                <Typography
                    sx={{
                        cursor: 'pointer',
                        color: '#6e3cfb',
                    }}
                    onClick={() => setShowMore(!showMore)}
                >
                    See {showMore ? 'less' : 'more'}
                </Typography>
            </Box>
        </Box>
    );
};

interface IProps {
    data: ReportModule;
}

export const Sentiment = (props: IProps) => {
    const { data } = props;
    const [selectedDate, setSelectedDate] = useState('');
    const [sentimentData, setSentimentData] =
        useState<BarLineChartProps | null>(null);
    const [articles, setArticles] = useState<SentimentArticle[] | null>(null);
    const onBarClick = (date?: string) => {
        if (!data.sentimentData || !date) return;
        setSelectedDate(date);
        let _articles = data.sentimentData.data.articleSentiments.filter(
            (as) => as.date == date
        )[0];
        setArticles(_articles.results);
    };

    useEffect(() => {
        if (!data.sentimentData) return;
        if (data.sentimentData.data.articleSentiments) {
            const positiveSeries =
                data.sentimentData.data.articleSentiments.map((dateDetails) =>
                    dateDetails.averagePositive
                        ? dateDetails.averagePositive
                        : 0
                );
            const negativeSeries =
                data.sentimentData.data.articleSentiments.map((dateDetails) =>
                    dateDetails.averageNegative
                        ? -1 * dateDetails.averageNegative
                        : 0
                );
            let neutralSeries = data.sentimentData.data.articleSentiments.map(
                (dateDetails) =>
                    dateDetails.averageNegative && dateDetails.averagePositive
                        ? -1 * dateDetails.averageNegative +
                          dateDetails.averagePositive
                        : 0
            );

            neutralSeries = UtilsService.convertPlotLine(neutralSeries);

            const labels = data.sentimentData.data.articleSentiments.map(
                (t) => t.date
            );

            let sentimentData: BarLineChartProps = {
                series: [
                    {
                        name: 'Positive',
                        data: positiveSeries,
                    },
                    {
                        name: 'Negative',
                        data: negativeSeries,
                    },
                    {
                        name: 'Average',
                        data: neutralSeries,
                    },
                ],
                height: 400,
                labels: labels,
            };
            setSentimentData({ ...sentimentData });
            let lastArticleDate = data.sentimentData.data.articleSentiments
                .filter((a) => a.results.length > 0)
                .pop();
            onBarClick(lastArticleDate?.date);
        }
    }, [data]);
    return (
        <Box>
            <Grid container>
                <Grid item sm={7}>
                    {sentimentData && (
                        <BarLineChart
                            onBarClick={onBarClick}
                            showLinesOnly={false}
                            {...sentimentData}
                        />
                    )}
                </Grid>
                <Grid pl={2} item sm={5}>
                    <Box display="flex">
                        <Typography mr={2} variant="h6">
                            Top Articles
                        </Typography>
                        <Circle mt={10} h={6} w={6} />
                        <Typography
                            ml={2}
                            color="#4a4b4c"
                            mt={0.5}
                            fontSize={14}
                        >
                            {UtilsService.formatStringDate(selectedDate)}
                        </Typography>
                    </Box>
                    <Box mt={3}>
                        {articles &&
                            articles.map((a: SentimentArticle) => (
                                <Box>
                                    <Article article={a} />
                                </Box>
                            ))}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};
