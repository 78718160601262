import {
    Box,
    Button,
    Chip,
    CircularProgress,
    DialogActions,
    DialogContent,
    Grid,
    IconButton,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Add } from '../../../components/icons/add';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Check, EditOutlined } from '@mui/icons-material';
import {
    BootstrapDialogLarge,
    BootstrapDialogMedium,
    BootstrapDialogTitle,
} from '../../../components/core/dialog';
import { Domain, ITrustedDomain } from '../../../services/interfaces';
import { EditBoxed } from '../../../components/icons/edit-boxed';
import { Input } from '../../../components/core/input';
import { useSnackbar } from 'notistack';
import { Close, Edit } from '@mui/icons-material';
import { useAccountService } from '../../../services/account.service';

interface IProps {
    domainList?: ITrustedDomain;
    refreshList: () => Promise<void>;
}

export const UpsertDomainList = (props: IProps) => {
    const [open, setOpen] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [editTitle, setEditTitle] = useState(false);
    const { saveDomainList } = useAccountService();
    const [processing, setProcessing] = useState<boolean>(false);
    const [domainInput, setDomainInput] = useState('');
    const [updatedDomainListName, setUpdatedDomainListName] =
        React.useState('');
    const [data, setData] = useState<ITrustedDomain>(
        props.domainList || {
            displayName: 'List1',
            domains: [],
        }
    );
    const handleClose = () => {
        setOpen(false);
        setData({
            displayName: 'List1',
            domains: [],
        });
        setEditTitle(false);
    };

    const openDialog = () => {
        setEditTitle(false);
    };
    const editDiaplayNameClick = () => {
        setEditTitle(true);
        setUpdatedDomainListName(data.displayName);
    };

    const handleSave = async () => {
        setProcessing(true);
        await saveDomainList(data);
        await props.refreshList();
        setProcessing(false);
        setOpen(false);
        setEditTitle(false);
        setData({
            displayName: 'List1',
            domains: [],
        });
    };

    const addDomain = () => {
        if (domainInput) {
            try {
                if (
                    !data.domains.map((d) => d.domainName).includes(domainInput)
                ) {
                    setData({
                        ...data,
                        domains: [
                            ...data.domains,
                            {
                                domainName: domainInput,
                            },
                        ],
                    });
                }
                setDomainInput('');
            } catch (error) {
                enqueueSnackbar('invalid domain', {
                    variant: 'error',
                });
            }
        }
    };

    const handleUpdateTitleSubmit = () => {
        setData({
            ...data,
            displayName: updatedDomainListName,
        });

        setEditTitle(false);
        setUpdatedDomainListName('');
    };

    return (
        <Box>
            {props.domainList ? (
                <IconButton color="primary" onClick={() => setOpen(true)}>
                    <EditOutlinedIcon sx={{ fontSize: 18 }} />
                </IconButton>
            ) : (
                <Button
                    startIcon={<Add color="#fff" />}
                    sx={{ borderRadius: 2 }}
                    onClick={() => setOpen(true)}
                    variant="contained"
                >
                    Create new list
                </Button>
            )}
            <BootstrapDialogMedium
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                sx={{ p: 4 }}
            >
                <BootstrapDialogTitle id="customized-dialog-title">
                    {!editTitle && (
                        <Box
                            display="flex"
                            // justifyContent={'center'}
                            alignItems={'center'}
                        >
                            <Typography variant="h4">
                                {data.displayName}
                            </Typography>
                            <EditOutlined
                                sx={{
                                    cursor: 'pointer',
                                    ml: 3,
                                    color: '#6e3cfb',
                                    minHeight: '16px',
                                    minWidth: '16px',
                                }}
                                onClick={editDiaplayNameClick}
                            />
                        </Box>
                    )}
                    {editTitle && (
                        <Input
                            type="text"
                            mt={0}
                            pt={0}
                            noShadow
                            sx={{
                                pr: '8px !important',
                            }}
                            pb={0}
                            onEnterPress={handleUpdateTitleSubmit}
                            value={updatedDomainListName}
                            onChange={(e) => {
                                setUpdatedDomainListName(e.target.value);
                            }}
                            endAdornment={
                                <Button
                                    sx={{ px: 4, py: 1, fontSize: 14 }}
                                    variant="contained"
                                    onClick={handleUpdateTitleSubmit}
                                >
                                    {updatedDomainListName &&
                                    updatedDomainListName != data.displayName
                                        ? 'Update'
                                        : 'Cancel'}
                                </Button>
                            }
                            placeholder="Enter the group name"
                        />
                    )}
                </BootstrapDialogTitle>
                <DialogContent>
                    <Box pt={4} pb={2}>
                        <Input
                            type="text"
                            mt={0}
                            pt={0}
                            pb={0}
                            sx={{
                                pr: '8px !important',
                            }}
                            borderRadius={2}
                            value={domainInput}
                            onEnterPress={addDomain}
                            onChange={(e) => {
                                setDomainInput(e.target.value);
                            }}
                            endAdornment={
                                <Button
                                    sx={{ px: 4, fontSize: 12 }}
                                    variant="contained"
                                    onClick={addDomain}
                                >
                                    Add
                                </Button>
                            }
                            placeholder="Enter a domain e.g., cnn.com"
                        />
                    </Box>
                    <Box
                        className="scrollable"
                        sx={{
                            minHeight: 200,
                            maxHeight: 200,
                            overflow: 'auto',
                        }}
                    >
                        {data.domains.length > 0 && (
                            <Grid container>
                                {data.domains.map((domain: Domain, index) => (
                                    <Grid key={index} item>
                                        <Chip
                                            sx={{
                                                mr: 2,
                                                mt: 2,
                                                borderRadius: '25px',
                                                color: '#003',
                                                borderColor: '#003',
                                            }}
                                            label={domain.domainName}
                                            onDelete={() => {
                                                setData({
                                                    ...data,
                                                    domains:
                                                        data.domains.filter(
                                                            (d) =>
                                                                d.domainName !=
                                                                domain.domainName
                                                        ),
                                                });
                                            }}
                                            deleteIcon={
                                                <Close
                                                    color="primary"
                                                    sx={{
                                                        fill: '#6e3cfb',
                                                        height: '14px',
                                                        cursor: 'pointer',
                                                    }}
                                                />
                                            }
                                            variant="outlined"
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                    </Box>
                </DialogContent>
                <Box p={2} justifyContent={'space-between'} display="flex">
                    <Box>
                        <Typography color={'#4a4b4c'} variant="subtitle2">
                            Domains added
                        </Typography>
                        <Typography variant="h6">
                            {data.domains.length}
                        </Typography>
                    </Box>
                    <Box display={'flex'}>
                        <Button variant="text" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button
                            onClick={handleSave}
                            disabled={processing}
                            variant="contained"
                            sx={{
                                textTransform: 'none',
                                ml: '16px !important',
                                px: 2,
                                borderRadius: 2,
                            }}
                        >
                            {!processing && <span>Save</span>}
                            {processing && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        color: '#ffffff',
                                    }}
                                />
                            )}
                        </Button>
                    </Box>
                </Box>
            </BootstrapDialogMedium>
        </Box>
    );
};
