export const Expand = (
    <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M16.666 11.6667L19.6043 14.5789C21.9475 16.9013 23.1191 18.0625 23.2995 19.4779C23.3437 19.8246 23.3437 20.1755 23.2995 20.5223C23.1191 21.9376 21.9475 23.0988 19.6043 25.4212L16.666 28.3334"
            stroke="#6e3cfb"
            stroke-width="1.5"
            stroke-linecap="round"
        />
    </svg>
);
