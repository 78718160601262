import React, { useContext, useState } from 'react';
import { DocumentFile } from '../../../services/interfaces';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { File } from './file';
import {
    Box,
    Button,
    CircularProgress,
    DialogActions,
    DialogContent,
    IconButton,
    Tooltip,
    Typography,
    styled,
} from '@mui/material';
import { Tag } from '../../../components/icons/tag';
import { Share } from '../../../components/icons/share';
import { Delete } from '../../../components/icons/delete';
import { RemoveTag } from '../../../components/icons/remove-tag';
import { EditTag } from '../tags/edit-tags';
import { BootstrapDialogMedium } from '../../../components/core/dialog';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useDocumentService } from '../../../services/document.service';
import { useSnackbar } from 'notistack';
import { DocumentInsightsContext } from '../../../providers/document-provider';
import { TagLight } from '../../../components/icons/tag-light';

interface IProps {
    files: DocumentFile[];
    tab: number;
    hideDelete?: boolean;
    selectedFiles: DocumentFile[] | null;
    setSelectedFiles: (files: DocumentFile[] | null) => void;
}

const DialogActionButton = styled(Button)(() => ({
    textTransform: 'none',
    ml: '16px !important',
    borderRadius: 8,
}));

enum EACTION {
    SHARE = 'SHARE',
    DELETE = 'DELETE',
    REMOVE_TAG = 'REMOVE_TAG',
    NONE = 'NONE',
}

export const FilesWrapper = (props: IProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const documentContext = useContext(DocumentInsightsContext);
    const { files, tab, hideDelete, selectedFiles, setSelectedFiles } = props;
    const [editTags, setEditTags] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [currectAction, setCurrentAction] = useState<EACTION>(EACTION.NONE);
    const { shareMultipleFiles, untagMultipleFiles, deleteMultipleFiles } =
        useDocumentService();

    const shareFiles = async (fileIds: string[]) => {
        if (!documentContext) return;
        const res = await shareMultipleFiles(fileIds);
        if (res) {
            enqueueSnackbar('Selected documents shared successfully');
            documentContext.setRefreshCount(documentContext.refreshCount + 1);
        } else {
            enqueueSnackbar('Something went wrong!', {
                variant: 'error',
            });
        }
    };
    const deleteFiles = async (fileIds: string[]) => {
        if (!documentContext) return;
        const res = await deleteMultipleFiles(fileIds);
        if (res) {
            enqueueSnackbar('Selected documents delete successfully');
            documentContext.setRefreshCount(documentContext.refreshCount + 1);
        } else {
            enqueueSnackbar('Something went wrong!', {
                variant: 'error',
            });
        }
    };
    const untagFiles = async (fileIds: string[]) => {
        if (!documentContext) return;
        const res = await untagMultipleFiles(fileIds);
        if (res) {
            enqueueSnackbar('Selected documents tags removed successfully');
            documentContext.setRefreshCount(documentContext.refreshCount + 1);
        } else {
            enqueueSnackbar('Something went wrong!', {
                variant: 'error',
            });
        }
    };

    const submitAction = async () => {
        if (!selectedFiles) return;
        setIsProcessing(true);
        const fileIds = selectedFiles?.map((f) => f.userFileId);
        switch (currectAction) {
            case EACTION.SHARE:
                await shareFiles(fileIds);
                break;
            case EACTION.DELETE:
                await deleteFiles(fileIds);
                break;
            case EACTION.REMOVE_TAG:
                await untagFiles(fileIds);
                break;
        }
        setCurrentAction(EACTION.NONE);
        props.setSelectedFiles(null);
        setIsProcessing(false);
    };
    const onSelect = (file: DocumentFile) => {
        if (selectedFiles) {
            if (
                selectedFiles.map((f) => f.userFileId).includes(file.userFileId)
            ) {
                setSelectedFiles(
                    selectedFiles.filter((f) => f.userFileId != file.userFileId)
                );
            } else {
                setSelectedFiles([...selectedFiles, file]);
            }
        }
    };

    const handleAddTagsClick = () => {
        if (!selectedFiles || selectedFiles.length == 0) return;
        setEditTags(true);
    };

    const handleActionClick = (action: EACTION) => {
        if (!selectedFiles || selectedFiles.length == 0) return;
        setCurrentAction(action);
    };
    return (
        <Box sx={{ minHeight: 'calc(100vh - 197px)' }}>
            <BootstrapDialogMedium
                onClose={() => {
                    setCurrentAction(EACTION.NONE);
                }}
                aria-labelledby="customized-dialog-title"
                open={currectAction != EACTION.NONE}
                sx={{
                    p: 4,
                    textAlign: 'center',
                }}
            >
                <DialogContent>
                    {(currectAction == EACTION.REMOVE_TAG ||
                        currectAction == EACTION.DELETE) && (
                        <ErrorOutlineIcon
                            sx={{ color: '#D82F44', fontSize: 64 }}
                        />
                    )}
                    {currectAction == EACTION.SHARE && (
                        <Share color="#FF832B" size={64} />
                    )}

                    <Typography mt={4} variant="h3">
                        {currectAction == EACTION.REMOVE_TAG &&
                            'Remove all tags'}
                        {currectAction == EACTION.SHARE &&
                            'Share all the selected files'}
                        {currectAction == EACTION.DELETE &&
                            'Delete all the selected documents'}
                    </Typography>
                    <Typography color="#4B4B4C" mt={1} fontSize={14}>
                        {currectAction == EACTION.REMOVE_TAG &&
                            'Are you sure you want to remove all the tags from the selected documents?'}
                        {currectAction == EACTION.SHARE &&
                            'Are you sure you want to share all the selected documents with your organisations?'}
                        {currectAction == EACTION.DELETE &&
                            'Are you sure you want to delete all the selected documents?'}
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ mt: 4 }}>
                    <Button
                        variant="text"
                        onClick={() => {
                            setCurrentAction(EACTION.NONE);
                            setIsProcessing(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <DialogActionButton
                        variant="contained"
                        onClick={submitAction}
                        disabled={isProcessing}
                        color={
                            EACTION.SHARE == currectAction ? 'primary' : 'error'
                        }
                    >
                        {currectAction == EACTION.REMOVE_TAG && 'Remove'}
                        {currectAction == EACTION.SHARE && 'Share'}
                        {currectAction == EACTION.DELETE && 'Delete'}
                        {isProcessing && (
                            <CircularProgress
                                size={18}
                                sx={{
                                    color: '#ffffff',
                                    ml: 2,
                                }}
                            />
                        )}
                    </DialogActionButton>
                </DialogActions>
            </BootstrapDialogMedium>
            {selectedFiles && (
                <Box
                    pr={4}
                    pl={7}
                    display={'flex'}
                    justifyContent={'space-between'}
                    py={3}
                >
                    <Typography fontSize={12}>
                        Selected Files: {selectedFiles.length}
                    </Typography>
                    <Box display={'flex'}>
                        <Box sx={{ borderRight: '1px solid #D7D9EC' }} pr={6}>
                            <Tooltip
                                placement="top"
                                arrow
                                title="Add tags to selected documents"
                            >
                                <IconButton
                                    disabled={
                                        !selectedFiles ||
                                        selectedFiles.length == 0
                                    }
                                    onClick={handleAddTagsClick}
                                    sx={{
                                        p: 0,
                                        '&.Mui-disabled': {
                                            fillOpacity: 0.35,
                                        },
                                    }}
                                >
                                    {TagLight}
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Box sx={{ borderRight: '1px solid #D7D9EC' }} px={6}>
                            <Tooltip
                                arrow
                                placement="top"
                                title="Remove all tags form the selected documents"
                            >
                                <IconButton
                                    disabled={
                                        !selectedFiles ||
                                        selectedFiles.length == 0
                                    }
                                    onClick={() =>
                                        handleActionClick(EACTION.REMOVE_TAG)
                                    }
                                    sx={{
                                        p: 0,
                                        '&.Mui-disabled': {
                                            fillOpacity: 0.35,
                                        },
                                    }}
                                >
                                    <RemoveTag size={18} />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Box sx={{ borderRight: '1px solid #D7D9EC' }} px={6}>
                            <Tooltip
                                arrow
                                placement="top"
                                title="Share selected documents"
                            >
                                <IconButton
                                    disabled={
                                        !selectedFiles ||
                                        selectedFiles.length == 0
                                    }
                                    onClick={() =>
                                        handleActionClick(EACTION.SHARE)
                                    }
                                    sx={{
                                        p: 0,
                                        '&.Mui-disabled': {
                                            fillOpacity: 0.55,
                                        },
                                    }}
                                >
                                    <Share color="#6e3cfb" size={18} />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Box pl={6}>
                            <Tooltip
                                arrow
                                placement="top"
                                title="Delete selected documents"
                            >
                                <IconButton
                                    disabled={
                                        !selectedFiles ||
                                        selectedFiles.length == 0
                                    }
                                    onClick={() =>
                                        handleActionClick(EACTION.DELETE)
                                    }
                                    sx={{
                                        p: 0,
                                        '&.Mui-disabled': {
                                            fillOpacity: 0.35,
                                        },
                                    }}
                                >
                                    <Delete color="#6e3cfb" size={18} />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                </Box>
            )}
            {selectedFiles && selectedFiles.length > 0 && (
                <EditTag
                    onClose={() => setEditTags(false)}
                    onUpdate={() => props.setSelectedFiles(null)}
                    files={selectedFiles}
                    open={editTags}
                />
            )}
            {files.map((file, id) => (
                <File
                    file={file}
                    hideDelete={hideDelete}
                    onSelect={selectedFiles ? onSelect : undefined}
                    tab={tab}
                    selected={
                        selectedFiles
                            ? selectedFiles
                                  .map((f) => f.userFileId)
                                  .includes(file.userFileId)
                            : false
                    }
                    key={file.userFileId}
                />
            ))}
        </Box>
    );
};
