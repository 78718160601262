import React, { Ref, createRef, useState } from 'react';
import { BorderedBox } from '../../../../components/core/box';
import { Box, Button, Grid, Typography } from '@mui/material';
import Dropzone, { DropzoneRef } from 'react-dropzone';
import Slide from '@mui/material/Slide';
import { UtilsService } from '../../../../services/utils.service';
import { Close } from '../../../../components/icons/close';

interface IProps {
    onImageSelected?: (file: File) => void;
    imageUrl?: string;
    maxSizeKB: number;
}

const SUPPORTED_FORMATS = ['.jpeg', '.jpg', '.png', '.svg'];

export const IconPicker = (props: IProps) => {
    const [errorMessage, setErrorMessage] = useState('');

    const containerRef = React.useRef(null);
    const dropzoneRef: Ref<DropzoneRef> | undefined = createRef();
    const uploadDocument = (uploadFiles: File[]) => {
        setErrorMessage('');
        let file = uploadFiles[0];
        if (!file) return;
        let fileExt = '.' + UtilsService.getFileType(file.name).toLowerCase();
        if (!SUPPORTED_FORMATS.includes(fileExt)) {
            setErrorMessage(`${fileExt} not supported`);
            return;
        }
        if (file.size > props.maxSizeKB * 1000) {
            setErrorMessage(`Max file size allowed is 50KB`);
            return;
        }
        if (props.onImageSelected) {
            props.onImageSelected(file);
        }
    };
    return (
        <BorderedBox sx={{ backgroundColor: '#f9f5ff' }} p={2}>
            <Grid ref={containerRef} container>
                <Grid item sm={6}>
                    <Typography fontSize={14} color="#4B4B4C" fontWeight={400}>
                        Upload Image
                    </Typography>
                    <Box display="flex">
                        <Box
                            sx={{
                                width: 40,
                                height: 40,
                                textAlign: 'center',
                                backgroundColor: '#FCEEEF',
                            }}
                        >
                            {props.imageUrl && (
                                <img
                                    style={{
                                        width: 30,
                                        height: 30,
                                        marginTop: 5,
                                        backgroundColor: '#6e3cfb',
                                        borderRadius: '50%',
                                    }}
                                    src={props.imageUrl}
                                />
                            )}
                        </Box>
                        <Button
                            onClick={() => {
                                //@ts-ignore
                                dropzoneRef.current.open();
                            }}
                            variant="text"
                        >
                            {' '}
                            Change
                        </Button>
                    </Box>
                </Grid>
                <Slide
                    direction="up"
                    container={containerRef.current}
                    in={!!errorMessage}
                    mountOnEnter
                    unmountOnExit
                >
                    <Grid textAlign="right" item sm={6}>
                        <Box
                            sx={{
                                backgroundColor: '#D82F44',
                                color: '#fff',
                                pt: 2,
                                ml: 'auto',
                                pb: 1,
                                px: 4,
                                maxWidth: 85,
                                borderRadius: 1,
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography
                                mt={0.25}
                                lineHeight={'16px'}
                                color="#fff"
                            >
                                Error
                            </Typography>
                            <Box
                                sx={{ cursor: 'pointer', mt: 0.25 }}
                                onClick={() => setErrorMessage('')}
                            >
                                <Close color="#fff" size={18} />
                            </Box>
                        </Box>
                        <Box>
                            <Typography color="error">
                                {errorMessage}
                            </Typography>
                        </Box>
                    </Grid>
                </Slide>
            </Grid>
            <Dropzone
                multiple={false}
                ref={dropzoneRef}
                onDrop={uploadDocument}
            >
                {({ getRootProps, getInputProps }) => (
                    <Box
                        {...getRootProps()}
                        sx={{
                            backgroundColor: '#fff',
                            py: 2,
                            mt: 2,
                            cursor: 'pointer',
                            width: '100%',
                            textAlign: 'center',
                        }}
                    >
                        <input {...getInputProps()} />{' '}
                        <Typography>
                            Drag and Drop an image or Browse
                        </Typography>
                    </Box>
                )}
            </Dropzone>
            <Typography
                mt={1}
                fontSize={10}
                fontWeight={400}
                textTransform="uppercase"
                color="#8D8D8D"
            >
                Supported file types: JPG/JPEG/PNG/SVG | file size limit: 50kb
            </Typography>
        </BorderedBox>
    );
};
