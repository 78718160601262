interface IProps {
    size?: number;
}
export const GooglePatentsIcon = (props: IProps) => (
    <svg
        width={props.size || '20'}
        height={props.size || '20'}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <mask
            id="mask0_1278_55999"
            maskUnits="userSpaceOnUse"
            x="9"
            y="10"
            width="9"
            height="9"
        >
            <rect
                x="9.16992"
                y="10"
                width="8.33333"
                height="8.33333"
                fill="#D9D9D9"
            />
        </mask>
        <g mask="url(#mask0_1278_55999)">
            <path
                d="M12.6202 14.6074L12.8907 13.7113L12.1789 13.1892H13.0683L13.3407 12.2837L13.6132 13.1892H14.5026L13.7888 13.7113L14.0592 14.6074L13.3407 14.0512L12.6202 14.6074ZM11.6046 17.4493V15.161C11.3847 14.9446 11.214 14.6914 11.0924 14.4011C10.9709 14.1109 10.9102 13.8004 10.9102 13.4696C10.9102 12.7921 11.1459 12.2176 11.6173 11.7462C12.0887 11.2748 12.6632 11.0391 13.3407 11.0391C14.0182 11.0391 14.5927 11.2748 15.0641 11.7462C15.5356 12.2176 15.7713 12.7921 15.7713 13.4696C15.7713 13.8004 15.7105 14.1109 15.589 14.4011C15.4674 14.6914 15.2967 14.9446 15.0768 15.161V17.4493L13.3407 16.9285L11.6046 17.4493ZM13.3407 15.553C13.9194 15.553 14.4113 15.3504 14.8164 14.9453C15.2215 14.5402 15.424 14.0483 15.424 13.4696C15.424 12.8909 15.2215 12.399 14.8164 11.9939C14.4113 11.5888 13.9194 11.3863 13.3407 11.3863C12.762 11.3863 12.2701 11.5888 11.865 11.9939C11.4599 12.399 11.2574 12.8909 11.2574 13.4696C11.2574 14.0483 11.4599 14.5402 11.865 14.9453C12.2701 15.3504 12.762 15.553 13.3407 15.553ZM11.9518 16.9572L13.3407 16.5679L14.7296 16.9572V15.4535C14.536 15.5959 14.3208 15.706 14.0842 15.7837C13.8476 15.8613 13.5998 15.9002 13.3407 15.9002C13.0816 15.9002 12.8338 15.8613 12.5972 15.7837C12.3606 15.706 12.1455 15.5959 11.9518 15.4535V16.9572Z"
                fill="#6e3cfb"
            />
        </g>
        <mask
            id="mask1_1278_55999"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="20"
            height="20"
        >
            <rect width="20" height="20" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask1_1278_55999)">
            <path
                d="M4.16599 3.33396V8.48621V8.46217V16.6673V3.33396ZM6.04102 11.4589H8.94324C9.06397 11.2196 9.19965 10.9972 9.35029 10.7915C9.50093 10.5858 9.66652 10.3917 9.84706 10.209H6.04102V11.4589ZM6.04102 14.7923H8.3551C8.32411 14.584 8.30729 14.3756 8.30462 14.1673C8.30194 13.959 8.31342 13.7506 8.33906 13.5423H6.04102V14.7923ZM4.42243 17.9173C4.00149 17.9173 3.64518 17.7714 3.35352 17.4798C3.06185 17.1881 2.91602 16.8318 2.91602 16.4109V3.5904C2.91602 3.16946 3.06185 2.81315 3.35352 2.52148C3.64518 2.22982 4.00149 2.08398 4.42243 2.08398H11.041L15.416 6.45894V8.68013C15.2162 8.61816 15.0135 8.57169 14.8078 8.54072C14.6022 8.50973 14.3882 8.49156 14.166 8.48621V7.08394H10.416V3.33396H4.42243C4.35832 3.33396 4.29955 3.36067 4.24612 3.41409C4.1927 3.46752 4.16599 3.52629 4.16599 3.5904V16.4109C4.16599 16.475 4.1927 16.5337 4.24612 16.5872C4.29955 16.6406 4.35832 16.6673 4.42243 16.6673H8.96729C9.08801 16.9066 9.22502 17.1291 9.37833 17.3348C9.53163 17.5404 9.70124 17.7346 9.88714 17.9173H4.42243Z"
                fill="#6e3cfb"
            />
        </g>
    </svg>
);
