import { Box, Divider, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BorderedBox } from '../../../../components/core/box';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useParams } from 'react-router-dom';
import { useFinancialsService } from '../../../../services/financials.service';
import {
    FinancialInsiderTradingResponseData,
    FinancialInsiderTradingResponseDataElement,
    InsiderTransaction,
    InstitutionOwnership,
    ReportModule,
} from '../../../../services/interfaces';
import { UtilsService } from '../../../../services/utils.service';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        padding: '10px 16px',
        borderBottom: 'none',
        textAlign: 'center',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        border: 'none',
        padding: '10px 16px',
        textAlign: 'center',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: '#f9f5ff',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

interface IProps {
    data: ReportModule;
}

export const InsiderTrading = (props: IProps) => {
    const [data, setData] = useState<InsiderTransaction[] | null>(null);

    useEffect(() => {
        if (props.data.data?.yahoo?.insiderTransactions)
            setData(props.data.data?.yahoo.insiderTransactions);
    }, [data]);

    return (
        <BorderedBox sx={{ backgroundColor: '#FFFFFF' }} pt={3}>
            <Box>
                <Typography px={4} variant="h6">
                    Insider Trading
                </Typography>
                <Box pt={2}>
                    <Divider />
                </Box>
                <Box pb={3}>
                    {' '}
                    <TableContainer style={{ maxHeight: 600 }}>
                        <Table
                            stickyHeader
                            sx={{ minWidth: 700 }}
                            aria-label="customized table"
                        >
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell
                                        sx={{ textAlign: 'left !important' }}
                                    >
                                        Insider
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        Transaction
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        Type
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        Value
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        Date
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        Shares
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data?.map((row: InsiderTransaction, index) => (
                                    <StyledTableRow key={index}>
                                        <StyledTableCell
                                            component="th"
                                            scope="row"
                                            sx={{
                                                textAlign: 'left !important',
                                            }}
                                        >
                                            <Box>
                                                <Typography fontWeight={600}>
                                                    {row.filerName}
                                                </Typography>
                                                <Typography>
                                                    {row.filerRelation}
                                                </Typography>
                                            </Box>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            {row.transactionText}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            {row.ownershipType == 'I'
                                                ? 'Indirect'
                                                : 'Direct'}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            {row.shareValue
                                                ? row.shareValue.toLocaleString(
                                                      'en',
                                                      {
                                                          minimumFractionDigits: 0,
                                                      }
                                                  )
                                                : 'NA'}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            {UtilsService.formatStringDate(
                                                row.transactionDate
                                            )}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            {row.shareVolume}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </BorderedBox>
    );
};
