import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BorderedBox } from '../../../components/core/box';
import InitialsAvator from '../../../components/core/avator';
import {
    EmployeeDetail,
    LinkedInCertification,
    LinkedInEducation,
    LinkedInExperience,
    LinkedInProject,
    LinkedInPublication,
    ReportModule,
} from '../../../services/interfaces';
import { NewsImagePlaceholder } from '../../../components/images/news-image-placeholder';
import { UtilsService } from '../../../services/utils.service';

interface IProps {
    data: ReportModule;
}

export const ManagementTeam = (props: IProps) => {
    const [selected, setSelected] = useState<EmployeeDetail | null>(null);
    const { data } = props;
    const placeholderImage =
        'https://images.unsplash.com/photo-1511285605577-4d62fb50d2f7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=876&q=80';

    const onImageError = (e: any, name: string) => {
        e.target.src =
            'https://ui-avatars.com/api/?font-size=.35&color=ffffff&background=c7c7cb&name=' +
            name;
    };
    useEffect(() => {
        if (!selected && data && data.employeeDetails?.length) {
            setSelected(data?.employeeDetails[0]);
        }
    }, [props.data]);
    return (
        <BorderedBox
            sx={{
                backgroundColor: '#f9f5ff',
            }}
            p={4}
            my={4}
        >
            <Typography variant="h4">Management Team</Typography>
            <Grid container>
                <Grid item sm={4}>
                    <Box
                        className="scrollable"
                        sx={{
                            maxHeight: 'calc(100vh - 220px)',
                            overflow: 'auto',
                        }}
                        pr={2}
                    >
                        {data?.employeeDetails?.map(
                            (e: EmployeeDetail, index) => (
                                <Box
                                    key={index}
                                    onClick={() => setSelected(e)}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        my: 2,
                                        px: 1,
                                        py: 3,
                                        cursor: 'pointer',
                                        borderRadius: 1,
                                        border:
                                            selected?.employeeName ==
                                            e.employeeName
                                                ? '1px solid #ccc'
                                                : 'none',
                                        backgroundColor:
                                            selected?.employeeName ==
                                            e.employeeName
                                                ? '#FFFFFF'
                                                : '#f9f5ff',
                                    }}
                                >
                                    {e.profilePicture ? (
                                        <Box
                                            component="img"
                                            sx={{
                                                width: 48,
                                                height: 48,
                                                borderRadius: 2,
                                            }}
                                            onError={(event) =>
                                                onImageError(
                                                    event,
                                                    e.employeeName
                                                )
                                            }
                                            src={`data:image/png;base64,${e.profilePicture}`}
                                        />
                                    ) : (
                                        <Box
                                            sx={{
                                                width: 48,
                                                height: 48,
                                                borderRadius: 2,
                                                backgroundColor: '#c7c7cb',
                                            }}
                                        >
                                            <InitialsAvator
                                                height={48}
                                                width={48}
                                                fontSize={16}
                                                backgroundColor="#c7c7cb"
                                                value={e.employeeName}
                                            />
                                        </Box>
                                    )}
                                    <Box pl={2}>
                                        <Typography
                                            fontSize={14}
                                            fontWeight={600}
                                        >
                                            {e.employeeName}
                                        </Typography>
                                        <Typography
                                            color="#4B4B4C"
                                            fontSize={12}
                                        >
                                            {e.role}
                                        </Typography>
                                    </Box>
                                </Box>
                            )
                        )}
                    </Box>
                </Grid>
                <Grid sm={8}>
                    {selected && (
                        <Box
                            className="scrollable"
                            sx={{
                                maxHeight: 'calc(100vh - 220px)',
                                overflow: 'auto',
                            }}
                            pl={2}
                        >
                            <Typography fontSize={14} fontWeight={600}>
                                {selected.employeeName}
                            </Typography>
                            <Typography color="#4B4B4C" fontSize={12}>
                                {selected.role}
                            </Typography>
                            <Typography mt={2} fontSize={14}>
                                {selected.employeeSummary}
                            </Typography>
                            {selected.linkedInData?.profile.experiences &&
                                selected.linkedInData?.profile.experiences
                                    .length > 0 && (
                                    <Box>
                                        <Typography pb={2} variant="h6">
                                            Experience
                                        </Typography>
                                        {selected.linkedInData?.profile.experiences.map(
                                            (exp: LinkedInExperience) => (
                                                <Box pb={2} display="flex">
                                                    <Box>
                                                        {exp.companyLogo ? (
                                                            <Box
                                                                sx={{
                                                                    height: 32,
                                                                    width: 32,
                                                                    backgroundColor:
                                                                        '#fff',
                                                                    borderRadius: 2,
                                                                }}
                                                                onError={(
                                                                    event
                                                                ) =>
                                                                    onImageError(
                                                                        event,
                                                                        exp.companyName
                                                                    )
                                                                }
                                                                component={
                                                                    'img'
                                                                }
                                                                src={
                                                                    exp.companyLogo
                                                                }
                                                            ></Box>
                                                        ) : (
                                                            <Box>
                                                                <NewsImagePlaceholder
                                                                    size={32}
                                                                />
                                                            </Box>
                                                        )}
                                                    </Box>
                                                    <Box pl={2}>
                                                        <Typography
                                                            fontSize={14}
                                                            fontWeight={600}
                                                        >
                                                            {exp.roleTitle}
                                                        </Typography>
                                                        <Box display="flex">
                                                            <Typography
                                                                fontSize={12}
                                                                mr={2}
                                                                pr={2}
                                                                sx={{
                                                                    borderRight:
                                                                        '1px solid #ccc',
                                                                }}
                                                            >
                                                                {
                                                                    exp.companyName
                                                                }
                                                            </Typography>

                                                            {exp.startsAt && (
                                                                <Typography
                                                                    fontSize={
                                                                        12
                                                                    }
                                                                >
                                                                    {UtilsService.formatStringDateWithoutDay(
                                                                        `${exp.startsAt.month}/${exp.startsAt.day}/${exp.startsAt.year}`
                                                                    )}
                                                                    -
                                                                    {exp.endsAt
                                                                        ? UtilsService.formatStringDateWithoutDay(
                                                                              `${exp.endsAt.month}/${exp.endsAt.day}/${exp.endsAt.year}`
                                                                          )
                                                                        : 'Present'}
                                                                </Typography>
                                                            )}
                                                        </Box>

                                                        <Typography
                                                            fontSize={12}
                                                            color="#4B4B4C"
                                                        >
                                                            {
                                                                exp.roleDescription
                                                            }
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            )
                                        )}
                                    </Box>
                                )}
                            {selected.linkedInData?.profile.education &&
                                selected.linkedInData?.profile.education
                                    .length > 0 && (
                                    <Box>
                                        <Typography variant="h6" pb={2}>
                                            Education
                                        </Typography>
                                        {selected.linkedInData?.profile.education.map(
                                            (edu: LinkedInEducation) => (
                                                <Box pb={2} component="li">
                                                    <Typography
                                                        fontWeight={600}
                                                        component="span"
                                                    >
                                                        {edu.instituteName}
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            ml: 2,
                                                            pl: 2,
                                                            borderLeft:
                                                                '1px solid #ccc',
                                                        }}
                                                        component="span"
                                                    >
                                                        {edu.courseName}
                                                    </Typography>
                                                    {edu.startsAt && (
                                                        <Typography
                                                            sx={{
                                                                ml: 2,
                                                                pl: 2,
                                                                borderLeft:
                                                                    '1px solid #ccc',
                                                            }}
                                                            component="span"
                                                        >
                                                            {edu.startsAt.year}
                                                            {edu.endsAt
                                                                ? ' - ' +
                                                                  edu.endsAt
                                                                      .year
                                                                : ''}
                                                        </Typography>
                                                    )}
                                                </Box>
                                            )
                                        )}
                                    </Box>
                                )}
                            {selected.linkedInData?.profile.publications &&
                                selected.linkedInData?.profile.publications
                                    .length > 0 && (
                                    <Box>
                                        <Typography variant="h6" pb={2}>
                                            Publications
                                        </Typography>
                                        {selected.linkedInData?.profile.publications.map(
                                            (pub: LinkedInPublication) => (
                                                <Box pb={2} component="li">
                                                    <Typography
                                                        fontWeight={600}
                                                        component="span"
                                                    >
                                                        {pub.name}
                                                    </Typography>
                                                </Box>
                                            )
                                        )}
                                    </Box>
                                )}
                            {selected.linkedInData?.profile.projects &&
                                selected.linkedInData?.profile.projects.length >
                                    0 && (
                                    <Box>
                                        <Typography variant="h6" pb={2}>
                                            Projects
                                        </Typography>
                                        {selected.linkedInData?.profile.projects.map(
                                            (p: LinkedInProject) => (
                                                <Box pb={2} component="li">
                                                    <Typography
                                                        fontWeight={600}
                                                        component="span"
                                                    >
                                                        {p.title}
                                                    </Typography>
                                                </Box>
                                            )
                                        )}
                                    </Box>
                                )}
                            {selected.linkedInData?.profile.certifications &&
                                selected.linkedInData?.profile.certifications
                                    .length > 0 && (
                                    <Box>
                                        <Typography variant="h6" pb={2}>
                                            Certifications
                                        </Typography>
                                        {selected.linkedInData?.profile.certifications.map(
                                            (c: LinkedInCertification) => (
                                                <Box pb={2} component="li">
                                                    <Typography
                                                        fontWeight={600}
                                                        component="span"
                                                    >
                                                        {c.name}
                                                    </Typography>
                                                </Box>
                                            )
                                        )}
                                    </Box>
                                )}
                        </Box>
                    )}
                </Grid>
            </Grid>
        </BorderedBox>
    );
};
