import styled from '@emotion/styled';
import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { SemiRadialChart } from '../../../../../components/charts/semi-radial';
import { BorderedBox, ContainedBox } from '../../../../../components/core/box';
import { NavigationLink } from '../../../../../components/core/link';
import { Circle } from '../../../../../components/icons/circle';
import { DateInsights } from '../../../../../components/insights/date-insights';
import { TextHighlight } from '../../../../../components/sentiment-highlights';
import { AppContext } from '../../../../../providers/app-provider';
import {
    Granularity,
    useInsightsService,
} from '../../../../../services/insights.service';
import {
    IDetailedInformation,
    Insight,
} from '../../../../../services/interfaces';
import { UtilsService } from '../../../../../services/utils.service';

const AssesmentOption = styled(Box)(() => ({
    padding: '10px 30px',
    backgroundColor: '#F5F5FA',
    textAlign: 'center',
    width: '100%',
    borderLeft: '1px solid',
    borderColor: '#6e3cfb',
}));
const AssesmentNumber = styled(Box)(() => ({
    width: 20,
    paddingTop: 10,
    textAlign: 'center',
}));

interface IProps {
    date: string;
    topicSubscriptionId?: string;
    barClickCount: number;
    granularity?: Granularity;
    renderDetails?: (
        _topicSubscriptionId: string,
        _date: string,
        _id: string,
        _summaryId: number
    ) => void;
}

export const TopicInsights = (props: IProps) => {
    const { topicSubscriptionId } = props;
    const { fetchDetailedInsights } = useInsightsService();
    const [data, setData] = useState<IDetailedInformation | null>();
    const [insights, setInsights] = useState<Insight[] | null>([]);
    const myRef = useRef(null);
    const appContext = useContext(AppContext);
    const executeScroll = () => {
        //@ts-ignore
        myRef?.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const refreshInsights = async () => {
        if (props.date && topicSubscriptionId) {
            setData(null);
            setInsights(null);
            let d = await fetchDetailedInsights(
                [topicSubscriptionId],
                props.granularity || Granularity.DAILY,
                1,
                props.date
            );
            setData(d.data[0]);
            setInsights(d.data[0]?.insights || []);
        }
    };

    useEffect(() => {
        if (props.barClickCount > 0) setTimeout(executeScroll, 0);
    }, [props.barClickCount]);

    const getSentimentAverage = () => {
        if (data?.sentiment.positive && data?.sentiment.negative) {
            return data?.sentiment.positive - data?.sentiment.negative;
        } else {
            return 0;
        }
    };

    useEffect(() => {
        refreshInsights();
    }, [props.date, topicSubscriptionId, appContext?.category]);

    return (
        <Box>
            <Box py={4}>
                <Box my={4} display="flex">
                    <Box>
                        <Typography variant="h4">
                            {
                                UtilsService.formatStringDate(props.date).split(
                                    ','
                                )[0]
                            }
                            {
                                UtilsService.formatStringDate(props.date).split(
                                    ','
                                )[1]
                            }
                        </Typography>
                    </Box>
                    <Box ml={5}>
                        <Circle mt={8} h={12} w={12} />
                    </Box>
                    <Box ml={5}>
                        <Typography display="flex" variant="h4">
                            {insights && <>{insights.length} </>}
                            {!insights && (
                                <Skeleton
                                    width={30}
                                    sx={{ transform: 'scale(1,1)' }}
                                    variant="text"
                                />
                            )}
                            Insights
                        </Typography>
                    </Box>
                </Box>
                <Divider />
                <Box mt={3}>
                    <DateInsights
                        renderDetails={props.renderDetails}
                        data={data}
                        insights={insights}
                        topicSubscriptionId={topicSubscriptionId}
                    />
                </Box>
            </Box>
            <Box ref={myRef}>
                {data && (
                    <BorderedBox>
                        <ContainedBox>
                            <Grid spacing={2} container>
                                <Grid xs={12} md={5} item>
                                    <Box>
                                        <Typography variant="h4">
                                            Sentiment of the{' '}
                                            {props.granularity ==
                                            Granularity.WEEKLY
                                                ? 'week'
                                                : 'day'}
                                        </Typography>
                                        <Grid
                                            sx={{
                                                backgroundColor: '#FFFAEC',
                                                border: '1px solid',
                                                borderColor: '#E3E3E3',
                                                padding: '12px',
                                            }}
                                            container
                                        >
                                            <Grid py={4} xs={6} item>
                                                <Typography
                                                    color="#4B4B4C"
                                                    variant="h4"
                                                >
                                                    {props.granularity ==
                                                    Granularity.WEEKLY
                                                        ? 'Weekly'
                                                        : 'Daily'}{' '}
                                                    Assesment
                                                </Typography>
                                                <Typography variant="h3">
                                                    {getSentimentAverage() > 0.5
                                                        ? 'Positive'
                                                        : getSentimentAverage() <
                                                          -0.5
                                                        ? 'Negative'
                                                        : 'Neutral'}
                                                </Typography>
                                            </Grid>
                                            <Grid xs={6} item>
                                                <Box display="flex">
                                                    <AssesmentNumber
                                                        sx={{
                                                            color: '#19AF55',
                                                        }}
                                                    >
                                                        1
                                                    </AssesmentNumber>
                                                    <AssesmentOption
                                                        sx={{
                                                            backgroundColor:
                                                                getSentimentAverage() >
                                                                0.5
                                                                    ? '#19AF55 !important'
                                                                    : '',
                                                        }}
                                                    >
                                                        Positive
                                                    </AssesmentOption>
                                                </Box>
                                                <Box display="flex">
                                                    <AssesmentNumber
                                                        sx={{
                                                            color: '#FCDC83',
                                                        }}
                                                    >
                                                        0
                                                    </AssesmentNumber>
                                                    <AssesmentOption
                                                        sx={{
                                                            backgroundColor:
                                                                getSentimentAverage() <
                                                                    0.5 &&
                                                                getSentimentAverage() >
                                                                    -0.5
                                                                    ? '#FCDC83 !important'
                                                                    : '',
                                                        }}
                                                    >
                                                        Neutral
                                                    </AssesmentOption>
                                                </Box>
                                                <Box display="flex">
                                                    <AssesmentNumber
                                                        sx={{
                                                            color: '#D82F44',
                                                        }}
                                                    >
                                                        -1
                                                    </AssesmentNumber>
                                                    <AssesmentOption
                                                        sx={{
                                                            backgroundColor:
                                                                getSentimentAverage() <
                                                                -0.5
                                                                    ? '#D82F44 !important'
                                                                    : '',
                                                        }}
                                                    >
                                                        Negative
                                                    </AssesmentOption>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid xs={12} md={7} item>
                                    <Grid pt={10} spacing={2} container>
                                        <Grid xs={4} item>
                                            {data?.sentiment.positive && (
                                                <SemiRadialChart
                                                    value={UtilsService.convertSentimentToPercentage(
                                                        data.sentiment.positive
                                                    )}
                                                    height={200}
                                                    color="#19AF55"
                                                />
                                            )}
                                            <Typography
                                                variant="h4"
                                                sx={{
                                                    color: '#A5A6AB',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                Positive
                                            </Typography>
                                        </Grid>
                                        <Grid xs={4} item>
                                            {data?.sentiment.negative && (
                                                <SemiRadialChart
                                                    value={UtilsService.convertSentimentToPercentage(
                                                        data.sentiment.negative
                                                    )}
                                                    height={200}
                                                    color="#D82F44"
                                                />
                                            )}
                                            <Typography
                                                variant="h4"
                                                sx={{
                                                    color: '#A5A6AB',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                Negative
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            xs={4}
                                            sx={{ textAlign: 'center' }}
                                            item
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: 80,
                                                    lineHeight: '105px',
                                                }}
                                            >
                                                {data?.mentions}
                                            </Typography>
                                            <Typography
                                                variant="h4"
                                                sx={{ color: '#A5A6AB' }}
                                            >
                                                Articles Count
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </ContainedBox>
                    </BorderedBox>
                )}
            </Box>
        </Box>
    );
};
