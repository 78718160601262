import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Switch,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Input } from '../../../../components/core/input';
import { RangeInput } from '../../../../components/core/input/rangeInput';
import DeleteIcon from '@mui/icons-material/Delete';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
//@ts-ignore
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { HtmlEditor } from '../../../../components/core/input/html-editor';
import {
    IChatbotConfig,
    IChatbotConfigData,
    IChatbotPredefinedQuestionConfig,
    IChatbotWelcomeMessageConfig,
    IMessage,
} from '../../../../services/interfaces';
import { Delete } from '../../../../components/icons/delete';
import { FilterUnchecked } from '../../../../components/icons/filter-unchecked';
import { FilterChecked } from '../../../../components/icons/filter-checked';
import { UtilsService } from '../../../../services/utils.service';
interface IProps {
    config: IChatbotConfig;
    updateConfig: (config: IChatbotConfig) => void;
}

const FONT_FAMILY_OPTIONS = [
    'open-sans',
    'sans-serif',
    'monospace',
    'cursive',
    'fantasy',
    'system-ui',
];

export const ChatBotTextSettings = (props: IProps) => {
    const [welcomeMessgaes, setWelcomeMessages] = useState<
        IChatbotWelcomeMessageConfig[]
    >([...props.config.designConfig.data.config.text.welcomeMessages]);
    const [predefinedQuestions, setPredefinedQuestions] = useState<
        IChatbotPredefinedQuestionConfig[] | undefined
    >(
        props.config.designConfig.data.config.text.predefinedQuestions
            ? [
                  ...props.config.designConfig.data.config.text
                      .predefinedQuestions,
              ]
            : undefined
    );

    const [errorMessage, setErrorMessage] = useState<string | undefined>(
        props.config.dataSourceConfig.customErrorMessage
    );

    const updateConfig = (config: IChatbotConfigData) => {
        props.updateConfig({
            ...props.config,
            designConfig: {
                data: {
                    version: props.config.designConfig.data.version,
                    config: {
                        ...config,
                    },
                },
            },
        });
    };
    const updateFontSize = (
        event: Event,
        value: number | number[],
        activeThumb: number
    ) => {
        if (typeof value == 'number')
            updateConfig({
                ...props.config.designConfig.data.config,
                appearance: {
                    ...props.config.designConfig.data.config.appearance,
                    font: {
                        ...props.config.designConfig.data.config.appearance
                            .font,
                        fontSize: value,
                    },
                },
            });
    };
    const updateFontFamily = (event: SelectChangeEvent) => {
        let value = event.target.value as string;
        updateConfig({
            ...props.config.designConfig.data.config,
            appearance: {
                ...props.config.designConfig.data.config.appearance,
                font: {
                    ...props.config.designConfig.data.config.appearance.font,
                    fontFamily: value,
                },
            },
        });
    };

    const updateChatbotTitle = (value: string) => {
        updateConfig({
            ...props.config.designConfig.data.config,
            text: {
                ...props.config.designConfig.data.config.text,
                title: value,
            },
        });
    };

    const updateChatbotInputPlaceholderText = (value: string) => {
        updateConfig({
            ...props.config.designConfig.data.config,
            text: {
                ...props.config.designConfig.data.config.text,
                inputPlaceholder: value,
            },
        });
    };

    const updateChatbotErrorText = (value: string) => {
        props.updateConfig({
            ...props.config,
            dataSourceConfig: {
                ...props.config.dataSourceConfig,
                customErrorMessage: UtilsService.extractContentFromHtmlString(
                    value
                )
                    ? value
                    : undefined,
            },
        });
    };

    const addWelcomeMessage = () => {
        let updatedMessages: IChatbotWelcomeMessageConfig[] = [
            ...props.config.designConfig.data.config.text.welcomeMessages,
            {
                order: welcomeMessgaes.length + 1,
                message: {
                    messageID: welcomeMessgaes.length + 1 + '',
                    vote: 'neutral',
                    isBookmarked: false,
                    messageType: 'INCOMING',
                    data: {
                        answer: `<p></p>`,
                    },
                },
            },
        ];
        updateConfig({
            ...props.config.designConfig.data.config,
            text: {
                ...props.config.designConfig.data.config.text,
                welcomeMessages: [...updatedMessages],
            },
        });
        setWelcomeMessages([...updatedMessages]);
    };

    const updateChatbotWelcomeMessages = (
        value: string,
        order: number,
        message: IMessage
    ) => {
        let updatedMessages: IChatbotWelcomeMessageConfig[] = [
            ...welcomeMessgaes.filter((m) => m.order != order),
            {
                order: order,
                message: {
                    messageID: welcomeMessgaes.length + 1 + '',
                    vote: 'neutral',
                    isBookmarked: false,
                    messageType: message.messageType,
                    data: {
                        answer: message.data.answer
                            ? value.split('\n').join('')
                            : undefined,
                        query: message.data.query
                            ? value.split('\n').join('')
                            : undefined,
                    },
                },
            },
        ];
        updateConfig({
            ...props.config.designConfig.data.config,
            text: {
                ...props.config.designConfig.data.config.text,
                welcomeMessages: [...updatedMessages],
            },
        });
    };

    const deleteChatbotWelcomeMessages = (order: number) => {
        let updatedMessages: IChatbotWelcomeMessageConfig[] = [
            ...welcomeMessgaes
                .filter((m) => m.order != order)
                .map((value, index) => {
                    value.order = index;
                    return value;
                }),
        ];
        updateConfig({
            ...props.config.designConfig.data.config,
            text: {
                ...props.config.designConfig.data.config.text,
                welcomeMessages: [...updatedMessages],
            },
        });
        setWelcomeMessages([...updatedMessages]);
    };

    const addPredefinedQuestion = () => {
        if (!props.config.designConfig.data.config.text.predefinedQuestions) {
            props.config.designConfig.data.config.text.predefinedQuestions = [];
        }
        let updatedPredefinedQuestions: IChatbotPredefinedQuestionConfig[] = [
            ...props.config.designConfig.data.config.text.predefinedQuestions,
            {
                order: predefinedQuestions ? predefinedQuestions.length + 1 : 1,
                question: '',
                answer: `<p></p>`,
                useDocumentsForGeneratingAnswer: false,
            },
        ];
        updateConfig({
            ...props.config.designConfig.data.config,
            text: {
                ...props.config.designConfig.data.config.text,
                predefinedQuestions: [...updatedPredefinedQuestions],
            },
        });
        setPredefinedQuestions([...updatedPredefinedQuestions]);
    };

    const updatePredefinedQuestions = (
        data?: IChatbotPredefinedQuestionConfig[]
    ) => {
        setPredefinedQuestions(data);

        updateConfig({
            ...props.config.designConfig.data.config,
            text: {
                ...props.config.designConfig.data.config.text,
                predefinedQuestions: data,
            },
        });
    };

    const updateChatbotPrefedinedQuestionQuery = (
        question: string,
        order: number,
        data: IChatbotPredefinedQuestionConfig
    ) => {
        if (!props.config.designConfig.data.config.text.predefinedQuestions) {
            props.config.designConfig.data.config.text.predefinedQuestions = [];
        }
        let updatedPredefinedQuestions: IChatbotPredefinedQuestionConfig[] = [
            ...props.config.designConfig.data.config.text.predefinedQuestions.filter(
                (m) => m.order != order
            ),
            {
                order: order,
                question: question,
                useDocumentsForGeneratingAnswer:
                    data.useDocumentsForGeneratingAnswer,
                answer: data.answer,
            },
        ];
        setPredefinedQuestions([...updatedPredefinedQuestions]);
        updateConfig({
            ...props.config.designConfig.data.config,
            text: {
                ...props.config.designConfig.data.config.text,
                predefinedQuestions: [...updatedPredefinedQuestions],
            },
        });
    };

    const updateChatbotPrefedinedQuestionAnswer = (
        value: string | undefined,
        order: number,
        data: IChatbotPredefinedQuestionConfig,
        hardRefresh?: boolean
    ) => {
        if (!props.config.designConfig.data.config.text.predefinedQuestions) {
            props.config.designConfig.data.config.text.predefinedQuestions = [];
        }
        let updatedPredefinedQuestions: IChatbotPredefinedQuestionConfig[] = [
            ...props.config.designConfig.data.config.text.predefinedQuestions.filter(
                (m) => m.order != order
            ),
            {
                order: order,
                question: data.question,
                useDocumentsForGeneratingAnswer: value ? false : true,
                answer: value,
            },
        ];

        updateConfig({
            ...props.config.designConfig.data.config,
            text: {
                ...props.config.designConfig.data.config.text,
                predefinedQuestions: [...updatedPredefinedQuestions],
            },
        });
        if (hardRefresh) {
            setPredefinedQuestions([...updatedPredefinedQuestions]);
        }
    };

    useEffect(() => {}, [
        predefinedQuestions,
        props.config.designConfig.data.config.text.predefinedQuestions,
    ]);

    const deletePredefinedQuestion = (order: number) => {
        let updatedPredefinedQuestions: IChatbotPredefinedQuestionConfig[] = (
            predefinedQuestions ? predefinedQuestions : []
        )
            .filter((m) => m.order != order)
            .map((value, index) => {
                value.order = index;
                return value;
            });
        updateConfig({
            ...props.config.designConfig.data.config,
            text: {
                ...props.config.designConfig.data.config.text,
                predefinedQuestions: [...updatedPredefinedQuestions],
            },
        });
        setPredefinedQuestions([...updatedPredefinedQuestions]);
    };
    return (
        <Box>
            <Box>
                <Typography my={2} variant="h6">
                    Header Title
                </Typography>
                <Input
                    mt={0}
                    pt={0}
                    pb={0}
                    backgroundColor="#fff"
                    type="text"
                    onChange={(e) => {
                        updateChatbotTitle(e.target.value.slice(0, 100));
                    }}
                    value={props.config.designConfig.data.config.text.title}
                    placeholder="Chatbot 1"
                />
            </Box>
            <Box pt={2}>
                <Typography my={2} variant="h6">
                    Font
                </Typography>
                <Box>
                    <RangeInput
                        label="Size"
                        defaultValue={
                            props.config.designConfig.data.config.appearance
                                .font.fontSize
                        }
                        min={8}
                        onChange={updateFontSize}
                        max={24}
                        valueLabelFormat={(value: number) => value + 'px'}
                        marks={[
                            {
                                value: 8,
                                label: '8px',
                            },
                            {
                                value: 24,
                                label: '24px',
                            },
                        ]}
                    />
                </Box>
                <Box>
                    <Grid
                        container
                        sx={{
                            py: 2,
                            px: 4,
                            mt: 1,
                            borderRadius: 2,
                            backgroundColor: '#f9f5ff',
                            border: '1px solid #D7D9EC',
                        }}
                    >
                        <Grid sm={2} pt={1.5} item>
                            <Typography fontSize={14} fontWeight={400}>
                                Style
                            </Typography>
                        </Grid>
                        <Grid sm={10} px={4} item>
                            <FormControl fullWidth>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={
                                        props.config.designConfig.data.config
                                            .appearance.font.fontFamily
                                    }
                                    label="Age"
                                    sx={{
                                        fontFamily: `${props.config.designConfig.data.config.appearance.font.fontFamily} !important`,
                                        backgroundColor: '#fff',
                                    }}
                                    onChange={updateFontFamily}
                                >
                                    {FONT_FAMILY_OPTIONS.map(
                                        (value: string) => (
                                            <MenuItem
                                                sx={{
                                                    fontFamily: `${value} !important`,
                                                }}
                                                value={value}
                                            >
                                                {value}
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box pt={2}>
                <Typography my={2} variant="h6">
                    Welcome Text
                </Typography>
                <Box
                    sx={{
                        border: '1px solid #D7D9EC',
                        borderRadius: 2,
                        px: 2,
                        pt: 1,
                        pb: 3,
                        backgroundColor: '#f9f5ff',
                    }}
                >
                    {welcomeMessgaes
                        .sort((a, b) => (a.order < b.order ? -1 : 1))
                        .map((message, index) => (
                            <Box
                                sx={{
                                    py: 1,
                                    borderRadius: 2,
                                    backgroundColor: '#f9f5ff',
                                    display: 'flex',
                                }}
                            >
                                <Box sx={{ width: 'calc(100% - 28px)' }}>
                                    {message.message.data.answer && (
                                        <HtmlEditor
                                            content={
                                                message.message.data.answer
                                            }
                                            onContentChange={(html: string) => {
                                                updateChatbotWelcomeMessages(
                                                    html,
                                                    message.order,
                                                    message.message
                                                );
                                            }}
                                        />
                                    )}
                                    {message.message.data.query && (
                                        <HtmlEditor
                                            content={message.message.data.query}
                                            onContentChange={(html: string) => {
                                                updateChatbotWelcomeMessages(
                                                    html,
                                                    message.order,
                                                    message.message
                                                );
                                            }}
                                        />
                                    )}
                                </Box>
                                <Box
                                    sx={{ width: '28px', textAlign: 'center' }}
                                >
                                    <IconButton
                                        sx={{ p: 0 }}
                                        onClick={() =>
                                            deleteChatbotWelcomeMessages(
                                                message.order
                                            )
                                        }
                                        aria-label="delete"
                                    >
                                        <Delete size={30} color="#D82F44" />
                                    </IconButton>
                                </Box>
                            </Box>
                        ))}
                    {welcomeMessgaes.length < 4 && (
                        <Box sx={{ textAlign: 'center' }}>
                            <Button
                                sx={{ mt: 2, px: 6, borderRadius: 2 }}
                                variant="contained"
                                onClick={() => addWelcomeMessage()}
                                startIcon={<AddRoundedIcon />}
                            >
                                Add More
                            </Button>
                        </Box>
                    )}
                </Box>
            </Box>
            <Box pt={4} pb={2}>
                <Divider />
            </Box>
            <Box>
                <Box display="flex" justifyContent={'space-between'}>
                    <Typography my={2} variant="h6">
                        Predefined Questions
                    </Typography>
                    <Box>
                        <Switch
                            onChange={() => {
                                updatePredefinedQuestions(
                                    props.config.designConfig.data.config.text
                                        .predefinedQuestions
                                        ? undefined
                                        : [
                                              {
                                                  question: 'Question',
                                                  answer: '<p>Answer</p>',
                                                  order: 1,
                                                  useDocumentsForGeneratingAnswer:
                                                      false,
                                              },
                                          ]
                                );
                            }}
                            checked={
                                !!props.config.designConfig.data.config.text
                                    .predefinedQuestions
                            }
                        />
                    </Box>
                </Box>
                {predefinedQuestions && (
                    <Box
                        sx={{
                            border: '1px solid #D7D9EC',
                            borderRadius: 2,
                            px: 2,
                            pt: 1,
                            pb: 3,
                            backgroundColor: '#f9f5ff',
                        }}
                    >
                        {predefinedQuestions
                            .sort((a, b) => (a.order < b.order ? -1 : 1))
                            .map((message, index) => (
                                <Box
                                    sx={{
                                        py: 1,
                                        mt: index > 0 ? 2 : 0,
                                        backgroundColor: '#f9f5ff',
                                        borderTop:
                                            index > 0 ? '1px solid #ccc' : '',
                                    }}
                                >
                                    <Box>
                                        <Typography my={2} variant="h6">
                                            Query {index + 1}
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width: 'calc(100% - 28px)',
                                                }}
                                            >
                                                <Input
                                                    mt={0}
                                                    pt={0}
                                                    pb={0}
                                                    onChange={(e) => {
                                                        updateChatbotPrefedinedQuestionQuery(
                                                            e.target.value,
                                                            message.order,
                                                            message
                                                        );
                                                    }}
                                                    value={message.question}
                                                    backgroundColor="#fff"
                                                    type="text"
                                                    placeholder="Type your question here..."
                                                />
                                            </Box>
                                            <Box
                                                sx={{
                                                    width: '28px',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <IconButton
                                                    sx={{ p: 0, mt: 3 }}
                                                    onClick={() =>
                                                        deletePredefinedQuestion(
                                                            message.order
                                                        )
                                                    }
                                                    aria-label="delete"
                                                >
                                                    <Delete
                                                        size={30}
                                                        color="#D82F44"
                                                    />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <FormControlLabel
                                        sx={{
                                            fontSize: 12,
                                            marginLeft: 0,
                                        }}
                                        control={
                                            <Checkbox
                                                size="small"
                                                icon={FilterUnchecked}
                                                onClick={() =>
                                                    updateChatbotPrefedinedQuestionAnswer(
                                                        message.answer
                                                            ? undefined
                                                            : '<p></p>',
                                                        message.order,
                                                        message,
                                                        true
                                                    )
                                                }
                                                checked={
                                                    message.answer
                                                        ? false
                                                        : true
                                                }
                                                checkedIcon={FilterChecked}
                                            />
                                        }
                                        label={
                                            <Typography
                                                variant="caption"
                                                color="textSecondary"
                                            >
                                                Use Knowledgebase for generating
                                                answers
                                            </Typography>
                                        }
                                    />
                                    {message.answer && (
                                        <Box>
                                            <Typography my={2} variant="h6">
                                                Response
                                            </Typography>
                                            {message.answer && (
                                                <HtmlEditor
                                                    content={message.answer}
                                                    onContentChange={(
                                                        html: string
                                                    ) => {
                                                        updateChatbotPrefedinedQuestionAnswer(
                                                            html,
                                                            message.order,
                                                            message
                                                        );
                                                    }}
                                                />
                                            )}
                                        </Box>
                                    )}
                                </Box>
                            ))}
                        {welcomeMessgaes.length < 4 && (
                            <Box sx={{ textAlign: 'center' }}>
                                <Button
                                    sx={{ mt: 2, px: 6, borderRadius: 2 }}
                                    variant="contained"
                                    onClick={() => addPredefinedQuestion()}
                                    startIcon={<AddRoundedIcon />}
                                >
                                    Add More
                                </Button>
                            </Box>
                        )}
                    </Box>
                )}
            </Box>
            <Box pt={4} pb={2}>
                <Divider />
            </Box>
            <Box>
                <Typography my={2} variant="h6">
                    Custom Error Message (Optional)
                </Typography>
                <HtmlEditor
                    content={errorMessage || '<p></p>'}
                    placeholder={`Message to be displayed in case of error.`}
                    onContentChange={updateChatbotErrorText}
                />
            </Box>
            <Box pt={4} pb={2}>
                <Divider />
            </Box>
            <Box>
                <Typography my={2} variant="h6">
                    Input Placeholder Text
                </Typography>
                <Input
                    mt={0}
                    pt={0}
                    pb={0}
                    onChange={(e) => {
                        updateChatbotInputPlaceholderText(
                            e.target.value.slice(0, 100)
                        );
                    }}
                    value={
                        props.config.designConfig.data.config.text
                            .inputPlaceholder
                    }
                    backgroundColor="#fff"
                    type="text"
                    placeholder="Type your question here..."
                />
            </Box>
        </Box>
    );
};
