import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { Skeleton } from '@mui/material';
import { UtilsService } from '../../services/utils.service';

export interface PlotProps {
    series: {
        name: string;
        data: (number | null)[];
    }[];
    labels: string[];
}
export interface Props extends PlotProps {
    cssClass?: string;
    showLinesOnly?: boolean;
    onBarClick?: (date: string) => void;
}

// colors: ['#19AF55', '#D82F44', '#6e3cfb'],

export const BarChart = (props: Props) => {
    const positive = props.series.filter((s: any) => s.name == 'Revenue')[0]
        ?.data;
    const negative = props.series.filter((s: any) => s.name == 'Earnings')[0]
        ?.data;
    const series = [
        {
            name: 'Revenue',
            type: 'column',
            data: positive,
        },
        {
            name: 'Earnings',
            type: 'column',
            data: negative,
        },
    ];
    const options: ApexOptions = {
        chart: {
            animations: {
                enabled: true,
                easing: 'linear',
                speed: 200,
                animateGradually: {
                    enabled: true,
                    delay: 0,
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 200,
                },
            },
            events: {
                dataPointSelection: (event, chartContext, config) => {
                    setTimeout(() => {
                        if (
                            props.labels[config.dataPointIndex] &&
                            props.series.filter(
                                (s: any) => s.name == 'Average'
                            )[0] &&
                            props.series.filter(
                                (s: any) => s.name == 'Average'
                            )[0].data[config.dataPointIndex] &&
                            props.onBarClick
                        ) {
                            props.onBarClick(
                                props.labels[config.dataPointIndex]
                            );
                        }
                    }, 10);
                },
            },
            height: 100,
            sparkline: {
                enabled: false,
            },
            stacked: false,
            toolbar: {
                show: !props.showLinesOnly,
                tools: {
                    download: !props.showLinesOnly,
                    selection: !props.showLinesOnly,
                    zoom: !props.showLinesOnly,
                    zoomin: !props.showLinesOnly,
                    zoomout: !props.showLinesOnly,
                    pan: !props.showLinesOnly,
                    reset: !props.showLinesOnly,
                    customIcons: [],
                },
            },
        },

        legend: {
            show: true,
        },
        colors: ['#19AF55', '#535FFF'],
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 2,
                borderRadiusApplication: 'end',
                borderRadiusWhenStacked: 'all',
                columnWidth: '60%',
                barHeight: '70%',
                distributed: false,
                rangeBarOverlap: true,
                rangeBarGroupRows: false,
            },
        },
        dataLabels: {
            enabled: true,
            offsetY: -10,
            style: {
                fontSize: '12px',
                colors: ['#304758'],
            },
            formatter: function (val: string, opts) {
                if (opts.dataPointIndex == 0) return 'NA';
                if (
                    opts.config.series[opts.seriesIndex].data[
                        opts.dataPointIndex
                    ] == 0
                ) {
                    return 'NA';
                }
                return (
                    UtilsService.getChangePercentage(
                        opts.config.series[opts.seriesIndex].data[
                            opts.dataPointIndex - 1
                        ],
                        opts.config.series[opts.seriesIndex].data[
                            opts.dataPointIndex
                        ]
                    ) + '%'
                );
            },
        },
        markers: {
            size: [0, 0, 1],
            colors: undefined,
            strokeColors: '#6e3cfb',
            strokeWidth: 2,
            strokeOpacity: 0.9,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [],
            shape: 'circle',
            radius: 2,
            offsetX: 0,
            offsetY: 0,
            onClick: undefined,
            onDblClick: undefined,
            showNullDataPoints: true,
            hover: {
                size: undefined,
                sizeOffset: 3,
            },
        },
        stroke: {
            width: [0, 0, 2],
            colors: ['#008FFB'],
            curve: 'smooth',
        },
        fill: { opacity: 1 },
        grid: {
            xaxis: {
                lines: {
                    show: false,
                },
            },
            yaxis: {
                lines: {
                    show: false,
                },
            },
        },
        yaxis: {
            axisBorder: {
                show: true,
            },
            axisTicks: {
                show: true,
            },
            labels: {
                show: true,
                formatter: function (val, opts): string {
                    if (val == 0) {
                        return 'NA';
                    }
                    return UtilsService.convertToInternationalCurrencySystem(
                        val
                    );
                },
            },
        },

        tooltip: {
            enabled: true,
            // custom: function({series, seriesIndex, dataPointIndex, w}) {
            //     return '<div class="arrow_box">' +
            //       '<span>' + series[seriesIndex][dataPointIndex] + '</span>' +
            //       '</div>'
            //   },
            marker: {
                show: true,
            },
            x: {
                show: true,
            },
        },
        xaxis: {
            categories: props.labels,

            tooltip: {
                enabled: false,
            },
        },
        // xaxis: {
        //     categories: [],
        //     labels: {
        //         show: false,
        //     },
        //     axisBorder: {
        //         show: false,
        //     },
        //     axisTicks: {
        //         show: false,
        //     },
        // },
    };

    return (
        <div style={{ maxWidth: '100%' }}>
            <Chart options={options} series={series} height={310} />
        </div>
    );
};
