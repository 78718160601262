interface IProps {
    height: string;
    width: string;
}
export const MiniLogo = (props: IProps) => (
    <svg
        width={props.width || 20}
        height={props.height || 20}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="20" height="20" />
        <path
            d="M17.2653 7.46488H5.97437C5.86716 7.46488 5.85546 7.37006 5.94513 7.25337L8.26472 4.21151C8.35049 4.09676 8.47767 4.02091 8.61899 4H19.9109C20.0176 4 20.0298 4.09481 19.9402 4.21151L17.6206 7.25045C17.5352 7.36546 17.408 7.44245 17.2663 7.46488H17.2653ZM16.4237 8.73005H5.08064C4.93932 8.75193 4.81116 8.82778 4.72393 8.94205L2.39459 11.9645C2.38514 11.9783 2.37699 11.9929 2.37023 12.0082L0.0603834 15.0063C-0.0287942 15.1206 -0.0166114 15.2105 0.0798758 15.2178H4.70444L2.49498 12.1765H13.7664C13.9082 12.1536 14.0364 12.0768 14.1231 11.9625L16.4525 8.93719C16.5422 8.83508 16.5305 8.73054 16.4232 8.73054L16.4237 8.73005Z"
            fill="#6E479B"
        />
        <path
            d="M1.75391 12.2171L1.75634 12.2195V12.2171H1.75391ZM2.49656 12.1758L6.66501 12.1855L4.707 15.2172L2.49656 12.1758Z"
            fill="#1E172A"
        />
    </svg>
);
