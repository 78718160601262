import { useEffect } from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { UtilsService } from '../../services/utils.service';
export interface AreaLineChartProps {
    series: {
        name: string;
        data: number[];
    }[];
    stockMin: number;
    stockMax: number;
    labels: string[];
    cssClass?: string;
    height: number;
    showLinesOnly?: boolean;
    onBarClick?: (date: string) => void;
}

// colors: ['#19AF55', '#D82F44', '#6e3cfb'],

export const AreaLineChart = (props: AreaLineChartProps) => {
    const floorNumber = (num: number, sign: number) => {
        num = num * sign;
        num = Math.floor(num);
        return num / sign;
    };
    const positive = props.series.filter((s: any) => s.name == 'Positive')[0]
        ?.data;
    const negative = props.series.filter((s: any) => s.name == 'Negative')[0]
        ?.data;
    const stock = props.series.filter((s: any) => s.name == 'Stock')[0]?.data;
    const series = [
        {
            name: 'Positive',
            type: 'area',
            data: positive,
        },
        {
            name: 'Negative',
            type: 'area',
            data: negative.map((n, i) => floorNumber(n - positive[i], 100)),
        },
        {
            name: 'Stock Price',
            type: 'line',
            data: stock,
        },
    ];
    const options: ApexOptions = {
        chart: {
            height: props.height,
            stacked: true,
            type: 'area',
            events: {
                dataPointSelection: (event, chartContext, config) => {
                    setTimeout(() => {
                        if (
                            props.labels[config.dataPointIndex] &&
                            props.onBarClick
                        ) {
                            props.onBarClick(
                                props.labels[config.dataPointIndex]
                            );
                        }
                    }, 10);
                },
            },
            toolbar: {
                show: !props.showLinesOnly,
                tools: {
                    download: !props.showLinesOnly,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                    customIcons: [],
                },
            },
        },

        legend: {
            show: true,
        },
        colors: ['#19AF55', '#D82F44', '#6e3cfb'],
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 2,
                borderRadiusApplication: 'end',
                borderRadiusWhenStacked: 'all',
                columnWidth: '30%',
                barHeight: '70%',
                distributed: false,
                rangeBarOverlap: true,
                rangeBarGroupRows: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        markers: {
            size: [0, 0, 1],
            colors: undefined,
            strokeColors: '#6e3cfb',
            strokeWidth: 2,
            strokeOpacity: 0.9,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [],
            shape: 'circle',
            radius: 2,
            offsetX: 0,
            offsetY: 0,
            onClick: undefined,
            onDblClick: undefined,
            showNullDataPoints: true,
            hover: {
                size: undefined,
                sizeOffset: 3,
            },
        },
        stroke: {
            width: [0, 0, 2],
            colors: ['#6e3cfb'],
            curve: ['straight', 'straight', 'smooth'],
        },
        fill: {
            type: 'gradient',
            gradient: {
                inverseColors: true,
                shade: 'light',
                type: 'vertical',
                shadeIntensity: 0.5,
                opacityFrom: 1,
                opacityTo: 0,
                colorStops: [
                    [
                        {
                            offset: 0,
                            color: '#19AF55',
                            opacity: 1,
                        },
                        {
                            offset: 0.6,
                            color: '#cccccc',
                            opacity: 50,
                        },
                        {
                            offset: 100,
                            color: '#19AF55',
                            opacity: 0,
                        },
                    ],
                    [
                        {
                            offset: 0,
                            color: '#D82F44',
                            opacity: 0,
                        },
                        {
                            offset: 50,
                            color: '#D82F44',
                            opacity: 0.5,
                        },
                        {
                            offset: 100,
                            color: '#D82F44',
                            opacity: 0.5,
                        },
                    ],
                    [
                        {
                            offset: 0,
                            color: '#6e3cfb',
                            opacity: 1,
                        },
                    ],
                ],
            },
        },
        grid: {
            xaxis: {
                lines: {
                    show: true,
                },
            },
            yaxis: {
                lines: {
                    show: true,
                },
            },
        },
        yaxis: [
            {
                min: -1,
                max: 1,
                labels: {
                    show: true,
                    formatter: (x, y) => {
                        if (y?.seriesIndex == 1) {
                            let val = x + positive[y.dataPointIndex];
                            val = floorNumber(val, 100);
                            return val.toString();
                        }
                        x = floorNumber(x, 10);
                        return x.toString();
                    },
                },
            },
            {
                min: -1,
                max: 1,
                labels: {
                    show: false,
                    formatter: (x, y) => {
                        if (y?.seriesIndex == 1) {
                            let val = x + positive[y.dataPointIndex];
                            val = floorNumber(val, 100);
                            return val.toString();
                        }
                        x = floorNumber(x, 10);
                        return x.toString();
                    },
                },
            },
            {
                min: props.stockMin,
                max: props.stockMax,
                opposite: true,
                labels: {
                    show: true,
                    formatter: (x, y) => {
                        if (y?.seriesIndex == 1) {
                            let val = x + positive[y.dataPointIndex];
                            val = floorNumber(val, 100);
                            return val.toString();
                        }
                        x = floorNumber(x, 10);
                        return x.toString();
                    },
                },
            },
        ],

        tooltip: {
            enabled: true,
            // custom: function({series, seriesIndex, dataPointIndex, w}) {
            //     return '<div class="arrow_box">' +
            //       '<span>' + series[seriesIndex][dataPointIndex] + '</span>' +
            //       '</div>'
            //   },
            marker: {
                show: true,
            },
            x: {
                show: true,
            },
        },
        xaxis: {
            categories: props.labels,
            labels: {
                formatter: function (val) {
                    return UtilsService.formatStringDateWithoutYear(val);
                },
            },
        },
    };

    return (
        <div>
            <Chart options={options} series={series} height={props.height} />
        </div>
    );
};
