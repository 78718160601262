import { useState } from 'react';
import {
    Cluster,
    Topic,
    UpsertClusterRequest,
} from '../../../../services/interfaces';
import { updateGroup, createGroup } from '../../../../analytics/analytics';
import { useClusterService } from '../../../../services/cluster.service';
import { useSnackbar } from 'notistack';
import { Check, Close, EditOutlined } from '@mui/icons-material';
import {
    Box,
    Button,
    CircularProgress,
    Divider,
    Modal,
    Typography,
} from '@mui/material';
import { BorderedBox } from '../../../../components/core/box';
import { Input } from '../../../../components/core/input';
import { AvailableTopicsContainer } from '../../../../components/group/available-topics-container';
import { SelectedTopicsContainer } from '../../../../components/group/selected-topics-container';
import { Add } from '../../../../components/icons/add';

interface IProps {
    cluster?: Cluster;
    refreshClusters: () => void;
}
export const AddOrEditWatchlist = (props: IProps) => {
    const [open, setOpen] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [editClusterName, setEditClusterName] = useState<boolean>(false);
    const [updatedClusterName, setUpdatedClusterName] = useState<string>('');
    const [selectedTopics, setSelectedTopics] = useState<Topic[]>([]);
    const [clusterName, setClusterName] = useState<string>('');
    const [editCluster, setEditCluster] = useState<Cluster>({
        clusterId: '',
        clusterName: '',
        topics: [],
    });

    let cluster = props.cluster;
    const { enqueueSnackbar } = useSnackbar();
    const { upsertCluster } = useClusterService();

    const handleOpen = () => {
        if (cluster) {
            setSelectedTopics(cluster.topics);
            setEditCluster(cluster);
        }
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setClusterName('');
        setEditClusterName(false);
        setUpdatedClusterName('');
        setSelectedTopics([]);
        setEditCluster({
            clusterId: '',
            clusterName: '',
            topics: [],
        });
    };
    const editClusterNameClick = () => {
        setUpdatedClusterName(editCluster.clusterName);
        setEditClusterName(true);
    };

    const handleEditNameClose = () => {
        setEditClusterName(false);
        setUpdatedClusterName(editCluster.clusterName);
    };

    const handleUpdateCluster = async () => {
        if (!editCluster.clusterName) return;
        if (!editCluster.clusterId) {
            createGroup(editCluster.clusterName);
        } else {
            updateGroup(editCluster.clusterName, editCluster.clusterId);
        }

        const req: UpsertClusterRequest = {
            dashboardClusterId: editCluster.clusterId
                ? editCluster.clusterId
                : undefined,
            dashboardClusterName: editCluster.clusterName,
            topicSubscriptionIds: selectedTopics.map(
                (topicItem: Topic) => topicItem.topicSubscriptionId
            ),
        };
        setProcessing(true);
        let res = await upsertCluster(req);
        setProcessing(false);
        if (res.success) {
            handleClose();
            await props.refreshClusters();
            enqueueSnackbar(
                `Watchlist ${
                    editCluster.clusterId ? 'Updated' : 'Created'
                }  successfully!`
            );
        }
    };

    const addTopic = (topic: Topic) =>
        setSelectedTopics([topic, ...selectedTopics]);

    const removeTopic = (topic: Topic) =>
        setSelectedTopics([
            ...selectedTopics.filter(
                (selectedTopic: Topic) =>
                    selectedTopic.topicSubscriptionId !=
                    topic.topicSubscriptionId
            ),
        ]);
    return (
        <>
            {cluster && (
                <EditOutlined
                    sx={{
                        color: '#6e3cfb',
                        cursor: 'pointer',
                        mx: 6,
                    }}
                    onClick={handleOpen}
                />
            )}
            {!cluster && (
                <Button
                    sx={{ px: 9, fontWeight: 600, borderRadius: '8px' }}
                    variant="contained"
                    onClick={() => {
                        setOpen(true);
                    }}
                >
                    <Box mr={2} pt={0.5}>
                        <Add size={12} color="#FFFFFF" />
                    </Box>
                    Watchlist
                </Button>
            )}

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ backdropFilter: 'blur(2px)' }}
            >
                <BorderedBox
                    sx={{
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 600,
                        bgcolor: '#FFFFFF',
                        p: 5,
                    }}
                >
                    {editCluster.clusterName && (
                        <Box>
                            {!editClusterName && (
                                <Box
                                    display="flex"
                                    // justifyContent={'center'}
                                    alignItems={'center'}
                                >
                                    <Typography variant="h4">
                                        {editCluster.clusterName}
                                    </Typography>
                                    <EditOutlined
                                        sx={{
                                            cursor: 'pointer',
                                            ml: 3,
                                            color: '#6e3cfb',
                                            minHeight: '16px',
                                            minWidth: '16px',
                                        }}
                                        onClick={editClusterNameClick}
                                    />
                                </Box>
                            )}
                            {editClusterName && (
                                <Input
                                    type="text"
                                    mt={0}
                                    value={updatedClusterName}
                                    onChange={(e) =>
                                        setUpdatedClusterName(e.target.value)
                                    }
                                    endAdornment={
                                        <Box display="flex">
                                            <Check
                                                sx={{
                                                    color: '#24A148',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                    setEditCluster({
                                                        ...editCluster,
                                                        clusterName:
                                                            updatedClusterName,
                                                    });
                                                    setEditClusterName(false);
                                                }}
                                            />
                                            <Close
                                                sx={{
                                                    color: '#DA1E28',
                                                    cursor: 'pointer',
                                                    ml: 2,
                                                }}
                                                onClick={() =>
                                                    handleEditNameClose()
                                                }
                                            />
                                        </Box>
                                    }
                                    placeholder="Enter the group name"
                                />
                            )}
                            <AvailableTopicsContainer
                                selectedTopics={selectedTopics}
                                addTopic={addTopic}
                            />
                            <Divider sx={{ my: 3 }} />
                            <SelectedTopicsContainer
                                topics={selectedTopics}
                                handleDelete={removeTopic}
                            />
                            <Box display="flex" justifyContent="space-between">
                                <Box>
                                    <Typography
                                        variant="subtitle2"
                                        color="rgba(75, 75, 76, 0.70)"
                                    >
                                        Topics Added
                                    </Typography>
                                    <Typography variant="h6">
                                        {selectedTopics.length}
                                    </Typography>
                                </Box>
                                <Box display="flex" justifyContent="right">
                                    <Button
                                        sx={{ color: '#4B4B4C', mr: 3 }}
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        disabled={
                                            selectedTopics.length == 0 ||
                                            processing
                                        }
                                        variant="contained"
                                        onClick={() => handleUpdateCluster()}
                                    >
                                        Save
                                        {processing && (
                                            <CircularProgress
                                                size={14}
                                                sx={{ color: '#fff', ml: 1 }}
                                            />
                                        )}
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    )}
                    {!editCluster.clusterName && (
                        <Box>
                            <Typography variant="h4">
                                Create new watchlist
                            </Typography>

                            <Typography variant="h6" mb={1} mt={4}>
                                Name your watchlist
                            </Typography>
                            <Input
                                type="text"
                                mt={2}
                                value={clusterName}
                                onChange={(e) => {
                                    setClusterName(e.target.value);
                                }}
                                placeholder="Watchlist 1"
                            />

                            <Box display="flex" mt={8} justifyContent="right">
                                <Button
                                    sx={{ color: '#4B4B4C', mr: 3 }}
                                    onClick={() => handleClose()}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    disabled={!clusterName}
                                    onClick={() =>
                                        setEditCluster({
                                            ...editCluster,
                                            clusterName: clusterName
                                                ? clusterName
                                                : '',
                                        })
                                    }
                                    sx={{ minWidth: '80px' }}
                                >
                                    Next
                                </Button>
                            </Box>
                            <Close
                                sx={{
                                    position: 'absolute',
                                    top: 24,
                                    right: 24,
                                    color: '#6e3cfb',
                                    cursor: 'pointer',
                                }}
                                onClick={handleClose}
                            />
                        </Box>
                    )}
                </BorderedBox>
            </Modal>
        </>
    );
};
