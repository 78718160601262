export const TagLight = (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <mask
            id="mask0_4851_221957"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="20"
            height="20"
        >
            <rect width="20" height="20" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_4851_221957)">
            <path
                d="M11.594 17.5411C11.3542 17.7804 11.0571 17.9 10.7026 17.9C10.3482 17.9 10.0513 17.7804 9.81197 17.5411L2.47863 10.2078C2.35791 10.087 2.2631 9.94808 2.1942 9.79088C2.12528 9.6337 2.09082 9.46604 2.09082 9.2879V3.32957C2.09082 2.98876 2.21395 2.69524 2.46022 2.44899C2.70647 2.20272 2.99999 2.07959 3.3408 2.07959H9.29913C9.47113 2.07959 9.63589 2.11316 9.79341 2.1803C9.95093 2.24742 10.0875 2.33778 10.203 2.45138L17.5363 9.79755C17.781 10.0422 17.9046 10.3411 17.9073 10.6942C17.9099 11.0472 17.7916 11.3434 17.5523 11.5828L11.594 17.5411ZM10.6994 16.6629L16.6613 10.7046L9.30272 3.32957H3.3408V9.2879L10.6994 16.6629ZM5.4232 6.45455C5.71228 6.45455 5.95831 6.35337 6.1613 6.15101C6.36429 5.94865 6.46578 5.70292 6.46578 5.41384C6.46578 5.12476 6.3646 4.87872 6.16224 4.67574C5.95988 4.47275 5.71415 4.37126 5.42507 4.37126C5.13599 4.37126 4.88995 4.47244 4.68697 4.6748C4.48398 4.87716 4.38249 5.12288 4.38249 5.41197C4.38249 5.70105 4.48367 5.94708 4.68603 6.15007C4.88839 6.35305 5.13411 6.45455 5.4232 6.45455Z"
                fill="#6e3cfb"
            />
        </g>
    </svg>
);
