import {
    Box,
    Button,
    DialogContent,
    Divider,
    Grid,
    Tooltip,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BorderedBox } from '../../../../../components/core/box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useFinancialsService } from '../../../../../services/financials.service';
import {
    EarningFilter,
    SecElement,
    SecElementSummary,
    SECEventsResponseData,
    SECEventsSummaryResponseData,
    SECEventStatements,
} from '../../../../../services/interfaces';
import { TextHighlight } from '../../../../../components/sentiment-highlights';
import { ToggleHighLight } from '../../../../../components/toggle-highlight';
import { SECEventFilter } from './sec-event-filter';
import { Business } from '../../../../../components/icons/business';
import { Management } from '../../../../../components/icons/management';
import { RiskFactors } from '../../../../../components/icons/risk-factor';
import {
    BootstrapDialogLarge,
    BootstrapDialogTitle,
} from '../../../../../components/core/dialog';
import { Bank } from '../../../../../components/icons/bank';
import { sum } from 'lodash';
import { NavigationLink } from '../../../../../components/core/link';
import { LinkIcon } from '../../../../../components/icons/link';
import { Subscribe } from '../../../../../utils/subscribe';
import { LockIcon } from '../../../../../components/icons/lock';

interface IProps {
    selectedSECFilter: EarningFilter | null;
    topicSubscriptionId: string;
    query: string;
}

export const Summary = (props: IProps) => {
    const topicSubscriptionId = props.topicSubscriptionId;
    const [dialogContent, setDialogContent] = useState<SecElement | null>(null);
    const { fetchSECSummary, fetchSECEvents } = useFinancialsService();
    const [open, setOpen] = React.useState<string>('forward');
    const [highlightSection, setHighlightSection] =
        React.useState<string>('events');
    const [secSummary, setSECSummary] =
        useState<SECEventsSummaryResponseData | null>(null);
    const [secEventsSummaries, setSECEventsSummaries] = useState<
        SECEventStatements[]
    >([]);
    const [secEvents, setSECEvents] = useState<SECEventsResponseData | null>(
        null
    );

    const [expandadSections, setExpandedSections] = useState<string[]>([
        'Risk Factors',
    ]);

    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [dialogSection, setDialogSection] = useState('');

    const closeDialog = () => {
        setOpenDialog(false);
    };

    const [scrollY, setScrollY] = useState(0);

    function logit() {
        if (window.pageYOffset) setScrollY(window.pageYOffset);
    }

    useEffect(() => {
        function watchScroll() {
            window.addEventListener('auto', logit);
        }
        watchScroll();
        return () => {
            window.removeEventListener('auto', logit);
        };
    });

    const refreshSECSummary = async () => {
        if (topicSubscriptionId && props.selectedSECFilter) {
            let data = await fetchSECSummary({
                financeCategory: 'sec_filings',
                topicSubscriptionId: topicSubscriptionId,
                year: props.selectedSECFilter.year,
                quarter: props.selectedSECFilter.quarter,
                documentType: props.selectedSECFilter.documentType,
            });
            setSECSummary({ ...data });
        }
    };

    const scrollToSection = (section: string) => {
        setTimeout(() => {
            //@ts-ignore
            document.getElementById(`${section}`).scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'start',
            });
        }, 0);
    };

    const scrollToOrder = (
        orderId: number,
        section: string,
        dialog?: boolean
    ) => {
        if (!expandadSections.includes(section)) {
            setExpandedSections([...expandadSections, section]);
        }
        setTimeout(() => {
            //@ts-ignore
            document
                .getElementById(
                    `${dialog ? 'dialog' : ''}${section}order${orderId}`
                )
                .scrollIntoView({
                    behavior: 'smooth',
                    block: dialog ? 'start' : 'center',
                    inline: dialog ? 'start' : 'center',
                });
        }, 0);
        highlightContent(orderId, section, dialog);
    };

    const highlightContent = (
        orderId: number,
        section: string,
        dialog?: boolean
    ) => {
        setTimeout(() => {
            //@ts-ignore
            document.getElementById(
                `${dialog ? 'dialog' : ''}${section}order${orderId}`
            ).style.backgroundColor = '#EBECF6';
        }, 900);
        setTimeout(() => {
            //@ts-ignore
            document.getElementById(
                `${dialog ? 'dialog' : ''}${section}order${orderId}`
            ).style.backgroundColor = '#fff';
        }, 1900);
        setTimeout(() => {
            //@ts-ignore
            document.getElementById(
                `${dialog ? 'dialog' : ''}${section}order${orderId}`
            ).style.backgroundColor = '#EBECF6';
        }, 2400);
        setTimeout(() => {
            //@ts-ignore
            document.getElementById(
                `${dialog ? 'dialog' : ''}${section}order${orderId}`
            ).style.backgroundColor = '#fff';
        }, 3400);
    };

    const handleSectionClick = (section: string) => {
        if (expandadSections.includes(section)) {
            setExpandedSections([
                ...expandadSections.filter((s) => s != section),
            ]);
        } else {
            setExpandedSections([...expandadSections, section]);
        }
    };

    const refreshSECEvents = async () => {
        if (topicSubscriptionId && props.selectedSECFilter) {
            let data = await fetchSECEvents({
                financeCategory: 'sec_filings',
                topicSubscriptionId: topicSubscriptionId,
                year: props.selectedSECFilter.year,
                quarter: props.selectedSECFilter.quarter,
                documentType: props.selectedSECFilter.documentType,
            });
            setSECEvents({ ...data });
        }
    };

    useEffect(() => {
        refreshSECSummary();
        refreshSECEvents();
        if (!open && topicSubscriptionId) handleClick('forward');
        if (open && !topicSubscriptionId) {
            setOpen('');
        }
    }, [topicSubscriptionId, props.selectedSECFilter]);

    const handleClick = (id: string) => {
        if (!topicSubscriptionId) return;
        if (open == id) {
            setOpen('');
            return;
        } else {
            setOpen(id);
            updateEventsSummaries(id);
        }
    };

    useEffect(() => {
        updateEventsSummaries(open);
    }, [secEvents]);

    const updateEventsSummaries = (id: string) => {
        if (
            id == 'forward' &&
            secEvents &&
            secEvents.forwardLookingStatements
        ) {
            setSECEventsSummaries([...secEvents.forwardLookingStatements]);
        } else if (id == 'positives' && secEvents && secEvents.keyPositives) {
            setSECEventsSummaries([...secEvents.keyPositives]);
        } else if (id == 'negatives' && secEvents && secEvents.keyNegatives) {
            setSECEventsSummaries([...secEvents.keyNegatives]);
        }
    };
    return (
        <BorderedBox sx={{ backgroundColor: '#FFFFFF' }}>
            <Box px={4}>
                <Grid container>
                    <Grid item sm={8}>
                        <Typography py={2} variant="h4">
                            SEC Filings - Event based Analysis & Key Highlights
                        </Typography>
                    </Grid>
                    <Grid item sm={4}>
                        <Box sx={{ float: 'right', pt: 1.5 }}>
                            <ToggleHighLight size={20} />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <Divider />
            </Box>
            <Grid px={0} container>
                <Grid item sm={4}>
                    <Grid container>
                        <Grid
                            py={2}
                            onClick={() => setHighlightSection('events')}
                            sx={
                                highlightSection == 'events'
                                    ? {
                                          backgroundColor: '#F5F5FA',
                                          borderBottom: '4px solid #6e3cfb',
                                      }
                                    : {
                                          cursor: 'pointer',
                                      }
                            }
                            item
                            sm={6}
                        >
                            <Typography
                                textAlign="center"
                                color={
                                    highlightSection == 'events'
                                        ? '#6e3cfb'
                                        : '#A5A6AB'
                                }
                                variant="h6"
                            >
                                Events
                            </Typography>
                        </Grid>
                        <Grid
                            py={2}
                            onClick={() => setHighlightSection('section')}
                            sx={
                                highlightSection == 'section'
                                    ? {
                                          backgroundColor: '#F5F5FA',
                                          borderBottom: '4px solid #6e3cfb',
                                      }
                                    : {
                                          cursor: 'pointer',
                                      }
                            }
                            item
                            sm={6}
                        >
                            <Typography
                                color={
                                    highlightSection == 'section'
                                        ? '#6e3cfb'
                                        : '#A5A6AB'
                                }
                                textAlign="center"
                                variant="h6"
                            >
                                Sections
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider
                        sx={{
                            borderColor: '#F5F5FA',
                            borderWidth: 1,
                        }}
                    />
                    {highlightSection == 'events' && (
                        <Box
                            className="scrollable"
                            sx={
                                scrollY > 0
                                    ? {
                                          maxHeight: 'calc(100vh - 260px)',
                                          overflow: 'auto',
                                      }
                                    : {}
                            }
                        >
                            <List
                                sx={{ width: '100%', p: 0 }}
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                            >
                                <ListItemButton
                                    sx={{
                                        bgcolor:
                                            open == 'forward'
                                                ? '#f9f5ff'
                                                : '#fff',
                                    }}
                                    onClick={() => handleClick('forward')}
                                >
                                    <ListItemIcon sx={{ minWidth: 16 }}>
                                        <Box
                                            height={8}
                                            width={8}
                                            sx={{
                                                backgroundColor: '#6B22E3',
                                                borderRadius: '50%',
                                            }}
                                        ></Box>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={{
                                            fontWeight: 600,
                                        }}
                                        primary="Forward Looking Statements"
                                    />
                                    {!topicSubscriptionId ? (
                                        <LockIcon color="#161616" />
                                    ) : open == 'forward' ? (
                                        <ExpandLess />
                                    ) : (
                                        <ExpandMore />
                                    )}
                                </ListItemButton>
                                {open == 'forward' ? null : (
                                    <Divider
                                        sx={{
                                            borderColor: '#F5F5FA',
                                            borderWidth: 0.5,
                                        }}
                                    />
                                )}
                                <Collapse
                                    in={open == 'forward'}
                                    timeout={100}
                                    unmountOnExit
                                >
                                    <List
                                        component="div"
                                        sx={{ pt: 3, pb: 2 }}
                                        disablePadding
                                    >
                                        <Box>
                                            {secEventsSummaries.map(
                                                (
                                                    s: SECEventStatements,
                                                    index: number
                                                ) => (
                                                    <SECEventFilter
                                                        scrollToOrder={
                                                            scrollToOrder
                                                        }
                                                        isLast={
                                                            index + 1 ==
                                                            secEventsSummaries.length
                                                        }
                                                        data={s}
                                                    />
                                                )
                                            )}
                                        </Box>
                                    </List>
                                    <Divider
                                        sx={{
                                            borderColor: '#F5F5FA',
                                            borderWidth: 1,
                                        }}
                                    />
                                </Collapse>
                            </List>
                            <List
                                sx={{ width: '100%', p: 0 }}
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                            >
                                <ListItemButton
                                    sx={{
                                        bgcolor:
                                            open == 'positives'
                                                ? '#f9f5ff'
                                                : '#fff',
                                    }}
                                    onClick={() => handleClick('positives')}
                                >
                                    <ListItemIcon sx={{ minWidth: 16 }}>
                                        <Box
                                            height={8}
                                            width={8}
                                            sx={{
                                                backgroundColor: '#3BE37E',
                                                borderRadius: '50%',
                                            }}
                                        ></Box>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={{
                                            fontWeight: 600,
                                        }}
                                        primary="Positive Indicators"
                                    />
                                    {!topicSubscriptionId ? (
                                        <LockIcon color="#161616" />
                                    ) : open == 'positives' ? (
                                        <ExpandLess />
                                    ) : (
                                        <ExpandMore />
                                    )}
                                </ListItemButton>
                                {open == 'positives' ? null : (
                                    <Divider
                                        sx={{
                                            borderColor: '#F5F5FA',
                                            borderWidth: 0.5,
                                        }}
                                    />
                                )}
                                <Collapse
                                    in={open == 'positives'}
                                    timeout={100}
                                    unmountOnExit
                                >
                                    <List
                                        component="div"
                                        sx={{ pt: 3, pb: 2 }}
                                        disablePadding
                                    >
                                        <Box>
                                            {secEventsSummaries.map(
                                                (
                                                    s: SECEventStatements,
                                                    index
                                                ) => (
                                                    <SECEventFilter
                                                        isLast={
                                                            index + 1 ==
                                                            secEventsSummaries.length
                                                        }
                                                        scrollToOrder={
                                                            scrollToOrder
                                                        }
                                                        data={s}
                                                    />
                                                )
                                            )}
                                        </Box>
                                    </List>
                                    <Divider
                                        sx={{
                                            borderColor: '#F5F5FA',
                                            borderWidth: 1,
                                        }}
                                    ></Divider>
                                </Collapse>
                            </List>
                            <List
                                sx={{ width: '100%', p: 0 }}
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                            >
                                <ListItemButton
                                    sx={{
                                        bgcolor:
                                            open == 'negatives'
                                                ? '#f9f5ff'
                                                : '#fff',
                                    }}
                                    onClick={() => handleClick('negatives')}
                                >
                                    <ListItemIcon sx={{ minWidth: 16 }}>
                                        <Box
                                            height={8}
                                            width={8}
                                            sx={{
                                                backgroundColor: '#E26573',
                                                borderRadius: '50%',
                                            }}
                                        ></Box>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={{
                                            fontWeight: 600,
                                        }}
                                        primary="Negative Indicators"
                                    />
                                    {!topicSubscriptionId ? (
                                        <LockIcon color="#161616" />
                                    ) : open == 'negatives' ? (
                                        <ExpandLess />
                                    ) : (
                                        <ExpandMore />
                                    )}
                                </ListItemButton>
                                {open == 'negatives' ? null : (
                                    <Divider
                                        sx={{
                                            borderColor: '#F5F5FA',
                                            borderWidth: 0.5,
                                        }}
                                    />
                                )}
                                <Collapse
                                    in={open == 'negatives'}
                                    timeout={100}
                                    unmountOnExit
                                >
                                    <List
                                        component="div"
                                        sx={{ pt: 3, pb: 2 }}
                                        disablePadding
                                    >
                                        <Box>
                                            {secEventsSummaries.map(
                                                (
                                                    s: SECEventStatements,
                                                    index
                                                ) => (
                                                    <SECEventFilter
                                                        scrollToOrder={
                                                            scrollToOrder
                                                        }
                                                        isLast={
                                                            index + 1 ==
                                                            secEventsSummaries.length
                                                        }
                                                        data={s}
                                                    />
                                                )
                                            )}
                                        </Box>
                                    </List>
                                </Collapse>
                            </List>
                        </Box>
                    )}
                    {highlightSection == 'section' && (
                        <Box
                            className="scrollable"
                            sx={
                                scrollY > 0
                                    ? {
                                          maxHeight: 'calc(100vh - 260px)',
                                          overflow: 'auto',
                                      }
                                    : {}
                            }
                        >
                            <List
                                sx={{ width: '100%', p: 0 }}
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                            >
                                <ListItemButton
                                    sx={{
                                        bgcolor: '#F5F5FA',
                                    }}
                                    onClick={() => scrollToSection('Business')}
                                >
                                    <ListItemIcon>
                                        <Bank size={16} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={{
                                            fontWeight: 600,
                                        }}
                                        sx={{
                                            paddingTop: 1,
                                            marginTop: 0,
                                        }}
                                        primary="Entire SEC Filings"
                                    />
                                </ListItemButton>
                            </List>
                            <List
                                sx={{ width: '100%', p: 0 }}
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                            >
                                <ListItemButton
                                    onClick={() => scrollToSection('Business')}
                                >
                                    <ListItemIcon>
                                        <Business size={16} />
                                    </ListItemIcon>
                                    <ListItemText primary="Business" />
                                    {!topicSubscriptionId ? (
                                        <LockIcon color="#161616" />
                                    ) : (
                                        <ArrowForwardIosIcon fontSize="small" />
                                    )}
                                </ListItemButton>
                            </List>
                            <Divider
                                sx={{
                                    borderColor: '#F5F5FA',
                                    borderWidth: 1,
                                }}
                            />
                            <List
                                sx={{ width: '100%', p: 0 }}
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                            >
                                <ListItemButton
                                    onClick={() => scrollToSection('MD and A')}
                                >
                                    <ListItemIcon>
                                        <Management size={16} />
                                    </ListItemIcon>
                                    <ListItemText primary="Management Discussion and Analysis" />
                                    {!topicSubscriptionId ? (
                                        <LockIcon color="#161616" />
                                    ) : (
                                        <ArrowForwardIosIcon fontSize="small" />
                                    )}
                                </ListItemButton>
                            </List>
                            <Divider
                                sx={{
                                    borderColor: '#F5F5FA',
                                    borderWidth: 1,
                                }}
                            />
                            <List
                                sx={{ width: '100%', p: 0 }}
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                            >
                                <ListItemButton
                                    onClick={() =>
                                        scrollToSection('Risk Factors')
                                    }
                                >
                                    <ListItemIcon>
                                        <RiskFactors size={16} />
                                    </ListItemIcon>
                                    <ListItemText primary="Risk Factors" />
                                    {!topicSubscriptionId ? (
                                        <LockIcon color="#161616" />
                                    ) : (
                                        <ArrowForwardIosIcon fontSize="small" />
                                    )}
                                </ListItemButton>
                            </List>
                        </Box>
                    )}
                </Grid>
                <Divider flexItem orientation="vertical" />
                <Grid
                    className="scrollable"
                    item
                    sx={
                        scrollY > 0
                            ? {
                                  maxHeight: 'calc(100vh - 210px)',
                                  overflow: 'auto',
                                  maxWidth: 'calc(66.6666% - 1px)',
                                  flex: '0 0 100%',
                                  backgroundColor: '#F5F5FA',
                              }
                            : {
                                  maxWidth: 'calc(66.6666% - 1px)',
                                  flex: '0 0 100%',
                                  backgroundColor: '#F5F5FA',
                              }
                    }
                >
                    {highlightSection == 'events' && secSummary && (
                        <Grid container py={2}>
                            {secSummary?.secElements.map((ses: SecElement) => (
                                <Grid id={ses.section} item sm={12} px={4}>
                                    <BorderedBox
                                        sx={{
                                            cursor: 'pointer',
                                            backgroundColor: '#fff',
                                        }}
                                        onClick={() =>
                                            handleSectionClick(ses.section)
                                        }
                                        my={1}
                                        px={4}
                                        py={2}
                                    >
                                        <Box display="flex">
                                            <Box pt={1} pr={2}>
                                                {ses.section == 'Business' && (
                                                    <Business size={24} />
                                                )}
                                                {ses.section ==
                                                    'Risk Factors' && (
                                                    <RiskFactors size={24} />
                                                )}
                                                {ses.section == 'MD and A' && (
                                                    <Management size={24} />
                                                )}
                                            </Box>
                                            <Typography
                                                pl={1}
                                                fontSize={20}
                                                variant="h5"
                                            >
                                                {ses.section == 'MD and A'
                                                    ? 'Management Discussion and Analysis'
                                                    : ses.section}
                                            </Typography>
                                            <Box pt={1} ml="auto">
                                                {expandadSections.includes(
                                                    ses.section
                                                ) ? (
                                                    <ExpandLess />
                                                ) : (
                                                    <ExpandMore />
                                                )}
                                            </Box>
                                        </Box>

                                        <Collapse
                                            in={expandadSections.includes(
                                                ses.section
                                            )}
                                            timeout="auto"
                                            unmountOnExit
                                        >
                                            <Box
                                                onClick={(event) =>
                                                    event.stopPropagation()
                                                }
                                            >
                                                <Box py={1}>
                                                    <Divider />
                                                </Box>
                                                {ses.summaries.map(
                                                    (
                                                        summary: SecElementSummary
                                                    ) => (
                                                        <Box
                                                            id={`${ses.section}order${summary.orderId}`}
                                                        >
                                                            <Box
                                                                py={1}
                                                                display="flex"
                                                            >
                                                                <Box>
                                                                    <Tooltip
                                                                        arrow
                                                                        title="View source"
                                                                        placement="top"
                                                                    >
                                                                        <Button
                                                                            onClick={(
                                                                                event
                                                                            ) => {
                                                                                event.stopPropagation();
                                                                                setOpenDialog(
                                                                                    true
                                                                                );
                                                                                setDialogContent(
                                                                                    ses
                                                                                );
                                                                                setDialogSection(
                                                                                    ses.section
                                                                                );
                                                                                setTimeout(
                                                                                    () => {
                                                                                        //@ts-ignore
                                                                                        document
                                                                                            .getElementById(
                                                                                                `dialog${ses.section}order${summary.orderId}`
                                                                                            )
                                                                                            .scrollIntoView(
                                                                                                {
                                                                                                    behavior:
                                                                                                        'smooth',
                                                                                                    block: 'start',
                                                                                                    inline: 'start',
                                                                                                }
                                                                                            );
                                                                                    },
                                                                                    0
                                                                                );
                                                                                highlightContent(
                                                                                    summary.orderId,
                                                                                    ses.section,
                                                                                    true
                                                                                );
                                                                            }}
                                                                            sx={{
                                                                                minWidth: 20,
                                                                                p: 0,
                                                                            }}
                                                                        >
                                                                            <svg
                                                                                width="18"
                                                                                height="18"
                                                                                viewBox="0 0 24 24"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M10.4 8L11.8104 9.39785C12.9351 10.5126 13.4975 11.07 13.5841 11.7493C13.6053 11.9158 13.6053 12.0842 13.5841 12.2507C13.4975 12.93 12.9351 13.4874 11.8104 14.6022L10.4 16M20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z"
                                                                                    stroke="#6e3cfb"
                                                                                    stroke-width="1.5"
                                                                                    stroke-linecap="round"
                                                                                />
                                                                            </svg>
                                                                        </Button>
                                                                    </Tooltip>
                                                                </Box>
                                                                <Box pl={1}>
                                                                    <Typography
                                                                        textColor="#4B4B4C"
                                                                        variant="caption"
                                                                        pt={4}
                                                                        text={
                                                                            summary.summary
                                                                        }
                                                                        highlight={
                                                                            summary.highlights
                                                                        }
                                                                        component={
                                                                            TextHighlight
                                                                        }
                                                                        fontSize={
                                                                            14
                                                                        }
                                                                    ></Typography>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    )
                                                )}
                                            </Box>
                                        </Collapse>
                                    </BorderedBox>
                                </Grid>
                            ))}
                        </Grid>
                    )}
                    {/* {highlightSection == 'events' &&
                        !props.topicSubscriptionId && (
                            <Box position={'relative'}>
                                <Grid
                                    container
                                    py={2}
                                    sx={{
                                        background:
                                            'linear-gradient(180deg, rgba(147, 158, 255, 0.25) 0%, rgba(255, 255, 255, 0.25) 100%)',
                                    }}
                                >
                                    {[
                                        {
                                            section: 'Risk Factors',
                                            summaries: [
                                                {
                                                    orderId: 1,
                                                    summary:
                                                        'Our operations and financial results are subject to various risks and uncertainties, including those discussed in Part I, Item 1A, Risk Factors in our Annual Report on Form 10-K. These risks could affect our business, financial conditions and future results, so investors should be aware of them.We are focused on increasing vehicle production, capacity and delivery capabilities, reducing costs, improving and developing our vehicles and battery technologies, vertically integrating and localizing our supply chain, and bringing new products to market. We produced 1,350,996 consumer vehicles and delivered 1,324,074 consumer vehicles through the third quarter. We are investing in capital-intensive projects and research and development to enable increased deliveries and deployments of our products.',
                                                    rawText:
                                                        'ITEM 1A. RISK FACTORS \n\nOur operations and financial results are subject to various risks and uncertainties, including the factors discussed in Part I, Item 1A, Risk Factors in our Annual Report on Form 10-K for the year ended December 31, 2022, which could adversely affect our business, financial conditions and future results.',
                                                    sentiments: {
                                                        positive: 0.0029,
                                                        negative: 0.22,
                                                        forwardLookingStatementScore: 0.469,
                                                    },
                                                    highlights: {
                                                        positive: [],
                                                        negative: [],
                                                        forwardLookingStatement:
                                                            [],
                                                    },
                                                },
                                            ],
                                        },
                                        {
                                            section: 'MD and A',
                                            summaries: [
                                                {
                                                    orderId: 1,
                                                    summary:
                                                        'We are focused on increasing vehicle production, capacity and delivery capabilities, reducing costs, improving and developing our vehicles and battery technologies, vertically integrating and localizing our supply chain, and bringing new products to market. We produced 1,350,996 consumer vehicles and delivered 1,324,074 consumer vehicles through the third quarter. We are investing in capital-intensive projects and research and development to enable increased deliveries and deployments of our products.We are focused on increasing vehicle production, capacity and delivery capabilities, reducing costs, improving and developing our vehicles and battery technologies, vertically integrating and localizing our supply chain, and bringing new products to market. We produced 1,350,996 consumer vehicles and delivered 1,324,074 consumer vehicles through the third quarter. We are investing in capital-intensive projects and research and development to enable increased deliveries and deployments of our products.',
                                                    rawText:
                                                        "ITEM 2. MANAGEMENT'S DISCUSSION AND ANALYSIS OF FINANCIAL CONDITION AND RESULTS OF OPERATIONS \n\nThe following discussion and analysis should be read in conjunction with the consolidated financial statements and the related notes included elsewhere in this Quarterly Report on Form 10-Q. \n\nOverview \n\nOur mission is to accelerate the world's transition to sustainable energy. We design, develop, manufacture, lease and sell high-performance fully electric vehicles, solar energy generation systems and energy storage products. We also offer maintenance, installation, operation, financial and other services related to our products. Additionally, we are increasingly focused on products and services based on artificial intelligence, robotics and automation.",
                                                    sentiments: {
                                                        positive: 0.4217,
                                                        negative: 0.004,
                                                        forwardLookingStatementScore: 0.0207,
                                                    },
                                                    highlights: {
                                                        positive: [
                                                            [0, 257],
                                                            [367, 506],
                                                        ],
                                                        negative: [],
                                                        forwardLookingStatement:
                                                            [],
                                                    },
                                                },
                                            ],
                                        },
                                    ].map((ses: any) => (
                                        <Grid
                                            id={ses.section}
                                            item
                                            sm={12}
                                            px={4}
                                        >
                                            <BorderedBox
                                                sx={{
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() =>
                                                    handleSectionClick(
                                                        ses.section
                                                    )
                                                }
                                                my={1}
                                                px={4}
                                                py={2}
                                            >
                                                <Box display="flex">
                                                    <Box pt={1} pr={2}>
                                                        {ses.section ==
                                                            'Business' && (
                                                            <Business
                                                                size={24}
                                                            />
                                                        )}
                                                        {ses.section ==
                                                            'Risk Factors' && (
                                                            <RiskFactors
                                                                size={24}
                                                            />
                                                        )}
                                                        {ses.section ==
                                                            'MD and A' && (
                                                            <Management
                                                                size={24}
                                                            />
                                                        )}
                                                    </Box>
                                                    <Typography
                                                        pl={1}
                                                        fontSize={20}
                                                        variant="h5"
                                                    >
                                                        {ses.section ==
                                                        'MD and A'
                                                            ? 'Management Discussion and Analysis'
                                                            : ses.section}
                                                    </Typography>
                                                    <Box pt={1} ml="auto">
                                                        <ExpandLess />
                                                    </Box>
                                                </Box>

                                                <Collapse
                                                    in={true}
                                                    timeout="auto"
                                                    unmountOnExit
                                                >
                                                    <Box
                                                        onClick={(event) =>
                                                            event.stopPropagation()
                                                        }
                                                    >
                                                        <Box py={1}>
                                                            <Divider />
                                                        </Box>
                                                        {ses.summaries.map(
                                                            (
                                                                summary: SecElementSummary
                                                            ) => (
                                                                <Box
                                                                    id={`${ses.section}order${summary.orderId}`}
                                                                >
                                                                    <Box
                                                                        py={1}
                                                                        display="flex"
                                                                    >
                                                                        <Box>
                                                                            <Tooltip
                                                                                arrow
                                                                                title="View source"
                                                                                placement="top"
                                                                            >
                                                                                <Button
                                                                                    onClick={(
                                                                                        event
                                                                                    ) => {
                                                                                        event.stopPropagation();
                                                                                        setOpenDialog(
                                                                                            true
                                                                                        );
                                                                                        setDialogContent(
                                                                                            ses
                                                                                        );
                                                                                        setDialogSection(
                                                                                            ses.section
                                                                                        );
                                                                                        setTimeout(
                                                                                            () => {
                                                                                                //@ts-ignore
                                                                                                document
                                                                                                    .getElementById(
                                                                                                        `dialog${ses.section}order${summary.orderId}`
                                                                                                    )
                                                                                                    .scrollIntoView(
                                                                                                        {
                                                                                                            behavior:
                                                                                                                'smooth',
                                                                                                            block: 'start',
                                                                                                            inline: 'start',
                                                                                                        }
                                                                                                    );
                                                                                            },
                                                                                            0
                                                                                        );
                                                                                        highlightContent(
                                                                                            summary.orderId,
                                                                                            ses.section,
                                                                                            true
                                                                                        );
                                                                                    }}
                                                                                    sx={{
                                                                                        minWidth: 20,
                                                                                        p: 0,
                                                                                    }}
                                                                                >
                                                                                    <svg
                                                                                        width="18"
                                                                                        height="18"
                                                                                        viewBox="0 0 24 24"
                                                                                        fill="none"
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                    >
                                                                                        <path
                                                                                            d="M10.4 8L11.8104 9.39785C12.9351 10.5126 13.4975 11.07 13.5841 11.7493C13.6053 11.9158 13.6053 12.0842 13.5841 12.2507C13.4975 12.93 12.9351 13.4874 11.8104 14.6022L10.4 16M20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z"
                                                                                            stroke="#6e3cfb"
                                                                                            stroke-width="1.5"
                                                                                            stroke-linecap="round"
                                                                                        />
                                                                                    </svg>
                                                                                </Button>
                                                                            </Tooltip>
                                                                        </Box>
                                                                        <Box
                                                                            pl={
                                                                                1
                                                                            }
                                                                        >
                                                                            <Typography
                                                                                textColor="#4B4B4C"
                                                                                variant="caption"
                                                                                pt={
                                                                                    4
                                                                                }
                                                                                text={
                                                                                    summary.summary
                                                                                }
                                                                                highlight={
                                                                                    summary.highlights
                                                                                }
                                                                                component={
                                                                                    TextHighlight
                                                                                }
                                                                                fontSize={
                                                                                    14
                                                                                }
                                                                            ></Typography>
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                            )
                                                        )}
                                                    </Box>
                                                </Collapse>
                                            </BorderedBox>
                                        </Grid>
                                    ))}
                                </Grid>
                                <Subscribe
                                    message="Unlock Exclusive Insights: Subscribe to Access High-Level Highlights from SEC Filings, Event-Based Sentiment Analysis, and Forward-Looking Statements"
                                    query={props.query}
                                    wideBox={true}
                                />
                            </Box>
                        )} */}
                    {highlightSection == 'section' && secSummary && (
                        <Grid sx={{ backgroundColor: '#FFFFFF' }} container>
                            <Box px={4} pt={2} display="flex">
                                <Box pt={2}>
                                    <Bank size={24} />
                                </Box>
                                <Typography
                                    ml={'auto'}
                                    pl={2}
                                    pt={0.5}
                                    variant="h3"
                                >
                                    Entire SEC Filings
                                </Typography>
                                {secSummary && (
                                    <Button
                                        component={NavigationLink}
                                        to={secSummary.articleLink}
                                        startIcon={LinkIcon}
                                        target="_blank"
                                    ></Button>
                                )}
                            </Box>
                            <Grid item sm={12} py={1}>
                                <Divider />
                            </Grid>
                            {secSummary?.secElements.map((ses: SecElement) => (
                                <Grid
                                    id={ses.section}
                                    item
                                    sm={12}
                                    mt={2}
                                    px={4}
                                >
                                    <Box
                                        sx={{ cursor: 'pointer' }}
                                        onClick={() =>
                                            handleSectionClick(ses.section)
                                        }
                                        my={2}
                                        pb={2}
                                    >
                                        <Box display="flex">
                                            <Box sx={{ width: 24 }}>
                                                {ses.section == 'Business' && (
                                                    <Business size={24} />
                                                )}
                                                {ses.section ==
                                                    'Risk Factors' && (
                                                    <RiskFactors size={24} />
                                                )}
                                                {ses.section == 'MD and A' && (
                                                    <Management size={24} />
                                                )}
                                            </Box>
                                            <Typography pl={2} variant="h4">
                                                {ses.section == 'MD and A'
                                                    ? 'Management Discussion and Analysis'
                                                    : ses.section}
                                            </Typography>
                                            <Box ml="auto">
                                                {expandadSections.includes(
                                                    ses.section
                                                ) ? (
                                                    <ExpandLess />
                                                ) : (
                                                    <ExpandMore />
                                                )}
                                            </Box>
                                        </Box>
                                        <Box py={1}>
                                            <Divider />
                                        </Box>
                                        {ses.summaries.map(
                                            (summary: SecElementSummary) => (
                                                <Box
                                                    id={`${ses.section}order${summary.orderId}`}
                                                >
                                                    <Box py={3} display="flex">
                                                        <Box pl={1}>
                                                            {summary.rawText
                                                                .split('\n')
                                                                .map(
                                                                    (
                                                                        text: string
                                                                    ) => (
                                                                        <>
                                                                            <Typography
                                                                                color="#4B4B4C"
                                                                                variant="caption"
                                                                                pt={
                                                                                    4
                                                                                }
                                                                                fontSize={
                                                                                    14
                                                                                }
                                                                            >
                                                                                {
                                                                                    text
                                                                                }
                                                                            </Typography>
                                                                            <br />
                                                                        </>
                                                                    )
                                                                )}
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            )
                                        )}
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    )}
                    {/* {highlightSection == 'section' &&
                        !props.topicSubscriptionId && (
                            <Box position={'relative'}>
                                <Grid
                                    sx={{
                                        background:
                                            'linear-gradient(180deg, rgba(147, 158, 255, 0.25) 0%, rgba(255, 255, 255, 0.25) 100%)',
                                    }}
                                    container
                                >
                                    <Box px={4} pt={2} display="flex">
                                        <Box pt={2}>
                                            <Bank size={24} />
                                        </Box>
                                        <Typography
                                            ml={'auto'}
                                            pl={2}
                                            pt={0.5}
                                            variant="h3"
                                        >
                                            Entire SEC Filings
                                        </Typography>
                                    </Box>
                                    <Grid item sm={12} py={1}>
                                        <Divider />
                                    </Grid>
                                    {[
                                        {
                                            section: 'Risk Factors',
                                            summaries: [
                                                {
                                                    orderId: 1,
                                                    summary:
                                                        'Our operations and financial results are subject to various risks and uncertainties, including those discussed in Part I, Item 1A, Risk Factors in our Annual Report on Form 10-K. These risks could affect our business, financial conditions and future results, so investors should be aware of them.We are focused on increasing vehicle production, capacity and delivery capabilities, reducing costs, improving and developing our vehicles and battery technologies, vertically integrating and localizing our supply chain, and bringing new products to market. We produced 1,350,996 consumer vehicles and delivered 1,324,074 consumer vehicles through the third quarter. We are investing in capital-intensive projects and research and development to enable increased deliveries and deployments of our products.',
                                                    rawText:
                                                        'ITEM 1A. RISK FACTORS \n\nOur operations and financial results are subject to various risks and uncertainties, including the factors discussed in Part I, Item 1A, Risk Factors in our Annual Report on Form 10-K for the year ended December 31, 2022, which could adversely affect our business, financial conditions and future results.',
                                                    sentiments: {
                                                        positive: 0.0029,
                                                        negative: 0.22,
                                                        forwardLookingStatementScore: 0.469,
                                                    },
                                                    highlights: {
                                                        positive: [],
                                                        negative: [],
                                                        forwardLookingStatement:
                                                            [],
                                                    },
                                                },
                                            ],
                                        },
                                        {
                                            section: 'MD and A',
                                            summaries: [
                                                {
                                                    orderId: 1,
                                                    summary:
                                                        'We are focused on increasing vehicle production, capacity and delivery capabilities, reducing costs, improving and developing our vehicles and battery technologies, vertically integrating and localizing our supply chain, and bringing new products to market. We produced 1,350,996 consumer vehicles and delivered 1,324,074 consumer vehicles through the third quarter. We are investing in capital-intensive projects and research and development to enable increased deliveries and deployments of our products.We are focused on increasing vehicle production, capacity and delivery capabilities, reducing costs, improving and developing our vehicles and battery technologies, vertically integrating and localizing our supply chain, and bringing new products to market. We produced 1,350,996 consumer vehicles and delivered 1,324,074 consumer vehicles through the third quarter. We are investing in capital-intensive projects and research and development to enable increased deliveries and deployments of our products.',
                                                    rawText:
                                                        "ITEM 2. MANAGEMENT'S DISCUSSION AND ANALYSIS OF FINANCIAL CONDITION AND RESULTS OF OPERATIONS \nThe following discussion and analysis should be read in conjunction with the consolidated financial statements and the related notes included elsewhere in this Quarterly Report on Form 10-Q. \n\nOverview \nOur mission is to accelerate the world's transition to sustainable energy. We design, develop, manufacture, lease and sell high-performance fully electric vehicles, solar energy generation systems and energy storage products. We also offer maintenance, installation, operation, financial and other services related to our products. Additionally, we are increasingly focused on products and services based on artificial intelligence, robotics and automation.",
                                                    sentiments: {
                                                        positive: 0.4217,
                                                        negative: 0.004,
                                                        forwardLookingStatementScore: 0.0207,
                                                    },
                                                    highlights: {
                                                        positive: [
                                                            [0, 257],
                                                            [367, 506],
                                                        ],
                                                        negative: [],
                                                        forwardLookingStatement:
                                                            [],
                                                    },
                                                },
                                            ],
                                        },
                                    ].map((ses: any) => (
                                        <Grid
                                            id={ses.section}
                                            item
                                            sm={12}
                                            mt={2}
                                            px={4}
                                        >
                                            <Box
                                                sx={{ cursor: 'pointer' }}
                                                onClick={() =>
                                                    handleSectionClick(
                                                        ses.section
                                                    )
                                                }
                                                my={2}
                                                pb={2}
                                            >
                                                <Box display="flex">
                                                    <Box sx={{ width: 24 }}>
                                                        {ses.section ==
                                                            'Business' && (
                                                            <Business
                                                                size={24}
                                                            />
                                                        )}
                                                        {ses.section ==
                                                            'Risk Factors' && (
                                                            <RiskFactors
                                                                size={24}
                                                            />
                                                        )}
                                                        {ses.section ==
                                                            'MD and A' && (
                                                            <Management
                                                                size={24}
                                                            />
                                                        )}
                                                    </Box>
                                                    <Typography
                                                        pl={2}
                                                        variant="h4"
                                                    >
                                                        {ses.section ==
                                                        'MD and A'
                                                            ? 'Management Discussion and Analysis'
                                                            : ses.section}
                                                    </Typography>
                                                    <Box ml="auto">
                                                        {expandadSections.includes(
                                                            ses.section
                                                        ) ? (
                                                            <ExpandLess />
                                                        ) : (
                                                            <ExpandMore />
                                                        )}
                                                    </Box>
                                                </Box>
                                                <Box py={1}>
                                                    <Divider />
                                                </Box>
                                                {ses.summaries.map(
                                                    (
                                                        summary: SecElementSummary
                                                    ) => (
                                                        <Box
                                                            id={`${ses.section}order${summary.orderId}`}
                                                        >
                                                            <Box
                                                                py={3}
                                                                display="flex"
                                                            >
                                                                <Box pl={1}>
                                                                    {summary.rawText
                                                                        .split(
                                                                            '\n'
                                                                        )
                                                                        .map(
                                                                            (
                                                                                text: string
                                                                            ) => (
                                                                                <>
                                                                                    <Typography
                                                                                        color="#4B4B4C"
                                                                                        variant="caption"
                                                                                        pt={
                                                                                            4
                                                                                        }
                                                                                        fontSize={
                                                                                            14
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            text
                                                                                        }
                                                                                    </Typography>
                                                                                    <br />
                                                                                </>
                                                                            )
                                                                        )}
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    )
                                                )}
                                            </Box>
                                        </Grid>
                                    ))}
                                </Grid>
                                <Subscribe
                                    message="Unlock Exclusive Insights: Subscribe to Access High-Level Highlights from SEC Filings, Event-Based Sentiment Analysis, and Forward-Looking Statements"
                                    query={props.query}
                                    wideBox={true}
                                />
                            </Box>
                        )} */}
                </Grid>
            </Grid>
            <BootstrapDialogLarge
                onClose={closeDialog}
                aria-labelledby="customized-dialog-title"
                open={openDialog}
                sx={{ p: 4 }}
            >
                <BootstrapDialogTitle id="customized-dialog-title">
                    <Box display="flex" justifyContent="space-between">
                        <Box display="flex">
                            <Box pt={1} pr={2}>
                                {dialogSection == 'Business' && (
                                    <Business size={24} />
                                )}
                                {dialogSection == 'Risk Factors' && (
                                    <RiskFactors size={24} />
                                )}
                                {dialogSection == 'MD and A' && (
                                    <Management size={24} />
                                )}
                            </Box>
                            <Typography pl={1} fontSize={20} variant="h5">
                                {dialogSection == 'MD and A'
                                    ? 'Management Discussion and Analysis'
                                    : dialogSection}
                            </Typography>
                        </Box>
                        {secSummary && (
                            <Typography
                                component={NavigationLink}
                                target="_blank"
                                color="#6e3cfb"
                                fontSize={14}
                                sx={{ textDecoration: 'underline' }}
                                to={secSummary.articleLink}
                                variant="subtitle2"
                            >
                                Complete SEC Filings
                            </Typography>
                        )}
                    </Box>
                </BootstrapDialogTitle>
                <DialogContent>
                    {dialogContent && (
                        <Box>
                            <Divider />
                            {dialogContent.summaries.map(
                                (summary: SecElementSummary) => (
                                    <Box
                                        id={`dialog${dialogContent.section}order${summary.orderId}`}
                                        py={3}
                                        display="flex"
                                    >
                                        <Box mr={2}>
                                            {' '}
                                            <FiberManualRecordIcon
                                                sx={{ fontSize: 8 }}
                                            />
                                        </Box>
                                        <Box pl={1}>
                                            {summary.rawText
                                                .split('\n')
                                                .map((text: string) => (
                                                    <>
                                                        <Typography
                                                            color="#4B4B4C"
                                                            variant="caption"
                                                            pt={2}
                                                            fontSize={14}
                                                        >
                                                            {text}
                                                        </Typography>
                                                        <Box py={0.5}></Box>
                                                    </>
                                                ))}
                                        </Box>
                                    </Box>
                                )
                            )}
                        </Box>
                    )}
                    <Box display="flex">
                        <Button
                            onClick={closeDialog}
                            variant="outlined"
                            sx={{
                                ml: 'auto',
                                color: '#9C9FD0',
                                borderColor: '#9C9FD0',
                                height: 28,
                                width: 58,
                            }}
                        >
                            Close
                        </Button>
                    </Box>
                </DialogContent>
            </BootstrapDialogLarge>
        </BorderedBox>
    );
};
