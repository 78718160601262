import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { tabSwitchTopic, toggleHighlight } from '../../../analytics/analytics';
import { DetailedInsights } from '../../../components/insights/detailed-insights';
import { PotentialIndicators } from '../../../components/insights/indicators';
import { ComparisonTable } from '../../../components/table/comparison-table';
import { ToggleHighLight } from '../../../components/toggle-highlight';
import { AppContext } from '../../../providers/app-provider';
import { Granularity } from '../../../services/insights.service';
import { Topic } from '../../../services/interfaces';
import { SentimentPlot } from './sentiment-plot';
import SingleSelect from '../../../components/core/input/single-select';
import { SortOrder } from '../../../components/icons/sort-order';
import { TabPanel } from '../../../components/core/tab-panel';

interface IProps {
    topicSubscriptionId?: string;
    inbuiltDetail?: boolean;
}

export const TopicDetails = (props: IProps) => {
    const appContext = useContext(AppContext);
    const [searchParam] = useSearchParams();
    const [value, setValue] = React.useState(0);
    const [potentialIndicatorsOrder, setPotentialIndicatorsOrder] =
        useState('Relevance');
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if (typeof newValue != 'number') return;
        setValue(newValue);
        if (topicSubscriptionId) {
            tabSwitchTopic(topicSubscriptionId, '', newValue.toString());
        }
    };
    const navigate = useNavigate();
    const [topicSubscriptionIds, settopicSubscriptionId] = useState<string[]>(
        []
    );

    const [topicName, setTopicName] = useState('');
    let { topicSubscriptionId } = props;
    useEffect(() => {
        if (topicSubscriptionId && appContext?.topics.length) {
            settopicSubscriptionId([topicSubscriptionId]);
            let topic = appContext.topics.filter(
                (s: Topic) => s.topicSubscriptionId == topicSubscriptionId
            )[0];
            if (!topic) {
                navigate('/');
            } else {
                setTopicName(topic.subscriptionName);
            }
        }
    }, [topicSubscriptionId, appContext?.topics]);

    useEffect(() => {
        let tab = searchParam.get('tab');
        if (tab) {
            setValue(parseInt(tab));
            setTimeout(() => {
                if (!tab) return;
                //@ts-ignore
                document
                    .getElementById(`simple-tabpanel-${parseInt(tab)}`)
                    .scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                        inline: 'center',
                    });
            }, 100);
        }
    }, [searchParam]);

    return (
        <Box sx={{ mt: 1, textAlign: 'left' }}>
            <Box sx={{ width: '100%', mt: 0 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                    >
                        <Tab label="Historical Sentiment Plot" />
                        <Tab label="Potential Indicators" />
                        <Tab label="Detailed Information" />
                        <Box display="flex" ml="auto">
                            {value == 1 && (
                                <SingleSelect
                                    getOptionText={(v: string) => v}
                                    placeholder="Select source"
                                    border="1px solid #9C9FD0"
                                    options={['Relevance', 'Recency']}
                                    pt={1}
                                    width={150}
                                    pb={0.5}
                                    textMt={0.75}
                                    iconMt={-0.75}
                                    ptContainer={0}
                                    mr={2}
                                    borderRadius={1}
                                    backgroundColor="#f9f5ff"
                                    icon={SortOrder}
                                    selectedValue={potentialIndicatorsOrder}
                                    onChange={(v: string) =>
                                        setPotentialIndicatorsOrder(v)
                                    }
                                />
                            )}
                            <Box pb={1}>
                                <ToggleHighLight />
                            </Box>
                        </Box>
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <SentimentPlot
                        inbuiltDetail={props.inbuiltDetail}
                        topicSubscriptionId={topicSubscriptionId}
                    />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    {topicSubscriptionId && (
                        <PotentialIndicators
                            granularity={Granularity.DAILY}
                            context="TOPIC"
                            inbuiltDetail={props.inbuiltDetail}
                            sortBy={potentialIndicatorsOrder}
                            topicSubscriptionIds={topicSubscriptionIds}
                        />
                    )}
                </TabPanel>
                <TabPanel value={value} index={2}>
                    {topicSubscriptionId && (
                        <DetailedInsights
                            granularity={Granularity.DAILY}
                            inbuiltDetail={props.inbuiltDetail}
                            topicSubscriptionIds={topicSubscriptionIds}
                        />
                    )}
                </TabPanel>
            </Box>
        </Box>
    );
};
