import styled from '@emotion/styled';
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    Paper,
    Tooltip,
    Typography,
} from '@mui/material';
import { createRef, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
    chatBoxQueryAIResponseReceived,
    chatBoxQueryResponseReceived,
    chatBoxQuerySendClick,
} from '../../../analytics/analytics';
import { AppContext } from '../../../providers/app-provider';
import EditIcon from '@mui/icons-material/Edit';
import {
    Article,
    LiveQueryResponse,
    LiveQueryResponseLLMData,
    LiveQueryResponseTerminationData,
    DocumentInsightChatSource,
    IMessage,
    QueryChatResponseSourceData,
    IDocumentQueryRequest,
    FileFilters,
    IProximityFilters,
    DocumentFile,
    PredefinedQuery,
    ILiveQAFiltersResponseData,
    IDocumentInsightFiltersResponseData,
} from '../../../services/interfaces';
import { UtilsService } from '../../../services/utils.service';
import { Bookmarks } from './bookmarks';
import { useDocumentService } from '../../../services/document.service';
import { useChatbotService } from '../../../services/chatbot.service';
import SendIcon from '@mui/icons-material/Send';
import { DocumentInsightsAnswers } from './answers';
import { EditPredefinedQuestions } from './edit-predefined-questions';
import { useLiveServices } from '../../../services/live.service';
import { useSnackbar } from 'notistack';
import { FeatureName, SubFeatureName } from '../../../services/interfaces';
import { usePermissions } from '../../../providers/permissions-provider';
import { Authorize } from '../../../components/authorize';
import GPTSelect from '../../../components/GTP-model-select';
import { BackArrow } from '../../../components/icons/back-arrow';
import { ChatBubbleQuestion } from '../../../components/icons/chat-question-bubble';
import { DocumentInsightChatboxImage } from '../../../components/icons/document-chatbox';
import { EditSquare } from '../../../components/icons/edit-square';
import { MiniLogo } from '../../../components/icons/mini-logo';
import { PILogo } from '../../../components/icons/pi-logo';
import { Question } from '../../../components/icons/question';
import { Reset } from '../../../components/icons/reset';
import { Avator } from '../../../components/icons/avator';
import { Input } from '../../../components/core/input';
import { DocumentInsightsContext } from '../../../providers/document-provider';

const MessageWrapper = styled(Box)(() => ({
    maxWidth: '95%',
    padding: 16,
    marginLeft: 8,
    width: 'fit-content',
    borderRadius: '0px 16px 16px 16px',
    border: '1px solid #D7D9EC',
    background: '#F9F9FF',
    textAlign: 'left',
}));

const MessageWrapperRight = styled(Box)(() => ({
    maxWidth: '90%',
    padding: 12,
    marginLeft: 8,
    width: 'fit-content',
    borderRadius: '16px 16px 0px 16px',
    border: '1px solid #D7D9EC',
    background: '#EBECF6',
    textAlign: 'right',
}));

export const ChatMessage = styled(Box)(() => ({
    fontSize: 14,
    fontWeight: 400,
}));

export const SentMessageWrapper = styled(MessageWrapper)(() => ({
    padding: 12,
    background: '#FFFFFF',
    textAlign: 'right',
}));

export const SentMessageWrapperRight = styled(MessageWrapperRight)(() => ({
    padding: 12,
    background: '#5B63CD',
    color: '#F9F9FF',
}));

export const IncomingMessageWrapper = styled(MessageWrapper)(() => ({
    padding: '0px 12px',
    background: '#F9F9FF',
}));

export const ActionButton = styled(Button)(() => ({
    marginLeft: 2,
    padding: 0,
    minWidth: 0,
    '&:hover  path': {
        fill: '#4B4B4C',
    },
    '&:hover': {
        backgroundColor: 'transparent !important',
    },
}));

interface IProps {
    handleClose: Function;
    open?: any;
    miniHeader?: boolean;
    userFileIds?: string[];
    isDialog?: boolean;
    fileName?: string;
}

//@ts-ignore
const PaperComponent = styled(Paper)(() => ({
    position: 'absolute !important',
    maxWidth: `1000px !important`,
    borderRadius: 8,
}));

interface IQuery {
    queryId: string;
    question: string;
    createdAt: Date;
    response: string | null;
    articles: Article[] | null;
}
interface IThread {
    threadId: string;
    question: IMessage;
    answers: IMessage[];
}

export const FILINGS_SECTIONS_MAP: { [key: string]: string } = {
    'MD and A': 'Management Discussion',
    Business: 'Business',
    'Risk Factors': 'Risk Factors',
    Discussion: 'Management Discussion',
    'Q&A': 'Question And Answers',
};

export const DocumentInsightsChat = (props: IProps) => {
    const { handleClose, open, isDialog, miniHeader } = props;
    const { enqueueSnackbar } = useSnackbar();
    const documentContext = useContext(DocumentInsightsContext);
    let inputRef = useRef();
    const [searchParam] = useSearchParams();
    const navigate = useNavigate();
    const appContext = useContext(AppContext);
    const { isFeatureAuthorized, isSubFeatureAuthorized } = usePermissions();
    const [server, setServer] = useState('');
    const [lastSentMessageId, setLastSentMessageId] = useState<string>('');
    const [viewBookmarks, setViewBookmarks] = useState(false);
    const [responseMessage, setResponseMessage] = useState<string | null>(null);
    const [processingId, setProcessingId] = useState<string | null>(null);
    const [currentThreadId, setCurrentThreadId] = useState<string | null>(null);
    const [chatThreadId, setChatThreadId] = useState<string | null>(null);
    const [threads, setThreads] = useState<IThread[]>([]);
    const [userFileIds, setUserFileIds] = useState<string[] | undefined>(
        undefined
    );
    const [filters, setFilters] = useState<IDocumentInsightFiltersResponseData>(
        {
            server: 'gpt-4o-mini',
        }
    );
    const container = createRef();
    const {
        updateDocumentInsightChat,
        fetchFilterQueryFiles,
        fetchDocumentInsightQueryFilter,
        updateDocumentInsightQueryFilter,
    } = useDocumentService();
    const {
        fetchChatbotLiveQueryResponse,
        fetchChatbotQueryResponseFromWeb,
        getPredefinedQueries,
    } = useChatbotService();
    const [queryMessage, setQueryMessage] = useState<string | undefined>('');

    const refreshFileIds = async () => {
        if (!searchParam.get('filters') && !searchParam.get('query')) {
            navigate(`/document-insight${window.location.search}`);
            return;
        }
        let filters = searchParam.get('filters');
        let proximity = searchParam.get('proximityFilters');
        let query = searchParam.get('query');
        if (query) {
            let fileQueryRequest: IDocumentQueryRequest = {
                userQuery: query,
                fuzziness: 1,
                proximity: 10,
                searchableField: 'EVERYWHERE',
            };
            if (filters) {
                let fileFilters: FileFilters = JSON.parse(filters);
                fileQueryRequest.filters = fileFilters;
            } else {
                fileQueryRequest.filters = { includeSharedFiles: true };
            }
            if (proximity) {
                let proximityFilters: IProximityFilters = JSON.parse(proximity);
                fileQueryRequest.fuzziness = proximityFilters.fuzziness;
                fileQueryRequest.proximity = proximityFilters.proximity;
                fileQueryRequest.searchableField =
                    proximityFilters.searchableFields;
            }
            let res = await fetchFilterQueryFiles(fileQueryRequest);
            let fileIds = res.files.map((f: DocumentFile) => f.userFileId);
            if (fileIds.length == 0) {
                navigate(`/document-insight${window.location.search}`);
                return;
            }
            setUserFileIds([...fileIds]);
        } else {
            setUserFileIds(undefined);
        }
    };

    useEffect(() => {
        if (props.userFileIds) {
            setUserFileIds(props.userFileIds);
        }
        if (!isDialog && !props.userFileIds) {
            refreshFileIds();
        }
    }, [props]);

    const refreshFilters = async () => {
        const data = await fetchDocumentInsightQueryFilter();
        setFilters(data);
        if (data.server) {
            setServer(data.server);
        }
    };

    useEffect(() => {
        refreshFilters();
    }, []);

    const sendMessage = async () => {
        if (!queryMessage) return;
        triggerMessage(queryMessage);
        setQueryMessage('');
    };
    const handleServerModelChange = async (s: string) => {
        setServer(s);
        let res = await updateDocumentInsightQueryFilter({
            ...filters,
            server: s,
        });
        if (res) {
            enqueueSnackbar('Model Updated Successfully', {
                variant: 'success',
            });
        }
    };

    const triggerMessage = (message: string) => {
        let query: IQuery = {
            queryId: UtilsService.uuidv4(),
            question: message,
            createdAt: new Date(),
            response: null,
            articles: null,
        };
        const threadId = UtilsService.uuidv4();
        const messageId = UtilsService.uuidv4();
        setLastSentMessageId(messageId);
        setThreads([
            ...threads,
            {
                threadId: threadId,
                question: {
                    messageType: 'OUTGOING',
                    messageID: messageId,
                    vote: 'neutral',
                    isBookmarked: false,
                    data: {
                        query: message,
                    },
                },
                answers: [],
            },
        ]);
        setCurrentThreadId(threadId);
        setProcessingId(threadId);
        processMessage(query, threadId);
    };

    const getWebResult = async (
        threadId: string,
        question: string,
        answer: IMessage
    ) => {
        if (!question && !answer.chatHistoryId && !answer.chatQueryId) return;
        const responseMessageId = UtilsService.uuidv4();
        let query: IQuery = {
            queryId: UtilsService.uuidv4(),
            question: question,
            createdAt: new Date(),
            response: null,
            articles: null,
        };
        setProcessingId(threadId);
        let response = await fetchChatbotQueryResponseFromWeb(
            question,
            'DOCUMENT_INSIGHTS',
            (data: string) => {
                if (data) {
                    let responses = data.split('\n');
                    // let res:TopicQueryResponse = JSON.parse(data);
                    processQueryResponse(
                        responses,
                        query,
                        responseMessageId,
                        threadId
                    );
                }
            },
            answer.chatHistoryId as string,
            answer.chatQueryId as string,
            userFileIds,
            chatThreadId
        );
    };

    const onResetClick = () => {
        setThreads([]);
        setChatThreadId(null);
        setResponseMessage(null);
        setCurrentThreadId(null);
    };

    const processMessage = async (query: IQuery, threadId: string) => {
        chatBoxQuerySendClick('topic');
        let filters = searchParam.get('filters');
        if (!filters) {
            filters = '{"includeSharedFiles":true}';
        }
        chatBoxQueryResponseReceived('topic');
        {
            const responseMessageId = UtilsService.uuidv4();
            let response = await fetchChatbotLiveQueryResponse(
                query.question,
                'DOCUMENT_INSIGHTS',
                (data: string) => {
                    if (data) {
                        let responses = data.split('\n');
                        // let res:TopicQueryResponse = JSON.parse(data);
                        processQueryResponse(
                            responses,
                            query,
                            responseMessageId,
                            threadId
                        );
                    }
                },
                userFileIds,
                chatThreadId,
                filters ? JSON.parse(filters) : undefined
            );
        }
    };

    const processResponseString = (responseString: string) => {
        let message = responseString;
        for (let i = 1; i < 10; i++) {
            message = message.split(`[${i}]`).join('');
        }
        return message;
    };

    const processResponseStringCitation = (
        responseString: string,
        sources: DocumentInsightChatSource[]
    ): {
        responseString: string;
        sources: DocumentInsightChatSource[];
    } => {
        let available = 0;
        for (let i = 0; i < sources.length; i++) {
            sources[i].id = i + 1;
            if (responseString.includes(`[${i + 1}]`)) {
                available++;
                sources[i].citationIndex = available;
                responseString = responseString
                    .split(`[${i + 1}]`)
                    .join(`[${available}]`);
            }
        }
        return {
            responseString,
            sources: sources.filter((v) => v.citationIndex),
            // sources,
        };
    };

    const processQueryResponse = (
        responses: string[],
        query: IQuery,
        messageID: string,
        threadId: string
    ) => {
        let responseString = '';
        let sources: DocumentInsightChatSource[] = [];
        for (let res of responses) {
            try {
                let parsedRes: LiveQueryResponse = JSON.parse(res);
                switch (parsedRes.chunk) {
                    case 'SOURCES':
                        sources = (
                            parsedRes.data as QueryChatResponseSourceData
                        ).sources as DocumentInsightChatSource[];
                        break;
                    case 'LLM':
                        responseString =
                            responseString +
                            (parsedRes.data as LiveQueryResponseLLMData).value;
                        setResponseMessage(
                            processResponseString(responseString)
                        );
                        break;
                    case 'TERMINATION':
                        setResponseMessage(null);
                        setProcessingId(null);
                        chatBoxQueryAIResponseReceived('topic');
                        let data = processResponseStringCitation(
                            responseString,
                            sources
                        );
                        if (
                            (parsedRes.data as LiveQueryResponseTerminationData)
                                .chatThreadId
                        ) {
                            setChatThreadId(
                                (
                                    parsedRes.data as LiveQueryResponseTerminationData
                                ).chatThreadId
                            );
                        }

                        pushMessage(threadId, {
                            messageType: 'INCOMING',
                            messageID,
                            vote: 'neutral',
                            isBookmarked: false,
                            chatHistoryId: (
                                parsedRes.data as LiveQueryResponseTerminationData
                            ).chatHistoryId,
                            chatAnswerId: (
                                parsedRes.data as LiveQueryResponseTerminationData
                            ).chatAnswerId,
                            chatQueryId: (
                                parsedRes.data as LiveQueryResponseTerminationData
                            ).chatQueryId,
                            data: {
                                answer: data.responseString,
                                sources:
                                    responseString.includes(
                                        `Unfortunately, I'm not able to confidently provide an answer to your question`
                                    ) ||
                                    responseString.includes(
                                        `Unfortunately I am not able to confidently provide an answer to your question.`
                                    )
                                        ? undefined
                                        : data.sources,
                                query: query.question,
                            },
                        });
                        break;
                }
            } catch (error) {}
        }
        query.response = responseString;
    };

    const pushMessage = (threadId: string, answer: IMessage) => {
        setThreads((thread) => {
            let newThreads = [...thread];
            let index = thread.findIndex(
                (t: IThread) => t.threadId == threadId
            );
            if (
                newThreads[index].answers.findIndex(
                    (ans) => ans.messageID == answer.messageID
                ) == -1
            ) {
                newThreads[index]?.answers.push(answer);
            }
            return newThreads;
        });
    };

    const getSourcesText = (
        sources: DocumentInsightChatSource[] | undefined
    ) => {
        if (!sources || !sources.length) {
            return '';
        }
        return `Sources: \n\n${sources
            .filter((s: DocumentInsightChatSource) => s.citationIndex)
            .map(
                (s: DocumentInsightChatSource) =>
                    `Source [${s.citationIndex}]: ${
                        s.pageNo ? `Page No - ${s.pageNo}` : ''
                    }\nSummary: ${s.summary}\n`
            )
            .join('\n')}`;
    };
    useEffect(() => {
        setViewBookmarks(false);
        setQueryMessage('');
    }, [open]);

    const handleCopyToClipboard = (message: IMessage) => {
        if (!message.data.answer || !message.data.sources) return;
        let data = processResponseStringCitation(
            message.data.answer,
            message.data.sources as DocumentInsightChatSource[]
        );
        let text = `Question: ${message.data.query}\nAnswer: ${
            data.responseString
        }\n\n${getSourcesText([...data.sources])}`;
        navigator.clipboard.writeText(text);
    };

    const updateMessage = async (
        threadId: string,
        message: IMessage,
        action?: 'bookmark' | 'upvote' | 'downvote'
    ) => {
        if (
            !message.chatHistoryId ||
            !message.chatAnswerId ||
            !message.chatQueryId
        )
            return;
        let vote: 'neutral' | 'upvote' | 'downvote' = 'neutral';

        let newThreads = threads.map((thread) => {
            if (thread.threadId == threadId) {
                if (thread && thread?.answers.length == 0) return thread;
                let _answers = thread?.answers.map((m: IMessage) => {
                    if (m.messageID == message.messageID) {
                        m.isBookmarked =
                            action == 'bookmark'
                                ? !m.isBookmarked
                                : m.isBookmarked;
                        vote = m.vote;
                        if (m.vote == action) {
                            m.vote = 'neutral';
                        } else if (action == 'upvote' || action == 'downvote') {
                            m.vote = action;
                        }
                    }
                    return m;
                });
                thread.answers = _answers;
                return thread;
            } else {
                return thread;
            }
        });

        setThreads(newThreads);

        let status = await updateDocumentInsightChat(
            message.chatHistoryId,
            message.isBookmarked,
            message.vote,
            'DOCUMENT_INSIGHTS',
            message.chatQueryId,
            message.chatAnswerId
        );
        if (!status) {
            let newThreads = threads.map((thread) => {
                if (thread.threadId == threadId) {
                    if (thread && thread?.answers.length == 0) return thread;
                    let _answers = thread?.answers.map((m: IMessage) => {
                        if (m.messageID == message.messageID) {
                            m.isBookmarked =
                                action == 'bookmark'
                                    ? !m.isBookmarked
                                    : m.isBookmarked;
                            m.vote = vote;
                        }
                        return m;
                    });
                    thread.answers = _answers;
                    return thread;
                } else {
                    return thread;
                }
            });

            setThreads(newThreads);
        }
    };

    useEffect(() => {
        if (container.current) {
            //@ts-ignore
            container.current.scrollTo(0, container.current.scrollHeight);
        }
    }, [threads, open, responseMessage]);

    const renderViewBookmarkAndClear = () => (
        <Box
            sx={{
                '& path': {
                    fill: '#29319B',
                },
                py: 1,
            }}
        >
            <Box
                sx={{ display: viewBookmarks ? '' : 'none' }}
                pt={miniHeader ? 1.5 : 8}
            >
                <Button
                    onClick={() => setViewBookmarks(false)}
                    sx={{
                        minWidth: 35,
                        mr: 4,
                        color: isDialog ? '#fff' : '#29319B',
                    }}
                >
                    <BackArrow color={isDialog ? '#fff' : '#29319B'} /> Go Back
                </Button>
            </Box>
            <Box
                sx={{
                    display: viewBookmarks ? 'none' : 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {/* <Tooltip placement="top" arrow title="View Bookmarks">
                    <Button
                        onClick={() => {
                            setViewBookmarks(true);
                        }}
                        sx={{ minWidth: 35, ml: 2 }}
                    >
                        <BookmarkIcon color="#29319B" />
                    </Button>
                </Tooltip> */}
                <Tooltip placement="top" arrow title="Clear Chat">
                    <Button
                        onClick={onResetClick}
                        sx={{
                            minWidth: 35,
                            mr: 4,
                            marginTop: miniHeader ? '' : 3,
                        }}
                    >
                        {Reset}
                    </Button>
                </Tooltip>
            </Box>
        </Box>
    );

    const renderChat = () => (
        <Box sx={{ position: 'relative' }}>
            {!viewBookmarks && RenderInputFloating()}
            <Box
                className="scrollable"
                ref={container}
                sx={{
                    height: `${
                        window.innerHeight -
                        (isDialog ? 270 : miniHeader ? 265 : 160) +
                        (viewBookmarks ? 76 : 0)
                    }px !important`,
                    overflow: 'auto',
                    pb: 20,
                }}
            >
                {!viewBookmarks && (
                    <Box>
                        {threads.length == 0 && (
                            <Box
                                className="scrollable"
                                ref={container}
                                sx={{
                                    height:
                                        window.innerHeight -
                                        (isDialog
                                            ? 300
                                            : miniHeader
                                            ? 370
                                            : 300),
                                    overflow: 'auto',
                                    pb: 5,
                                }}
                            >
                                <Box textAlign="center" pt={30}>
                                    {DocumentInsightChatboxImage}
                                    {!props.fileName ? (
                                        <Typography pt={4} variant="h5">
                                            Greetings, please ask me any
                                            questions about your selected
                                            document(s),
                                            <br /> and I’ll do my best to
                                            answer!
                                        </Typography>
                                    ) : (
                                        <Typography pt={4} variant="h5">
                                            Greetings, please ask me any
                                            questions on <br /> "
                                            {props.fileName}",
                                            <br /> and I’ll do my best to
                                            answer!
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                        )}
                        {threads.map((t: IThread) => (
                            <Box p={3} pb={0}>
                                {t.question.data.query && (
                                    <Box
                                        display="flex"
                                        justifyContent={
                                            isDialog ? 'left' : 'right'
                                        }
                                    >
                                        {isDialog && <Avator size={28} />}
                                        {isDialog && (
                                            <SentMessageWrapper>
                                                <ChatMessage>
                                                    {t.question.data.query}
                                                </ChatMessage>
                                            </SentMessageWrapper>
                                        )}
                                        {!isDialog &&
                                            lastSentMessageId ==
                                                t.question.messageID && (
                                                <ActionButton
                                                    onClick={() => {
                                                        setQueryMessage(
                                                            t.question.data
                                                                .query
                                                        );
                                                        //@ts-ignore
                                                        inputRef.current.focus();
                                                    }}
                                                    sx={{
                                                        height: 30,
                                                        mt: 3,
                                                        ml: '8px !important',
                                                    }}
                                                >
                                                    <EditIcon
                                                        sx={{
                                                            fontSize: '20px',
                                                        }}
                                                    />
                                                </ActionButton>
                                            )}
                                        {!isDialog && (
                                            <SentMessageWrapperRight>
                                                <ChatMessage>
                                                    {t.question.data.query}
                                                </ChatMessage>
                                            </SentMessageWrapperRight>
                                        )}

                                        {isDialog &&
                                            lastSentMessageId ==
                                                t.question.messageID && (
                                                <ActionButton
                                                    onClick={() => {
                                                        setQueryMessage(
                                                            t.question.data
                                                                .query
                                                        );
                                                        //@ts-ignore
                                                        inputRef.current.focus();
                                                    }}
                                                    sx={{
                                                        height: 30,
                                                        mt: 3,
                                                        ml: '8px !important',
                                                    }}
                                                >
                                                    <EditIcon
                                                        sx={{
                                                            fontSize: '20px',
                                                        }}
                                                    />
                                                </ActionButton>
                                            )}
                                    </Box>
                                )}

                                <DocumentInsightsAnswers
                                    answers={t.answers}
                                    handleCopyToClipboard={(ans: IMessage) =>
                                        handleCopyToClipboard(ans)
                                    }
                                    updateMessage={(
                                        ans: IMessage,
                                        type:
                                            | 'bookmark'
                                            | 'upvote'
                                            | 'downvote'
                                            | undefined
                                    ) => updateMessage(t.threadId, ans, type)}
                                    threadId={t.threadId}
                                    getWebResult={() =>
                                        getWebResult(
                                            t.threadId,
                                            t.question.data.query as string,
                                            t.answers[0]
                                        )
                                    }
                                    processingId={processingId}
                                    responseMessage={responseMessage}
                                    currentThreadId={currentThreadId}
                                    isDialog={isDialog}
                                />
                            </Box>
                        ))}
                    </Box>
                )}

                {(props.isDialog || miniHeader) &&
                    props.userFileIds &&
                    viewBookmarks && (
                        <Box>
                            <Bookmarks userFileIds={props.userFileIds} />
                        </Box>
                    )}
            </Box>
        </Box>
    );

    const renderInputField = () => (
        <Authorize
            disabled={
                !!(userFileIds && userFileIds.length && userFileIds.length < 2)
            }
            featureName={FeatureName.DocumentInsights}
            subFeatureName={SubFeatureName.MultipleFilesChat}
        >
            <Box
                className="scrollable"
                py={3}
                sx={{
                    boxShadow: '0px -1px 4px rgba(0, 0, 0, 0.08)',
                    maxHeight: 100,
                    overflow: 'auto',
                }}
            >
                <Input
                    type="text"
                    mt={0}
                    inputRef={inputRef}
                    noBorder
                    value={queryMessage}
                    onChange={(e) => {
                        setQueryMessage(e.target.value);
                    }}
                    onEnterPress={sendMessage}
                    endAdornment={
                        <Button
                            sx={{
                                px: 4,
                                py: 1,
                                fontSize: 18,
                                borderRadius: 10,
                            }}
                            variant="text"
                            disabled={!queryMessage}
                            onClick={sendMessage}
                        >
                            <SendIcon sx={{ fontSize: 28 }} />
                        </Button>
                    }
                    placeholder="Type your question here..."
                />
            </Box>
        </Authorize>
    );
    const RenderInputFloating = () => {
        const container = useRef();
        const [editQueries, setEditQueries] = useState(false);
        const [openDrawer, setOpenDrawer] = useState(false);
        const [predefinedQueries, setPredefinedQueries] = useState<
            PredefinedQuery[]
        >([]);
        const refreshQueries = async () => {
            let filters = searchParam.get('filters');
            if (!filters) {
                filters = '{"includeSharedFiles":true}';
            }
            const queries = await getPredefinedQueries(
                false,
                userFileIds,
                searchParam.get('filters') ? JSON.parse(filters) : undefined
            );
            setPredefinedQueries(queries);
        };

        useEffect(() => {
            if (
                searchParam.get('filters') ||
                (userFileIds && userFileIds.length > 0)
            ) {
                refreshQueries();
            }
        }, [searchParam.get('filters'), userFileIds]);
        useEffect(() => {
            const handler = (event: any) => {
                //@ts-ignore
                if (!container.current.contains(event.target)) {
                    setOpenDrawer(false);
                }
            };
            document.addEventListener('click', handler);
            return () => {
                document.removeEventListener('click', handler);
            };
        });

        return (
            <Box
                py={3}
                position="absolute"
                bottom={0}
                left={0}
                right={0}
                display="flex"
                justifyContent="center"
            >
                <Authorize
                    disabled={
                        !(
                            !isSubFeatureAuthorized(
                                FeatureName.DocumentInsights,
                                SubFeatureName.MultipleFilesChat
                            ) &&
                            (userFileIds
                                ? userFileIds.length > 1
                                : !!(
                                      documentContext &&
                                      documentContext.activeContextFileCount > 1
                                  ))
                        )
                    }
                    featureName={FeatureName.DocumentInsights}
                    subFeatureName={SubFeatureName.MultipleFilesChat}
                >
                    <Box display="flex" justifyContent="center">
                        <EditPredefinedQuestions
                            editQueries={editQueries}
                            setEditQueries={(display, refresh) => {
                                setEditQueries(display);
                                if (refresh) {
                                    refreshQueries();
                                }
                            }}
                        />
                        <Box
                            ref={container}
                            sx={{
                                width: '682px',
                                zIndex: 1,
                                border:
                                    !isSubFeatureAuthorized(
                                        FeatureName.DocumentInsights,
                                        SubFeatureName.MultipleFilesChat
                                    ) &&
                                    (userFileIds
                                        ? userFileIds.length > 1
                                        : !!(
                                              documentContext &&
                                              documentContext.activeContextFileCount >
                                                  1
                                          ))
                                        ? '1px solid #ccc'
                                        : '1px solid #29319B',
                                borderRadius: '8px',
                                background: '#fff',
                                boxShadow:
                                    '0px 10px 20px -10px rgba(0, 0, 0, 0.50)',
                                '&:hover': {
                                    boxShadow:
                                        '0px 10px 30px -10px rgba(0, 0, 0, 0.50)',
                                },
                            }}
                        >
                            {openDrawer && (
                                <Box
                                    sx={{
                                        borderRadius: '8px 8px 0px 0px',
                                        width: '680px',
                                    }}
                                    textAlign={'left'}
                                    px={4}
                                >
                                    <Box
                                        sx={{
                                            borderBottom: '1px solid #D7D9EC',
                                        }}
                                    >
                                        <Box
                                            display="flex"
                                            justifyContent={'space-between'}
                                            pt={3}
                                            pb={2}
                                        >
                                            <Typography pt={1} variant="h6">
                                                Predefined Question
                                            </Typography>
                                            <Button
                                                variant="text"
                                                onClick={() => {
                                                    setOpenDrawer(false);
                                                    setEditQueries(true);
                                                }}
                                            >
                                                {EditSquare}
                                            </Button>
                                        </Box>
                                        <Box
                                            sx={{
                                                maxHeight: 280,
                                                overflowY: 'auto',
                                            }}
                                        >
                                            {predefinedQueries.map((pq) => (
                                                <Box
                                                    sx={{
                                                        borderTop:
                                                            '1px solid #D7D9EC',
                                                        display: 'flex',
                                                        py: 2.5,
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => {
                                                        setOpenDrawer(false);
                                                        triggerMessage(
                                                            pq.predefinedQuery
                                                        );
                                                    }}
                                                >
                                                    <Box>
                                                        <Question
                                                            color="#4a4b4c"
                                                            size={20}
                                                        />
                                                    </Box>
                                                    <Typography
                                                        ml={1}
                                                        fontSize={14}
                                                    >
                                                        {pq.predefinedQuery}
                                                    </Typography>
                                                </Box>
                                            ))}
                                        </Box>
                                    </Box>
                                </Box>
                            )}
                            <Input
                                type="text"
                                mt={0}
                                disabled={
                                    !isSubFeatureAuthorized(
                                        FeatureName.DocumentInsights,
                                        SubFeatureName.MultipleFilesChat
                                    ) &&
                                    (userFileIds
                                        ? userFileIds.length > 1
                                        : !!(
                                              documentContext &&
                                              documentContext.activeContextFileCount >
                                                  1
                                          ))
                                }
                                noBorder
                                noShadow={true}
                                inputRef={inputRef}
                                borderRadius={
                                    openDrawer ? '0px 0px 8px 8px' : '8px'
                                }
                                borderColor={openDrawer ? '#29319B' : '#A5A6AB'}
                                sx={{
                                    pl: 4,
                                    width: '680px',

                                    zIndex: 10,
                                }}
                                value={queryMessage}
                                onChange={(e) => {
                                    setQueryMessage(e.target.value);
                                }}
                                startAdornment={
                                    <Button
                                        onClick={() => setOpenDrawer(true)}
                                        sx={{
                                            p: 0,
                                            mr: 1,
                                            fontSize: 18,
                                            minWidth: 10,
                                            borderRadius: 10,
                                            display: 'flex',
                                            justifyContent: 'right',
                                        }}
                                        variant="text"
                                    >
                                        <ChatBubbleQuestion
                                            color={
                                                !isSubFeatureAuthorized(
                                                    FeatureName.DocumentInsights,
                                                    SubFeatureName.MultipleFilesChat
                                                ) &&
                                                (userFileIds
                                                    ? userFileIds.length > 1
                                                    : !!(
                                                          documentContext &&
                                                          documentContext.activeContextFileCount >
                                                              1
                                                      ))
                                                    ? '#ccc'
                                                    : '#29319B'
                                            }
                                            size={24}
                                        />
                                    </Button>
                                }
                                onEnterPress={sendMessage}
                                inputPadding={'10.5px 10px 10.5px 0px'}
                                endAdornment={
                                    <Button
                                        sx={{
                                            py: 1,
                                            fontSize: 18,
                                            borderRadius: 10,
                                            display: 'flex',
                                            justifyContent: 'right',
                                        }}
                                        variant="text"
                                        disabled={!queryMessage}
                                        onClick={sendMessage}
                                    >
                                        <SendIcon sx={{ fontSize: 28 }} />
                                    </Button>
                                }
                                placeholder={`Type your question here...`}
                            />
                        </Box>
                    </Box>
                </Authorize>
            </Box>
        );
    };

    const renderChatDialog = () => (
        <Dialog
            onClose={() => handleClose()}
            PaperComponent={PaperComponent}
            aria-labelledby="simple-dialog-title"
            open={open}
        >
            <DialogContent sx={{ p: 0, width: 1000 }}>
                <Box
                    sx={{
                        backgroundColor: '#29319B',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box px={4} py={3}>
                        <Box pb={1} display="flex">
                            <Box
                                display="inline-block"
                                pt={2.5}
                                mr={1}
                                component="span"
                            >
                                <PILogo />
                            </Box>
                            <Box>
                                <Typography
                                    color="#fff"
                                    fontSize={24}
                                    fontWeight={600}
                                >
                                    Photon Insights
                                </Typography>
                                <Typography
                                    color="#fff"
                                    fontSize={12}
                                    textTransform="uppercase"
                                    fontWeight={600}
                                >
                                    Document insight
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    {renderViewBookmarkAndClear()}
                </Box>
                {renderChat()}
                {!viewBookmarks && renderInputField()}
            </DialogContent>
        </Dialog>
    );

    const renderChatComponent = () => (
        <Box>
            {!props.miniHeader && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        borderBottom: '1px solid #E3E3E3',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box p={3}>
                            <Box display="flex" alignItems="center">
                                <Box
                                    display="inline-block"
                                    pt={2.5}
                                    mr={1}
                                    component="span"
                                >
                                    <MiniLogo height="20" width="20" />
                                </Box>
                                <Typography
                                    color="#29319B"
                                    fontSize={20}
                                    fontWeight={600}
                                >
                                    Photon Chatbot
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex">
                        <Authorize
                            featureName={FeatureName.Chatbot}
                            subFeatureName={SubFeatureName.ModelSelection}
                        >
                            <Box mt={4.25} mr={2}>
                                <GPTSelect
                                    selectedValue={server}
                                    fontSize={14}
                                    width={180}
                                    disabled={
                                        !isSubFeatureAuthorized(
                                            FeatureName.Chatbot,
                                            SubFeatureName.ModelSelection
                                        )
                                    }
                                    iconSize={14}
                                    pt={1}
                                    pb={0.5}
                                    onChange={handleServerModelChange}
                                />
                            </Box>
                        </Authorize>
                        {renderViewBookmarkAndClear()}
                    </Box>
                </Box>
            )}
            {props.miniHeader && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        borderBottom: '1px solid #E3E3E3',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box px={4}>
                            <Box pt={1} display="flex">
                                <Box
                                    display="inline-block"
                                    pt={2.5}
                                    mr={1}
                                    component="span"
                                >
                                    <MiniLogo height="16" width="16" />
                                </Box>
                                <Typography
                                    color="#29319B"
                                    fontSize={16}
                                    pt={1}
                                    fontWeight={600}
                                >
                                    Photon Chatbot
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex">
                        <Authorize
                            featureName={FeatureName.Chatbot}
                            subFeatureName={SubFeatureName.ModelSelection}
                        >
                            <Box mt={1.5} mr={2}>
                                <GPTSelect
                                    selectedValue={server}
                                    fontSize={14}
                                    width={180}
                                    disabled={
                                        !isSubFeatureAuthorized(
                                            FeatureName.Chatbot,
                                            SubFeatureName.ModelSelection
                                        )
                                    }
                                    iconSize={14}
                                    pt={1}
                                    pb={0.5}
                                    onChange={handleServerModelChange}
                                />
                            </Box>
                        </Authorize>
                        {renderViewBookmarkAndClear()}
                    </Box>
                </Box>
            )}
            {renderChat()}
        </Box>
    );

    return isDialog ? renderChatDialog() : renderChatComponent();
};
