import React, { useContext, useEffect } from 'react';
//@ts-ignore
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { Box } from '@mui/material';
import { UtilsService } from '../../services/utils.service';
import { useAuthService } from '../../services/auth.service';
import { useInterval } from '../../hooks/use-interval';
import { AppContext } from '../../providers/app-provider';
const { REACT_APP_TAWK_TO_PROPERTY_ID, REACT_APP_TAWK_TO_WIDGET_ID } =
    process.env;

declare global {
    interface Window {
        Tawk_API: any;
    }
}

const EXCLUDED_PATHS = ['/signup', '/login'];

export const SupportChat = () => {
    const { getUserName } = useAuthService();
    const onLoad = async () => {
        let userId = await UtilsService.getUserId();
        let name = await getUserName();
        window.Tawk_API = window.Tawk_API || {};
        window.Tawk_API.setAttributes({
            email: `${userId}@photoninsights.com`,
            id: userId,
            name: name,
        });
    };
    const removeBranding = () => {
        const widgetVisibleElement = document.querySelector('.widget-visible');

        if (widgetVisibleElement) {
            const iframes =
                widgetVisibleElement.querySelectorAll<HTMLIFrameElement>(
                    'iframe'
                );

            iframes.forEach((iframe) => {
                const iframeDoc =
                    iframe.contentDocument || iframe.contentWindow?.document;

                if (iframeDoc) {
                    const brandingElements =
                        iframeDoc.querySelectorAll('.tawk-branding');
                    brandingElements.forEach((brandingElement) => {
                        if (brandingElement) {
                            // Apply CSS to hide the branding element
                            console.log('hiding element', brandingElement);
                            //@ts-ignore
                            brandingElement.style.display = 'none';
                        }
                    });
                }
            });
        }
    };
    useInterval(() => {
        removeBranding();
    }, 0.5 * 1000);

    useEffect(() => {
        if (EXCLUDED_PATHS.includes(window.location.pathname)) {
            document.body.classList.add('hide-support');
        } else {
            document.body.classList.remove('hide-support');
        }
    }, [window.location.pathname]);
    return (
        <Box id="support">
            {REACT_APP_TAWK_TO_PROPERTY_ID && REACT_APP_TAWK_TO_WIDGET_ID && (
                <TawkMessengerReact
                    propertyId={REACT_APP_TAWK_TO_PROPERTY_ID}
                    widgetId={REACT_APP_TAWK_TO_WIDGET_ID}
                    onLoad={onLoad}
                />
            )}
        </Box>
    );
};
