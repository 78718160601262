import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { BorderedBox } from '../../../../components/core/box';
import {
    Box,
    Typography,
    Tabs,
    Divider,
    Switch,
    Button,
    Chip,
} from '@mui/material';
import { CustomTab } from '../../../chat-widget/chatbot/settings';
import { DocumentInsightsContext } from '../../../../providers/document-provider';
import { useDocumentService } from '../../../../services/document.service';
import { IOSSwitch } from '../../../../components/core/ios-switch';
import SingleSelect from '../../../../components/core/input/single-select';
import { Input } from '../../../../components/core/input';
import { useSnackbar } from 'notistack';
import { AnswerLength, DataSourceOptions } from './common-input';
import { IDiligenceTemplateConfiguration } from '../../../../services/interfaces';

interface IProps {
    config: IDiligenceTemplateConfiguration;
    updateConfig: (config: IDiligenceTemplateConfiguration) => void;
}

export const CustomRequestConfig = (props: IProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const [value, setValue] = useState(0);
    const [webSource, setWebSource] = useState(
        props.config.sectionTemplate.onlineSearchSource || 'INTERNET'
    );
    const [domains, setDomains] = useState<string[]>([]);
    const [domainInput, setDomainInput] = useState('');
    const documentContext = useContext(DocumentInsightsContext);
    const { fetchFilesFilters } = useDocumentService();
    const [enableInternalData, setEnableInternalData] = useState(false);
    const removeDomain = (domain: string) => {
        setDomains([...domains.filter((d: string) => d != domain)]);
    };
    const addDomain = () => {
        if (domainInput) {
            try {
                if (!domains.includes(domainInput)) {
                    // setDomains([domainInput, ...domains]);
                    props.updateConfig({
                        ...props.config,
                        sectionTemplate: {
                            ...props.config.sectionTemplate,
                            customDomains: [domainInput, ...domains],
                        },
                    });
                }
                setDomainInput('');
            } catch (error) {
                enqueueSnackbar('invalid domain', {
                    variant: 'error',
                });
            }
        }
    };

    useEffect(() => {
        setWebSource(
            props.config.sectionTemplate.onlineSearchSource || 'INTERNET'
        );
        setDomains(props.config.sectionTemplate.customDomains || []);
    }, [props.config]);
    return (
        <BorderedBox sx={{ backgroundColor: '#f9f5ff', p: 3 }}>
            <Box py={1} display="flex" justifyContent="space-between">
                <Box>
                    <Typography color="#003" variant="h6">
                        {props.config.sectionTemplate.name}
                    </Typography>
                    <Typography color="#8D8D8D" fontSize={12}>
                        {props.config.sectionTemplate.explanation}
                    </Typography>
                </Box>
                <Box></Box>
            </Box>

            <AnswerLength
                updateConfig={props.updateConfig}
                config={props.config}
            />
            <DataSourceOptions
                updateConfig={props.updateConfig}
                config={props.config}
            />
        </BorderedBox>
    );
};
