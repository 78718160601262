import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { CheckedIcon } from '../../icons/checked-icon';
import { UncheckedIcon } from '../../icons/unchecked-icon';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { InputDownArrow } from '../../icons/input-down-arrow';
import { Button, Grid, Menu, Typography, styled } from '@mui/material';
import { BorderedBox } from '../box';

export const ViewModeText = styled(Typography)(() => ({
    textAlign: 'center',
    borderRadius: 4,
    fontWeight: 600,
    cursor: 'pointer',
}));

interface IProps {
    options: any[];
    placeholder: string;
    getOptionText: Function;
    onChange: Function;
    selectedValue: any;
    icon?: JSX.Element;
    pt?: number;
    fontSize?: number;
    ptContainer?: number;
    pb?: number;
    width?: number;
    ml?: number;
    mt?: number;
    backgroundColor?: string;
    border?: string;
    borderRadius?: number;
    disableCheckbox?: boolean;
}

export default function SingleSelectTab(props: IProps) {
    return (
        <div>
            <BorderedBox
                sx={{ borderRadius: '4px !important', width: props.width }}
                px={1}
            >
                <Box display="flex">
                    {props.options.map((o) => (
                        <Grid
                            onClick={() => {
                                props.onChange(o);
                            }}
                            py={1}
                            item
                            xs={6}
                        >
                            <ViewModeText
                                variant="body2"
                                fontSize={props.fontSize || 9}
                                py={0.5}
                                px={2}
                                sx={{
                                    color:
                                        props.selectedValue == o
                                            ? '#6e3cfb'
                                            : '#4B4B4C',
                                    backgroundColor:
                                        props.selectedValue == o
                                            ? '#EBECF6'
                                            : '#ffffff',
                                }}
                            >
                                {props.getOptionText(o)}
                            </ViewModeText>
                        </Grid>
                    ))}
                </Box>
            </BorderedBox>
        </div>
    );
}
