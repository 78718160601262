import * as React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { LeftSide } from './left-side';
import { MiniLogo } from '../icons/mini-logo';

interface IProps {
    children: React.ReactNode;
}

export default function AuthLayout(props: IProps) {
    return (
        <Container maxWidth={false} disableGutters>
            <Grid container>
                <Grid
                    item
                    display={{ xs: 'none', sm: 'block' }}
                    sx={{ backgroundColor: '#29319B' }}
                    sm={6}
                >
                    <LeftSide />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <Grid container>
                        <Grid
                            display={{ xs: 'block' }}
                            md={2}
                            xs={1}
                            item
                        ></Grid>
                        <Grid md={8} xs={10} item>
                            <Box
                                sx={{
                                    width: '100%',
                                    paddingTop: 'calc(100vh/2 - 430px)',
                                    textAlign: 'left',
                                }}
                            >
                                <Box
                                    display="flex"
                                    pt={4}
                                    mb={{ xs: 8, sm: 28 }}
                                >
                                    <Box p={1} pl={0}>
                                        <MiniLogo width="32" height="32" />
                                    </Box>{' '}
                                    <Typography variant="h3">
                                        Forgepoint
                                    </Typography>
                                </Box>
                            </Box>
                            {props.children}
                        </Grid>
                        <Grid
                            display={{ xs: 'block' }}
                            md={2}
                            xs={1}
                            item
                        ></Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}
