import {
    Box,
    Button,
    CircularProgress,
    Divider,
    IconButton,
    Tooltip,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import SyncIcon from '@mui/icons-material/Sync';
import { BorderedBox } from '../../../../components/core/box';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckIcon from '@mui/icons-material/Check';
import LinkIcon from '@mui/icons-material/Link';
import { DRIVE } from './drive';
import { Delete } from '@mui/icons-material';
import { useDriveService } from '../../../../services/drive.service';
import {
    ILoginUrlData,
    IIntegrationAccount,
} from '../../../../services/interfaces';
import { Arrow } from '../../../../components/icons/arrow';
import { UtilsService } from '../../../../services/utils.service';
import { DeleteIntegration } from './integration-account/delete-integration';
import { useSearchParams } from 'react-router-dom';
import { ECURRENT_TAB } from '..';
interface IProps {
    driveId: string;
    onDelete: () => void;
    integrationAccounts: IIntegrationAccount[];
    setIntegrationAccount: (
        integrationAccount: IIntegrationAccount | undefined
    ) => void;
}

export const DriveElement = (props: IProps) => {
    const [isProcessing, setIsProcessing] = useState(false);
    const { fetchIntegrationLoginUrl } = useDriveService();
    const [searchParams, setSearchParams] = useSearchParams();
    const drive = DRIVE.filter((d) => d.id == props.driveId)[0];
    const onConnectClick = async (title: string) => {
        searchParams.delete('currentTab');
        searchParams.set('currentTab', ECURRENT_TAB.INTEGRATIONS);
        setSearchParams(searchParams);
        setIsProcessing(true);
        const data: ILoginUrlData = await fetchIntegrationLoginUrl(title);
        //@ts-ignore
        window.open(data.login_url, '_self').focus();
    };
    return drive ? (
        <BorderedBox mt={4} p={2.5}>
            <Box display={'flex'} justifyContent="space-between">
                <Box display={'flex'}>
                    <Box>{drive.icon}</Box>
                    <Box ml={3}>
                        <Typography mt={2} variant="h6">
                            {drive.title}
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <Button
                        sx={{ borderRadius: 90, fontWeight: 600, mt: 1 }}
                        startIcon={
                            props.integrationAccounts.length > 0 ? (
                                <CheckIcon color="success" />
                            ) : (
                                <LinkIcon />
                            )
                        }
                        disabled={
                            isProcessing &&
                            props.integrationAccounts.length == 0
                        }
                        color={
                            props.integrationAccounts.length > 0
                                ? 'success'
                                : 'primary'
                        }
                        endIcon={
                            isProcessing &&
                            props.integrationAccounts.length == 0 ? (
                                <CircularProgress
                                    sx={{ color: '#fff' }}
                                    size={16}
                                />
                            ) : null
                        }
                        onClick={() => {
                            if (props.integrationAccounts.length == 0)
                                onConnectClick(drive.name);
                        }}
                        variant="outlined"
                    >
                        {props.integrationAccounts.length > 0
                            ? 'Connected'
                            : 'Connect'}
                    </Button>
                    {props.integrationAccounts.length > 0 && (
                        <IconButton
                            aria-label="close"
                            disabled={isProcessing}
                            onClick={onConnectClick.bind(null, drive.name)}
                            sx={{
                                mx: 3,
                                mt: 1,
                            }}
                        >
                            <Tooltip arrow placement="top" title={`Add More`}>
                                <AddIcon
                                    sx={{
                                        color: isProcessing
                                            ? '#95A2FF'
                                            : '#6e3cfb',
                                    }}
                                />
                            </Tooltip>
                        </IconButton>
                    )}
                </Box>
            </Box>
            {props.integrationAccounts.length > 0 && (
                <Box>
                    <Box pt={4} pb={2}>
                        <Divider />
                    </Box>
                    {props.integrationAccounts.map((iA) => (
                        <BorderedBox
                            display={'flex'}
                            justifyContent="space-between"
                            sx={{
                                '&:hover': {
                                    backgroundColor: '#f9f5ff',
                                    '& .show-on-hover': {
                                        display: 'block !important',
                                    },
                                },
                            }}
                            mt={2}
                            p={2}
                        >
                            <Box>
                                <Typography variant="h6">
                                    {iA.preferred_username}
                                </Typography>
                            </Box>
                            <Box display={'flex'}>
                                <Box
                                    className="show-on-hover"
                                    sx={{
                                        display: 'none',
                                        mr: 4,
                                    }}
                                >
                                    <DeleteIntegration
                                        onDelete={props.onDelete}
                                        integrationAccount={iA}
                                    />
                                </Box>
                                <Box>
                                    <IconButton
                                        aria-label="close"
                                        onClick={() =>
                                            props.setIntegrationAccount(iA)
                                        }
                                        sx={{
                                            mx: 3,
                                            color: (theme) =>
                                                theme.palette.grey[500],
                                        }}
                                    >
                                        <Tooltip
                                            arrow
                                            placement="top"
                                            title={`View Drive`}
                                        >
                                            <ArrowForwardIcon
                                                sx={{ color: '#6e3cfb' }}
                                            />
                                        </Tooltip>
                                    </IconButton>
                                </Box>
                            </Box>
                        </BorderedBox>
                    ))}
                </Box>
            )}
        </BorderedBox>
    ) : null;
};
