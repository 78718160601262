import { useState, useContext } from 'react';
import {
    Box,
    Typography,
    Divider,
    Button,
    CircularProgress,
} from '@mui/material';
import {
    createGroup,
    createGroupNameCtaClick,
    clusterRequestTopicClick,
} from '../../../analytics/analytics';
import { Topic, UpsertClusterRequest } from '../../../services/interfaces';
import { useClusterService } from '../../../services/cluster.service';
import { AppContext } from '../../../providers/app-provider';
import { useSnackbar } from 'notistack';
import { useTopicService } from '../../../services/topic.service';
import { BorderedBox } from '../../../components/core/box';

interface IProps {
    query: string;
    setClusterName: (name: string) => void;
    clusterName: string | undefined;
    handleClose: () => void;
    clusterId: string | undefined;
    setClusterId: (id: string) => void;
}

const ConfirmWatchlist = (props: IProps) => {
    const appContext = useContext(AppContext);
    const clusterName = props.clusterName;
    const [processing, setProcessing] = useState<boolean>(false);
    const { subscribeToTopic, getPredefinedTopics } = useTopicService();
    const { enqueueSnackbar } = useSnackbar();

    const { upsertCluster, reFetchUserClusters } = useClusterService();

    const addCluster = async (topicSubscriptionId: string) => {
        if (clusterName) {
            createGroupNameCtaClick(clusterName);
            if (!props.clusterId) {
                createGroup(clusterName);
            }
            let oldTopicSubscriptionIds = appContext?.clusters
                ?.find(
                    (cluster) => cluster.dashboardClusterId === props.clusterId
                )
                ?.userTopicSubscriptions.map(
                    (topic) => topic.topicSubscriptionId
                );

            const req: UpsertClusterRequest = {
                dashboardClusterId: props.clusterId
                    ? props.clusterId
                    : undefined,
                dashboardClusterName: clusterName,
                topicSubscriptionIds: oldTopicSubscriptionIds
                    ? [...(oldTopicSubscriptionIds as []), topicSubscriptionId]
                    : [topicSubscriptionId],
            };
            let res = await upsertCluster(req);
            if (res.success) {
                await reFetchUserClusters();
                setProcessing(false);
                props.handleClose();
                props.setClusterName('');
                enqueueSnackbar(
                    `Added “${props.query}” to your watchlist “${clusterName}” successfully!`
                );
            }
        }
    };

    const handleCreateWatchlist = async () => {
        if (clusterName) {
            setProcessing(true);
            let topicInDb = appContext?.topics.find(
                (topic: Topic) =>
                    topic.subscriptionName.toLowerCase() ===
                    props.query.toLowerCase()
            );

            if (topicInDb) {
                addCluster(topicInDb.topicSubscriptionId);
            } else {
                const res = await subscribeToTopic(props.query);
                if (res) {
                    clusterRequestTopicClick(props.query);
                    let topics = await getPredefinedTopics();

                    appContext?.setTopics([...topics]);
                    let topic = topics.find(
                        (topic: Topic) =>
                            topic.subscriptionName.toLowerCase() ===
                            props.query.toLowerCase()
                    );
                    if (topic) addCluster(topic.topicSubscriptionId);
                }
            }
        }
    };

    const handleCancel = () => {
        props.handleClose();
        props.setClusterName('');
        props.setClusterId('');
    };

    return (
        <Box>
            <Typography variant="h4">
                Are you sure you’d like to subscribe??{' '}
            </Typography>

            <Typography variant="subtitle1" mb={2} mt={4}>
                Do you want to add "
                <span style={{ fontStyle: 'italic', fontWeight: 600 }}>
                    {props.query}
                </span>
                " to your watchlist "
                <span style={{ fontStyle: 'italic', fontWeight: 600 }}>
                    {props.clusterName}
                </span>
                " ?
            </Typography>
            <Divider sx={{ my: 4 }} />
            <BorderedBox sx={{ backgroundColor: '#f9f5ff', p: 6 }}>
                <Typography variant="h6" mb={3}>
                    Suggestion
                </Typography>
                <Typography fontSize="14px">
                    While subscribing/adding to watchlist, we recommend adding
                    topics/key phrases like <b>Renewable Energy</b>,{' '}
                    <b>Artificial Intelligence</b>, <b>US 2024 Elections</b>{' '}
                    etc., and avoiding queries or sentences.
                </Typography>
            </BorderedBox>

            <Box display="flex" mt={4} justifyContent="right">
                <Button
                    sx={{ color: '#4B4B4C', mr: 3 }}
                    onClick={() => handleCancel()}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    onClick={() => handleCreateWatchlist()}
                    sx={{ minWidth: '80px' }}
                >
                    {processing ? (
                        <CircularProgress
                            size={25}
                            sx={{
                                color: '#ffffff',
                                ml: 2,
                            }}
                        />
                    ) : (
                        'Yes'
                    )}
                </Button>
            </Box>
        </Box>
    );
};

export default ConfirmWatchlist;
