import { Box, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSearchParams, Outlet } from 'react-router-dom';
import { Landing } from './landing';
import { OnboardDialog } from '../../components/onboarding';

export const Research = () => {
    const [searchParam] = useSearchParams();
    const [queryMessage, setQueryMessage] = useState<string>('');

    useEffect(() => {
        let query = searchParam.get('query');
        setQueryMessage(query == null ? '' : query);
    }, [searchParam.get('query')]);

    return (
        <Box>
            <OnboardDialog />
            {!queryMessage && <Landing />}
            {queryMessage && <Outlet />}
        </Box>
    );
};
