import { useState } from 'react';
import { ReportSectionSource } from '../../../services/interfaces';
import { BorderedBox } from '../../../components/core/box';
import CloseIcon from '@mui/icons-material/Close';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Paper,
    Tooltip,
    Typography,
} from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FadeIn from 'react-fade-in';
import { NavigationLink } from '../../../components/core/link';
import { UtilsService } from '../../../services/utils.service';
import { ViewFile } from '../../document-insights/drive/view-file';
import { Circle } from '../../../components/icons/circle';
import { NoData } from './no-data';
import { LiveResponse } from '../../../components/live/live-response';

interface IProps {
    title?: string;
    text?: string;
    sources?: ReportSectionSource[];
}

export const MarkdownSources = (props: IProps) => {
    const { text, sources } = props;
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };

    const handleCitationClick = (index: number) => {
        if (!sources) return;
        let s = sources[index - 1];
        if (s) {
            if (s.answerSource == 'ARTICLE') {
                window.open(s.url, '_blank');
            }
            if (s.answerSource == 'USER_DATA') {
                window.open(`/document-insight/file/${s.userFileId}`, '_blank');
            }
        }
    };

    return (
        <BorderedBox
            sx={{
                backgroundColor: '#f9f5ff',
            }}
            pb={4}
            my={4}
        >
            <Typography px={4} pt={4} variant="h4">
                {props.title}
            </Typography>
            <Box
                className="scrollable"
                sx={{
                    maxHeight: 'calc(100vh - 220px)',
                    overflow: 'auto',
                }}
                px={4}
            >
                {!text && <NoData />}
                {text && (
                    <Box>
                        {text && (
                            <LiveResponse
                                onCitationClick={handleCitationClick}
                                text={text}
                            />
                        )}

                        <Box>
                            <Box display={'flex'}>
                                <Button
                                    aria-label="close"
                                    onClick={() => {
                                        setOpen(true);
                                    }}
                                    sx={{
                                        color: '#003',
                                        mx: 3,
                                    }}
                                    startIcon={
                                        <FormatListBulletedIcon
                                            sx={{
                                                fontSize: 20,
                                                color: '#6e3cfb',
                                            }}
                                        />
                                    }
                                >
                                    View sources
                                </Button>
                            </Box>
                            <Dialog
                                PaperComponent={Paper}
                                PaperProps={{
                                    sx: {
                                        width: '100%',
                                    },
                                }}
                                open={open}
                                onClose={handleClose}
                            >
                                <DialogTitle pb={0}>
                                    <Box>
                                        <Box display="flex">
                                            <Typography variant="h4">
                                                Sources
                                            </Typography>

                                            <IconButton
                                                aria-label="close"
                                                onClick={handleClose}
                                                sx={{
                                                    position: 'absolute',
                                                    right: 24,
                                                    top: 24,
                                                    color: (theme) =>
                                                        theme.palette.grey[500],
                                                }}
                                            >
                                                <Tooltip
                                                    arrow
                                                    placement="top"
                                                    title={`Close`}
                                                >
                                                    <CloseIcon />
                                                </Tooltip>
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </DialogTitle>
                                <DialogContent>
                                    <Box textAlign="left">
                                        <FadeIn delay={200}>
                                            {sources?.map(
                                                (
                                                    s: ReportSectionSource,
                                                    index
                                                ) => (
                                                    <Box py={2}>
                                                        <Box
                                                            id={
                                                                'citation-' +
                                                                (index + 1)
                                                            }
                                                        >
                                                            <Box
                                                                minHeight={30}
                                                                display="flex"
                                                            >
                                                                <Typography
                                                                    mt={0.5}
                                                                    lineHeight={
                                                                        '20px'
                                                                    }
                                                                    variant="h6"
                                                                >
                                                                    {index + 1}.
                                                                </Typography>
                                                                <Typography
                                                                    pl={1}
                                                                    fontWeight={
                                                                        600
                                                                    }
                                                                    variant="h6"
                                                                >
                                                                    {s.answerSource ==
                                                                    'ARTICLE'
                                                                        ? s.sourceName
                                                                        : 'Document'}
                                                                </Typography>

                                                                <Typography
                                                                    ml="auto"
                                                                    mt={1}
                                                                    fontSize={
                                                                        14
                                                                    }
                                                                    color="#4B4B4C"
                                                                >
                                                                    {UtilsService.formatStringDate(
                                                                        s.answerSource ==
                                                                            'ARTICLE'
                                                                            ? s.timestamp
                                                                            : s.uploadedOn
                                                                    )}
                                                                </Typography>
                                                            </Box>
                                                            {s.answerSource ==
                                                            'ARTICLE' ? (
                                                                <Typography
                                                                    component={
                                                                        NavigationLink
                                                                    }
                                                                    to={s.url}
                                                                    target="_blank"
                                                                    className="clamp-line-1"
                                                                    variant="h6"
                                                                    color="#6e3cfb"
                                                                >
                                                                    {s?.title}
                                                                </Typography>
                                                            ) : (
                                                                <ViewFile
                                                                    fileName={
                                                                        s.title
                                                                    }
                                                                    pageNo={
                                                                        s.pageNo
                                                                    }
                                                                    fileId={
                                                                        s.userFileId
                                                                    }
                                                                    loader={
                                                                        <Box
                                                                            display={
                                                                                'flex'
                                                                            }
                                                                        >
                                                                            <Typography
                                                                                display={
                                                                                    'flex'
                                                                                }
                                                                                className="clamp-line-1"
                                                                                variant="h6"
                                                                                color="#6e3cfb"
                                                                            >
                                                                                {
                                                                                    s.title
                                                                                }{' '}
                                                                            </Typography>
                                                                            <Button
                                                                                sx={{
                                                                                    px: 2,
                                                                                    py: 0,
                                                                                    minWidth: 30,
                                                                                }}
                                                                            >
                                                                                <CircularProgress
                                                                                    size={
                                                                                        14
                                                                                    }
                                                                                />
                                                                            </Button>
                                                                        </Box>
                                                                    }
                                                                >
                                                                    <Typography
                                                                        display={
                                                                            'flex'
                                                                        }
                                                                        className="clamp-line-1"
                                                                        variant="h6"
                                                                        color="#6e3cfb"
                                                                    >
                                                                        {
                                                                            s.title
                                                                        }{' '}
                                                                    </Typography>
                                                                </ViewFile>
                                                            )}

                                                            {s.summary && (
                                                                <Typography
                                                                    mt={2}
                                                                    className="clamp-line-4"
                                                                    fontSize={
                                                                        14
                                                                    }
                                                                    variant="subtitle1"
                                                                >
                                                                    {s.summary}{' '}
                                                                </Typography>
                                                            )}
                                                        </Box>
                                                        <Box pt={3}>
                                                            <Divider />
                                                        </Box>
                                                    </Box>
                                                )
                                            )}
                                        </FadeIn>
                                    </Box>
                                </DialogContent>
                            </Dialog>
                        </Box>
                    </Box>
                )}
            </Box>
        </BorderedBox>
    );
};
