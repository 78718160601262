import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Circle } from '../../../components/icons/circle';
import CloseIcon from '@mui/icons-material/Close';
import { Delete } from '../../../components/icons/delete';
import { FileIcon } from '../../../components/icons/file';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { More } from '../../../components/icons/more';
import { useParams, useSearchParams } from 'react-router-dom';
import { NavigationLink } from '../../../components/core/link';
import {
    DocumentFile,
    FileTag,
    UpdateDocumentTags,
} from '../../../services/interfaces';
import { UtilsService } from '../../../services/utils.service';
import { Checkbox, Chip, CircularProgress, Tooltip } from '@mui/material';
import { useDocumentService } from '../../../services/document.service';
import { DocumentInsightsContext } from '../../../providers/document-provider';
import { DeleteData } from './delete';
import {
    documentInsightFileClick,
    documentInsightFileDeleteClick,
} from '../../../analytics/analytics';
import { EditTag } from '../tags/edit-tags';
import { Tag } from '../../../components/icons/tag';
import { Add } from '../../../components/icons/add';
import { ShareFile } from './share';
import { Doc } from '../../../components/icons/doc';
import { Share } from '../../../components/icons/share';
import { YoutubeFileIcon } from '../../../components/icons/youtube-file-icon';
import { LinkFileIcon } from '../../../components/icons/link-file-icon';
import { Unshare } from '../../../components/icons/unshare';
import { DownloadIcon } from '../../../components/icons/download';
import { FileDownloadOutlined } from '@mui/icons-material';
const ITEM_HEIGHT = 48;

export const TagBlock = styled(Chip)(() => ({
    border: 'none',
    backgroundColor: '#EBECF6',
    mr: 2,
    color: '#4B4B4C',
    fontSize: 10,
    height: 20,
    fontWeight: 600,
    '& .MuiChip-deleteIcon': {
        color: '#000 !important',
    },
}));

export const FileInfo = styled(Typography)(() => ({
    fontSize: 10,
    fontWeight: 600,
    marginTop: 2,
    marginBottom: 2,
    color: '#A5A6AB',
}));

export const FileMenuItem = styled(MenuItem)(() => ({
    paddingRight: 8,
    paddingLeft: 8,
    '&:hover': {
        backgroundColor: '#EBECF6',
    },
}));

interface IProps {
    file: DocumentFile;
    hideDelete?: boolean;
    tab: number;
    selected?: boolean;
    onSelect?: (file: DocumentFile) => void;
    accountId?: string;
}

export const File = (props: IProps) => {
    const { file, tab } = props;
    const { updateDocumentTags } = useDocumentService();
    const [editTags, setEditTags] = useState<boolean>(false);
    const [deleteFile, setDeleteFile] = useState<boolean>(false);
    const [shareFile, setShareFile] = useState<boolean>(false);
    const [moreMenuEl, setMoreMenuEl] = useState<null | HTMLElement>(null);
    const [isDownloadingFile, setIsDownloadingFile] = useState<boolean>(false);
    const { type, typeId, fileId } = useParams();
    const [isSelected, setIsSelected] = useState(false);

    const openMoreMenu = Boolean(moreMenuEl);
    const documentContext = useContext(DocumentInsightsContext);
    const { enqueueSnackbar } = useSnackbar();

    const [searchParam] = useSearchParams();
    const { downloadFile } = useDocumentService();

    useEffect(() => {
        setIsSelected(type == 'file' && typeId == file.userFileId.toString());
    }, [type, typeId, file]);

    const handleDeleteFileClick = async () => {
        if (file) {
            documentInsightFileDeleteClick(file.userFileId, file.fileName);
            setDeleteFile(true);
        }
    };
    const handleMoreMenuClose = () => {
        setMoreMenuEl(null);
    };
    const handleMoreMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setMoreMenuEl(event.currentTarget);
    };

    const viewDocumentClick = async () => {
        if (!isDownloadingFile) {
            try {
                setIsDownloadingFile(true);
                if (props.file.userFileId) {
                    await downloadFile(props.file.userFileId);
                }
            } finally {
                setIsDownloadingFile(false);
            }
        }
    };

    const removeTag = async (tagId: string | undefined) => {
        if (!props.file.tags || !tagId || !documentContext) return;
        let body: UpdateDocumentTags = {
            userFileId: props.file.userFileId,
        };
        body.userTags = [];
        body.userTags = props.file.tags.filter(
            (t: FileTag) => t.userTagId != tagId
        );
        let res = await updateDocumentTags(body);
        if (res) {
            enqueueSnackbar(`Tags updated successfully`);
            documentContext.setRefreshCount(documentContext.refreshCount + 1);
        }
    };

    return (
        <Box
            display="flow-root"
            sx={{
                backgroundColor: isSelected ? '#6e3cfb' : '',
            }}
            id={file.userFileId}
        >
            {file && (
                <EditTag
                    onClose={() => setEditTags(false)}
                    file={file}
                    open={editTags}
                />
            )}
            {file && (
                <DeleteData
                    onClose={() => setDeleteFile(false)}
                    file={file}
                    open={deleteFile}
                />
            )}
            {file && (
                <ShareFile
                    onClose={() => setShareFile(false)}
                    file={file}
                    open={shareFile}
                />
            )}
            <Box
                sx={{
                    backgroundColor:
                        props.onSelect && props.selected && !isSelected
                            ? '#f9f5ff'
                            : '',
                }}
                px={4}
                py={4}
            >
                <Grid container>
                    <Grid
                        width={
                            props.onSelect
                                ? '80px !important'
                                : '48px !important'
                        }
                        display={'flex'}
                        item
                    >
                        {props.onSelect && (
                            <Tooltip
                                arrow
                                placement="top"
                                title={
                                    file.isOwner
                                        ? 'Select File'
                                        : "You don't have permission to select this file"
                                }
                            >
                                <Box>
                                    <Checkbox
                                        checked={props.selected}
                                        disabled={!file.isOwner}
                                        onClick={() =>
                                            props.onSelect &&
                                            props.onSelect(file)
                                        }
                                        sx={{
                                            '& .MuiSvgIcon-root': {
                                                fontSize: 20,
                                            },
                                            color: isSelected
                                                ? '#FFFFFF !important'
                                                : '#6e3cfb',
                                        }}
                                    />
                                </Box>
                            </Tooltip>
                        )}
                        {file.fileType == 'LINKS' && LinkFileIcon}
                        {file.fileType == 'YOUTUBE' && YoutubeFileIcon}
                        {file.fileType == 'VIDEO' && (
                            <FileIcon
                                type="Video"
                                textBackgroundColor="#6e3cfb"
                            />
                        )}
                        {['DOCUMENTS', 'AUDIO', 'IMAGES'].includes(
                            file.fileType
                        ) && (
                            <FileIcon
                                type={
                                    UtilsService.getFileType(file.fileName)
                                        .length <= 3
                                        ? UtilsService.getFileType(
                                              file.fileName
                                          )
                                        : UtilsService.getFileType(
                                              file.metadata?.ext ||
                                                  file.fileName
                                          )
                                }
                                textBackgroundColor="#6e3cfb"
                            />
                        )}
                    </Grid>
                    <Grid
                        width={
                            props.onSelect
                                ? 'calc(100% - 80px) !important'
                                : 'calc(100% - 48px) !important'
                        }
                        maxWidth={
                            props.onSelect
                                ? 'calc(100% - 80px) !important'
                                : 'calc(100% - 48px) !important'
                        }
                        sx={{
                            flexBasis: props.onSelect
                                ? 'calc(100% - 80px) !important'
                                : 'calc(100% - 48px) !important',
                        }}
                        pl={1.5}
                        item
                    >
                        <Grid spacing={1} container>
                            <Grid
                                sm={searchParam.get('query') ? 8 : 9}
                                pl={'0px !important'}
                                item
                            >
                                <Box display="flex">
                                    <Tooltip
                                        arrow
                                        title={file.fileName}
                                        placement="top"
                                    >
                                        <Typography
                                            sx={{
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}
                                            color={
                                                isSelected
                                                    ? '#ffffff'
                                                    : '#4c4c4c'
                                            }
                                            variant="h6"
                                            onClick={() => {
                                                documentInsightFileClick(
                                                    file.userFileId,
                                                    file.fileName
                                                );
                                            }}
                                            component={NavigationLink}
                                            to={`/document-insight/file/${
                                                file.userFileId
                                            }${
                                                props.tab == 2
                                                    ? '/shared'
                                                    : props.tab == 0
                                                    ? ''
                                                    : '/integration/' +
                                                      props.accountId
                                            }${window.location.search}`}
                                        >
                                            {file.fileName}
                                        </Typography>
                                    </Tooltip>

                                    {file.status == 'done' && (
                                        <Box pt={0.5} pl={2}>
                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17ZM13.447 7.00022C13.6681 6.75333 13.6471 6.37401 13.4002 6.15298C13.1533 5.93195 12.774 5.9529 12.553 6.19978L9.85764 9.21033C9.31148 9.82036 8.94368 10.229 8.62922 10.4933C8.32965 10.7451 8.1537 10.8 8 10.8C7.8463 10.8 7.67035 10.7451 7.37078 10.4933C7.05632 10.229 6.68852 9.82035 6.14236 9.21032L5.44702 8.43367C5.22599 8.18679 4.84667 8.16584 4.59978 8.38687C4.3529 8.6079 4.33195 8.98722 4.55298 9.23411L5.27802 10.0439C5.7867 10.6121 6.21099 11.0861 6.59868 11.4119C7.00879 11.7566 7.45114 12 8 12C8.54886 12 8.99121 11.7566 9.40132 11.4119C9.78901 11.0861 10.2133 10.6122 10.722 10.0439L13.447 7.00022Z"
                                                    fill="#19AF55"
                                                />
                                            </svg>
                                        </Box>
                                    )}
                                    {[
                                        'in-progress',

                                        'insight-in-progress',
                                    ].includes(file.status) && (
                                        <Tooltip
                                            arrow
                                            placement="top"
                                            title="We are still processing your file. Please check back in a few minutes."
                                        >
                                            <Box width={20}>
                                                <CircularProgress
                                                    color="warning"
                                                    size={20}
                                                    sx={{ ml: 2 }}
                                                />
                                            </Box>
                                        </Tooltip>
                                    )}
                                    {(file.status == 'insight-error' ||
                                        file.status == 'upload-error') && (
                                        <Tooltip
                                            arrow
                                            placement="top"
                                            title="Please delete and reupload, and if the error persists, please contact us!"
                                        >
                                            <Box pl={2}>
                                                <svg
                                                    width="16"
                                                    height="24"
                                                    viewBox="0 0 16 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        clip-rule="evenodd"
                                                        d="M16 12C16 16.4183 12.4183 20 8 20C3.58172 20 0 16.4183 0 12C0 7.58172 3.58172 4 8 4C12.4183 4 16 7.58172 16 12ZM8 10.6C8.33137 10.6 8.6 10.8686 8.6 11.2V16C8.6 16.3314 8.33137 16.6 8 16.6C7.66863 16.6 7.4 16.3314 7.4 16V11.2C7.4 10.8686 7.66863 10.6 8 10.6ZM8 9.6C8.44183 9.6 8.8 9.24183 8.8 8.8C8.8 8.35817 8.44183 8 8 8C7.55817 8 7.2 8.35817 7.2 8.8C7.2 9.24183 7.55817 9.6 8 9.6Z"
                                                        fill="#D82F44"
                                                    />
                                                </svg>
                                            </Box>
                                        </Tooltip>
                                    )}
                                    {file.status == 'quota-exceeded' && (
                                        <Tooltip
                                            arrow
                                            placement="top"
                                            title="Quota Exceeded"
                                        >
                                            <Box pl={2}>
                                                <svg
                                                    width="16"
                                                    height="24"
                                                    viewBox="0 0 16 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        clip-rule="evenodd"
                                                        d="M16 12C16 16.4183 12.4183 20 8 20C3.58172 20 0 16.4183 0 12C0 7.58172 3.58172 4 8 4C12.4183 4 16 7.58172 16 12ZM8 10.6C8.33137 10.6 8.6 10.8686 8.6 11.2V16C8.6 16.3314 8.33137 16.6 8 16.6C7.66863 16.6 7.4 16.3314 7.4 16V11.2C7.4 10.8686 7.66863 10.6 8 10.6ZM8 9.6C8.44183 9.6 8.8 9.24183 8.8 8.8C8.8 8.35817 8.44183 8 8 8C7.55817 8 7.2 8.35817 7.2 8.8C7.2 9.24183 7.55817 9.6 8 9.6Z"
                                                        fill="#D82F44"
                                                    />
                                                </svg>
                                            </Box>
                                        </Tooltip>
                                    )}
                                </Box>
                            </Grid>

                            <Grid sm={searchParam.get('query') ? 4 : 3} item>
                                {[0, 1].includes(tab) && file.isOwner ? (
                                    <Tooltip
                                        arrow
                                        placement="top"
                                        title={'More'}
                                    >
                                        <Typography
                                            aria-label="more"
                                            id="long-button"
                                            component={Button}
                                            variant="subtitle2"
                                            aria-controls={
                                                openMoreMenu
                                                    ? 'long-menu'
                                                    : undefined
                                            }
                                            aria-expanded={
                                                openMoreMenu
                                                    ? 'true'
                                                    : undefined
                                            }
                                            aria-haspopup="true"
                                            onClick={handleMoreMenuClick}
                                            sx={{
                                                color: '#6e3cfb',
                                                minWidth: '10px',
                                                float: 'right',
                                                fontSize: 16,
                                            }}
                                        >
                                            <Box
                                                component="span"
                                                ml={1}
                                                pt={'3px'}
                                            >
                                                <More
                                                    color={
                                                        isSelected
                                                            ? '#ffffff'
                                                            : '#6e3cfb'
                                                    }
                                                />
                                            </Box>
                                        </Typography>
                                    </Tooltip>
                                ) : (
                                    <Box
                                        component="span"
                                        ml={1}
                                        sx={{
                                            opacity: 0,
                                            color: '#6e3cfb',
                                            minWidth: '20px',
                                            float: 'right',
                                            fontSize: 16,
                                        }}
                                        pt={'3px'}
                                    >
                                        <More color={'#ffffff'} />
                                    </Box>
                                )}

                                {searchParam.get('query') && (
                                    <Button
                                        sx={{
                                            py:
                                                fileId == file.userFileId
                                                    ? '3px !important'
                                                    : 1,
                                            px:
                                                fileId == file.userFileId
                                                    ? '7px !important'
                                                    : 2,
                                            mt: 0,
                                            fontWeight: 400,
                                            float: 'right',
                                            color: '#6e3cfb',
                                            border:
                                                fileId == file.userFileId
                                                    ? '1px solid #6e3cfb'
                                                    : '',
                                            backgroundColor: isSelected
                                                ? '#FFFFFF'
                                                : '#383FA133',
                                            '&:hover': {
                                                color: '#6e3cfb',
                                                backgroundColor: isSelected
                                                    ? '#FFFFFF'
                                                    : '#383FA140',
                                            },
                                        }}
                                        component={NavigationLink}
                                        to={`/document-insight/matches/${file.userFileId}${window.location.search}`}
                                        variant="contained"
                                    >
                                        View Matches
                                    </Button>
                                )}
                                {tab == 2 && file.isOwner && (
                                    <Button
                                        sx={{
                                            py: 0,
                                            px: 2,
                                            fontSize: 14,
                                            maxHeight: 24,
                                            fontWeight: 600,
                                            float: 'right',
                                            backgroundColor: '#f9f5ff',
                                            '&:hover': {
                                                backgroundColor: '#f9f5ff',
                                            },
                                        }}
                                        startIcon={Unshare}
                                        onClick={() => setShareFile(true)}
                                        variant="outlined"
                                    >
                                        Unshare
                                    </Button>
                                )}
                                {file.isOwner && (
                                    <Menu
                                        id="more-menu"
                                        MenuListProps={{
                                            'aria-labelledby': 'long-button',
                                        }}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        sx={{
                                            py: 0,
                                        }}
                                        anchorEl={moreMenuEl}
                                        open={openMoreMenu}
                                        onClose={handleMoreMenuClose}
                                        PaperProps={{
                                            style: {
                                                maxHeight: ITEM_HEIGHT * 4.5,
                                                width: '18ch',
                                            },
                                        }}
                                    >
                                        <FileMenuItem
                                            onClick={() => {
                                                handleMoreMenuClose();
                                                setEditTags(true);
                                            }}
                                        >
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                            >
                                                <Doc size={18} />
                                                <Box
                                                    component="span"
                                                    sx={{ pl: 3 }}
                                                >
                                                    Edit File
                                                </Box>
                                            </Box>
                                        </FileMenuItem>
                                        <FileMenuItem
                                            onClick={() => {
                                                handleMoreMenuClose();
                                                setShareFile(true);
                                            }}
                                        >
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                            >
                                                <Share size={18} />
                                                <Box
                                                    component="span"
                                                    sx={{ pl: 3 }}
                                                >
                                                    {file.isShared
                                                        ? 'Revoke Sharing'
                                                        : 'Share'}
                                                </Box>
                                            </Box>
                                        </FileMenuItem>
                                        <FileMenuItem
                                            onClick={() => {
                                                viewDocumentClick();
                                            }}
                                        >
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                            >
                                                <FileDownloadOutlined
                                                    sx={{ fontSize: '18px' }}
                                                />
                                                <Box
                                                    component="span"
                                                    sx={{ pl: 3 }}
                                                >
                                                    Download
                                                </Box>
                                            </Box>
                                        </FileMenuItem>
                                        {!props.hideDelete && file.isOwner && (
                                            <FileMenuItem
                                                onClick={() => {
                                                    handleMoreMenuClose();
                                                    handleDeleteFileClick();
                                                }}
                                            >
                                                <Box
                                                    display="flex"
                                                    alignItems="center"
                                                >
                                                    <Delete size={18} />
                                                    <Box
                                                        component="span"
                                                        sx={{ pl: 3 }}
                                                    >
                                                        Delete File
                                                    </Box>
                                                </Box>
                                            </FileMenuItem>
                                        )}
                                    </Menu>
                                )}
                            </Grid>
                            <Box sx={{ width: '100%' }}>
                                {[0, 1].includes(tab) &&
                                    file &&
                                    !file.isOwner && (
                                        <FileInfo
                                            mb="4px !important"
                                            color={
                                                isSelected
                                                    ? '#ffffff !important'
                                                    : ''
                                            }
                                        >
                                            {(searchParam.get('query') ||
                                                searchParam.get('filters')) &&
                                                file &&
                                                !file.isOwner && (
                                                    <>
                                                        Shared File{' '}
                                                        <Circle
                                                            mt={0}
                                                            mx={4}
                                                            h={8}
                                                            w={8}
                                                        />{' '}
                                                    </>
                                                )}
                                            {UtilsService.formatStringDate(
                                                file.lastUpdated
                                            )}{' '}
                                            <Circle mt={0} mx={4} h={8} w={8} />{' '}
                                            {(searchParam.get('query') ||
                                                searchParam.get('filters')) &&
                                            file &&
                                            !file.isOwner ? (
                                                <>
                                                    Owner Name:
                                                    {file.ownerFirstName}{' '}
                                                    {file.ownerLastName}{' '}
                                                </>
                                            ) : (
                                                <>
                                                    {file.metadata && (
                                                        <span>
                                                            {UtilsService.getFileSize(
                                                                file.metadata
                                                                    .size
                                                            )}
                                                        </span>
                                                    )}
                                                </>
                                            )}
                                        </FileInfo>
                                    )}

                                {tab == 2 && (
                                    <FileInfo
                                        mb="4px !important"
                                        color={
                                            isSelected
                                                ? '#ffffff !important'
                                                : ''
                                        }
                                    >
                                        {file && !file.isOwner && (
                                            <>
                                                Owner: {file.ownerFirstName}{' '}
                                                {file.ownerLastName}{' '}
                                                <Circle
                                                    mt={0}
                                                    mx={4}
                                                    color="#D9D9D9"
                                                    h={8}
                                                    w={8}
                                                />{' '}
                                                {file.metadata && (
                                                    <span>
                                                        {UtilsService.formatStringDate(
                                                            file.lastUpdated
                                                        )}
                                                    </span>
                                                )}
                                            </>
                                        )}
                                        {file && file.isOwner && (
                                            <>
                                                Shared On:{' '}
                                                {UtilsService.formatStringDate(
                                                    file.sharedOn
                                                )}
                                            </>
                                        )}
                                    </FileInfo>
                                )}
                                {[0, 1].includes(tab) && file.isOwner && (
                                    <Box
                                        sx={{ flexWrap: 'wrap', width: '100%' }}
                                        display="flex"
                                    >
                                        {!file.tags?.length && file.isOwner && (
                                            <Box
                                                component={Button}
                                                onClick={() => {
                                                    setEditTags(true);
                                                }}
                                                sx={{
                                                    cursor: 'pointer',
                                                    p: 0,
                                                    mt: 1,
                                                    height: 24,
                                                    px: 1,
                                                    fontWeight: 600,
                                                    fontSize: 12,
                                                    border: '1px solid #6e3cfb',
                                                    backgroundColor: '#EBECF6',
                                                    minWidth: 0,
                                                    '&:hover': {
                                                        backgroundColor:
                                                            '#D7D9EC !important',
                                                    },
                                                }}
                                            >
                                                <Box
                                                    component="span"
                                                    pt={1}
                                                    mr={1}
                                                >
                                                    <Tag />
                                                </Box>
                                                ADD TAGS
                                            </Box>
                                        )}
                                        {file.tags &&
                                            file.isOwner &&
                                            file.tags.length > 0 && (
                                                <Typography
                                                    fontWeight={600}
                                                    fontSize={10}
                                                    pt={2.5}
                                                    pr={1}
                                                    color={
                                                        isSelected
                                                            ? '#ffffff !important'
                                                            : '#A5A6AB'
                                                    }
                                                >
                                                    Tags:
                                                </Typography>
                                            )}
                                        <Box
                                            sx={{
                                                maxWidth: 'calc(100% - 40px)',
                                            }}
                                        >
                                            {file.tags?.map((t: FileTag) => (
                                                <TagBlock
                                                    variant="outlined"
                                                    label={t.title}
                                                    sx={{
                                                        border: 'none',
                                                        backgroundColor:
                                                            '#EBECF6',
                                                        mr: 2,
                                                        mt: 2,
                                                    }}
                                                    size="small"
                                                    deleteIcon={
                                                        <Tooltip
                                                            arrow
                                                            placement="top"
                                                            title={`Remove`}
                                                        >
                                                            <CloseIcon
                                                                sx={{
                                                                    fontSize:
                                                                        '12px !important',
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    }
                                                    onDelete={
                                                        file.isOwner
                                                            ? () =>
                                                                  removeTag(
                                                                      t.userTagId
                                                                  )
                                                            : undefined
                                                    }
                                                />
                                            ))}
                                            {file.tags &&
                                                file.tags.length > 0 &&
                                                file.isOwner && (
                                                    <Tooltip
                                                        arrow
                                                        placement="top"
                                                        title={`Add tag`}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                padding: 0,
                                                                mt: 2,
                                                                pr: 0.25,
                                                                backgroundColor:
                                                                    '#EBECF6',
                                                                minWidth: 20,
                                                                minHeight: 20,
                                                                '&:hover': {
                                                                    backgroundColor:
                                                                        '#b0b2da',
                                                                },
                                                            }}
                                                            component={Button}
                                                            onClick={() => {
                                                                setEditTags(
                                                                    true
                                                                );
                                                            }}
                                                            variant="h6"
                                                        >
                                                            <Add color="#6e3cfb" />
                                                        </Typography>
                                                    </Tooltip>
                                                )}
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Divider />
        </Box>
    );
};
