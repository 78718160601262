// ==============================|| OVERRIDES - TAB ||============================== //

export default function Tab(theme: any) {
    return {
        MuiTab: {
            styleOverrides: {
                root: {
                    minHeight: 44,
                    fontSize: 16,
                    color: '#A5A6AB',
                    '&.Mui-selected': {
                        color: '#6e3cfb',
                        backgroundColor: '#EBECF6',
                        borderRadius: '8px 8px 0px 0px',
                    },
                },
            },
        },
    };
}
