import { Button, Switch, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { HeaderContainer } from '../../../components/header/container';
import { ViewModeText } from '../../../components/core/input/single-select-tab';
import { useEffect, useState } from 'react';
import { BorderedBox } from '../../../components/core/box';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Support } from '../../../components/support';
import { TourProvider, useTour } from '@reactour/tour';
import { FeatureName, SubFeatureName } from '../../../services/interfaces';
import { usePermissions } from '../../../providers/permissions-provider';
import { Authorize } from '../../../components/authorize';

export const Header = () => {
    const { setIsOpen } = useTour();
    const [rowNumber, setRowNumber] = useState<number>(0);
    const { isFeatureAuthorized, isSubFeatureAuthorized } = usePermissions();
    const [query, setQuery] = useState<string>('');
    const [searchParam] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        let insights = searchParam.get('insights');
        let query = searchParam.get('query');
        if (query) {
            setQuery(query);
        }
        if (
            insights &&
            isSubFeatureAuthorized(
                FeatureName.InstantInsights,
                SubFeatureName.InsightsMode
            )
        ) {
            setRowNumber(insights == 'true' ? 1 : 0);
        }
    }, [searchParam]);

    const updateRow = (value: number) => {
        setRowNumber(value);
        let _topicId = searchParam.get('id');
        if (value == 1 && window.location.href.indexOf('live') > -1) {
            navigate(
                `/research/overview?query=${encodeURIComponent(
                    query
                )}&id=${_topicId}&insights=true`
            );
        }
        if (value == 0 && window.location.href.indexOf('research') > -1) {
            navigate(
                `/live?query=${encodeURIComponent(query)}&id=${
                    _topicId ? _topicId : ''
                }&insights=false`
            );
        }
    };

    return (
        <HeaderContainer>
            <Grid sx={{ px: 8, py: 2 }} container>
                <Grid item xs={12}>
                    <Box display={'flex'} justifyContent={'space-between'}>
                        <Box sx={{ textAlign: 'left' }}>
                            <Typography variant="h2">
                                Forgepoint Live
                            </Typography>
                            <Typography sx={{ mt: 1 }} variant="subtitle2">
                                Get real-time insights on your search queries.
                            </Typography>
                        </Box>
                        <Box display="flex">
                            {query && (
                                <Box
                                    mt={4}
                                    display="flex"
                                    justifyContent="end"
                                    alignItems="center"
                                >
                                    <Box>
                                        <Typography color="#003" variant="h6">
                                            Insights
                                        </Typography>
                                    </Box>
                                    <BorderedBox
                                        sx={{
                                            borderRadius: '8px !important',
                                            ml: 2,
                                        }}
                                    >
                                        <Authorize
                                            featureName={
                                                FeatureName.InstantInsights
                                            }
                                            subFeatureName={
                                                SubFeatureName.InsightsMode
                                            }
                                        >
                                            <Grid
                                                width={100}
                                                sx={{
                                                    opacity:
                                                        isSubFeatureAuthorized(
                                                            FeatureName.InstantInsights,
                                                            SubFeatureName.InsightsMode
                                                        )
                                                            ? 1
                                                            : 0.5,
                                                }}
                                                container
                                            >
                                                <Grid
                                                    onClick={() => {
                                                        isSubFeatureAuthorized(
                                                            FeatureName.InstantInsights,
                                                            SubFeatureName.InsightsMode
                                                        ) && updateRow(0);
                                                    }}
                                                    item
                                                    xs={6}
                                                >
                                                    <ViewModeText
                                                        variant="body2"
                                                        py={1}
                                                        px={2}
                                                        sx={{
                                                            color:
                                                                rowNumber == 0
                                                                    ? '#FFF'
                                                                    : '#525252',
                                                            backgroundColor:
                                                                rowNumber == 0
                                                                    ? '#29319B'
                                                                    : '',
                                                        }}
                                                    >
                                                        OFF
                                                    </ViewModeText>
                                                </Grid>

                                                <Grid
                                                    onClick={() => {
                                                        isSubFeatureAuthorized(
                                                            FeatureName.InstantInsights,
                                                            SubFeatureName.InsightsMode
                                                        ) && updateRow(1);
                                                    }}
                                                    item
                                                    xs={6}
                                                >
                                                    <ViewModeText
                                                        variant="body2"
                                                        py={1}
                                                        px={2}
                                                        sx={{
                                                            color:
                                                                rowNumber == 1
                                                                    ? '#FFF'
                                                                    : '#525252',
                                                            backgroundColor:
                                                                rowNumber == 1
                                                                    ? '#29319B'
                                                                    : '',
                                                        }}
                                                    >
                                                        ON
                                                    </ViewModeText>
                                                </Grid>
                                            </Grid>
                                        </Authorize>
                                    </BorderedBox>
                                </Box>
                            )}
                            <Box display="flex">
                                {/* <Button
                                    variant="contained"
                                    onClick={() => setIsOpen(true)}
                                >
                                    Start Tour
                                </Button> */}
                                <Box mt={4} ml={5}>
                                    <Support feature="photonLive" />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </HeaderContainer>
    );
};
