import { Box, Divider, Grid, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { BorderedBox } from '../../../../../components/core/box';
import { Avator } from '../../../../../components/icons/avator';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useFinancialsService } from '../../../../../services/financials.service';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
    EarningFilter,
    EventStatements,
    FinancialEventsResponseData,
    FinancialsSummaryResponseData,
    PersonalityTraitData,
    Statements,
} from '../../../../../services/interfaces';
import { TextHighlight } from '../../../../../components/sentiment-highlights';
import { ToggleHighLight } from '../../../../../components/toggle-highlight';
import { Statement } from './statement';
import { PersonalityTraits } from './personality-trait';
import { Subscribe } from '../../../../../utils/subscribe';
import { LockIcon } from '../../../../../components/icons/lock';
import { AppContext } from '../../../../../providers/app-provider';

interface IProps {
    selectedEarningFilter: EarningFilter | null;
    topicSubscriptionId: string;
    query: string;
}

export const Summary = (props: IProps) => {
    const topicSubscriptionId = props.topicSubscriptionId;
    const appContext = useContext(AppContext);
    const {
        fetchFinancialsSummary,
        fetchFinancialsSummaryEvents,
        fetchPersonalityTraits,
    } = useFinancialsService();
    const [open, setOpen] = React.useState<string>('');
    const [openTrait, setOpenTrait] = React.useState<boolean>(false);
    const [highlightSection, setHighlightSection] =
        React.useState<string>('events');
    const [financialSummary, setFinancialSummary] =
        useState<FinancialsSummaryResponseData | null>(null);
    const [summaries, setSummaries] = useState<EventStatements[]>([]);
    const [financialSummaryEvents, setFinancialSummaryEvents] =
        useState<FinancialEventsResponseData | null>(null);

    const [personalityTrait, setPersonalityTrait] =
        useState<PersonalityTraitData | null>(null);

    const [scrollY, setScrollY] = useState(0);

    function logit() {
        if (window.pageYOffset > 0) {
            setScrollY(window.pageYOffset);
        }
    }

    useEffect(() => {
        function watchScroll() {
            window.addEventListener('auto', logit);
        }
        watchScroll();
        return () => {
            window.removeEventListener('auto', logit);
        };
    });

    const refreshFinancialsSummary = async () => {
        if (topicSubscriptionId && props.selectedEarningFilter) {
            let data = await fetchFinancialsSummary({
                financeCategory: 'earnings_calls',
                topicSubscriptionId: topicSubscriptionId,
                year: props.selectedEarningFilter.year,
                quarter: props.selectedEarningFilter.quarter,
            });
            setFinancialSummary({ ...data });
        }
    };

    const refreshFinancialsSummaryEvents = async () => {
        if (topicSubscriptionId && props.selectedEarningFilter) {
            let data = await fetchFinancialsSummaryEvents({
                financeCategory: 'earnings_calls',
                topicSubscriptionId: topicSubscriptionId,
                year: props.selectedEarningFilter.year,
                quarter: props.selectedEarningFilter.quarter,
            });
            setFinancialSummaryEvents({ ...data });
        }
    };

    const refreshPersonalityTraits = async () => {
        if (topicSubscriptionId && props.selectedEarningFilter) {
            let data = await fetchPersonalityTraits({
                financeCategory: 'earnings_calls',
                topicSubscriptionId: topicSubscriptionId,
                year: props.selectedEarningFilter.year,
                quarter: props.selectedEarningFilter.quarter,
            });
            setPersonalityTrait({ ...data });
        }
    };

    useEffect(() => {
        updateEventsData(open);
    }, [financialSummaryEvents, financialSummary]);

    useEffect(() => {
        refreshFinancialsSummary();
        refreshFinancialsSummaryEvents();
        refreshPersonalityTraits();
    }, [topicSubscriptionId, props.selectedEarningFilter]);

    useEffect(() => {
        if (!open && financialSummary && financialSummaryEvents) {
            handleClick('forward');
        }
    }, [financialSummary, financialSummaryEvents]);

    const updateEventsData = (id: string) => {
        if (id == 'forward' && financialSummaryEvents && financialSummary) {
            setSummaries([...financialSummaryEvents.forwardLookingStatements]);
        } else if (
            id == 'positives' &&
            financialSummary &&
            financialSummaryEvents
        ) {
            setSummaries([...financialSummaryEvents.keyPositives]);
        } else if (
            id == 'negatives' &&
            financialSummary &&
            financialSummaryEvents
        ) {
            setSummaries([...financialSummaryEvents.keyNegatives]);
        } else if ((id == 'entire' || id == 'management') && financialSummary) {
            setSummaries([
                ...financialSummary.summaries.map(
                    (value: Statements): EventStatements => {
                        return {
                            orderId: value.speakerTexts[0].orderId,
                            rawText: '',
                            summary: '',
                            speakerName: value.speakerName,
                            speakerRole: value.speakerRole,
                        };
                    }
                ),
            ]);
        } else if (id == 'qna' && financialSummary) {
            setSummaries([
                ...financialSummary.summaries
                    .filter(
                        (s: Statements) =>
                            s.sections &&
                            s.sections.level1 == 'Q&A' &&
                            s.sections.level2 == 'Question'
                    )
                    .map((value: Statements): EventStatements => {
                        return {
                            orderId: value.speakerTexts[0].orderId,
                            rawText: value.speakerTexts[0].rawText,
                            summary: '',
                            speakerName: value.speakerName,
                            speakerRole: value.speakerRole,
                        };
                    }),
            ]);
        }
    };

    useEffect(() => {
        updateEventsData(open);
    }, [open]);

    const handleClick = (id: string) => {
        if (topicSubscriptionId) {
            setOpenTrait(false);
            if (open == id) {
                setOpen('');
            } else {
                setOpen(id);
            }
        }
    };

    const handleTraitOpen = () => {
        setOpenTrait(true);
        setOpen('');
    };
    return (
        <BorderedBox sx={{ backgroundColor: '#FFFFFF' }}>
            <Box px={4}>
                <Grid container>
                    <Grid item sm={8}>
                        <Typography py={2} variant="h4">
                            Earnings Calls - Event Based Analysis
                        </Typography>
                    </Grid>
                    <Grid item sm={4}>
                        {!openTrait && (
                            <Box sx={{ float: 'right', pt: 1.5 }}>
                                <ToggleHighLight size={20} />
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <Divider />
            </Box>
            <Grid px={0} container>
                <Grid
                    item
                    sm={4}
                    sx={{
                        maxHeight: `calc(100vh -$ {appContext?.accountActionBannerVisible?'22px':'0px'} - 340px)`,
                        overflowY: 'auto',
                    }}
                >
                    <Grid container>
                        <Grid
                            py={2}
                            onClick={() => setHighlightSection('events')}
                            sx={
                                highlightSection == 'events'
                                    ? {
                                          backgroundColor: '#F5F5FA',
                                          borderBottom: '4px solid #6e3cfb',
                                      }
                                    : {
                                          cursor: 'pointer',
                                      }
                            }
                            item
                            sm={6}
                        >
                            <Typography
                                textAlign="center"
                                fontWeight={500}
                                color={
                                    highlightSection == 'events'
                                        ? '#6e3cfb'
                                        : '#A5A6AB'
                                }
                                variant="h6"
                            >
                                Events
                            </Typography>
                        </Grid>
                        <Grid
                            py={2}
                            onClick={() => setHighlightSection('section')}
                            sx={
                                highlightSection == 'section'
                                    ? {
                                          backgroundColor: '#F5F5FA',
                                          borderBottom: '4px solid #6e3cfb',
                                      }
                                    : {
                                          cursor: 'pointer',
                                      }
                            }
                            item
                            sm={6}
                        >
                            <Typography
                                color={
                                    highlightSection == 'section'
                                        ? '#6e3cfb'
                                        : '#A5A6AB'
                                }
                                textAlign="center"
                                fontWeight={500}
                                variant="h6"
                            >
                                Sections
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider
                        sx={{
                            borderColor: '#F5F5FA',
                            borderWidth: 1,
                        }}
                    />
                    {highlightSection == 'events' && (
                        <Box>
                            <List
                                sx={{ width: '100%', p: 0 }}
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                            >
                                <ListItemButton
                                    sx={{
                                        bgcolor:
                                            open == 'forward'
                                                ? '#f9f5ff'
                                                : '#fff',
                                    }}
                                    onClick={() => handleClick('forward')}
                                >
                                    <ListItemIcon sx={{ minWidth: 16 }}>
                                        <Box
                                            height={8}
                                            width={8}
                                            sx={{
                                                backgroundColor: '#6B22E3',
                                                borderRadius: '50%',
                                            }}
                                        ></Box>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={{
                                            fontSize: 16,
                                            fontWeight: 600,
                                        }}
                                        primary="Forward Looking Statements"
                                    />
                                    {!topicSubscriptionId ? (
                                        <LockIcon color="#161616" />
                                    ) : open == 'forward' ? (
                                        <ExpandLess />
                                    ) : (
                                        <ExpandMore />
                                    )}
                                </ListItemButton>
                                {open == 'forward' ? null : (
                                    <Divider
                                        sx={{
                                            borderColor: '#F5F5FA',
                                            borderWidth: 0.5,
                                        }}
                                    />
                                )}
                                <Collapse
                                    in={open == 'forward'}
                                    timeout={100}
                                    unmountOnExit
                                >
                                    <List component="div" disablePadding>
                                        <Box
                                            className="scrollable"
                                            sx={{
                                                maxHeight:
                                                    'calc(100vh - 625px)',
                                                overflow: 'auto',
                                                pt: 4,
                                            }}
                                        >
                                            {summaries.map(
                                                (s: EventStatements, index) => (
                                                    <Statement
                                                        isLast={
                                                            summaries.length ==
                                                            index + 1
                                                        }
                                                        data={s}
                                                    />
                                                )
                                            )}
                                        </Box>
                                    </List>
                                </Collapse>
                            </List>
                            <List
                                sx={{ width: '100%', p: 0 }}
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                            >
                                <ListItemButton
                                    sx={{
                                        bgcolor:
                                            open == 'positives'
                                                ? '#f9f5ff'
                                                : '#fff',
                                    }}
                                    onClick={() => handleClick('positives')}
                                >
                                    <ListItemIcon sx={{ minWidth: 16 }}>
                                        <Box
                                            height={8}
                                            width={8}
                                            sx={{
                                                backgroundColor: '#3BE37E',
                                                borderRadius: '50%',
                                            }}
                                        ></Box>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={{
                                            fontSize: 16,
                                            fontWeight: 600,
                                        }}
                                        primary="Positive Indicators"
                                    />
                                    {!topicSubscriptionId ? (
                                        <LockIcon color="#161616" />
                                    ) : open == 'positives' ? (
                                        <ExpandLess />
                                    ) : (
                                        <ExpandMore />
                                    )}
                                </ListItemButton>
                                {open == 'positives' ? null : (
                                    <Divider
                                        sx={{
                                            borderColor: '#F5F5FA',
                                            borderWidth: 0.5,
                                        }}
                                    />
                                )}
                                <Collapse
                                    in={open == 'positives'}
                                    timeout={100}
                                    unmountOnExit
                                >
                                    <List component="div" disablePadding>
                                        <Box
                                            className="scrollable"
                                            sx={{
                                                maxHeight:
                                                    'calc(100vh - 625px)',
                                                overflow: 'auto',
                                                pt: 4,
                                            }}
                                        >
                                            {summaries.map(
                                                (s: EventStatements, index) => (
                                                    <Statement
                                                        isLast={
                                                            summaries.length ==
                                                            index + 1
                                                        }
                                                        data={s}
                                                    />
                                                )
                                            )}
                                        </Box>
                                    </List>
                                </Collapse>
                            </List>
                            <List
                                sx={{ width: '100%', p: 0 }}
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                            >
                                <ListItemButton
                                    sx={{
                                        bgcolor:
                                            open == 'negatives'
                                                ? '#f9f5ff'
                                                : '#fff',
                                    }}
                                    onClick={() => handleClick('negatives')}
                                >
                                    <ListItemIcon sx={{ minWidth: 16 }}>
                                        <Box
                                            height={8}
                                            width={8}
                                            sx={{
                                                backgroundColor: '#E26573',
                                                borderRadius: '50%',
                                            }}
                                        ></Box>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={{
                                            fontSize: 16,
                                            fontWeight: 600,
                                        }}
                                        primary="Negative Indicators"
                                    />
                                    {!topicSubscriptionId ? (
                                        <LockIcon color="#161616" />
                                    ) : open == 'negatives' ? (
                                        <ExpandLess />
                                    ) : (
                                        <ExpandMore />
                                    )}
                                </ListItemButton>
                                {open == 'negatives' ? null : (
                                    <Divider
                                        sx={{
                                            borderColor: '#F5F5FA',
                                            borderWidth: 0.5,
                                        }}
                                    />
                                )}
                                <Collapse
                                    in={open == 'negatives'}
                                    timeout={100}
                                    unmountOnExit
                                >
                                    <List component="div" disablePadding>
                                        <Box
                                            className="scrollable"
                                            sx={{
                                                maxHeight:
                                                    'calc(100vh - 625px)',
                                                overflow: 'auto',
                                                pt: 4,
                                            }}
                                        >
                                            {summaries.map(
                                                (s: EventStatements, index) => (
                                                    <Statement
                                                        isLast={
                                                            summaries.length ==
                                                            index + 1
                                                        }
                                                        data={s}
                                                    />
                                                )
                                            )}
                                        </Box>
                                    </List>
                                </Collapse>
                            </List>
                            <List
                                sx={{ width: '100%', p: 0 }}
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                            >
                                <ListItemButton
                                    sx={{
                                        bgcolor:
                                            open == 'negatives'
                                                ? '#f9f5ff'
                                                : '#fff',
                                    }}
                                    onClick={() => {
                                        handleTraitOpen();
                                    }}
                                >
                                    <ListItemIcon sx={{ minWidth: 16 }}>
                                        <Box
                                            height={8}
                                            width={8}
                                            sx={{
                                                backgroundColor: '#FF832B',
                                                borderRadius: '50%',
                                            }}
                                        ></Box>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={{
                                            fontSize: 16,
                                            fontWeight: 600,
                                        }}
                                        primary="Personality Traits"
                                    />
                                    {!topicSubscriptionId && (
                                        <LockIcon color="#161616" />
                                    )}
                                </ListItemButton>
                            </List>
                        </Box>
                    )}
                    {highlightSection == 'section' && (
                        <Box>
                            <List
                                sx={{ width: '100%', p: 0 }}
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                            >
                                <ListItemButton
                                    sx={{
                                        bgcolor:
                                            open == 'entire'
                                                ? '#f9f5ff'
                                                : '#fff',
                                    }}
                                    onClick={() => handleClick('entire')}
                                >
                                    <ListItemText
                                        primaryTypographyProps={{
                                            fontSize: 16,
                                            fontWeight: 600,
                                        }}
                                        primary="Full Earnings Calls"
                                    />
                                    {!topicSubscriptionId && (
                                        <LockIcon color="#161616" />
                                    )}
                                    {topicSubscriptionId && (
                                        <ArrowForwardIosIcon
                                            sx={{
                                                color: '#A5A6AB',
                                                fontSize: '14px',
                                                mr: 1,
                                            }}
                                            fontSize="small"
                                        />
                                    )}
                                </ListItemButton>
                            </List>
                            <Divider
                                sx={{
                                    borderColor: '#F5F5FA',
                                    borderWidth: 1,
                                }}
                            />

                            <List
                                sx={{ width: '100%', p: 0 }}
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                            >
                                <ListItemButton
                                    sx={{
                                        bgcolor:
                                            open == 'management'
                                                ? '#f9f5ff'
                                                : '#fff',
                                    }}
                                    onClick={() => handleClick('management')}
                                >
                                    <ListItemText
                                        primaryTypographyProps={{
                                            fontSize: 16,
                                            fontWeight: 600,
                                        }}
                                        primary="Management Discussion"
                                    />
                                    {!topicSubscriptionId ? (
                                        <LockIcon color="#161616" />
                                    ) : open == 'management' ? (
                                        <ExpandLess />
                                    ) : (
                                        <ExpandMore />
                                    )}
                                </ListItemButton>
                                {open == 'management' ? null : (
                                    <Divider
                                        sx={{
                                            borderColor: '#F5F5FA',
                                            borderWidth: 0.5,
                                        }}
                                    />
                                )}
                                <Collapse
                                    in={open == 'management'}
                                    timeout={100}
                                    unmountOnExit
                                >
                                    <List component="div" disablePadding>
                                        <Box
                                            className="scrollable"
                                            sx={{
                                                maxHeight:
                                                    'calc(100vh - 580px)',
                                                overflow: 'auto',
                                                pt: 4,
                                            }}
                                        >
                                            {summaries
                                                .reduce(
                                                    (
                                                        arr: EventStatements[],
                                                        s: EventStatements
                                                    ) => {
                                                        if (
                                                            !arr
                                                                .map(
                                                                    (
                                                                        st: EventStatements
                                                                    ) =>
                                                                        st.speakerName +
                                                                        st.speakerRole
                                                                )
                                                                .includes(
                                                                    s.speakerName +
                                                                        s.speakerRole
                                                                )
                                                        ) {
                                                            arr.push(s);
                                                        }

                                                        return arr;
                                                    },
                                                    []
                                                )
                                                .map(
                                                    (
                                                        s: EventStatements,
                                                        index,
                                                        arr
                                                    ) => (
                                                        <Statement
                                                            data={s}
                                                            isLast={
                                                                arr.length ==
                                                                index + 1
                                                            }
                                                            hideText={true}
                                                        />
                                                    )
                                                )}
                                        </Box>
                                    </List>
                                </Collapse>
                            </List>
                            <List
                                sx={{ width: '100%', p: 0 }}
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                            >
                                <ListItemButton
                                    sx={{
                                        bgcolor:
                                            open == 'qna' ? '#f9f5ff' : '#fff',
                                    }}
                                    onClick={() => handleClick('qna')}
                                >
                                    <ListItemText
                                        primaryTypographyProps={{
                                            fontSize: 16,
                                            fontWeight: 600,
                                        }}
                                        primary="Question and Answers"
                                    />
                                    {!topicSubscriptionId ? (
                                        <LockIcon color="#161616" />
                                    ) : open == 'negatives' ? (
                                        <ExpandLess />
                                    ) : (
                                        <ExpandMore />
                                    )}
                                </ListItemButton>
                                <Collapse
                                    in={open == 'qna'}
                                    timeout={100}
                                    unmountOnExit
                                >
                                    <List component="div" disablePadding>
                                        <Box
                                            className="scrollable"
                                            sx={{
                                                maxHeight:
                                                    'calc(100vh - 580px)',
                                                overflow: 'auto',
                                                pt: 4,
                                            }}
                                        >
                                            {summaries.map(
                                                (s: EventStatements, index) => (
                                                    <Statement
                                                        isQuestion={true}
                                                        data={s}
                                                        isLast={
                                                            summaries.length ==
                                                            index + 1
                                                        }
                                                    />
                                                )
                                            )}
                                        </Box>
                                    </List>
                                </Collapse>
                            </List>
                        </Box>
                    )}
                </Grid>
                <Divider flexItem orientation="vertical" />
                <Grid
                    item
                    sx={{
                        width: 'calc(66.666666% - 1px)',
                        maxHeight: 'calc(100vh - 340px)',
                        overflowY: 'auto',
                    }}
                >
                    {!openTrait && financialSummary && (
                        <Box>
                            <Typography
                                px={4}
                                fontSize={20}
                                pt={2}
                                variant="h5"
                            >
                                Full Call Transcript
                            </Typography>
                            <Box
                                className="scrollable"
                                sx={
                                    scrollY > 0
                                        ? {
                                              maxHeight: 'calc(100vh - 220px)',
                                              overflow: 'auto',
                                          }
                                        : {}
                                }
                                pt={2}
                                mt={2}
                            >
                                <Box px={4}>
                                    {financialSummary?.summaries.map(
                                        (fs: Statements) => (
                                            <Box>
                                                <Box>
                                                    <Box pb={1} display="flex">
                                                        {!fs.sections?.level1 ||
                                                        fs.sections?.level1 !==
                                                            'Q&A' ? (
                                                            <Box pt={0.5}>
                                                                <Avator
                                                                    size={20}
                                                                />
                                                            </Box>
                                                        ) : fs.sections
                                                              .level2 ==
                                                          'Question' ? (
                                                            <Typography
                                                                color="#E59413"
                                                                mr={2}
                                                                variant="subtitle1"
                                                            >
                                                                Question
                                                            </Typography>
                                                        ) : (
                                                            <Typography
                                                                color="#585960"
                                                                mr={
                                                                    '8px !important'
                                                                }
                                                                variant="subtitle1"
                                                            >
                                                                Answer
                                                            </Typography>
                                                        )}
                                                        {fs.sections?.level1 ==
                                                            'Q&A' && (
                                                            <Divider
                                                                flexItem
                                                                sx={{
                                                                    background:
                                                                        '#E3E3E3',
                                                                }}
                                                                orientation="vertical"
                                                            />
                                                        )}
                                                        <Typography
                                                            color="#000033"
                                                            ml={2}
                                                            mr={2}
                                                            fontWeight={600}
                                                            fontSize={16}
                                                            variant="subtitle1"
                                                        >
                                                            {fs.speakerName}
                                                        </Typography>
                                                        <Divider
                                                            flexItem
                                                            sx={{
                                                                background:
                                                                    '#E3E3E3',
                                                            }}
                                                            orientation="vertical"
                                                        />
                                                        <Typography
                                                            mx={2}
                                                            variant="caption"
                                                            pt={1.5}
                                                            color="#8D8D8D"
                                                            fontSize={14}
                                                        >
                                                            {fs.speakerRole}
                                                        </Typography>
                                                    </Box>
                                                    {fs.speakerTexts.map(
                                                        (st) => (
                                                            <Box
                                                                id={`order${st.orderId}`}
                                                                py={1}
                                                            >
                                                                <Typography
                                                                    textColor="#4B4B4C"
                                                                    variant="caption"
                                                                    pt={4}
                                                                    text={
                                                                        st.rawText
                                                                    }
                                                                    highlight={
                                                                        st.highlights
                                                                    }
                                                                    component={
                                                                        TextHighlight
                                                                    }
                                                                    fontSize={
                                                                        14
                                                                    }
                                                                ></Typography>
                                                            </Box>
                                                        )
                                                    )}
                                                </Box>
                                                <Box pb={4} pt={4}>
                                                    <Divider
                                                        sx={{
                                                            borderColor:
                                                                '#D7D9EC',
                                                        }}
                                                    />
                                                </Box>
                                            </Box>
                                        )
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    )}
                    {openTrait && financialSummary && (
                        <PersonalityTraits
                            personalityTraitData={personalityTrait}
                        />
                    )}
                    {/* {!financialSummary && !props.topicSubscriptionId && (
                        <Box position={'relative'}>
                            <Box
                                sx={{
                                    background:
                                        'linear-gradient(180deg, rgba(147, 158, 255, 0.25) 0%, rgba(255, 255, 255, 0.25) 100%)',
                                }}
                            >
                                <Typography
                                    px={4}
                                    fontSize={20}
                                    pt={2}
                                    variant="h5"
                                >
                                    Full Call Transcript
                                </Typography>
                                <Box
                    className="scrollable"
                                    sx={
                                        scrollY > 0
                                            ? {
                                                  maxHeight:
                                                      'calc(100vh - 220px)',
                                                  overflow: 'auto',
                                              }
                                            : {}
                                    }
                                    pt={2}
                                    mt={2}
                                >
                                    <Box px={4}>
                                        {[
                                            {
                                                speakerName: 'Elon Musk',
                                                speakerRole: 'CEO',
                                                sections: { level1: 'string' },
                                                speakerTexts:
                                                    "Regarding Autopilot and AI, our vehicles are now driven over 0.5 billion miles with FSD beta, full self-driving beta, and that number is growing rapidly. We recently completed a 10,000 GPU cluster of H100s. We think probably bring it into operation faster than anyone's ever brought that much compute per unit time into production, since training is the fundamental limiting factor on progress with full self-driving and vehicle autonomy.",
                                            },
                                            {
                                                speakerName: 'Elon Musk',
                                                speakerRole: 'CEO',
                                                sections: { level1: 'string' },
                                                speakerTexts:
                                                    "Regarding Autopilot and AI, our vehicles are now driven over 0.5 billion miles with FSD beta, full self-driving beta, and that number is growing rapidly. We recently completed a 10,000 GPU cluster of H100s. We think probably bring it into operation faster than anyone's ever brought that much compute per unit time into production, since training is the fundamental limiting factor on progress with full self-driving and vehicle autonomy.",
                                            },
                                            {
                                                speakerName: 'Elon Musk',
                                                speakerRole: 'CEO',
                                                sections: { level1: 'string' },
                                                speakerTexts:
                                                    "Regarding Autopilot and AI, our vehicles are now driven over 0.5 billion miles with FSD beta, full self-driving beta, and that number is growing rapidly. We recently completed a 10,000 GPU cluster of H100s. We think probably bring it into operation faster than anyone's ever brought that much compute per unit time into production, since training is the fundamental limiting factor on progress with full self-driving and vehicle autonomy.",
                                            },
                                        ].map((fs: any) => (
                                            <Box>
                                                <Box>
                                                    <Box pb={1} display="flex">
                                                        {!fs.sections?.level1 ||
                                                        fs.sections?.level1 !==
                                                            'Q&A' ? (
                                                            <Box pt={0.5}>
                                                                <Avator
                                                                    size={20}
                                                                />
                                                            </Box>
                                                        ) : (
                                                            <Typography
                                                                color="#585960"
                                                                mr={
                                                                    '8px !important'
                                                                }
                                                                variant="subtitle1"
                                                            >
                                                                Answer
                                                            </Typography>
                                                        )}
                                                        {fs.sections?.level1 ==
                                                            'Q&A' && (
                                                            <Divider
                                                                flexItem
                                                                sx={{
                                                                    background:
                                                                        '#E3E3E3',
                                                                }}
                                                                orientation="vertical"
                                                            />
                                                        )}
                                                        <Typography
                                                            color="#000033"
                                                            ml={2}
                                                            mr={2}
                                                            fontWeight={600}
                                                            fontSize={16}
                                                            variant="subtitle1"
                                                        >
                                                            {fs.speakerName}
                                                        </Typography>
                                                        <Divider
                                                            flexItem
                                                            sx={{
                                                                background:
                                                                    '#E3E3E3',
                                                            }}
                                                            orientation="vertical"
                                                        />
                                                        <Typography
                                                            mx={2}
                                                            variant="caption"
                                                            pt={1.5}
                                                            color="#8D8D8D"
                                                            fontSize={14}
                                                        >
                                                            {fs.speakerRole}
                                                        </Typography>
                                                    </Box>

                                                    <Box py={1}>
                                                        <Typography
                                                            variant="caption"
                                                            pt={4}
                                                            fontSize={14}
                                                        >
                                                            {fs.speakerTexts}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <Box pb={4} pt={4}>
                                                    <Divider
                                                        sx={{
                                                            borderColor:
                                                                '#D7D9EC',
                                                        }}
                                                    />
                                                </Box>
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>
                            </Box>
                            <Subscribe
                                message="Unlock Exclusive Insights: Subscribe to Access High-Level Earnings Call Highlights, Event-Based Sentiment Analysis, and Forward-Looking Statements"
                                query={props.query}
                                wideBox={true}
                            />
                        </Box>
                    )} */}
                </Grid>
            </Grid>
        </BorderedBox>
    );
};
