import { IIconProps, IMenuItem } from './menu-item.interface';
import * as React from 'react';
import { Dashboard } from '../../icons/dashboard';
import { SearchInsights } from '../../icons/search-insights';
import { Comparison } from '../../icons/comparison';
import InitialsAvator from '../../core/avator';
import { PhotonLive } from '../../icons/photon-live';
import { MyAccount } from '../../icons/my-account';
import { HelpCenter } from '../../icons/help-center';
import { HowToUse } from '../../icons/how-to-use';
import { LogOut } from '../../icons/log-out';
import { CirGenerator } from '../../icons/cir-generator';
import { DocumentInsight } from '../../icons/document-insight';
import { CompanyAnalysis } from '../../icons/company-analysis';
import { ChatWidgetIcon } from '../../icons/chat-widget';
import { CustomIntelligence } from '../../icons/custom-intelligence';
import { PasswordResetIcon } from '../../icons/password-reset-icon';
import { Live } from '../../icons/live';
import { LiveInsights } from '../../icons/live-insights';
import { AccountCircle } from '../../icons/account-circle';
import { Logout, SmsOutlined, Star, StarBorder } from '@mui/icons-material';
import { DiligenceSuite } from '../../icons/diligence-suite';

const DashboardItem: IMenuItem = {
    title: 'Dashboard',
    link: '/dashboard',
    description: 'Create watchlists for selected topics',
    permissions: ['TOPIC_INSIGHTS_FEATURE'],
    icon: (props: IIconProps) => {
        return <Dashboard fillColor={props.fillColor} />;
    },
};

const DocumentInsightItem: IMenuItem = {
    title: 'Docuinsights',
    link: '/document-insight',
    description:
        'For your custom data - from pdfs to docs to Youtube videos, etc. - obtain concise summarizations, and ask questions across files of interest',
    permissions: ['DOCUMENT_INSIGHTS_FEATURE'],
    icon: (props: IIconProps) => {
        return <CustomIntelligence fillColor={props.fillColor} />;
    },
};
const YouGPTItem: IMenuItem = {
    title: 'Forgepoint Chat',
    link: '/chat-widget',
    description: 'Custom chatbot based on your uploaded documents',
    permissions: ['CHATBOT_FEATURE'],
    icon: (props: IIconProps) => {
        return (
            <SmsOutlined
                sx={{
                    color: props.fillColor,
                }}
            />
        );
    },
};
const LiveItem: IMenuItem = {
    title: 'Forgepoint Live',
    link: '/',
    description: 'For any query, obtain quick insights',
    permissions: ['LIVE_QA'],
    icon: (props: IIconProps) => {
        return <Live fillColor={props.fillColor} />;
    },
};

const Research: IMenuItem = {
    title: 'Forgepoint Live',
    link: '/',
    description: 'Get insights on created topic groups',
    permissions: ['LIVE_QA_FEATURE'],
    icon: (props: IIconProps) => {
        return <Live fillColor={props.fillColor} />;
    },
};

const Diligence: IMenuItem = {
    title: 'Diligence Suite',
    link: '/diligence-suite',
    description: 'Get insights on created topic groups',
    permissions: ['DILIGENCE_FEATURE'],
    icon: (props: IIconProps) => {
        return <DiligenceSuite fillColor={props.fillColor} />;
    },
};

export const MenuItems: IMenuItem[] = [
    Research,
    // LiveItem,
    // LiveInsightsItem,
    DocumentInsightItem,
    // YouGPTItem,
    Diligence,
    // DashboardItem,
    // ComparisonItem,
    // CIRItem,
    // CompanyAnalysisItem,
];

const UserItem = (userName: string): IMenuItem => {
    return {
        title: userName,
        link: '/logout',
        icon: (props: IIconProps) => {
            return (
                <InitialsAvator
                    height={24}
                    width={24}
                    backgroundColor="#D9D9D9"
                    color="#003"
                    value={userName}
                />
            );
        },
        sx: { borderTop: '1px solid #ccc', backgroundColor: '#f9f5ff' },
        description: 'My Profile',
        children: [
            {
                title: 'Account',
                link: '/my-account',
                icon: (props: IIconProps) => {
                    return <AccountCircle fillColor={props.fillColor} />;
                },
            },
            // {
            //     title: 'Watchlists & Alerts',
            //     link: '/watchlists-and-alerts',
            //     icon: (props: IIconProps) => {
            //         return <StarBorder sx={{ color: props.fillColor }} />;
            //     },
            // },
            {
                title: 'Logout',
                link: '/logout',
                icon: (props: IIconProps) => {
                    return <Logout sx={{ color: props.fillColor }} />;
                },
            },
        ],
    };
};

export const FooterMenuItems: (
    | IMenuItem
    | ((userName: string) => IMenuItem)
)[] = [UserItem];

export const getMenuItems = (
    items: IMenuItem[],
    userPermissions: string[]
): IMenuItem[] => {
    if (userPermissions.includes('ALL')) {
        return items;
    }
    let menuItems: IMenuItem[] = [];
    for (let item of items) {
        if (item.children) {
            item.children = getMenuItems(item.children, userPermissions);
        }
        if (!item.permissions) {
            menuItems.push(item);
        } else {
            if (item.permissions.every((v) => userPermissions.includes(v))) {
                menuItems.push(item);
            }
        }
    }
    return menuItems;
};
