import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    BorderedContainedBox,
    ContainedBox,
} from '../../../../../components/core/box';
import { NegativeInsight } from '../../../../../components/icons/negative-insight';
import { PositiveInsight } from '../../../../../components/icons/positive-insight';
import { useFinancialsService } from '../../../../../services/financials.service';
import {
    EarningFilter,
    EarningsCall,
    EventStatements,
    EventsKeys,
    KeyInsightsResponseData,
    PositiveSummary,
} from '../../../../../services/interfaces';

interface IProps {
    earningCall: EarningsCall;
}

export const KeyInsights = (props: IProps) => {
    const { earningCall } = props;
    return (
        <BorderedContainedBox sx={{ backgroundColor: '#f9f5ff' }}>
            <Typography color="#000033" fontWeight={600} variant="h4">
                Key Financial Insights from Management Discussion
            </Typography>
            <Grid spacing={4} container>
                <Grid item sm={6}>
                    <Box pt={2} pl={'0px !important'}>
                        {earningCall.insights.keyInsights.keyPositives?.map(
                            (ps: EventStatements) => (
                                <Box my={2} display="flex">
                                    <Box pt={0.5} sx={{ width: 16 }}>
                                        {PositiveInsight}
                                    </Box>
                                    <Typography
                                        pl={2}
                                        fontSize={14}
                                        color="#4B4B4C"
                                        lineHeight={'20px'}
                                        variant="caption"
                                    >
                                        {ps.summary}
                                    </Typography>
                                </Box>
                            )
                        )}
                    </Box>
                </Grid>
                <Grid item sm={6}>
                    <Box pt={2}>
                        {earningCall.insights.keyInsights.keyNegatives?.map(
                            (ps: EventStatements) => (
                                <Box my={2} display="flex">
                                    <Box pt={1} sx={{ width: 16 }}>
                                        {NegativeInsight}
                                    </Box>
                                    <Typography
                                        pl={2}
                                        fontSize={14}
                                        lineHeight={'20px'}
                                        color="#4B4B4C"
                                        variant="caption"
                                    >
                                        {ps.summary}
                                    </Typography>
                                </Box>
                            )
                        )}
                    </Box>
                </Grid>
            </Grid>
        </BorderedContainedBox>
    );
};
