import {
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    Tooltip,
    Typography,
} from '@mui/material';
import React, { createRef, useContext, useEffect, useState } from 'react';
import { Circle } from '../../../components/icons/circle';
import { FileIcon } from '../../../components/icons/file';
import {
    NegativeLinearProgress,
    PositiveLinearProgress,
} from '../../../components/progress';
import {
    FileUploadStatus,
    useDocumentService,
} from '../../../services/document.service';
import { UtilsService } from '../../../services/utils.service';
import { useSnackbar } from 'notistack';
import { FileInfo } from '../drive/file';
import {
    AddFileRequest,
    EFileUploadType,
    FileUploadRequest,
    IFileUploadResponse,
    IFileUploadResponseChunkS3UploadLinkData,
} from '../../../services/interfaces';
import {
    DocumentInsightsContext,
    IUploadFile,
} from '../../../providers/document-provider';
import { documentInsightFileUploadSuccess } from '../../../analytics/analytics';
import { useLocation } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import { DropzoneIcon } from '../../../components/icons/dropzone';
import { Close } from '../../../components/icons/close';
import { UploadFileIcon } from '../../../components/icons/upload-file';
import { AppContext } from '../../../providers/app-provider';

interface IProps {
    fileData: IUploadFile;
    skipUpload?: boolean;
    onRemoveFile: () => void;
    onFileConclude: () => void;
}

export const FileUpload = (props: IProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const documentContext = useContext(DocumentInsightsContext);
    const { uploadFileToS3, submitFile, fetchUploadFileResponse } =
        useDocumentService();
    const [progress, setProgress] = useState<number>(0);
    const filePushRef = React.useRef(false);
    const [xhrRef, setXHRRef] = useState<XMLHttpRequest | null>(null);
    const [pushed, setPushed] = useState<boolean>(false);
    const [fileStatus, setFileStatus] = useState<
        'IN_PROGRESS' | 'SUCCESS' | 'FAILED' | 'ABORTED'
    >('IN_PROGRESS');

    const uploadProgress = async (data: FileUploadStatus, fileId: string) => {
        let p = Math.floor(data.progress * 100);
        setProgress(Math.max(p, progress));

        if (data.created && fileId) {
            await submitFile([fileId]);
            if (documentContext)
                documentContext.setRefreshCount(
                    documentContext.refreshCount + 1
                );
            setPushed(true);
            setFileStatus('SUCCESS');
            setTimeout(() => {
                props.onFileConclude();
            }, 0);
            enqueueSnackbar(
                `${UtilsService.trimText(
                    props.fileData.file.name,
                    35
                )} uploaded successfully!`
            );
            setTimeout(() => {
                if (documentContext) {
                    documentContext?.setUploadFiles([
                        ...documentContext?.uploadFiles.filter(
                            (f) => f.id != props.fileData.id
                        ),
                    ]);
                }
            }, 0);

            documentInsightFileUploadSuccess(
                'undefined',
                props.fileData.file.name,
                props.fileData.file.size
            );
        }
    };

    const abortFile = () => {
        setFileStatus('ABORTED');
        if (xhrRef) {
            xhrRef?.abort();
        }
        props.onRemoveFile();
    };

    useEffect(() => {
        if (filePushRef.current) return;
        filePushRef.current = true;
        if (!props.skipUpload) {
            uploadDocument();
        }
    }, [props.fileData.file]);

    const handleFileUploadResponse = (res: string) => {
        let fileId = '';
        let preSignedUrl = '';
        for (let v of res.split('\n')) {
            try {
                let data = JSON.parse(v);
                switch (data.chunkType) {
                    case 'S3_UPLOAD_LINK': {
                        fileId = (
                            data.data as IFileUploadResponseChunkS3UploadLinkData
                        ).userFileId;
                        preSignedUrl = (
                            data.data as IFileUploadResponseChunkS3UploadLinkData
                        ).preSignedUrl;
                        break;
                    }
                    case 'TERMINATION': {
                        let xhr = uploadFileToS3(
                            props.fileData.file,
                            preSignedUrl,
                            (value: FileUploadStatus) => {
                                uploadProgress(value, fileId);
                            }
                        );

                        setXHRRef(xhr);
                        break;
                    }
                }
            } catch (error) {
                if (res == 'Rate limit exceeded') {
                    setFileStatus('FAILED');
                }
            }
        }
    };
    const uploadDocument = () => {
        let data: FileUploadRequest = {
            fileDetails: [
                {
                    metadata: {
                        size: props.fileData.file.size,
                        ext:
                            '.' +
                            UtilsService.getFileType(
                                props.fileData.file.name
                            ).toLowerCase(),
                        mime: props.fileData.file.type,
                    },
                    fileName: props.fileData.file.name,
                },
            ],
            upload: EFileUploadType.FILE,
        };
        fetchUploadFileResponse(data, handleFileUploadResponse);
    };

    return (
        <Box>
            <Box my={2}>
                <Grid container>
                    <Grid item>
                        <UploadFileIcon />
                    </Grid>

                    <Grid
                        sx={{
                            width: `calc(100% - ${
                                fileStatus !== 'SUCCESS' ? 64 : 40
                            }px)`,
                            px: 2,
                        }}
                        item
                    >
                        <Box display="flex" justifyContent="space-between">
                            <Tooltip
                                placement="top"
                                title={props.fileData.file.name}
                            >
                                <Typography
                                    className="clamp-line-1"
                                    sx={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        fontSize: 14,
                                    }}
                                    color="#4B4B4C"
                                >
                                    {props.fileData.file.name}
                                </Typography>
                            </Tooltip>
                            {fileStatus == 'SUCCESS' ? (
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M9.9974 18.3337C14.5998 18.3337 18.3307 14.6027 18.3307 10.0003C18.3307 5.39795 14.5998 1.66699 9.9974 1.66699C5.39502 1.66699 1.66406 5.39795 1.66406 10.0003C1.66406 14.6027 5.39502 18.3337 9.9974 18.3337ZM14.6297 7.91722C14.86 7.66005 14.8381 7.26492 14.581 7.03468C14.3238 6.80444 13.9287 6.82626 13.6984 7.08343L10.8908 10.2194C10.3219 10.8549 9.93873 11.2806 9.61116 11.5559C9.29912 11.8182 9.11584 11.8753 8.95573 11.8753C8.79562 11.8753 8.61234 11.8182 8.3003 11.5559C7.97273 11.2806 7.5896 10.8549 7.02068 10.2194L6.29637 9.4104C6.06613 9.15323 5.67101 9.1314 5.41384 9.36165C5.15667 9.59189 5.13484 9.98702 5.36508 10.2442L6.12034 11.0878C6.65021 11.6796 7.09218 12.1733 7.49602 12.5128C7.92322 12.8718 8.384 13.1253 8.95573 13.1253C9.52746 13.1253 9.98824 12.8718 10.4154 12.5128C10.8193 12.1733 11.2612 11.6797 11.7911 11.0878L14.6297 7.91722Z"
                                        fill="#19AF55"
                                    />
                                </svg>
                            ) : fileStatus == 'FAILED' ? (
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                    }}
                                    color="error"
                                >
                                    Failed
                                </Typography>
                            ) : (
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                    }}
                                    color="#A5A6AB"
                                >
                                    {progress}%
                                </Typography>
                            )}
                        </Box>
                        {!props.skipUpload && fileStatus !== 'FAILED' && (
                            <PositiveLinearProgress
                                sx={{
                                    ml: 'auto',
                                    mt: 1,
                                    height: '6px !important',
                                }}
                                variant={
                                    (progress > 0 && progress < 100) ||
                                    fileStatus == 'SUCCESS'
                                        ? 'determinate'
                                        : 'indeterminate'
                                }
                                value={progress}
                            />
                        )}
                        {!props.skipUpload && fileStatus == 'FAILED' && (
                            <NegativeLinearProgress
                                sx={{
                                    ml: 'auto',
                                    mt: 1,
                                    height: '6px !important',
                                }}
                                variant={'determinate'}
                                value={100}
                            />
                        )}
                        <FileInfo>
                            {UtilsService.getFileSize(
                                (props.fileData.file.size * progress) / 100
                            )}{' '}
                            of{' '}
                            {UtilsService.getFileSize(props.fileData.file.size)}
                            {fileStatus == 'SUCCESS' ? ' Completed' : ''}
                        </FileInfo>
                    </Grid>
                    {fileStatus !== 'SUCCESS' && (
                        <Grid item>
                            <IconButton
                                sx={{ p: 0, mt: 4.5, ml: 1 }}
                                onClick={abortFile}
                            >
                                <Close color="#000" />
                            </IconButton>
                        </Grid>
                    )}
                </Grid>
            </Box>
            <Divider />
        </Box>
    );
};
const SUPPORTED_FORMATS = [
    '.csv',
    '.doc',
    '.docx',
    '.eml',
    '.epub',
    '.gif',
    '.htm',
    '.html',
    '.jpeg',
    '.jpg',
    '.json',
    '.log',
    '.mp3',
    '.msg',
    '.odt',
    '.ogg',
    '.pdf',
    '.png',
    '.pptx',
    '.ps',
    '.psv',
    '.rtf',
    '.tab',
    '.tff',
    '.tif',
    '.tiff',
    '.tsv',
    '.txt',
    '.wav',
    '.xls',
    '.xlsx',
    '.mp3',
    '.wav',
    '.m4a',
    '.wma',
    '.aac',
    '.mp4',
    '.mov',
    '.3gp',
    '.wmv',
    '.avi',
];

const PLAN_FORMATS = {
    BASIC: [
        '.pdf',
        '.doc',
        '.docx',
        '.ppt',
        '.txt',
        '.jpeg',
        '.jpg',
        '.png',
        '.gif',
        '.tif',
        '.tiff',
    ],
    FREE: [
        '.pdf',
        '.doc',
        '.docx',
        '.ppt',
        '.txt',
        '.jpeg',
        '.jpg',
        '.png',
        '.gif',
        '.tif',
        '.tiff',
    ],
    PRO: [
        '.pdf',
        '.doc',
        '.docx',
        '.ppt',
        '.txt',
        '.jpeg',
        '.jpg',
        '.png',
        '.gif',
        '.tif',
        '.tiff',
        '.mp3',
        '.wav',
        '.m4a',
        '.wma',
        '.aac',
        '.mp4',
        '.mov',
        '.3gp',
        '.wmv',
        '.avi',
    ],
    ENTERPRISE: SUPPORTED_FORMATS,
};
type PlanType = 'BASIC' | 'FREE' | 'PRO' | 'ENTERPRISE';

export const FileUploadSection = () => {
    const documentContext = useContext(DocumentInsightsContext);
    const { pathname } = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const appContext = useContext(AppContext);
    const [discardedFiles, setDiscardedFiles] = useState<string[]>([]);
    const [uploadFiles, setUploadFiles] = useState<IUploadFile[]>([]);
    const [concludedFiles, setConcludedFiles] = useState<string[]>([]);
    const container = createRef();
    useEffect(() => {
        documentContext?.setUploadFiles([...uploadFiles]);
    }, [uploadFiles]);
    useEffect(() => {
        if (container.current) {
            //@ts-ignore
            container.current.scrollTo(0, 0);
        }
    }, [pathname]);
    function getAllowedFileTypes(): string[] {
        let planType: PlanType = appContext?.subscriptionInfo
            ? (appContext.subscriptionInfo.plan_type as PlanType)
            : 'ENTERPRISE';
        return PLAN_FORMATS[planType];
    }
    const uploadDocument = (_uploadFiles: File[]) => {
        if (!documentContext) return;
        let f: IUploadFile[] = [];
        for (let file of _uploadFiles) {
            let fileExt =
                '.' + UtilsService.getFileType(file.name).toLowerCase();
            if (!getAllowedFileTypes().includes(fileExt)) {
                enqueueSnackbar(`${fileExt} not supported`, {
                    variant: 'error',
                });
                return;
            }
            if (file.size > getUploadLimit()) {
                enqueueSnackbar(
                    `File size exceeds limit of ${UtilsService.getFileSize(
                        getUploadLimit()
                    )}`,
                    { variant: 'error' }
                );
                return;
            }
            f.push({
                file,
                id: UtilsService.uuidv4(),
            });
        }
        setUploadFiles([...uploadFiles, ...f]);
    };

    const onFileConclude = (id: string) => {
        setTimeout(() => {
            // setConcludedFiles([...concludedFiles, id]);
            setConcludedFiles((concludedFiles) => [...concludedFiles, id]);
        }, 0);
    };

    const getUploadLimit = () => {
        if (appContext?.subscriptionInfo) {
            let plan = appContext.subscriptionInfo.plan_type;
            switch (plan) {
                case 'FREE':
                    return UtilsService.mbToBytes(10);
                case 'BASIC':
                    return UtilsService.mbToBytes(50);
                case 'PRO':
                    return UtilsService.mbToBytes(100);
            }
        }
        return UtilsService.mbToBytes(50);
    };

    const getAllowedFormats = () => {
        if (appContext?.subscriptionInfo) {
            let plan = appContext.subscriptionInfo.plan_type;
            switch (plan) {
                case 'FREE':
                    return 'pdf, doc, docx, ppt, txt, images';
                case 'BASIC':
                    return 'pdf, doc, docx, ppt, txt, images';
                case 'PRO':
                    return 'pdf, doc, docx, ppt, txt, images, audio/video files';
            }
        }
        return 'pdf, doc, docx, ppt, txt, images, audio/video files';
    };

    return (
        <Box minHeight={406}>
            <Box pb={4}>
                <Box ref={container}>
                    <Dropzone onDrop={uploadDocument}>
                        {({ getRootProps, getInputProps }) => (
                            <fieldset
                                {...getRootProps()}
                                style={{
                                    position: 'relative',
                                    border: '1px dashed #A5A6AB',
                                    borderRadius: 8,
                                    paddingBottom: 40,
                                    cursor: 'pointer',
                                }}
                            >
                                <input {...getInputProps()} />{' '}
                                <Box pt={10} pb={6}>
                                    {DropzoneIcon}
                                </Box>
                                <Typography color="#000033" variant="h4">
                                    Click to <span color="#6e3cfb">UPLOAD</span>{' '}
                                    or Drag and drop
                                </Typography>
                                <Typography color="#A5A6AB" variant="caption">
                                    {getAllowedFormats()}{' '}
                                    <Box px={1} component="span">
                                        <Circle mt={0} h={8} w={8} />{' '}
                                    </Box>
                                    Max file size upto{' '}
                                    {UtilsService.getFileSize(getUploadLimit())}
                                </Typography>
                            </fieldset>
                        )}
                    </Dropzone>
                </Box>
            </Box>
            <Box textAlign="left">
                {uploadFiles.filter((f) => !discardedFiles.includes(f.id))
                    .length > 0 && (
                    <Box display="flex" justifyContent={'space-between'}>
                        {' '}
                        <Typography variant="h6">Uploaded files</Typography>
                        {concludedFiles.length > 0 && (
                            <Button
                                variant="text"
                                sx={{ py: 0, mr: 3 }}
                                onClick={() => {
                                    setDiscardedFiles([
                                        ...discardedFiles,
                                        ...concludedFiles,
                                    ]);
                                }}
                                color="primary"
                            >
                                Clear All
                            </Button>
                        )}
                    </Box>
                )}
                {uploadFiles.filter((f) => !discardedFiles.includes(f.id))
                    .length > 0 && (
                    <Box
                        className="scrollable"
                        sx={{
                            maxHeight: '250px',
                            overflow: 'auto',
                            px: 1,
                        }}
                    >
                        {uploadFiles
                            .filter((f) => !discardedFiles.includes(f.id))
                            .map((file) => (
                                <FileUpload
                                    onRemoveFile={() => {
                                        setDiscardedFiles([
                                            ...discardedFiles,
                                            file.id,
                                        ]);
                                    }}
                                    onFileConclude={() => {
                                        onFileConclude(file.id);
                                    }}
                                    key={file.id}
                                    fileData={file}
                                />
                            ))}
                    </Box>
                )}
            </Box>
        </Box>
    );
};
