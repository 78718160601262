import { Box, Button, Divider, Tab, Tabs, Typography } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { LiveQueryResponseVolumeTrendsData } from '../../../services/interfaces';
import {
    BarLineChartProps,
    BarLineDualAxisChart,
} from '../../../components/charts/bar-line-dual-axis-chart';
import { UtilsService } from '../../../services/utils.service';
import { useSearchParams } from 'react-router-dom';
import { EntityArticles } from './entity-articles';
import { NoDataImage } from '../../../components/images/no-data';
import { VolumeChartPlaceholder } from '../../../components/images/volume-chart-placeholder';
import AddToWatchList from '../../add-to-watchlist';
import { AppContext } from '../../../providers/app-provider';

interface IProps {
    entities: LiveQueryResponseVolumeTrendsData[];
}

export const VolumeTrends = (props: IProps) => {
    const appContext = useContext(AppContext);
    const [selectedEntities, setSelectedEntities] = useState<
        LiveQueryResponseVolumeTrendsData[]
    >([]);
    const [searchParam] = useSearchParams();
    const [value, setValue] = useState(0);
    const myRef = useRef(null);
    const [selectedDate, setSelectedDate] = useState('');

    const [volumeChartData, setVolumeChartDate] =
        useState<BarLineChartProps | null>(null);

    useEffect(() => {
        if (props.entities.length > 0) {
            setSelectedEntities([props.entities[0]]);
        }
        setSelectedDate('');
    }, [props.entities]);

    useEffect(() => {
        if (!selectedEntities?.length) return;
        let series: {
            name: string;
            type: string;
            data: (number | null)[];
        }[] = [];

        let labels = [
            ...new Set(
                [
                    ...selectedEntities.map((e) =>
                        e.newsVolume.map((v) => new Date(v.date))
                    ),
                ]
                    .reduce(function (prev, next) {
                        return prev.concat(next);
                    })
                    .sort((a, b) => (a < b ? -1 : 1))
                    .map((v) => UtilsService.formatDate(v))
            ),
        ];
        selectedEntities.map((selectedEntity) => {
            series.push({
                name: UtilsService.firstCharUppercase(
                    selectedEntity.query ? selectedEntity.query : ''
                ),
                data: labels.map((date) => {
                    let entity = selectedEntity.newsVolume.filter(
                        (e) => UtilsService.formatStringDate(e.date) == date
                    );

                    return entity[0] ? entity[0].value : null;
                }),
                type: 'column',
            });
        });

        let volumeData: BarLineChartProps = {
            series,
            height: 400,
            yAxisTitle: 'Articles Volume',
            labels: labels,
        };
        setVolumeChartDate({ ...volumeData });
    }, [selectedEntities]);
    return (
        <Box>
            {props.entities.filter((e) => !!e.query).length > 0 && (
                <Box
                    px={6}
                    py={4}
                    justifyContent="space-between"
                    sx={{
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.05)',
                        borderBottom: '1px solid #D7D9EC',
                    }}
                >
                    <Typography
                        mt={3}
                        variant="subtitle1"
                        sx={{
                            backgroundColor: 'rgba(229, 148, 19, 0.20)',
                            width: 'fit-content',
                            paddingX: '8px',
                            paddingY: '4px',
                            borderRadius: '4px',
                        }}
                    >
                        Multiple Entities detected in the searched query.
                        Explore related entities for deeper analysis from your
                        query below.
                    </Typography>

                    <Box mt={3} display="flex">
                        <Box display="flex">
                            <Typography
                                mr={4}
                                my={1}
                                variant="h6"
                                color={'#4b4b4c'}
                            >
                                Detected Entities:{' '}
                            </Typography>
                            {props.entities.map((e) => (
                                <Box
                                    sx={{
                                        backgroundColor: selectedEntities
                                            ?.map((s) => s.query)
                                            .includes(e.query)
                                            ? '#EBECF6'
                                            : '#fff',
                                        border: `1px solid ${
                                            selectedEntities
                                                ?.map((s) => s.query)
                                                .includes(e.query)
                                                ? '#6e3cfb'
                                                : '#D7D9EC'
                                        }`,
                                        borderRadius: 1,
                                        mr: 2,
                                        color: selectedEntities
                                            ?.map((s) => s.query)
                                            .includes(e.query)
                                            ? '#6e3cfb'
                                            : '#4B4B4C',
                                        py: 0,
                                        px: 2,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Button
                                        sx={{}}
                                        onClick={() => {
                                            if (
                                                !selectedEntities
                                                    ?.map((s) => s.query)
                                                    .includes(e.query)
                                            ) {
                                                setSelectedEntities([
                                                    ...selectedEntities,
                                                    e,
                                                ]);
                                            } else if (
                                                selectedEntities.length > 1
                                            ) {
                                                setSelectedEntities([
                                                    ...selectedEntities.filter(
                                                        (s) =>
                                                            s.query != e.query
                                                    ),
                                                ]);
                                            }
                                        }}
                                    >
                                        <Typography variant="h6">
                                            {e.query}
                                        </Typography>
                                    </Button>
                                    <Divider orientation="vertical" />
                                    <Box ml={2}>
                                        {e.query && (
                                            <AddToWatchList
                                                query={e.query}
                                                entity={true}
                                            />
                                        )}
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Box>
            )}
            <Box
                p={6}
                sx={{
                    maxHeight:
                        props.entities.filter((e) => !!e.query).length > 0
                            ? `calc(100vh - ${
                                  appContext?.accountActionBannerVisible
                                      ? '22px'
                                      : '0px'
                              } - 380px)`
                            : `calc(100vh - ${
                                  appContext?.accountActionBannerVisible
                                      ? '22px'
                                      : '0px'
                              } - 255px)`,
                    overflowY: 'auto',
                }}
            >
                {volumeChartData && volumeChartData.labels.length > 0 ? (
                    <Box>
                        <BarLineDualAxisChart
                            onBarClick={(date: string) => {
                                setSelectedDate(date);
                                //@ts-ignore
                                myRef?.current?.scrollIntoView({
                                    behavior: 'smooth',
                                    block: 'center',
                                    inline: 'start',
                                });
                            }}
                            showLinesOnly={false}
                            {...volumeChartData}
                        />
                    </Box>
                ) : (
                    volumeChartData && (
                        <Box textAlign={'center'} px={4} py={20}>
                            <Box>{NoDataImage}</Box>
                            <br />
                            <Typography variant="h4" mt={14}>
                                Sorry, No data available
                            </Typography>
                            <Typography fontSize={14} fontWeight={400} mt={2}>
                                No data available for this entities. Please try
                                with some different query
                            </Typography>
                        </Box>
                    )
                )}
                {!selectedDate && (
                    <Box textAlign={'center'} px={4} py={20}>
                        <Box>{VolumeChartPlaceholder}</Box>
                        <br />
                        <Typography fontSize={14} fontWeight={400} mt={4}>
                            See the relevant articles from that date on the
                            topic by clicking the Volume bar.
                        </Typography>
                    </Box>
                )}
                <Box sx={{ opacity: selectedDate ? 1 : 0 }}>
                    <Typography ml={2} ref={myRef} variant="h4">
                        Articles of {selectedDate}
                    </Typography>
                    <Box sx={{ display: selectedDate ? '' : 'none' }}>
                        {props.entities.filter((e) => !!e.query).length > 0 && (
                            <Box
                                sx={{
                                    borderBottom: 1,
                                    borderColor: 'divider',
                                    mt: 5,
                                    mb: 3,
                                }}
                            >
                                <Tabs
                                    value={value}
                                    onChange={(
                                        event: React.SyntheticEvent,
                                        newValue: number
                                    ) => {
                                        setValue(newValue);
                                    }}
                                    aria-label="basic tabs example"
                                >
                                    {props.entities
                                        .filter(
                                            (en) =>
                                                en.newsSentiment.filter(
                                                    (v) =>
                                                        UtilsService.formatStringDate(
                                                            v.date
                                                        ) == selectedDate
                                                ).length > 0 && en.query
                                        )
                                        .map((e, index) => (
                                            <Tab
                                                value={index}
                                                label={e.query}
                                            />
                                        ))}
                                </Tabs>
                            </Box>
                        )}
                        <Box mt={2}>
                            {props.entities.map((e, index) => (
                                <Box
                                    sx={{
                                        display: index == value ? '' : 'none',
                                    }}
                                >
                                    <EntityArticles
                                        data={e}
                                        date={selectedDate}
                                    />
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
