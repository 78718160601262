import { Box, Divider, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BorderedBox } from '../../../../../components/core/box';
import { Avator } from '../../../../../components/icons/avator';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
    EarningsCall,
    EventStatements,
    Statements,
} from '../../../../../services/interfaces';
import { TextHighlight } from '../../../../../components/sentiment-highlights';
import { ToggleHighLight } from '../../../../../components/toggle-highlight';
import { Statement } from './statement';

interface IProps {
    earningCall: EarningsCall;
}

export const Summary = (props: IProps) => {
    // const { topicSubscriptionId } = useParams();
    // const {
    //     fetchFinancialsSummary,
    //     fetchFinancialsSummaryEvents,
    //     fetchPersonalityTraits,
    // } = useFinancialsService();
    const [open, setOpen] = React.useState<string>('');
    const [openTrait, setOpenTrait] = React.useState<boolean>(false);
    const [highlightSection, setHighlightSection] =
        React.useState<string>('events');
    const [financialSummary, setFinancialSummary] = useState<
        Statements[] | null
    >(null);
    const [summaries, setSummaries] = useState<EventStatements[]>([]);
    // const [financialSummaryEvents, setFinancialSummaryEvents] =
    //     useState<FinancialEventsResponseData | null>(null);

    // const [personalityTrait, setPersonalityTrait] =
    //     useState<PersonalityTraitData | null>(null);

    const [scrollY, setScrollY] = useState(0);

    useEffect(() => {
        setFinancialSummary(props.earningCall.insights.sections);
    }, [props.earningCall]);

    // function logit() {
    //     if (window.pageYOffset > 0) {
    //         setScrollY(window.pageYOffset);
    //     }
    // }

    // useEffect(() => {
    //     function watchScroll() {
    //         window.addEventListener('auto', logit);
    //     }
    //     watchScroll();
    //     return () => {
    //         window.removeEventListener('auto', logit);
    //     };
    // });

    // const refreshFinancialsSummary = async () => {
    //     if (topicSubscriptionId) {
    //         let data = await fetchFinancialsSummary({
    //             financeCategory: 'earnings_calls',
    //             topicSubscriptionId: topicSubscriptionId,
    //             year: props.selectedEarningFilter.year,
    //             quarter: props.selectedEarningFilter.quarter,
    //         });
    //         setFinancialSummary({ ...data });
    //     }
    // };

    // const refreshFinancialsSummaryEvents = async () => {
    //     if (topicSubscriptionId) {
    //         let data = await fetchFinancialsSummaryEvents({
    //             financeCategory: 'earnings_calls',
    //             topicSubscriptionId: topicSubscriptionId,
    //             year: props.selectedEarningFilter.year,
    //             quarter: props.selectedEarningFilter.quarter,
    //         });
    //         setFinancialSummaryEvents({ ...data });
    //     }
    // };

    // const refreshPersonalityTraits = async () => {
    //     if (topicSubscriptionId) {
    //         let data = await fetchPersonalityTraits({
    //             financeCategory: 'earnings_calls',
    //             topicSubscriptionId: topicSubscriptionId,
    //             year: props.selectedEarningFilter.year,
    //             quarter: props.selectedEarningFilter.quarter,
    //         });
    //         setPersonalityTrait({ ...data });
    //     }
    // };

    // useEffect(() => {
    //     updateEventsData(open);
    // }, [financialSummaryEvents, financialSummary]);

    // useEffect(() => {
    //     refreshFinancialsSummary();
    //     refreshFinancialsSummaryEvents();
    //     refreshPersonalityTraits();
    // }, [topicSubscriptionId, props.selectedEarningFilter]);

    // useEffect(() => {
    //     if (!open && financialSummary && financialSummaryEvents) {
    //         handleClick('forward');
    //     }
    // }, [financialSummary, financialSummaryEvents]);

    const updateEventsData = (id: string) => {
        if (
            id == 'forward' &&
            props.earningCall.insights.events.forwardLookingStatements
        ) {
            setSummaries([
                ...props.earningCall.insights.events.forwardLookingStatements,
            ]);
        } else if (
            id == 'positives' &&
            props.earningCall.insights.events.keyPositives
        ) {
            setSummaries([...props.earningCall.insights.events.keyPositives]);
        } else if (
            id == 'negatives' &&
            props.earningCall.insights.events.keyNegatives
        ) {
            setSummaries([...props.earningCall.insights.events.keyNegatives]);
        } else if ((id == 'entire' || id == 'management') && financialSummary) {
            setSummaries([
                ...financialSummary.map(
                    (value: Statements): EventStatements => {
                        return {
                            orderId: value.speakerTexts[0].orderId,
                            rawText: '',
                            summary: '',
                            speakerName: value.speakerName,
                            speakerRole: value.speakerRole,
                        };
                    }
                ),
            ]);
        } else if (id == 'qna' && financialSummary) {
            setSummaries([
                ...financialSummary
                    .filter(
                        (s: Statements) =>
                            s.sections &&
                            s.sections.level1 == 'Q&A' &&
                            s.sections.level2 == 'Question'
                    )
                    .map((value: Statements): EventStatements => {
                        return {
                            orderId: value.speakerTexts[0].orderId,
                            rawText: value.speakerTexts[0].rawText,
                            summary: '',
                            speakerName: value.speakerName,
                            speakerRole: value.speakerRole,
                        };
                    }),
            ]);
        }
    };

    useEffect(() => {
        updateEventsData(open);
    }, [open]);

    const handleClick = (id: string) => {
        setOpenTrait(false);
        if (open == id) {
            setOpen('');
        } else {
            setOpen(id);
        }
    };

    const handleTraitOpen = () => {
        setOpenTrait(true);
        setOpen('');
    };
    return (
        <BorderedBox sx={{ backgroundColor: '#FFFFFF' }}>
            <Box px={4}>
                <Grid container>
                    <Grid item sm={8}>
                        <Typography py={2} variant="h4">
                            Earnings Calls - Event Based Analysis
                        </Typography>
                    </Grid>
                    <Grid item sm={4}>
                        {!openTrait && (
                            <Box sx={{ float: 'right', pt: 1.5 }}>
                                <ToggleHighLight size={20} />
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <Divider />
            </Box>
            <Grid px={0} container>
                <Grid item sm={4}>
                    <Grid container>
                        <Grid
                            py={2}
                            onClick={() => setHighlightSection('events')}
                            sx={
                                highlightSection == 'events'
                                    ? {
                                          backgroundColor: '#F5F5FA',
                                          borderBottom: '4px solid #6e3cfb',
                                      }
                                    : {
                                          cursor: 'pointer',
                                      }
                            }
                            item
                            sm={6}
                        >
                            <Typography
                                textAlign="center"
                                fontWeight={500}
                                color={
                                    highlightSection == 'events'
                                        ? '#6e3cfb'
                                        : '#A5A6AB'
                                }
                                variant="h6"
                            >
                                Events
                            </Typography>
                        </Grid>
                        <Grid
                            py={2}
                            onClick={() => setHighlightSection('section')}
                            sx={
                                highlightSection == 'section'
                                    ? {
                                          backgroundColor: '#F5F5FA',
                                          borderBottom: '4px solid #6e3cfb',
                                      }
                                    : {
                                          cursor: 'pointer',
                                      }
                            }
                            item
                            sm={6}
                        >
                            <Typography
                                color={
                                    highlightSection == 'section'
                                        ? '#6e3cfb'
                                        : '#A5A6AB'
                                }
                                textAlign="center"
                                fontWeight={500}
                                variant="h6"
                            >
                                Sections
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider
                        sx={{
                            borderColor: '#F5F5FA',
                            borderWidth: 1,
                        }}
                    />
                    {highlightSection == 'events' && (
                        <Box>
                            <List
                                sx={{ width: '100%', p: 0 }}
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                            >
                                <ListItemButton
                                    sx={{
                                        bgcolor:
                                            open == 'forward'
                                                ? '#f9f5ff'
                                                : '#fff',
                                    }}
                                    onClick={() => handleClick('forward')}
                                >
                                    <ListItemIcon sx={{ minWidth: 16 }}>
                                        <Box
                                            height={8}
                                            width={8}
                                            sx={{
                                                backgroundColor: '#6B22E3',
                                                borderRadius: '50%',
                                            }}
                                        ></Box>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={{
                                            fontSize: 16,
                                            fontWeight: 600,
                                        }}
                                        primary="Forward Looking Statements"
                                    />
                                    {open == 'forward' ? (
                                        <ExpandLess />
                                    ) : (
                                        <ExpandMore />
                                    )}
                                </ListItemButton>
                                {open == 'forward' ? null : (
                                    <Divider
                                        sx={{
                                            borderColor: '#F5F5FA',
                                            borderWidth: 0.5,
                                        }}
                                    />
                                )}
                                <Collapse
                                    in={open == 'forward'}
                                    timeout={100}
                                    unmountOnExit
                                >
                                    <List component="div" disablePadding>
                                        <Box
                                            className="scrollable"
                                            sx={
                                                scrollY > 0
                                                    ? {
                                                          maxHeight:
                                                              'calc(100vh - 350px)',
                                                          overflow: 'auto',
                                                          pt: 4,
                                                      }
                                                    : { pt: 4 }
                                            }
                                        >
                                            {props.earningCall.insights.events.forwardLookingStatements.map(
                                                (s: EventStatements, index) => (
                                                    <Statement
                                                        isLast={
                                                            props.earningCall
                                                                .insights
                                                                .sections
                                                                .length ==
                                                            index + 1
                                                        }
                                                        data={s}
                                                    />
                                                )
                                            )}
                                        </Box>
                                    </List>
                                </Collapse>
                            </List>
                            <List
                                sx={{ width: '100%', p: 0 }}
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                            >
                                <ListItemButton
                                    sx={{
                                        bgcolor:
                                            open == 'positives'
                                                ? '#f9f5ff'
                                                : '#fff',
                                    }}
                                    onClick={() => handleClick('positives')}
                                >
                                    <ListItemIcon sx={{ minWidth: 16 }}>
                                        <Box
                                            height={8}
                                            width={8}
                                            sx={{
                                                backgroundColor: '#3BE37E',
                                                borderRadius: '50%',
                                            }}
                                        ></Box>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={{
                                            fontSize: 16,
                                            fontWeight: 600,
                                        }}
                                        primary="Positive Indicators"
                                    />
                                    {open == 'positives' ? (
                                        <ExpandLess />
                                    ) : (
                                        <ExpandMore />
                                    )}
                                </ListItemButton>
                                {open == 'positives' ? null : (
                                    <Divider
                                        sx={{
                                            borderColor: '#F5F5FA',
                                            borderWidth: 0.5,
                                        }}
                                    />
                                )}
                                <Collapse
                                    in={open == 'positives'}
                                    timeout={100}
                                    unmountOnExit
                                >
                                    <List component="div" disablePadding>
                                        <Box
                                            className="scrollable"
                                            sx={
                                                scrollY > 0
                                                    ? {
                                                          maxHeight:
                                                              'calc(100vh - 350px)',
                                                          overflow: 'auto',
                                                          pt: 4,
                                                      }
                                                    : { pt: 4 }
                                            }
                                        >
                                            {props.earningCall.insights.events.keyPositives.map(
                                                (s: EventStatements, index) => (
                                                    <Statement
                                                        isLast={
                                                            summaries.length ==
                                                            index + 1
                                                        }
                                                        data={s}
                                                    />
                                                )
                                            )}
                                        </Box>
                                    </List>
                                </Collapse>
                            </List>
                            <List
                                sx={{ width: '100%', p: 0 }}
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                            >
                                <ListItemButton
                                    sx={{
                                        bgcolor:
                                            open == 'negatives'
                                                ? '#f9f5ff'
                                                : '#fff',
                                    }}
                                    onClick={() => handleClick('negatives')}
                                >
                                    <ListItemIcon sx={{ minWidth: 16 }}>
                                        <Box
                                            height={8}
                                            width={8}
                                            sx={{
                                                backgroundColor: '#E26573',
                                                borderRadius: '50%',
                                            }}
                                        ></Box>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={{
                                            fontSize: 16,
                                            fontWeight: 600,
                                        }}
                                        primary="Negative Indicators"
                                    />
                                    {open == 'negatives' ? (
                                        <ExpandLess />
                                    ) : (
                                        <ExpandMore />
                                    )}
                                </ListItemButton>
                                {open == 'negatives' ? null : (
                                    <Divider
                                        sx={{
                                            borderColor: '#F5F5FA',
                                            borderWidth: 0.5,
                                        }}
                                    />
                                )}
                                <Collapse
                                    in={open == 'negatives'}
                                    timeout={100}
                                    unmountOnExit
                                >
                                    <List component="div" disablePadding>
                                        <Box
                                            className="scrollable"
                                            sx={
                                                scrollY > 0
                                                    ? {
                                                          maxHeight:
                                                              'calc(100vh - 350px)',
                                                          overflow: 'auto',
                                                          pt: 4,
                                                      }
                                                    : { pt: 4 }
                                            }
                                        >
                                            {props.earningCall.insights.events.keyNegatives.map(
                                                (s: EventStatements, index) => (
                                                    <Statement
                                                        isLast={
                                                            summaries.length ==
                                                            index + 1
                                                        }
                                                        data={s}
                                                    />
                                                )
                                            )}
                                        </Box>
                                    </List>
                                </Collapse>
                            </List>
                        </Box>
                    )}
                    {highlightSection == 'section' && (
                        <Box>
                            <List
                                sx={{ width: '100%', p: 0 }}
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                            >
                                <ListItemButton
                                    sx={{
                                        bgcolor:
                                            open == 'entire'
                                                ? '#f9f5ff'
                                                : '#fff',
                                    }}
                                    onClick={() => handleClick('entire')}
                                >
                                    <ListItemText
                                        primaryTypographyProps={{
                                            fontSize: 16,
                                            fontWeight: 600,
                                        }}
                                        primary="Full Earnings Calls"
                                    />
                                    <ArrowForwardIosIcon
                                        sx={{
                                            color: '#A5A6AB',
                                            fontSize: '14px',
                                            mr: 1,
                                        }}
                                        fontSize="small"
                                    />
                                </ListItemButton>
                            </List>
                            <Divider
                                sx={{
                                    borderColor: '#F5F5FA',
                                    borderWidth: 1,
                                }}
                            />

                            <List
                                sx={{ width: '100%', p: 0 }}
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                            >
                                <ListItemButton
                                    sx={{
                                        bgcolor:
                                            open == 'management'
                                                ? '#f9f5ff'
                                                : '#fff',
                                    }}
                                    onClick={() => handleClick('management')}
                                >
                                    <ListItemText
                                        primaryTypographyProps={{
                                            fontSize: 16,
                                            fontWeight: 600,
                                        }}
                                        primary="Management Discussion"
                                    />
                                    {open == 'management' ? (
                                        <ExpandLess />
                                    ) : (
                                        <ExpandMore />
                                    )}
                                </ListItemButton>
                                {open == 'management' ? null : (
                                    <Divider
                                        sx={{
                                            borderColor: '#F5F5FA',
                                            borderWidth: 0.5,
                                        }}
                                    />
                                )}
                                <Collapse
                                    in={open == 'management'}
                                    timeout={100}
                                    unmountOnExit
                                >
                                    <List component="div" disablePadding>
                                        <Box
                                            className="scrollable"
                                            sx={
                                                scrollY > 0
                                                    ? {
                                                          maxHeight:
                                                              'calc(100vh - 350px)',
                                                          overflow: 'auto',
                                                          pt: 4,
                                                      }
                                                    : { pt: 4 }
                                            }
                                        >
                                            {summaries
                                                .reduce(
                                                    (
                                                        arr: EventStatements[],
                                                        s: EventStatements
                                                    ) => {
                                                        if (
                                                            !arr
                                                                .map(
                                                                    (
                                                                        st: EventStatements
                                                                    ) =>
                                                                        st.speakerName +
                                                                        st.speakerRole
                                                                )
                                                                .includes(
                                                                    s.speakerName +
                                                                        s.speakerRole
                                                                )
                                                        ) {
                                                            arr.push(s);
                                                        }

                                                        return arr;
                                                    },
                                                    []
                                                )
                                                .map(
                                                    (
                                                        s: EventStatements,
                                                        index,
                                                        arr
                                                    ) => (
                                                        <Statement
                                                            data={s}
                                                            isLast={
                                                                arr.length ==
                                                                index + 1
                                                            }
                                                            hideText={true}
                                                        />
                                                    )
                                                )}
                                        </Box>
                                    </List>
                                </Collapse>
                            </List>
                            <List
                                sx={{ width: '100%', p: 0 }}
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                            >
                                <ListItemButton
                                    sx={{
                                        bgcolor:
                                            open == 'qna' ? '#f9f5ff' : '#fff',
                                    }}
                                    onClick={() => handleClick('qna')}
                                >
                                    <ListItemText
                                        primaryTypographyProps={{
                                            fontSize: 16,
                                            fontWeight: 600,
                                        }}
                                        primary="Question and Answers"
                                    />
                                    {open == 'negatives' ? (
                                        <ExpandLess />
                                    ) : (
                                        <ExpandMore />
                                    )}
                                </ListItemButton>
                                <Collapse
                                    in={open == 'qna'}
                                    timeout={100}
                                    unmountOnExit
                                >
                                    <List component="div" disablePadding>
                                        <Box
                                            className="scrollable"
                                            sx={
                                                scrollY > 0
                                                    ? {
                                                          maxHeight:
                                                              'calc(100vh - 350px)',
                                                          overflow: 'auto',
                                                          pt: 4,
                                                      }
                                                    : { pt: 4 }
                                            }
                                        >
                                            {summaries.map(
                                                (s: EventStatements, index) => (
                                                    <Statement
                                                        isQuestion={true}
                                                        data={s}
                                                        isLast={
                                                            summaries.length ==
                                                            index + 1
                                                        }
                                                    />
                                                )
                                            )}
                                        </Box>
                                    </List>
                                </Collapse>
                            </List>
                        </Box>
                    )}
                </Grid>
                <Divider flexItem orientation="vertical" />
                <Grid item sx={{ width: 'calc(66.666666% - 1px)' }}>
                    {!openTrait && (
                        <Box>
                            <Typography
                                px={4}
                                fontSize={20}
                                pt={2}
                                variant="h5"
                            >
                                Full Call Transcript
                            </Typography>
                            <Box
                                className="scrollable"
                                sx={
                                    scrollY > 0
                                        ? {
                                              maxHeight: 'calc(100vh - 220px)',
                                              overflow: 'auto',
                                          }
                                        : {}
                                }
                                pt={2}
                                mt={2}
                            >
                                <Box px={4}>
                                    {financialSummary?.map((fs: Statements) => (
                                        <Box>
                                            <Box>
                                                <Box pb={1} display="flex">
                                                    {!fs.sections?.level1 ||
                                                    fs.sections?.level1 !==
                                                        'Q&A' ? (
                                                        <Box pt={0.5}>
                                                            <Avator size={20} />
                                                        </Box>
                                                    ) : fs.sections.level2 ==
                                                      'Question' ? (
                                                        <Typography
                                                            color="#E59413"
                                                            mr={2}
                                                            variant="subtitle1"
                                                        >
                                                            Question
                                                        </Typography>
                                                    ) : (
                                                        <Typography
                                                            color="#585960"
                                                            mr={
                                                                '8px !important'
                                                            }
                                                            variant="subtitle1"
                                                        >
                                                            Answer
                                                        </Typography>
                                                    )}
                                                    {fs.sections?.level1 ==
                                                        'Q&A' && (
                                                        <Divider
                                                            flexItem
                                                            sx={{
                                                                background:
                                                                    '#E3E3E3',
                                                            }}
                                                            orientation="vertical"
                                                        />
                                                    )}
                                                    <Typography
                                                        color="#000033"
                                                        ml={2}
                                                        mr={2}
                                                        fontWeight={600}
                                                        fontSize={16}
                                                        variant="subtitle1"
                                                    >
                                                        {fs.speakerName}
                                                    </Typography>
                                                    <Divider
                                                        flexItem
                                                        sx={{
                                                            background:
                                                                '#E3E3E3',
                                                        }}
                                                        orientation="vertical"
                                                    />
                                                    <Typography
                                                        mx={2}
                                                        variant="caption"
                                                        pt={1.5}
                                                        color="#8D8D8D"
                                                        fontSize={14}
                                                    >
                                                        {fs.speakerRole}
                                                    </Typography>
                                                </Box>
                                                {fs.speakerTexts.map((st) => (
                                                    <Box
                                                        id={`order${st.orderId}`}
                                                        py={1}
                                                    >
                                                        <Typography
                                                            textColor="#4B4B4C"
                                                            variant="caption"
                                                            pt={4}
                                                            text={st.rawText}
                                                            highlight={
                                                                st.highlights
                                                            }
                                                            component={
                                                                TextHighlight
                                                            }
                                                            fontSize={14}
                                                        ></Typography>
                                                    </Box>
                                                ))}
                                            </Box>
                                            <Box pb={4} pt={4}>
                                                <Divider
                                                    sx={{
                                                        borderColor: '#D7D9EC',
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        </Box>
                    )}
                    {/* {openTrait && (
                        <PersonalityTraits
                            personalityTraitData={personalityTrait}
                        />
                    )}  */}
                </Grid>
            </Grid>
        </BorderedBox>
    );
};
