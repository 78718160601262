import {
    Box,
    TableContainer,
    TableHead,
    Typography,
    TableBody,
    Skeleton,
} from '@mui/material';
import { potentialIndicatorTitleClick } from '../../analytics/analytics';
import { PotentialSentiment } from '../../services/interfaces';
import { UtilsService } from '../../services/utils.service';
import { NavigationLink } from '../core/link';
import { TableBodyRow } from '../core/table/common/row';
import { TableHeadCell } from '../core/table/comparison/head-cell';
import { TableHeadRow } from '../core/table/comparison/head-row';
import { TableNegativeCell } from '../core/table/comparison/negative-cell';
import { TablePositiveCell } from '../core/table/comparison/positive-cell';
import { ComparisonTableWrapper } from '../core/table/comparison/table';
import { TextHighlight } from '../sentiment-highlights';

interface IProps {
    data: (PotentialSentiment | null)[][] | null;
    compact?: boolean;
    context: 'TOPIC' | 'ARTICLE' | 'SOURCE_DISTRIBUTION' | 'FINANCIAL';
    inbuiltDetail?: boolean;
    renderDetails: Function;
}

export const IndicatorsTable = (props: IProps) => {
    const { data, renderDetails } = props;
    return (
        <Box>
            <TableContainer>
                <ComparisonTableWrapper
                    sx={{
                        border: props.compact ? 'none !important' : '',
                    }}
                    aria-label="simple table"
                >
                    <TableHead
                        sx={{
                            borderTop: props.compact ? '1px solid #e0e0e0' : '',
                            backgroundColor: props.compact ? '#F9F9FF' : '',
                        }}
                    >
                        <TableHeadRow>
                            <TableHeadCell>
                                <Typography
                                    sx={{ color: '#19AF55' }}
                                    variant="subtitle1"
                                >
                                    Positive
                                </Typography>
                            </TableHeadCell>
                            <TableHeadCell>
                                <Typography
                                    sx={{ color: '#D82F44' }}
                                    variant="subtitle1"
                                >
                                    Negative
                                </Typography>
                            </TableHeadCell>
                        </TableHeadRow>
                    </TableHead>
                    <TableBody>
                        {data?.map(
                            (row: (PotentialSentiment | null)[], index) => (
                                <TableBodyRow key={index}>
                                    <TablePositiveCell
                                        sx={{
                                            borderLeftWidth: props.compact
                                                ? '2px !important'
                                                : '10px !important',
                                            padding: '8px 10px',
                                        }}
                                    >
                                        {row[0] && (
                                            <Box pb={1}>
                                                <Box pb={2}>
                                                    {(props.context ==
                                                        'TOPIC' ||
                                                        props.context ==
                                                            'FINANCIAL') && (
                                                        <Box display="flex">
                                                            <Typography
                                                                to={`/insight/article/${row[0].topicSubscriptionId}/${row[0].date}/${row[0].id}/${row[0].summaryId}`}
                                                                component={
                                                                    props.inbuiltDetail
                                                                        ? Box
                                                                        : NavigationLink
                                                                }
                                                                sx={{
                                                                    cursor: 'pointer',
                                                                }}
                                                                color="#6e3cfb"
                                                                fontSize={
                                                                    props.compact
                                                                        ? 12
                                                                        : 14
                                                                }
                                                                onClick={() => {
                                                                    if (
                                                                        row[0]
                                                                    ) {
                                                                        if (
                                                                            props.inbuiltDetail
                                                                        ) {
                                                                            renderDetails(
                                                                                row[0]
                                                                                    .topicSubscriptionId,
                                                                                row[0]
                                                                                    .date,
                                                                                row[0]
                                                                                    .id,
                                                                                row[0]
                                                                                    .summaryId
                                                                            );
                                                                        }
                                                                        potentialIndicatorTitleClick(
                                                                            row[0].summaryId.toString(),
                                                                            row[0]
                                                                                .id,
                                                                            row[0]
                                                                                .topicSubscriptionId,
                                                                            row[0]
                                                                                .topicName,
                                                                            `/insight/article/${row[0].topicSubscriptionId}/${row[0].date}/${row[0].id}/${row[0].summaryId}`
                                                                        );
                                                                    }
                                                                }}
                                                                variant="subtitle2"
                                                            >
                                                                {
                                                                    row[0]
                                                                        .topicName
                                                                }
                                                            </Typography>
                                                            <Typography
                                                                sx={{
                                                                    borderLeft:
                                                                        '1px solid #ccc',
                                                                    ml: 2,
                                                                    pl: 2,
                                                                    color: '#8D8D8D',
                                                                }}
                                                                fontSize={
                                                                    props.compact
                                                                        ? 12
                                                                        : 14
                                                                }
                                                                variant="subtitle2"
                                                            >
                                                                {UtilsService.formatStringDateForPotentialIndicators(
                                                                    row[0].date
                                                                )}
                                                            </Typography>
                                                        </Box>
                                                    )}
                                                    {(props.context ==
                                                        'ARTICLE' ||
                                                        props.context ==
                                                            'SOURCE_DISTRIBUTION') && (
                                                        <Typography variant="subtitle2">
                                                            {row[0].sourceName}
                                                        </Typography>
                                                    )}
                                                </Box>
                                                <Typography
                                                    text={row[0].summary}
                                                    textFontSize={
                                                        props.compact
                                                            ? '12px'
                                                            : '14px'
                                                    }
                                                    highlight={
                                                        row[0].highlightIndexes
                                                    }
                                                    collapsable={props.compact}
                                                    component={TextHighlight}
                                                    textVariant="caption"
                                                ></Typography>
                                                {!props.compact &&
                                                    Array.isArray(
                                                        row[0].sources
                                                    ) && (
                                                        <Box>
                                                            <Typography
                                                                color="#4B4B4C"
                                                                variant="caption"
                                                            >
                                                                Sources: [
                                                                {row[0].sources.join(
                                                                    ', '
                                                                )}
                                                                ]
                                                            </Typography>
                                                        </Box>
                                                    )}
                                                {/* {props.compact && (<Typography
                                                                sx={{
                                                                    color: '#8D8D8D',
                                                                }}
                                                                fontSize={12}
                                                                variant="subtitle2"
                                                            >
                                                                {UtilsService.formatStringDateForPotentialIndicators(
                                                                    row[0].date
                                                                )}
                                                            </Typography>
                                                            )} */}
                                            </Box>
                                        )}
                                    </TablePositiveCell>
                                    <TableNegativeCell
                                        sx={{
                                            borderLeftWidth: props.compact
                                                ? '2px !important'
                                                : '10px !important',
                                            padding: '8px 10px',
                                        }}
                                    >
                                        {row[1] && (
                                            <Box>
                                                <Box pb={2}>
                                                    {(props.context ==
                                                        'TOPIC' ||
                                                        props.context ==
                                                            'FINANCIAL') && (
                                                        <Box display="flex">
                                                            <Typography
                                                                to={`/insight/article/${row[1].topicSubscriptionId}/${row[1].date}/${row[1].id}/${row[1].summaryId}`}
                                                                component={
                                                                    props.inbuiltDetail
                                                                        ? Box
                                                                        : NavigationLink
                                                                }
                                                                sx={{
                                                                    cursor: 'pointer',
                                                                }}
                                                                color="#6e3cfb"
                                                                fontSize={
                                                                    props.compact
                                                                        ? 12
                                                                        : 14
                                                                }
                                                                onClick={() => {
                                                                    if (
                                                                        row[1]
                                                                    ) {
                                                                        if (
                                                                            props.inbuiltDetail
                                                                        ) {
                                                                            renderDetails(
                                                                                row[1]
                                                                                    .topicSubscriptionId,
                                                                                row[1]
                                                                                    .date,
                                                                                row[1]
                                                                                    .id,
                                                                                row[1]
                                                                                    .summaryId
                                                                            );
                                                                        }
                                                                        potentialIndicatorTitleClick(
                                                                            row[1].summaryId.toString(),
                                                                            row[1]
                                                                                .id,
                                                                            row[1]
                                                                                .topicSubscriptionId,
                                                                            row[1]
                                                                                .topicName,
                                                                            `/insight/article/${row[1].topicSubscriptionId}/${row[1].date}/${row[1].id}/${row[1].summaryId}`
                                                                        );
                                                                    }
                                                                }}
                                                                variant="subtitle2"
                                                            >
                                                                {
                                                                    row[1]
                                                                        .topicName
                                                                }
                                                            </Typography>
                                                            <Typography
                                                                sx={{
                                                                    borderLeft:
                                                                        '1px solid #ccc',
                                                                    ml: 2,
                                                                    pl: 2,
                                                                    color: '#8D8D8D',
                                                                }}
                                                                fontSize={
                                                                    props.compact
                                                                        ? 12
                                                                        : 14
                                                                }
                                                                variant="subtitle2"
                                                            >
                                                                {UtilsService.formatStringDateForPotentialIndicators(
                                                                    row[1].date
                                                                )}
                                                            </Typography>
                                                        </Box>
                                                    )}
                                                    {(props.context ==
                                                        'ARTICLE' ||
                                                        props.context ==
                                                            'SOURCE_DISTRIBUTION') && (
                                                        <Typography variant="subtitle2">
                                                            {row[1].sourceName}
                                                        </Typography>
                                                    )}
                                                </Box>
                                                <Box>
                                                    <Typography
                                                        text={row[1].summary}
                                                        textFontSize={
                                                            props.compact
                                                                ? '12px'
                                                                : '14px'
                                                        }
                                                        highlight={
                                                            row[1]
                                                                .highlightIndexes
                                                        }
                                                        collapsable={
                                                            props.compact
                                                        }
                                                        component={
                                                            TextHighlight
                                                        }
                                                        textVariant="caption"
                                                    ></Typography>
                                                    {!props.compact &&
                                                        Array.isArray(
                                                            row[1].sources
                                                        ) && (
                                                            <Box>
                                                                <Typography
                                                                    color="#4B4B4C"
                                                                    variant="caption"
                                                                >
                                                                    Sources: [
                                                                    {row[1].sources.join(
                                                                        ', '
                                                                    )}
                                                                    ]
                                                                </Typography>
                                                            </Box>
                                                        )}
                                                    {/* {props.compact && (<Typography
                                                                sx={{
                                                                    color: '#8D8D8D',
                                                                }}
                                                                fontSize={12}
                                                                variant="subtitle2"
                                                            >
                                                                {UtilsService.formatStringDateForPotentialIndicators(
                                                                    row[1].date
                                                                )}
                                                            </Typography>
                                                            )} */}
                                                </Box>
                                            </Box>
                                        )}
                                    </TableNegativeCell>
                                </TableBodyRow>
                            )
                        )}
                        {!data &&
                            [1, 2, 3, 4, 5, 6].map(() => (
                                <TableBodyRow>
                                    <TablePositiveCell
                                        sx={{
                                            borderLeftWidth: props.compact
                                                ? '2px !important'
                                                : '10px !important',
                                        }}
                                    >
                                        <Skeleton
                                            variant="text"
                                            sx={{ fontSize: '1rem' }}
                                        />
                                        <Skeleton
                                            variant="text"
                                            sx={{ fontSize: '1rem' }}
                                        />
                                        <Skeleton
                                            variant="text"
                                            sx={{ fontSize: '1rem' }}
                                        />
                                        <Skeleton
                                            variant="text"
                                            sx={{ fontSize: '1rem' }}
                                        />
                                    </TablePositiveCell>
                                    <TableNegativeCell
                                        sx={{
                                            borderLeftWidth: props.compact
                                                ? '2px !important'
                                                : '10px !important',
                                        }}
                                    >
                                        <Skeleton
                                            variant="text"
                                            sx={{ fontSize: '1rem' }}
                                        />
                                        <Skeleton
                                            variant="text"
                                            sx={{ fontSize: '1rem' }}
                                        />
                                        <Skeleton
                                            variant="text"
                                            sx={{ fontSize: '1rem' }}
                                        />
                                        <Skeleton
                                            variant="text"
                                            sx={{ fontSize: '1rem' }}
                                        />
                                    </TableNegativeCell>
                                </TableBodyRow>
                            ))}
                    </TableBody>
                </ComparisonTableWrapper>
            </TableContainer>
        </Box>
    );
};
