import {
    Box,
    Button,
    Divider,
    Tab,
    Tabs,
    Tooltip,
    TooltipProps,
    Typography,
    styled,
    tooltipClasses,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { BorderedBox, ContainedBox } from '../../../../components/core/box';
import SingleSelect from '../../../../components/core/input/single-select';
import { useFinancialsService } from '../../../../services/financials.service';
import {
    EarningFilter,
    ExecutiveSummaryResponseData,
    FinancialAllFilingsResponseDataElement,
} from '../../../../services/interfaces';
import { EarningCall } from './earning-call';
import { SECFilings } from './sec-filings';
import { CompanyDetails } from './company-details';
import { useChatbotService } from '../../../../services/chatbot.service';
import {
    IQueryEntitiesesponseData,
    LiveInsightsQueryResponse,
} from '../../../../services/interfaces';
import AddToWatchList from '../../../add-to-watchlist';
import { NoDataImage } from '../../../../components/images/no-data';
import { InfoOutlined } from '@mui/icons-material';
import { LockIcon } from '../../../../components/icons/lock';
import {
    FinancialInfoResponseData,
    FinancialInfoResponseResult,
    TopicSubscription,
} from '../../live-insights/financial-info-interface';
import { AppContext } from '../../../../providers/app-provider';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ pt: 0 }}>{children}</Box>}
        </div>
    );
}

interface IProps {
    entities: IQueryEntitiesesponseData[];
    onFetched: () => void;
    query: string;
}

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#FFFFFF',
        color: 'black',
        maxWidth: 700,
        padding: '24px',
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #D7D9EC',
        boxShadow: '0px 10px 20px -10px rgba(0, 0, 0, 0.50)',
    },
}));

const CompanyAnalysis = (props: IProps) => {
    const [financialsData, setFinancialsData] = useState<
        FinancialInfoResponseResult[]
    >([]);
    const appContext = useContext(AppContext);
    const [topic, setTopic] = useState<TopicSubscription[]>([]);
    const [value, setValue] = React.useState(0);
    const [earningsFilters, setEarningsFilters] = useState<EarningFilter[]>([]);
    const [selectedEarningFilter, setSelectedEarningFilter] =
        useState<EarningFilter | null>(null);
    const [documentTypeFilters, setDocumentTypeFilters] = useState<string[]>(
        []
    );
    const [selectedSECFilter, setSelectedSECFilter] =
        useState<EarningFilter | null>(null);
    const [selectedDocumentTypeFilter, setSelectedDocumentTypeFilter] =
        useState<string | null>(null);
    const handleSelectedDocumentTypeFilterChange = (filter: string) => {
        setSelectedDocumentTypeFilter(filter);
    };
    const [topicDetails, setTopicDetails] =
        useState<ExecutiveSummaryResponseData | null>(null);
    const [quarterFilters, setQuarterFilter] = useState<EarningFilter[]>([]);
    const [secFilters, setSECFilters] = useState<EarningFilter[]>([]);
    const [streamFinished, setStreamFinished] = useState(true);
    const [topicSubscriptionId, setTopicSubscriptionId] = useState<
        string | undefined
    >('');
    const [selectedEntity, setSelectedEntity] =
        useState<FinancialInfoResponseResult | null>(null);
    const { fetchChatbotFinancialsResponse } = useChatbotService();

    useEffect(() => {
        refreshFinancialInfo();
    }, [props.entities, props.query]);

    const refreshFinancialInfo = async () => {
        let message = props.query;
        if (!message) return;
        await fetchChatbotFinancialsResponse(
            message,
            props.entities.filter((e) => e.entityGroup == 'ORG'),
            (data: string) => {
                if (data) {
                    processQueryResponse(data.split('\n'));
                }
            },
            true
        );
    };

    const processQueryResponse = (responses: string[]) => {
        setStreamFinished(false);
        let _entities: FinancialInfoResponseResult[] = [];
        for (let res of responses) {
            try {
                let parsedRes: LiveInsightsQueryResponse = JSON.parse(res);
                switch (parsedRes.chunk) {
                    case 'TICKER_DATA':
                        let data = (parsedRes.data as FinancialInfoResponseData)
                            .results;
                        if (
                            (
                                parsedRes.data as FinancialInfoResponseData
                            ).results.map((result) => ({
                                topicSubscriptionId: result.topicSubscriptionId,
                                query: result.query,
                            }))
                        ) {
                            setTopic([
                                ...(
                                    parsedRes.data as FinancialInfoResponseData
                                ).results.map((result) => ({
                                    topicDetails: {
                                        topicSubscriptionId:
                                            result.topicSubscriptionId,
                                        query: result.query,
                                    },
                                })),
                            ]);
                        }
                        _entities = [..._entities, ...data];
                        break;
                    case 'TERMINATION':
                        setStreamFinished(true);
                        props.onFetched();
                        setFinancialsData(_entities);
                        break;
                }
            } catch (error) {}
        }
    };

    const getEarningsFilterText = (filter: EarningFilter) => {
        if (!filter) {
            return '';
        }
        return `${filter.year} - ${filter.quarter}`;
    };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if (newValue !== undefined) setValue(newValue);
    };

    const handleSelectedEarningFilterChange = (filter: EarningFilter) => {
        setSelectedEarningFilter({ ...filter });
    };

    const getSECFiltersText = (filter: EarningFilter) => {
        if (!filter) {
            return '';
        }
        return `${filter.year} - ${filter.quarter}`;
    };

    const handleSelectedSECFilterChange = (filter: EarningFilter) => {
        setSelectedSECFilter({ ...filter });
    };

    useEffect(() => {
        if (financialsData.length > 0) {
            setSelectedEntity(financialsData[0]);
        }
    }, [financialsData]);

    const { fetchEarningsFilters, fetchExecutiveSummary } =
        useFinancialsService();

    const openFiling = (filing: FinancialAllFilingsResponseDataElement) => {
        if (filing.financeCategory == 'sec_filings') {
            setValue(2);
            setSelectedDocumentTypeFilter(filing.filterElement.documentType);
            setQuarterFilter(
                secFilters.filter(
                    (f) => f.documentType == filing.filterElement.documentType
                )
            );
            handleSelectedSECFilterChange(
                secFilters
                    .filter(
                        (f) =>
                            f.documentType == filing.filterElement.documentType
                    )
                    .filter(
                        (f) =>
                            f.quarter == filing.filterElement.quarter &&
                            f.year == filing.filterElement.year
                    )[0]
            );
        }
        if (filing.financeCategory == 'earnings_calls') {
            setValue(1);
            handleSelectedEarningFilterChange(
                earningsFilters.filter(
                    (f) =>
                        f.quarter == filing.filterElement.quarter &&
                        f.year == filing.filterElement.year
                )[0]
            );
        }
    };

    const refreshEarningsFilters = async () => {
        if (!topicSubscriptionId) return;
        let data = await fetchEarningsFilters({
            topicSubscriptionId,
            financeCategory: 'earnings_calls',
        });
        setEarningsFilters([
            ...data.filters.sort((a: EarningFilter, b: EarningFilter) => {
                if (a.year < b.year) return 1;
                else if (a.year > b.year) return -1;
                else if (a.quarter.split('')[1] < b.quarter.split('')[1])
                    return 1;
                else return -1;
            }),
        ]);
        if (data.filters[0]) handleSelectedEarningFilterChange(data.filters[0]);
    };

    const refreshSECFilters = async () => {
        if (!topicSubscriptionId) return;
        let data = await fetchEarningsFilters({
            topicSubscriptionId,
            financeCategory: 'sec_filings',
        });
        setSECFilters([
            ...data.filters.sort((a: EarningFilter, b: EarningFilter) => {
                if (a.year < b.year) return 1;
                else if (a.year > b.year) return -1;
                else if (a.quarter.split('')[1] < b.quarter.split('')[1])
                    return 1;
                else return -1;
            }),
        ]);

        let docTypeFilters = [
            ...new Set([...data.filters.map((f) => f.documentType)]),
        ];
        setDocumentTypeFilters([...docTypeFilters]);
        setSelectedDocumentTypeFilter(docTypeFilters[0]);
        if (data.filters[0]) handleSelectedSECFilterChange(data.filters[0]);
    };

    useEffect(() => {
        setQuarterFilter(
            secFilters.filter(
                (f) => f.documentType == selectedDocumentTypeFilter
            )
        );
        if (selectedSECFilter?.documentType != selectedDocumentTypeFilter) {
            setSelectedSECFilter(
                secFilters.filter(
                    (f) => f.documentType == selectedDocumentTypeFilter
                )[0]
            );
        }
    }, [selectedDocumentTypeFilter]);

    const refreshTopicDetails = async () => {
        if (!topicSubscriptionId) return;
        const data = await fetchExecutiveSummary(topicSubscriptionId);
        setTopicDetails(data);
    };

    const openSearch = () => {
        //@ts-ignore
        inputRef.current.click();
    };

    useEffect(() => {
        if (!topicSubscriptionId) {
            setValue(0);
            setTopicDetails(null);
            return;
        } else {
            refreshEarningsFilters();
            refreshSECFilters();
            refreshTopicDetails();
        }
    }, [topicSubscriptionId]);

    useEffect(() => {
        setTopicSubscriptionId(selectedEntity?.topicSubscriptionId);
    }, [selectedEntity]);

    const updateCompanyName = (name: string) => {
        let nameArr = name.split(' ');
        nameArr.map(
            (name, index) =>
                (nameArr[index] = name.charAt(0).toUpperCase() + name.slice(1))
        );
        return nameArr.join(' ');
    };
    return (
        <Box>
            {topic.filter(
                (t) =>
                    t?.topicDetails?.query !== props.query?.toLowerCase().trim()
            ).length > 1 && (
                <Box
                    pb={6}
                    py={4}
                    sx={{
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.05)',
                        borderBottom: '1px solid #D7D9EC',
                    }}
                >
                    <Typography
                        mt={3}
                        variant="subtitle1"
                        sx={{
                            backgroundColor: 'rgba(229, 148, 19, 0.20)',
                            width: 'fit-content',
                            paddingX: '8px',
                            paddingY: '4px',
                            borderRadius: '4px',
                        }}
                    >
                        Multiple Entities detected in the searched query.
                        Explore related entities for deeper analysis from your
                        query below.
                    </Typography>

                    <Box mt={3} display="flex">
                        <Typography
                            mr={4}
                            my={1}
                            variant="h6"
                            color={'#4b4b4c'}
                        >
                            Detected Entities:{' '}
                        </Typography>
                        {financialsData.map((e, index) => (
                            <Box
                                sx={{
                                    backgroundColor:
                                        selectedEntity?.query == e.query
                                            ? '#EBECF6'
                                            : '#fff',
                                    border: `1px solid ${
                                        selectedEntity?.query == e.query
                                            ? '#6e3cfb'
                                            : '#D7D9EC'
                                    }`,
                                    borderRadius: 1,
                                    mr: 2,
                                    color:
                                        selectedEntity?.query == e.query
                                            ? '#6e3cfb'
                                            : '#4B4B4C',
                                    py: 0,
                                    px: 2,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Button
                                    sx={{}}
                                    onClick={() => {
                                        setSelectedEntity(e);
                                    }}
                                >
                                    <Typography variant="h6">
                                        {e.query}
                                    </Typography>
                                </Button>
                                <Divider orientation="vertical" />
                                <Box ml={2}>
                                    <AddToWatchList
                                        query={e.query}
                                        entity={true}
                                    />
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </Box>
            )}

            <Box sx={{ mt: 1, textAlign: 'left' }}>
                <Box
                    sx={{
                        width: '100%',
                        mt: 0,
                        maxHeight:
                            topic.filter(
                                (t) =>
                                    t?.topicDetails?.query !==
                                    props.query?.toLowerCase().trim()
                            ).length > 1
                                ? `calc(100vh - ${
                                      appContext?.accountActionBannerVisible
                                          ? '22px'
                                          : '0px'
                                  } - 380px)`
                                : `calc(100vh - ${
                                      appContext?.accountActionBannerVisible
                                          ? '22px'
                                          : '0px'
                                  } - 255px)`,
                        overflowY: 'auto',
                    }}
                >
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        {!selectedEntity && (
                            <Box textAlign={'center'} px={4} py={20}>
                                <Box>{NoDataImage}</Box>
                                <br />
                                <Typography variant="h4">
                                    Sorry, No data available
                                </Typography>
                                <Typography
                                    fontSize={14}
                                    fontWeight={400}
                                    mt={2}
                                >
                                    No data available for this entities. Please
                                    try with some different query
                                </Typography>
                            </Box>
                        )}

                        {selectedEntity && (
                            <Box>
                                <Box display="flex" mb={4}>
                                    <BorderedBox
                                        sx={{
                                            p: 2,
                                            backgroundColor: '#f9f5ff',
                                        }}
                                        display="flex"
                                        alignItems="center"
                                    >
                                        <Typography
                                            variant="h6"
                                            sx={{ mr: 2 }}
                                        >{`${updateCompanyName(
                                            selectedEntity.query
                                        )} ${
                                            selectedEntity.ticker
                                                ? `(${selectedEntity.ticker})`
                                                : ''
                                        }`}</Typography>
                                        <HtmlTooltip
                                            placement="bottom-start"
                                            title={
                                                <Typography variant="subtitle1">
                                                    {
                                                        selectedEntity.company_description
                                                    }
                                                </Typography>
                                            }
                                        >
                                            <InfoOutlined />
                                        </HtmlTooltip>
                                    </BorderedBox>
                                    {selectedEntity.sector && (
                                        <BorderedBox
                                            sx={{
                                                p: 2,
                                                ml: 4,
                                                backgroundColor: '#f9f5ff',
                                            }}
                                        >
                                            <Typography variant="h6">
                                                Sector: {selectedEntity.sector}
                                            </Typography>
                                        </BorderedBox>
                                    )}
                                    {selectedEntity.industry && (
                                        <BorderedBox
                                            sx={{
                                                p: 2,
                                                ml: 4,
                                                backgroundColor: '#f9f5ff',
                                            }}
                                        >
                                            <Typography variant="h6">
                                                Industry:{' '}
                                                {selectedEntity.industry}
                                            </Typography>
                                        </BorderedBox>
                                    )}
                                </Box>
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="basic tabs example"
                                >
                                    <Tab
                                        sx={{
                                            px: 20,
                                            fontWeight: 600,
                                            color: '#4B4B4C',
                                        }}
                                        label="Company Details"
                                    />
                                    <Tab
                                        sx={{
                                            px: 20,
                                            fontWeight: 600,
                                            color: '#4B4B4C',
                                        }}
                                        label="Earnings Calls"
                                    />
                                    <Tab
                                        sx={{
                                            px: 20,
                                            fontWeight: 600,
                                            color: '#4B4B4C',
                                        }}
                                        label="SEC Filings"
                                    />
                                    {selectedEntity.topicSubscriptionId && (
                                        <Box
                                            sx={{
                                                marginLeft: 'auto',
                                                mb: 1,
                                            }}
                                        >
                                            {value == 1 && (
                                                <SingleSelect
                                                    pb={1}
                                                    pt={1}
                                                    getOptionText={
                                                        getEarningsFilterText
                                                    }
                                                    placeholder="Select Source"
                                                    options={earningsFilters}
                                                    disableCheckbox={true}
                                                    selectedValue={
                                                        selectedEarningFilter
                                                    }
                                                    onChange={
                                                        handleSelectedEarningFilterChange
                                                    }
                                                />
                                            )}{' '}
                                            {value == 2 && (
                                                <Box display="flex">
                                                    <SingleSelect
                                                        pb={1}
                                                        pt={1}
                                                        getOptionText={(
                                                            s: any
                                                        ) => s}
                                                        placeholder="Select Source"
                                                        options={
                                                            documentTypeFilters
                                                        }
                                                        disableCheckbox={true}
                                                        selectedValue={
                                                            selectedDocumentTypeFilter
                                                        }
                                                        onChange={
                                                            handleSelectedDocumentTypeFilterChange
                                                        }
                                                    />
                                                    <SingleSelect
                                                        pb={1}
                                                        pt={1}
                                                        ml={2}
                                                        getOptionText={
                                                            getSECFiltersText
                                                        }
                                                        placeholder="Select Source"
                                                        options={quarterFilters}
                                                        disableCheckbox={true}
                                                        selectedValue={
                                                            selectedSECFilter
                                                        }
                                                        onChange={
                                                            handleSelectedSECFilterChange
                                                        }
                                                    />
                                                </Box>
                                            )}
                                        </Box>
                                    )}
                                </Tabs>
                            </Box>
                        )}
                    </Box>

                    {selectedEntity && (
                        <Box>
                            <TabPanel value={value} index={0}>
                                <CompanyDetails
                                    openFiling={openFiling}
                                    openSearch={openSearch}
                                    companyData={selectedEntity}
                                    query={props.query}
                                />
                            </TabPanel>

                            <TabPanel value={value} index={1}>
                                <EarningCall
                                    selectedEarningFilter={
                                        selectedEarningFilter
                                    }
                                    topicSubscriptionId={
                                        selectedEntity.topicSubscriptionId
                                    }
                                    query={props.query}
                                />
                            </TabPanel>

                            <TabPanel value={value} index={2}>
                                <SECFilings
                                    selectedSECFilter={selectedSECFilter}
                                    topicSubscriptionId={
                                        selectedEntity.topicSubscriptionId
                                    }
                                    query={props.query}
                                />
                            </TabPanel>
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default CompanyAnalysis;
