import {
    Box,
    Breadcrumbs,
    Grid,
    Skeleton,
    Tooltip,
    Typography,
} from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useContext, useEffect, useState } from 'react';
import { TableHeadRow } from '../core/table/comparison/head-row';
import { TableBodyRow } from '../core/table/common/row';
import {
    Granularity,
    useInsightsService,
} from '../../services/insights.service';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
    IDetailedInformation,
    Insight,
    Topic,
} from '../../services/interfaces';
import { NegativeLinearProgress, PositiveLinearProgress } from '../progress';
import { DataTableWrapper } from '../core/table/data/table';
import { NavigationLink } from '../core/link';
import InitialsAvator from '../core/avator';
import { HeadCell } from '../core/table/data/head-cell';
import { SortOrder } from '../icons/sort';

import { UtilsService } from '../../services/utils.service';
import { Expand } from '../icons/expand';
import { TextHighlight } from '../sentiment-highlights';
import { AppContext } from '../../providers/app-provider';
import { DetailedInformation } from '../../pages/insight/details/detailed-information';

interface IProps {
    granularity: Granularity;
    topicSubscriptionIds: string[];
    inbuiltDetail?: boolean;
}

export const DetailedInsights = (props: IProps) => {
    const { fetchDetailedInsights } = useInsightsService();
    const [topicName, setTopicName] = useState('');
    const [topicSubscriptionId, setTopicSubscriptionId] = useState('');
    const [date, setDate] = useState('');
    const [id, setId] = useState('');
    const [pagesCount, setPagesCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const appContext = useContext(AppContext);
    const [data, setData] = useState<IDetailedInformation[] | null>();
    const refetchDetailedInsights = async () => {
        let response: {
            data: IDetailedInformation[];
            count: string;
        } = await fetchDetailedInsights(
            props.topicSubscriptionIds,
            props.granularity,
            currentPage
        );
        setData([...response.data]);

        setPagesCount(Math.ceil(parseInt(response.count) / 10));
    };

    useEffect(() => {
        if (topicSubscriptionId && appContext?.topics.length) {
            let topic = appContext.topics.filter(
                (s: Topic) => s.topicSubscriptionId == topicSubscriptionId
            )[0];
            if (topic) {
                setTopicName(topic.subscriptionName);
            }
        }
    }, [topicSubscriptionId, appContext?.topics]);

    useEffect(() => {
        setData(null);
        refetchDetailedInsights();
    }, [
        props.granularity,
        props.topicSubscriptionIds,
        currentPage,
        appContext?.category,
        appContext?.filters.date,
    ]);

    const renderDetails = (
        _topicSubscriptionId: string,
        _date: string,

        _id: string
    ) => {
        setTopicSubscriptionId(_topicSubscriptionId);
        setDate(_date);
        setId(_id);
    };

    return (
        <Box>
            {topicSubscriptionId && (
                <Box pt={6}>
                    <Stack spacing={1}>
                        <Breadcrumbs
                            separator={<NavigateNextIcon fontSize="small" />}
                            aria-label="breadcrumb"
                        >
                            <Typography
                                color="#6e3cfb"
                                sx={{
                                    textDecoration: 'none',
                                }}
                            >
                                {topicName}
                            </Typography>
                            <Typography
                                onClick={() => {
                                    setTopicSubscriptionId('');
                                }}
                                color="#6e3cfb"
                                sx={{
                                    textDecoration: 'none',
                                    cursor: 'pointer',
                                }}
                            >
                                Detailed Information
                            </Typography>
                            <Typography
                                color="inherit"
                                sx={{
                                    textDecoration: 'none',
                                }}
                            >
                                Insight
                            </Typography>
                        </Breadcrumbs>
                    </Stack>
                    <Box pt={2}>
                        <Typography variant="h1">Insight collected</Typography>
                        {appContext && (
                            <Typography variant="subtitle1">
                                {UtilsService.formatStringDate(date)}
                            </Typography>
                        )}
                    </Box>
                    <DetailedInformation
                        topicSubscriptionId={topicSubscriptionId}
                        level="article"
                        filter1={id}
                    />
                </Box>
            )}
            <Box display={topicSubscriptionId ? 'none' : ''}>
                <TableContainer>
                    <DataTableWrapper>
                        <TableHead>
                            <TableHeadRow>
                                <HeadCell sortOrder={SortOrder.ASC}>
                                    <Typography variant="subtitle1">
                                        Summary
                                    </Typography>
                                </HeadCell>
                                <HeadCell>
                                    <Typography variant="subtitle1">
                                        Positive
                                    </Typography>
                                </HeadCell>
                                <HeadCell>
                                    <Typography variant="subtitle1">
                                        Negative
                                    </Typography>
                                </HeadCell>
                                <HeadCell alignCenter>
                                    <Typography variant="subtitle1">
                                        Mentions
                                    </Typography>
                                </HeadCell>
                                <HeadCell minWidth={20}>{''}</HeadCell>
                            </TableHeadRow>
                        </TableHead>
                        <TableBody>
                            {data?.map((row: IDetailedInformation) => (
                                <TableBodyRow>
                                    <TableCell align="left">
                                        <Box>
                                            <Box sx={{ display: 'flex' }}>
                                                <InitialsAvator
                                                    value={row.topicName}
                                                />
                                                <Typography
                                                    variant="subtitle2"
                                                    fontSize={14}
                                                    to={`/insight/article/${row.topicSubscriptionId}/${row.date}/${row.id}`}
                                                    component={
                                                        props.inbuiltDetail
                                                            ? Box
                                                            : NavigationLink
                                                    }
                                                    sx={{
                                                        cursor: 'pointer',
                                                        padding:
                                                            '2px 0px 0px 4px',
                                                    }}
                                                    onClick={() => {
                                                        if (
                                                            props.inbuiltDetail
                                                        ) {
                                                            renderDetails(
                                                                row.topicSubscriptionId,
                                                                row.date,
                                                                row.id
                                                            );
                                                        }
                                                    }}
                                                >
                                                    {row.topicName}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ mb: 2 }}>
                                                <Typography
                                                    sx={{ color: '#A5A6AB' }}
                                                    variant="caption"
                                                >
                                                    {UtilsService.formatStringDate(
                                                        row.date
                                                    )}
                                                </Typography>
                                            </Box>
                                            {row.insights.map(
                                                (insight: Insight, index) => (
                                                    <Box>
                                                        <Box>
                                                            <Typography
                                                                text={
                                                                    insight.summary
                                                                }
                                                                highlight={
                                                                    insight.highlightIndexes
                                                                }
                                                                component={
                                                                    TextHighlight
                                                                }
                                                                variant="caption"
                                                            ></Typography>
                                                        </Box>
                                                        {index !=
                                                            row.insights
                                                                .length -
                                                                1 && (
                                                            <Grid
                                                                sx={{
                                                                    width: 40,
                                                                    my: 2,
                                                                }}
                                                                justifyContent="space-between"
                                                                container
                                                            >
                                                                {[1, 2, 3].map(
                                                                    () => (
                                                                        <Grid
                                                                            item
                                                                            sx={{
                                                                                height: 8,
                                                                                width: 8,
                                                                                backgroundColor:
                                                                                    '#ccc',
                                                                                borderRadius:
                                                                                    '50%',
                                                                            }}
                                                                        ></Grid>
                                                                    )
                                                                )}
                                                            </Grid>
                                                        )}
                                                    </Box>
                                                )
                                            )}
                                        </Box>
                                    </TableCell>
                                    <TableCell align="left">
                                        {row.sentiment.positive && (
                                            <Box>
                                                <PositiveLinearProgress
                                                    variant="determinate"
                                                    value={UtilsService.convertSentimentToPercentage(
                                                        row.sentiment.positive
                                                    )}
                                                />
                                                <Typography
                                                    sx={{ mt: 2 }}
                                                    variant="h6"
                                                >
                                                    {UtilsService.convertSentimentToPercentage(
                                                        row.sentiment.positive
                                                    )}
                                                    %
                                                </Typography>
                                            </Box>
                                        )}
                                    </TableCell>
                                    <TableCell align="left">
                                        {row.sentiment.negative && (
                                            <Box>
                                                <NegativeLinearProgress
                                                    variant="determinate"
                                                    value={UtilsService.convertSentimentToPercentage(
                                                        row.sentiment.negative
                                                    )}
                                                />
                                                <Typography
                                                    sx={{ mt: 2 }}
                                                    variant="h6"
                                                >
                                                    {UtilsService.convertSentimentToPercentage(
                                                        row.sentiment.negative
                                                    )}
                                                    %
                                                </Typography>
                                            </Box>
                                        )}
                                    </TableCell>
                                    <TableCell align="center">
                                        <Box display="flex">
                                            <Box
                                                sx={{
                                                    border: '1px solid',
                                                    borderColor: '#EBECF6',
                                                    borderRadius: '60%',
                                                    marginLeft: 'auto',
                                                    padding: 3,
                                                    marginRight: 'auto',
                                                }}
                                            >
                                                <Typography variant="h6">
                                                    {row.mentions}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography
                                            to={`/insight/article/${props.topicSubscriptionIds[0]}/${row.date}/${row.id}`}
                                            component={
                                                props.inbuiltDetail
                                                    ? Box
                                                    : NavigationLink
                                            }
                                            sx={{
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                if (props.inbuiltDetail) {
                                                    renderDetails(
                                                        row.topicSubscriptionId,
                                                        row.date,
                                                        row.id
                                                    );
                                                }
                                            }}
                                        >
                                            <Tooltip
                                                arrow
                                                title="View sources"
                                                placement="top"
                                            >
                                                {Expand}
                                            </Tooltip>
                                        </Typography>
                                    </TableCell>
                                </TableBodyRow>
                            ))}
                            {!data &&
                                [1, 2, 3].map(() => (
                                    <TableBodyRow>
                                        <TableCell width="70%" align="left">
                                            <Box>
                                                <Box sx={{ display: 'flex' }}>
                                                    <Skeleton
                                                        variant="circular"
                                                        width={18}
                                                        height={18}
                                                    />
                                                    <Skeleton
                                                        variant="text"
                                                        width={100}
                                                        sx={{
                                                            fontSize: '1rem',
                                                            ml: 4,
                                                        }}
                                                    />
                                                </Box>
                                                <Box sx={{ mb: 2 }}>
                                                    <Skeleton
                                                        variant="text"
                                                        width={150}
                                                        sx={{
                                                            fontSize: '1rem',
                                                        }}
                                                    />
                                                </Box>
                                                <Box>
                                                    <Box>
                                                        <Typography variant="caption">
                                                            <Skeleton
                                                                variant="text"
                                                                sx={{
                                                                    fontSize:
                                                                        '1rem',
                                                                }}
                                                            />
                                                            <Skeleton
                                                                variant="text"
                                                                sx={{
                                                                    fontSize:
                                                                        '1rem',
                                                                }}
                                                            />
                                                            <Skeleton
                                                                variant="text"
                                                                sx={{
                                                                    fontSize:
                                                                        '1rem',
                                                                }}
                                                            />
                                                            <Skeleton
                                                                variant="text"
                                                                sx={{
                                                                    fontSize:
                                                                        '1rem',
                                                                }}
                                                            />
                                                        </Typography>
                                                    </Box>
                                                    <Grid
                                                        sx={{
                                                            width: 40,
                                                            my: 2,
                                                        }}
                                                        justifyContent="space-between"
                                                        container
                                                    >
                                                        {[1, 2, 3].map(() => (
                                                            <Grid
                                                                item
                                                                sx={{
                                                                    height: 8,
                                                                    width: 8,
                                                                    backgroundColor:
                                                                        '#ccc',
                                                                    borderRadius:
                                                                        '50%',
                                                                }}
                                                            ></Grid>
                                                        ))}
                                                    </Grid>
                                                    <Box>
                                                        <Typography variant="caption">
                                                            <Skeleton
                                                                variant="text"
                                                                sx={{
                                                                    fontSize:
                                                                        '1rem',
                                                                }}
                                                            />
                                                            <Skeleton
                                                                variant="text"
                                                                sx={{
                                                                    fontSize:
                                                                        '1rem',
                                                                }}
                                                            />
                                                            <Skeleton
                                                                variant="text"
                                                                sx={{
                                                                    fontSize:
                                                                        '1rem',
                                                                }}
                                                            />
                                                            <Skeleton
                                                                variant="text"
                                                                sx={{
                                                                    fontSize:
                                                                        '1rem',
                                                                }}
                                                            />
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Box>
                                                <Skeleton
                                                    variant="text"
                                                    sx={{ fontSize: '1rem' }}
                                                />
                                                <Skeleton
                                                    variant="text"
                                                    sx={{ fontSize: '1rem' }}
                                                />
                                            </Box>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Box>
                                                <Skeleton
                                                    variant="text"
                                                    sx={{ fontSize: '1rem' }}
                                                />
                                                <Skeleton
                                                    variant="text"
                                                    sx={{ fontSize: '1rem' }}
                                                />
                                            </Box>
                                        </TableCell>
                                    </TableBodyRow>
                                ))}
                        </TableBody>
                    </DataTableWrapper>
                </TableContainer>
                <Box sx={{ mt: 2 }}>
                    <Stack spacing={2}>
                        <Pagination
                            sx={{ mr: 'auto' }}
                            count={pagesCount}
                            size="small"
                            onChange={(e, p) => {
                                setCurrentPage(p);
                            }}
                            renderItem={(item) => (
                                <PaginationItem
                                    slots={{
                                        previous: ArrowBackIcon,
                                        next: ArrowForwardIcon,
                                    }}
                                    {...item}
                                />
                            )}
                        />
                    </Stack>
                </Box>
            </Box>
        </Box>
    );
};
