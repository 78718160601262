import { styled, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { FooterMenuItems, MenuItems } from '../menu-items/menu-items';
import { IMenuItem, IMenuItemChild } from '../menu-items/menu-item.interface';
import Grid from '@mui/material/Grid';
import { MiniLogo } from '../../icons/mini-logo';
import { boxedArrow } from '../../icons/boxed-arrow';
import { NavLink } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import NotificationsNoneRoundedIcon from '@mui/icons-material/NotificationsNoneRounded';
import { useContext, useEffect, useState } from 'react';
import {
    leftMenuCollapsed,
    leftMenuExpanded,
    leftMenuItemClick,
} from '../../../analytics/analytics';
import {
    Avatar,
    Badge,
    Menu,
    MenuItem,
    Tooltip,
    Typography,
} from '@mui/material';
import { AppContext } from '../../../providers/app-provider';
import { NotificationIcon } from '../../icons/notification-icon';
import { useAuthService } from '../../../services/auth.service';
import { NavigationLink } from '../../core/link';
import { Logo } from '../../icons/logo';
import { UtilsService } from '../../../services/utils.service';
const drawerWidth = 240;

declare const window: any;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(22)} + 1px)`,
    backgroundColor: theme.palette.secondary.light,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(22)} + 1px)`,
        display: 'block !important',
    },
    [theme.breakpoints.up('xs')]: {
        display: 'none',
    },
});
const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const FooterMenuList = styled(List)(({ theme }) => ({
    position: 'absolute',
    bottom: 0,
    width: '100%',
    paddingBottom: 0,
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    backgroundColor: theme.palette.secondary.light,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));
interface IProps {
    menuItem: IMenuItem;
}

const MenuItemWithChild = (props: IProps) => {
    const appContext = useContext(AppContext);
    const { menuItem } = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Box>
            <Tooltip
                arrow
                open={false}
                placement="right"
                title={
                    appContext?.menuExpanded
                        ? menuItem.description || ''
                        : `${menuItem.title}${
                              menuItem.description
                                  ? `: ${menuItem.description}`
                                  : ''
                          }`
                }
            >
                <ListItem
                    disablePadding
                    sx={{
                        display: 'block',
                        textDecoration: 'none',
                        ...menuItem.sx,
                    }}
                >
                    <ListItemButton
                        onClick={handleClick}
                        sx={{
                            minHeight: 48,
                            justifyContent: appContext?.menuExpanded
                                ? 'initial'
                                : 'center',
                            px: 2.5,
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: appContext?.menuExpanded ? 3 : 'auto',
                                ml: appContext?.menuExpanded ? 5 : 5,
                                justifyContent: 'center',
                            }}
                        >
                            <menuItem.icon
                                backgroundFillColor={'#000033'}
                                fillColor={'#000033'}
                            />
                        </ListItemIcon>
                        <ListItemText
                            primary={menuItem.title}
                            sx={{
                                opacity: appContext?.menuExpanded ? 1 : 0,
                                color: '#000033',
                            }}
                        />
                        <Box
                            sx={{
                                opacity: appContext?.menuExpanded ? 1 : 0,
                            }}
                        >
                            {anchorEl ? <ExpandLess /> : <ExpandMore />}
                        </Box>
                    </ListItemButton>
                </ListItem>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                sx={{
                    zIndex: 10000,
                }}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: -10,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            bottom: -10,
                            left: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                        minWidth: 220,
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
                {menuItem.children?.map(
                    (menuItemChild: IMenuItemChild, index: number) => (
                        <Box>
                            <Tooltip
                                open={false}
                                arrow
                                placement="right"
                                title={
                                    appContext?.menuExpanded
                                        ? menuItemChild.description || ''
                                        : `${menuItemChild.title}${
                                              menuItemChild.description
                                                  ? `: ${menuItemChild.description}`
                                                  : ''
                                          }`
                                }
                            >
                                <NavLink
                                    onClick={() => {
                                        leftMenuItemClick(
                                            menuItem.title,
                                            menuItem.link
                                        );
                                    }}
                                    style={{
                                        textDecoration: 'none',
                                    }}
                                    key={index}
                                    to={menuItemChild.link}
                                >
                                    {({ isActive }: { isActive: boolean }) => (
                                        <MenuItem
                                            sx={{
                                                backgroundColor: isActive
                                                    ? '#6e3cfb !important'
                                                    : '',
                                            }}
                                            onClick={handleClose}
                                        >
                                            <ListItemIcon>
                                                <menuItemChild.icon
                                                    backgroundFillColor={
                                                        !isActive
                                                            ? '#f9f5ff'
                                                            : '#6e3cfb'
                                                    }
                                                    fillColor={
                                                        isActive
                                                            ? '#f9f5ff'
                                                            : '#000033'
                                                    }
                                                />
                                            </ListItemIcon>
                                            <Typography
                                                sx={{
                                                    color: isActive
                                                        ? '#f9f5ff'
                                                        : '#000033',
                                                    fontWeight: isActive
                                                        ? 600
                                                        : 400,
                                                }}
                                            >
                                                {menuItemChild.title}
                                            </Typography>
                                        </MenuItem>
                                    )}
                                </NavLink>
                            </Tooltip>
                        </Box>
                    )
                )}
            </Menu>
        </Box>
    );
};

export const LeftMenu = () => {
    const appContext = useContext(AppContext);
    const { getUserName } = useAuthService();
    const [name, setName] = useState<string | undefined>('');
    const [unreadCount, setUnreadCount] = useState(0);
    const [subMenuStates, setSubMenuStates] = useState<string[]>([]);
    const refreshName = async () => {
        setName(await getUserName());
    };
    useEffect(() => {
        refreshName();
    }, []);
    useEffect(() => {
        setUnreadCount(window.unreadCount);
    }, [window.unreadCount]);

    const toggleDrawer = () => {
        if (appContext?.menuExpanded) {
            leftMenuCollapsed();
        } else {
            leftMenuExpanded();
        }
        window.dispatchEvent(new Event('resize'));
        appContext?.setMenuExpanded(!appContext.menuExpanded);
    };

    const toggleSubMenuState = (title: string) => {
        if (getSubMenuState(title)) {
            setSubMenuStates([
                ...subMenuStates.filter(
                    (menuItem: string) => menuItem != title
                ),
            ]);
        } else {
            appContext?.setMenuExpanded(true);
            setSubMenuStates([...subMenuStates, title]);
        }
    };

    const getSubMenuState = (title: string) => {
        return !!(subMenuStates.includes(title) && appContext?.menuExpanded);
    };
    const onHover = () => {
        appContext?.setMenuExpanded(true);
    };
    const onMouseRemoved = () => {
        appContext?.setMenuExpanded(false);
    };

    return (
        <>
            <Drawer
                onMouseOver={onHover}
                onMouseOut={onMouseRemoved}
                sx={{
                    position: 'fixed',
                    zIndex: 99,
                }}
                PaperProps={{
                    sx: {
                        boxShadow: appContext?.menuExpanded
                            ? '0px 15px 15px 0px rgba(0, 0, 0, 0.25)'
                            : 'none',
                    },
                }}
                variant="permanent"
                open={appContext?.menuExpanded}
            >
                <DrawerHeader>
                    <Grid container sx={{ maxHeight: 40, overflow: 'hidden' }}>
                        <Grid
                            sx={{
                                m: 0,
                                mt: appContext?.menuExpanded ? 1.5 : 2.5,
                                ml: 8,
                            }}
                            item
                        >
                            <NavigationLink to={`/`}>
                                {appContext?.menuExpanded ? (
                                    <Box
                                        pr={1}
                                        sx={{
                                            verticalAlign: 'sub',
                                        }}
                                        component="span"
                                    >
                                        <Logo width={140} />
                                    </Box>
                                ) : (
                                    <Box
                                        pr={1}
                                        sx={{
                                            verticalAlign: 'sub',
                                        }}
                                        component="span"
                                    >
                                        <MiniLogo height="20" width="20" />
                                    </Box>
                                )}
                            </NavigationLink>
                        </Grid>
                    </Grid>
                </DrawerHeader>
                <Divider />
                <List id="app-menu" sx={{ pt: 0 }}>
                    {appContext?.menuItems.map(
                        (menuItem: IMenuItem, index: number) => (
                            <Box py={1}>
                                {!menuItem.children && (
                                    <Tooltip
                                        arrow
                                        open={false}
                                        enterDelay={1000}
                                        placement="right"
                                        title={
                                            appContext?.menuExpanded
                                                ? menuItem.description || ''
                                                : `${menuItem.title}${
                                                      menuItem.description
                                                          ? `: ${menuItem.description}`
                                                          : ''
                                                  }`
                                        }
                                    >
                                        <NavLink
                                            style={{ textDecoration: 'none' }}
                                            key={index}
                                            to={menuItem.link}
                                        >
                                            {({
                                                isActive,
                                            }: {
                                                isActive: boolean;
                                            }) => (
                                                <ListItem
                                                    disablePadding
                                                    sx={{
                                                        display: 'block',
                                                        backgroundColor:
                                                            isActive
                                                                ? '#6e3cfb'
                                                                : '',
                                                        textDecoration: 'none',
                                                    }}
                                                >
                                                    <ListItemButton
                                                        id={UtilsService.generateIdFromString(
                                                            menuItem.title
                                                        )}
                                                        sx={{
                                                            minHeight: 48,
                                                            justifyContent:
                                                                appContext?.menuExpanded
                                                                    ? 'initial'
                                                                    : 'center',
                                                            px: 2.5,
                                                        }}
                                                    >
                                                        <ListItemIcon
                                                            sx={{
                                                                minWidth: 0,
                                                                mr: appContext?.menuExpanded
                                                                    ? 3
                                                                    : 'auto',
                                                                ml: appContext?.menuExpanded
                                                                    ? 5
                                                                    : 5,
                                                                justifyContent:
                                                                    'center',
                                                            }}
                                                        >
                                                            <menuItem.icon
                                                                backgroundFillColor={
                                                                    !isActive
                                                                        ? '#f9f5ff'
                                                                        : '#6e3cfb'
                                                                }
                                                                fillColor={
                                                                    isActive
                                                                        ? '#f9f5ff'
                                                                        : '#000033'
                                                                }
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={
                                                                menuItem.title
                                                            }
                                                            primaryTypographyProps={{
                                                                fontWeight:
                                                                    isActive
                                                                        ? 600
                                                                        : 400,
                                                            }}
                                                            sx={{
                                                                opacity:
                                                                    appContext?.menuExpanded
                                                                        ? 1
                                                                        : 0,
                                                                color: isActive
                                                                    ? '#f9f5ff'
                                                                    : '#000033',
                                                            }}
                                                        />
                                                    </ListItemButton>
                                                </ListItem>
                                            )}
                                        </NavLink>
                                    </Tooltip>
                                )}
                                {menuItem.children && (
                                    <Box>
                                        <Tooltip
                                            enterDelay={400}
                                            open={false}
                                            placement="right"
                                            title={
                                                appContext?.menuExpanded
                                                    ? menuItem.description || ''
                                                    : `${menuItem.title}${
                                                          menuItem.description
                                                              ? `: ${menuItem.description}`
                                                              : ''
                                                      }`
                                            }
                                        >
                                            <ListItem
                                                disablePadding
                                                sx={{
                                                    display: 'block',
                                                    textDecoration: 'none',
                                                }}
                                            >
                                                <ListItemButton
                                                    onClick={() => {
                                                        toggleSubMenuState(
                                                            menuItem.title
                                                        );
                                                    }}
                                                    sx={{
                                                        minHeight: 48,
                                                        justifyContent:
                                                            appContext?.menuExpanded
                                                                ? 'initial'
                                                                : 'center',
                                                        px: 2.5,
                                                    }}
                                                >
                                                    <ListItemIcon
                                                        sx={{
                                                            minWidth: 0,
                                                            mr: appContext?.menuExpanded
                                                                ? 3
                                                                : 'auto',
                                                            ml: appContext?.menuExpanded
                                                                ? 5
                                                                : 5,
                                                            justifyContent:
                                                                'center',
                                                        }}
                                                    >
                                                        <menuItem.icon
                                                            backgroundFillColor={
                                                                '#000033'
                                                            }
                                                            fillColor={
                                                                '#000033'
                                                            }
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={menuItem.title}
                                                        sx={{
                                                            opacity:
                                                                appContext?.menuExpanded
                                                                    ? 1
                                                                    : 0,
                                                            color: '#000033',
                                                        }}
                                                    />
                                                    <Box
                                                        sx={{
                                                            opacity:
                                                                appContext?.menuExpanded
                                                                    ? 1
                                                                    : 0,
                                                        }}
                                                    >
                                                        {getSubMenuState(
                                                            menuItem.title
                                                        ) ? (
                                                            <ExpandLess />
                                                        ) : (
                                                            <ExpandMore />
                                                        )}
                                                    </Box>
                                                </ListItemButton>
                                            </ListItem>
                                        </Tooltip>
                                        <Collapse
                                            in={getSubMenuState(menuItem.title)}
                                            timeout="auto"
                                            unmountOnExit
                                        >
                                            {menuItem.children?.map(
                                                (
                                                    menuItemChild: IMenuItemChild,
                                                    index: number
                                                ) => (
                                                    <Box>
                                                        <Tooltip
                                                            open={false}
                                                            arrow
                                                            placement="right"
                                                            title={
                                                                appContext?.menuExpanded
                                                                    ? menuItemChild.description ||
                                                                      ''
                                                                    : `${
                                                                          menuItemChild.title
                                                                      }${
                                                                          menuItemChild.description
                                                                              ? `: ${menuItemChild.description}`
                                                                              : ''
                                                                      }`
                                                            }
                                                        >
                                                            <NavLink
                                                                onClick={() => {
                                                                    leftMenuItemClick(
                                                                        menuItem.title,
                                                                        menuItem.link
                                                                    );
                                                                }}
                                                                style={{
                                                                    textDecoration:
                                                                        'none',
                                                                }}
                                                                key={index}
                                                                to={
                                                                    menuItemChild.link
                                                                }
                                                            >
                                                                {({
                                                                    isActive,
                                                                }: {
                                                                    isActive: boolean;
                                                                }) => (
                                                                    <ListItem
                                                                        disablePadding
                                                                        sx={{
                                                                            display:
                                                                                'block',
                                                                            backgroundColor:
                                                                                isActive
                                                                                    ? '#6e3cfb'
                                                                                    : '',
                                                                            textDecoration:
                                                                                'none',
                                                                        }}
                                                                    >
                                                                        <ListItemButton
                                                                            sx={{
                                                                                minHeight: 48,
                                                                                justifyContent:
                                                                                    appContext?.menuExpanded
                                                                                        ? 'initial'
                                                                                        : 'center',
                                                                                px: 2.5,
                                                                                pl: 10,
                                                                            }}
                                                                        >
                                                                            <ListItemIcon
                                                                                sx={{
                                                                                    minWidth: 0,
                                                                                    mr: appContext?.menuExpanded
                                                                                        ? 3
                                                                                        : 'auto',
                                                                                    ml: appContext?.menuExpanded
                                                                                        ? 5
                                                                                        : 5,
                                                                                    justifyContent:
                                                                                        'center',
                                                                                }}
                                                                            >
                                                                                <menuItemChild.icon
                                                                                    backgroundFillColor={
                                                                                        !isActive
                                                                                            ? '#f9f5ff'
                                                                                            : '#6e3cfb'
                                                                                    }
                                                                                    fillColor={
                                                                                        isActive
                                                                                            ? '#f9f5ff'
                                                                                            : '#000033'
                                                                                    }
                                                                                />
                                                                            </ListItemIcon>
                                                                            <ListItemText
                                                                                primary={
                                                                                    menuItemChild.title
                                                                                }
                                                                                primaryTypographyProps={{
                                                                                    fontWeight:
                                                                                        isActive
                                                                                            ? 600
                                                                                            : 400,
                                                                                }}
                                                                                sx={{
                                                                                    opacity:
                                                                                        appContext?.menuExpanded
                                                                                            ? 1
                                                                                            : 0,
                                                                                    color: isActive
                                                                                        ? '#f9f5ff'
                                                                                        : '#000033',
                                                                                }}
                                                                            />
                                                                        </ListItemButton>
                                                                    </ListItem>
                                                                )}
                                                            </NavLink>
                                                        </Tooltip>
                                                    </Box>
                                                )
                                            )}
                                        </Collapse>
                                    </Box>
                                )}
                            </Box>
                        )
                    )}

                    {/* <HeadwayWidget/> */}
                </List>

                <FooterMenuList>
                    <Box id="updates">
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: appContext?.menuExpanded
                                    ? 'initial'
                                    : 'center',
                                px: 2.5,
                            }}
                            onClick={() => {
                                window.unreadCount = 0;
                                setUnreadCount(0);
                            }}
                            className="beamer"
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: appContext?.menuExpanded ? 3 : 'auto',
                                    ml: appContext?.menuExpanded ? 5 : 5,
                                    justifyContent: 'center',
                                }}
                            >
                                <Badge
                                    invisible={unreadCount < 1}
                                    variant="dot"
                                    sx={{
                                        '& .MuiBadge-dot': {
                                            backgroundColor: '#19AF55',
                                        },
                                    }}
                                >
                                    {NotificationIcon}
                                </Badge>
                            </ListItemIcon>
                            <ListItemText
                                primary={'Product Updates'}
                                primaryTypographyProps={{
                                    fontWeight: 400,
                                }}
                                sx={{
                                    opacity: appContext?.menuExpanded ? 1 : 0,
                                    color: '#000033',
                                }}
                            />
                        </ListItemButton>
                    </Box>
                    {name &&
                        FooterMenuItems.map(
                            (
                                mItem:
                                    | IMenuItem
                                    | ((userName: string) => IMenuItem),
                                index: number
                            ) => {
                                let menuItem =
                                    typeof mItem == 'function'
                                        ? mItem(name)
                                        : mItem;

                                return (
                                    <Box id="account" py={1}>
                                        {!menuItem.children && (
                                            <Tooltip
                                                arrow
                                                open={false}
                                                placement="right"
                                                title={
                                                    appContext?.menuExpanded
                                                        ? menuItem.description ||
                                                          ''
                                                        : `${menuItem.title}${
                                                              menuItem.description
                                                                  ? `: ${menuItem.description}`
                                                                  : ''
                                                          }`
                                                }
                                            >
                                                <NavLink
                                                    style={{
                                                        textDecoration: 'none',
                                                    }}
                                                    key={index}
                                                    to={menuItem.link}
                                                >
                                                    {({
                                                        isActive,
                                                    }: {
                                                        isActive: boolean;
                                                    }) => (
                                                        <ListItem
                                                            disablePadding
                                                            sx={{
                                                                display:
                                                                    'block',
                                                                backgroundColor:
                                                                    isActive
                                                                        ? '#6e3cfb'
                                                                        : '',
                                                                textDecoration:
                                                                    'none',
                                                            }}
                                                        >
                                                            <ListItemButton
                                                                sx={{
                                                                    minHeight: 48,
                                                                    justifyContent:
                                                                        appContext?.menuExpanded
                                                                            ? 'initial'
                                                                            : 'center',
                                                                    px: 2.5,
                                                                }}
                                                            >
                                                                <ListItemIcon
                                                                    sx={{
                                                                        minWidth: 0,
                                                                        mr: appContext?.menuExpanded
                                                                            ? 3
                                                                            : 'auto',
                                                                        ml: appContext?.menuExpanded
                                                                            ? 5
                                                                            : 5,
                                                                        justifyContent:
                                                                            'center',
                                                                    }}
                                                                >
                                                                    <menuItem.icon
                                                                        backgroundFillColor={
                                                                            !isActive
                                                                                ? '#f9f5ff'
                                                                                : '#6e3cfb'
                                                                        }
                                                                        fillColor={
                                                                            isActive
                                                                                ? '#f9f5ff'
                                                                                : '#000033'
                                                                        }
                                                                    />
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    primary={
                                                                        menuItem.title
                                                                    }
                                                                    primaryTypographyProps={{
                                                                        fontWeight:
                                                                            isActive
                                                                                ? 600
                                                                                : 400,
                                                                    }}
                                                                    sx={{
                                                                        opacity:
                                                                            appContext?.menuExpanded
                                                                                ? 1
                                                                                : 0,
                                                                        color: isActive
                                                                            ? '#f9f5ff'
                                                                            : '#000033',
                                                                    }}
                                                                />
                                                            </ListItemButton>
                                                        </ListItem>
                                                    )}
                                                </NavLink>
                                            </Tooltip>
                                        )}
                                        {menuItem.children && (
                                            <MenuItemWithChild
                                                menuItem={menuItem}
                                            />
                                        )}
                                    </Box>
                                );
                            }
                        )}
                </FooterMenuList>
            </Drawer>
        </>
    );
};
