import { useEffect } from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { UtilsService } from '../../services/utils.service';
import React from 'react';
export interface BarLineChartProps {
    series: {
        name: string;
        data: number[];
    }[];
    labels: string[];
    cssClass?: string;
    height: number;
    opacity?: number;
    showLinesOnly?: boolean;
    onBarClick?: (date: string) => void;
    onLabelClick?: (label: string) => void;
}

// colors: ['#19AF55', '#D82F44', '#6e3cfb'],

export const BarLineChart = (props: BarLineChartProps) => {
    const positive = props.series.filter((s: any) => s.name == 'Positive')[0]
        ?.data;
    const negative = props.series.filter((s: any) => s.name == 'Negative')[0]
        ?.data;
    const neutral = props.series.filter((s: any) => s.name == 'Average')[0]
        ?.data;
    let series = [
        {
            name: 'Positive',
            type: 'column',
            data: positive,
        },
        {
            name: 'Negative',
            type: 'column',
            data: negative,
        },
    ];

    if (neutral && neutral.length) {
        series.push({
            name: 'Average',
            type: 'line',
            data: neutral,
        });
    }
    const options: ApexOptions = {
        chart: {
            height: props.height,
            stacked: true,
            events: {
                dataPointSelection: (event, chartContext, config) => {
                    setTimeout(() => {
                        if (
                            props.labels[config.dataPointIndex] &&
                            props.onBarClick
                        ) {
                            props.onBarClick(
                                props.labels[config.dataPointIndex]
                            );
                        }
                    }, 10);
                },
            },
            toolbar: {
                show: !props.showLinesOnly,
                tools: {
                    download: !props.showLinesOnly,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                    customIcons: [],
                },
            },
        },

        legend: {
            show: true,
        },
        colors: ['#19AF55', '#D82F44', '#6e3cfb'],
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 2,
                borderRadiusApplication: 'end',
                borderRadiusWhenStacked: 'all',
                columnWidth: '30%',
                barHeight: '70%',
                distributed: false,
                rangeBarOverlap: true,
                rangeBarGroupRows: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        markers: {
            size: [0, 0, 1],
            colors: undefined,
            strokeColors: '#6e3cfb',
            strokeWidth: 2,
            strokeOpacity: 0.9,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [],
            shape: 'circle',
            radius: 2,
            offsetX: 0,
            offsetY: 0,
            onClick: undefined,
            onDblClick: undefined,
            showNullDataPoints: true,
            hover: {
                size: undefined,
                sizeOffset: 3,
            },
        },
        stroke: {
            width: [0, 0, 2],
            colors: ['#008FFB'],
            curve: 'smooth',
        },
        fill: {
            opacity: [
                props.opacity ? props.opacity : 1,
                props.opacity ? props.opacity : 1,
                1,
            ],
        },
        grid: {
            xaxis: {
                lines: {
                    show: false,
                },
            },
            yaxis: {
                lines: {
                    show: true,
                },
            },
        },
        yaxis: {
            min: -1,
            max: 1,
            tickAmount: 10,
            logarithmic: true,
            logBase: 10,
            forceNiceScale: true,
            labels: {
                show: true,
                formatter: function (val, opts): string {
                    if (opts && opts.seriesIndex == 2) {
                        let pVal = opts.series[0][opts.dataPointIndex];
                        if (pVal == 0) {
                            return 'No Data Available';
                        }
                    }
                    if (
                        [
                            -1, -0.8, -0.6, -0.4, -0.2, 0, 0.2, 0.4, 0.6, 0.8,
                            1,
                        ].includes(parseFloat(val.toFixed(3)))
                    ) {
                        return val.toFixed(1);
                    } else if (val !== 0) {
                        return val.toFixed(3);
                    } else {
                        return 'No Data Available';
                    }
                },
            },
        },
        tooltip: {
            enabled: true,
            marker: {
                show: true,
            },
            x: {
                show: true,
            },
        },
        xaxis: {
            categories: props.labels,
            tooltip: {
                enabled: false,
            },
            labels: {
                formatter: function (val) {
                    return UtilsService.formatStringDateWithoutYear(val);
                },
            },
        },
    };

    return (
        <div>
            <Chart options={options} series={series} height={props.height} />
        </div>
    );
};
