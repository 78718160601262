import React from 'react';

export const CheckedIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.95491 5.06107C3 6.3754 3 8.25027 3 12C3 15.7497 3 17.6246 3.95491 18.9389C4.26331 19.3634 4.6366 19.7367 5.06107 20.0451C6.3754 21 8.25027 21 12 21C15.7497 21 17.6246 21 18.9389 20.0451C19.3634 19.7367 19.7367 19.3634 20.0451 18.9389C21 17.6246 21 15.7497 21 12C21 8.25027 21 6.3754 20.0451 5.06107C19.7367 4.6366 19.3634 4.26331 18.9389 3.95491C17.6246 3 15.7497 3 12 3C8.25027 3 6.3754 3 5.06107 3.95491C4.6366 4.26331 4.26331 4.6366 3.95491 5.06107ZM17.5588 9.50027C17.8351 9.19167 17.8089 8.71752 17.5003 8.44123C17.1917 8.16493 16.7175 8.19113 16.4412 8.49973L13.0721 12.2629C12.3894 13.0254 11.9296 13.5363 11.5365 13.8667C11.1621 14.1814 10.9421 14.25 10.75 14.25C10.5579 14.25 10.3379 14.1814 9.96348 13.8667C9.5704 13.5363 9.11064 13.0254 8.42794 12.2629L7.55877 11.2921C7.28248 10.9835 6.80833 10.9573 6.49973 11.2336C6.19113 11.5099 6.16493 11.984 6.44123 12.2926L7.34753 13.3049C7.98337 14.0152 8.51374 14.6076 8.99835 15.0149C9.51099 15.4458 10.0639 15.75 10.75 15.75C11.4361 15.75 11.989 15.4458 12.5016 15.0149C12.9863 14.6076 13.5166 14.0152 14.1525 13.3049L17.5588 9.50027Z"
                fill="#6e3cfb"
            />
        </svg>
    );
};
