import { useState, useEffect } from 'react';
import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    Menu,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material';
import { BorderedBox } from '../../../components/core/box';
import { Topic } from '../../../services/interfaces';
import { useTopicService } from '../../../services/topic.service';
import { FilterAltOutlined } from '@mui/icons-material';

export const Subscriptions = () => {
    const [userTopics, setUserTopics] = useState<Topic[]>([]);
    const [filteredUserTopics, setFilteredUserTopics] = useState<Topic[]>([]);
    const [value, setValue] = useState('all');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { getUserTopics } = useTopicService();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
        handleClose();
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    let refreshUserTopics = async () => {
        let topics = await getUserTopics();
        if (topics) {
            setUserTopics(topics);
        }
    };

    useEffect(() => {
        refreshUserTopics();
    }, []);

    useEffect(() => {
        switch (value) {
            case 'all':
                setFilteredUserTopics(userTopics);
                break;
            case 'success':
                setFilteredUserTopics(
                    userTopics.filter((topic) => topic.topicId)
                );
                break;
            case 'processing':
                setFilteredUserTopics(
                    userTopics.filter((topic) => !topic.topicId)
                );
                break;
        }
    }, [value, userTopics]);

    return (
        <Box pt={4}>
            <Box mb={4} display="flex" justifyContent="space-between">
                <Typography variant="h3">Subscriptions</Typography>
                <Box display="flex" justifyContent="right">
                    <Button
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        <FilterAltOutlined />
                    </Button>
                    <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                        <FormControl sx={{ p: 4 }}>
                            <Typography fontWeight={600}>
                                Show Status:
                            </Typography>
                            <RadioGroup value={value} onChange={handleChange}>
                                <FormControlLabel
                                    value="all"
                                    control={<Radio />}
                                    label="All"
                                />
                                <FormControlLabel
                                    value="success"
                                    control={<Radio />}
                                    label="Success"
                                />
                                <FormControlLabel
                                    value="processing"
                                    control={<Radio />}
                                    label="Processing"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Menu>
                </Box>
            </Box>

            <Box display="flex">
                <Typography
                    fontSize="14px"
                    fontWeight={600}
                    width={'calc(85% - 10px)'}
                    pl={4}
                >
                    Topics
                </Typography>
                <Typography fontSize="14px" align="left" fontWeight={600}>
                    Status
                </Typography>
            </Box>
            <Box sx={{ maxHeight: 'calc(100vh - 278px)', overflowY: 'auto' }}>
                {filteredUserTopics.length > 0 &&
                    filteredUserTopics.map((topic) => (
                        <BorderedBox
                            sx={{
                                backgroundColor: '#f9f5ff',
                                px: 4,
                                py: 3,
                                mt: 2,
                                display: 'flex',
                            }}
                        >
                            <Typography variant="h6" width={'85%'}>
                                {topic.subscriptionName}
                            </Typography>
                            <Typography variant="h6" align="left">
                                {topic.topicId ? (
                                    <span style={{ color: '#24A148' }}>
                                        Success
                                    </span>
                                ) : (
                                    <span style={{ color: '#FF832B' }}>
                                        Processing
                                    </span>
                                )}
                            </Typography>
                        </BorderedBox>
                    ))}
            </Box>
        </Box>
    );
};
