import {
    Box,
    Typography,
    Modal,
    Divider,
    Button,
    Checkbox,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { BorderedBox } from '../../../../components/core/box';
import { NavigationLink } from '../../../../components/core/link';
import { useChatbotService } from '../../../../services/chatbot.service';
import {
    IQueryEntitiesesponseData,
    IRecentHeadlines,
    LiveInsightsQueryResponse,
    LiveQueryResponseDataSources,
    LiveQueryResponseDataSourcesResult,
    LiveQueryResponseDataTrends,
    LiveQueryResponseLLMData,
    LiveQueryResponseSummaryData,
    LiveQueryResponseVolumeTrendsData,
    LiveQueryEntities,
    HighlightIndexes,
} from '../../../../services/interfaces';
import { WordCloud } from '../../live-insights/word-cloud';
import { VolumeTrends } from '../../live-insights/volume-trends';
import { Sentiments } from '../../live-insights/sentiments';
import { RecentHeadlines } from '../../live-insights/recent-headlines';
import { VolumeTrendsIcon } from '../../../../components/icons/volume-trends';
import { SentimentsIcon } from '../../../../components/icons/sentiments';
import { RecentHeadlinesIcon } from '../../../../components/icons/recent-headlines';
import { BuzzwordCloudIcon } from '../../../../components/icons/buzzword-cloud';
import { CorporateCultureIcon } from '../../../../components/icons/corporate-culture';
import { CorporateCulture } from '../../live-insights/corporate-culture';
import CompanyAnalysis from '../company-analysis';
import { Overview } from '../../live-insights/overview';
import { GoogleScholar } from '../../live-insights/google-scholar';
import { GooglePatents } from '../../live-insights/google-patents';
import { GooglePatentsIcon } from '../../../../components/icons/google-patents';
import { GoogleScholarIcon } from '../../../../components/icons/google-scholar';
import { WebMedia } from '../../live-insights/web-media';
import { WebMediaIcon } from '../../../../components/icons/web-media';
import { ExecutiveSummaryIcon } from '../../../../components/icons/executive-sumary';
import { ErrorBoundary } from '../../../../utils/error-boundary';
import { Chat } from '../../chat';
import { useTopicService } from '../../../../services/topic.service';
import Edit from '@mui/icons-material/Edit';

interface IProps {
    query: string;
    topicId?: string;
}
declare const window: any;
export const ResearchTabs = (props: IProps) => {
    let containerRef = useRef(null);
    const [tab, setTab] = useState<string>('');
    const [query, setQuery] = useState<string>('');
    const [words, setWords] = useState<{ [key: string]: number } | null>(null);
    const [detectedEntities, setDetectedEntities] = useState<
        IQueryEntitiesesponseData[] | null
    >(null);
    const [sources, setSources] = useState<
        LiveQueryResponseDataSourcesResult[] | null
    >(null);
    const [recentHeadlines, setRecentHeadlines] = useState<
        IRecentHeadlines[] | null
    >(null);
    const [entities, setEntities] = useState<LiveQueryEntities[]>([]);
    const [highlightIndexes, setHighlightIndexes] = useState<
        HighlightIndexes | undefined
    >(undefined);
    const [volumeTrendsEntities, setVolumeTrendsEntities] = useState<{
        '15D': LiveQueryResponseVolumeTrendsData[];
        '1M': LiveQueryResponseVolumeTrendsData[];
        '3M': LiveQueryResponseVolumeTrendsData[];
    }>({
        '15D': [],
        '1M': [],
        '3M': [],
    });
    const [selectedFilters, setSelectedFilters] = useState({
        overview: true,
        chat: true,
        'company-analysis': true,
        sentiment: true,
        'employee-sentiment': true,
        'recent-headlines': true,
        'news-volume': true,
        'research-papers': true,
        patents: true,
        'web-media': true,
        'word-cloud': true,
    });

    useEffect(() => {
        if (!selectedFilters[tab as keyof typeof selectedFilters]) {
            tab == 'overview' ? setTab('chat') : setTab('overview');
        }
    }, [selectedFilters]);

    const {
        fetchChatbotQueryEntities,
        fetchChatbotLiveQueryResponse,
        fetchChatbotQueryRecentHeadlines,
        fetchChatbotQueryVolumeTrendResponse,
    } = useChatbotService();
    const { fetchInsigtsFilter, updateInsightsFilter } = useTopicService();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [aiResponse, setAIResponse] = useState<string>('');
    const [streamFinished, setStreamFinished] = useState(true);
    const [googlePatentsFetched, setGooglePatentsFetched] = useState(false);
    const [googleScholarFetched, setGoogleScholarFetched] = useState(false);
    const [recentHeadlinesFetched, setRecentHeadlinesFetched] = useState(false);
    const [searchTrendsFetched, setSearchTrendsFetched] = useState(false);
    const [financialsFetched, setFinancialFetched] = useState(false);
    const [webMediaFetched, setWebMediaFetched] = useState(false);
    const [sentimentsFetched, setSentimentsFetched] = useState(false);
    const [corporateCulturFetched, setCorporateCulturFetched] = useState(false);

    const processMessage = async (message: string) => {
        setStreamFinished(false);
        resetStepsStatus();
        setQuery(message);
        setWords(null);
        //@ts-ignore
        window.wordsLoaded = false;
        setSources(null);
        setDetectedEntities(null);
        setRecentHeadlines(null);
        setRecentHeadlinesFetched(false);
        setGooglePatentsFetched(false);
        setGoogleScholarFetched(false);
        setSearchTrendsFetched(false);
        setFinancialFetched(false);
        setVolumeTrendsEntities({
            '15D': [],
            '1M': [],
            '3M': [],
        });
        setAIResponse('');
        fetchRecentHeadlinesAndBuzzword(message);
        await fetchChatbotLiveQueryResponse(
            message,
            'INSTANT_INSIGHTS',
            (data: string) => {
                if (data) {
                    processQueryResponse(data.split('\n'), 'LLM');
                }
            }
        );
        let entities = await fetchDetectedEntities(message);
        await fetchChatbotQueryVolumeTrendResponse(
            message,
            (data: string) => {
                if (data) {
                    processQueryResponse(data.split('\n'), 'VOLUME_TRENDS');
                }
            },
            entities,
            true
        );
    };

    const fetchRecentHeadlinesAndBuzzword = async (message: string) => {
        let search = await fetchChatbotQueryRecentHeadlines(message);
        if (!window.wordsLoaded) {
            //@ts-ignore
            window.wordsLoaded = true;
            setWords(search.wordmap);
        }
        setRecentHeadlines(search.results);
        setRecentHeadlinesFetched(true);
    };

    const fetchDetectedEntities = async (
        message: string
    ): Promise<IQueryEntitiesesponseData[]> => {
        let entities = await fetchChatbotQueryEntities(message);
        setDetectedEntities([...entities]);
        return [...entities];
    };

    const processResponseStringCitation = (
        responseString: string,
        sources: LiveQueryResponseDataSourcesResult[]
    ): {
        responseString: string;
        sources: LiveQueryResponseDataSourcesResult[];
    } => {
        for (let i = 0; i < sources.length; i++) {
            sources[i].id = i + 1;
            if (responseString.includes(`[${i + 1}]`)) {
                sources[i].citationIndex = i;
            }
        }
        return {
            responseString,
            sources,
        };
    };
    const resetStepsStatus = () => {
        setWebMediaFetched(false);
        setFinancialFetched(false);
        setSentimentsFetched(false);
        setCorporateCulturFetched(false);
        setRecentHeadlinesFetched(false);
        setGooglePatentsFetched(false);
        setGoogleScholarFetched(false);
    };

    const processQueryResponse = (responses: string[], key: string) => {
        setStreamFinished(false);
        let responseString = '';
        let _entities: LiveQueryResponseDataTrends[] = [];
        let _vEntities: LiveQueryResponseVolumeTrendsData[] = [];
        let sources: LiveQueryResponseDataSourcesResult[] = [];
        let finalSources: LiveQueryResponseDataSourcesResult[] = [];
        for (let res of responses) {
            setStreamFinished(false);
            try {
                let parsedRes: LiveInsightsQueryResponse = JSON.parse(res);
                switch (parsedRes.chunk) {
                    case 'SOURCES':
                        sources = [
                            ...(parsedRes.data as LiveQueryResponseDataSources)
                                .sources,
                        ];
                        setSources([...sources]);
                        break;
                    case 'VOLUME_TRENDS':
                        let vTrends =
                            parsedRes.data as LiveQueryResponseVolumeTrendsData;
                        if (vTrends.newsVolume.length > 0) {
                            _vEntities = [
                                ..._vEntities.filter(
                                    (e) => e.query != vTrends.query
                                ),
                                vTrends,
                            ];

                            setVolumeTrendsEntities({
                                ...volumeTrendsEntities,
                                '1M': [..._vEntities],
                            });
                        }
                        break;
                    case 'SUMMARY':
                        let summaries: string[] = (
                            parsedRes.data as LiveQueryResponseSummaryData
                        ).data;
                        for (let index = 0; index < sources.length; index++) {
                            if (summaries[index]) {
                                sources[index].article = summaries[index];
                                finalSources.push(sources[index]);
                            }
                        }
                        let parsedData = processResponseStringCitation(
                            responseString,
                            finalSources
                        );
                        setAIResponse(parsedData.responseString);
                        setSources([...parsedData.sources]);
                        break;
                    case 'LLM':
                        responseString =
                            responseString +
                            (parsedRes.data as LiveQueryResponseLLMData).value;
                        let data = processResponseStringCitation(
                            responseString,
                            finalSources
                        );
                        setAIResponse(data.responseString);
                        break;
                    case 'ANSWER_SENTIMENTS':
                        setStreamFinished(false);
                        let indexes = parsedRes.data as HighlightIndexes;
                        setHighlightIndexes(indexes);
                        break;
                    case 'ANSWER_ENTITIES':
                        setStreamFinished(false);
                        let _entities = parsedRes.data as LiveQueryEntities[];
                        setEntities(
                            _entities.filter((e) => e.entityGroup != 'MISC')
                        );
                        break;
                    case 'TERMINATION':
                        setStreamFinished(true);
                        break;
                }
            } catch (error) {}
        }
    };

    const refreshFilters = async () => {
        const data = await fetchInsigtsFilter();
        if (data.userConfiguration) setSelectedFilters(data.userConfiguration);
        handleClose();
    };

    const saveFilters = async () => {
        let res = await updateInsightsFilter(selectedFilters);
        if (res) {
            refreshFilters();
        }
        handleClose();
    };

    const updateFilters = (tabName: string) => {
        let filters = selectedFilters;
        filters[tabName as keyof typeof filters] =
            !filters[tabName as keyof typeof filters];
        setSelectedFilters({ ...filters });
    };

    useEffect(() => {
        if (containerRef.current) {
            //@ts-ignore
            containerRef.current.scrollTo(0, 0);
        }
    }, [tab, props.query]);

    const tabData = [
        {
            title: 'Overview',
            icon: ExecutiveSummaryIcon,
            tabName: 'overview',
            link: `/research/overview${window.location.search}`,
            selected: tab == 'overview',
            status: !aiResponse ? 'PROCESSING' : 'DONE',
            helperText: 'Brief snapshot of recent happenings.',
        },
        {
            title: 'Ask Specifics',
            icon: ExecutiveSummaryIcon,
            tabName: 'chat',
            link: `/research/chat${window.location.search}`,
            selected: tab == 'chat',
            status: 'DONE',
            helperText: 'Web-based conversational search and query.',
        },
        {
            title: 'Company Analysis',
            icon: ExecutiveSummaryIcon,
            tabName: 'company-analysis',
            link: `/research/company-analysis${window.location.search}`,
            selected: tab == 'company-analysis',
            status: !financialsFetched ? 'PROCESSING' : 'DONE',
            helperText:
                'Access critical financial information for smarter decision-making.',
        },

        {
            title: 'Sentiment',
            icon: SentimentsIcon,
            tabName: 'sentiment',
            link: `/research/sentiment${window.location.search}`,
            selected: tab == 'sentiment',
            status: !sentimentsFetched ? 'PROCESSING' : 'DONE',
            helperText: 'Brief overview and sentiment trends.',
        },
        {
            title: 'Employee Sentiment',
            icon: CorporateCultureIcon,
            tabName: 'employee-sentiment',
            link: `/research/employee-sentiments${window.location.search}`,
            selected: tab == 'employee-sentiment',
            status: !corporateCulturFetched ? 'PROCESSING' : 'DONE',
            helperText: 'Employee Culture from Glassdoor and Indeed.',
        },
        {
            title: 'Recent Headlines',
            icon: RecentHeadlinesIcon,
            tabName: 'recent-headlines',
            link: `/research/recent-headlines${window.location.search}`,
            selected: tab == 'recent-headlines',
            status: !recentHeadlinesFetched ? 'PROCESSING' : 'DONE',
            helperText: 'Recent headlines corresponding to your query.',
        },
        {
            title: 'News Volume',
            icon: VolumeTrendsIcon,
            tabName: 'news-volume',
            link: `/research/news-volume${window.location.search}`,
            selected: tab == 'news-volume',
            status:
                volumeTrendsEntities['1M'].length == 0 ? 'PROCESSING' : 'DONE',
            helperText:
                'Discover the pulse of current events with our News Volume Trends visualization.',
        },
        {
            title: 'Research Papers',
            icon: <GoogleScholarIcon />,
            tabName: 'research-papers',
            link: `/research/research-papers${window.location.search}`,
            selected: tab == 'research-papers',
            status: !googleScholarFetched ? 'PROCESSING' : 'DONE',
            helperText: 'Access information on Research Papers.',
        },
        {
            title: 'Patents',
            icon: <GooglePatentsIcon />,
            tabName: 'patents',
            link: `/research/patents${window.location.search}`,
            selected: tab == 'patents',
            status: !googlePatentsFetched ? 'PROCESSING' : 'DONE',
            helperText: 'Access information on Patents.',
        },
        {
            title: 'Web Media',
            icon: WebMediaIcon,
            tabName: 'web-media',
            link: `/research/web-media${window.location.search}`,
            selected: tab == 'web-media',
            status: !webMediaFetched ? 'PROCESSING' : 'DONE',
            helperText: 'Access media data from Youtube and TV Media.',
        },
        {
            title: 'Buzzword Cloud',
            icon: BuzzwordCloudIcon,
            tabName: 'word-cloud',
            link: `/research/word-cloud${window.location.search}`,
            selected: tab == 'word-cloud',
            status: !recentHeadlinesFetched ? 'PROCESSING' : 'DONE',
            helperText:
                'Capture the landscape with word clouds sourced from top media outlets.',
        },
    ];

    const updateTab = () => {
        let newTab = tabData.find(
            (t) => window.location.href.indexOf(t.tabName) > -1
        );
        setTab(newTab ? newTab.tabName : 'overview');
    };

    useEffect(() => {
        if (props.query) {
            setQuery(props.query);
            processMessage(props.query);
        }
        updateTab();
    }, [props.query]);

    useEffect(() => {
        refreshFilters();
        updateTab();
    }, []);

    return (
        <>
            {tab && (
                <Box>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        sx={{ backdropFilter: 'blur(2px)' }}
                    >
                        <BorderedBox
                            sx={{
                                position: 'absolute' as 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: 700,
                                bgcolor: '#FFFFFF',
                                p: 5,
                            }}
                        >
                            <Typography variant="h4">
                                Edit/Choose sub-features
                            </Typography>
                            <Box mt={6}>
                                <Typography
                                    fontSize="14px"
                                    fontWeight={600}
                                    mb={2}
                                >
                                    Selected Features
                                </Typography>
                                <Box display="flex" flexWrap="wrap">
                                    {tabData
                                        .filter(
                                            (data) =>
                                                selectedFilters[
                                                    data.tabName as keyof typeof selectedFilters
                                                ]
                                        )
                                        .map((d) => (
                                            <Box
                                                sx={{
                                                    backgroundColor: '#EBECF6',
                                                    p: 2,
                                                    mr: 2,
                                                    mb: 2,
                                                }}
                                            >
                                                {d.tabName
                                                    .split('-')
                                                    .map(
                                                        (word) =>
                                                            word
                                                                .charAt(0)
                                                                .toUpperCase() +
                                                            word.slice(1)
                                                    )
                                                    .join(' ')}
                                            </Box>
                                        ))}
                                </Box>
                            </Box>
                            <Divider />
                            <Box mt={3} mb={6}>
                                <Typography
                                    fontSize="14px"
                                    fontWeight={600}
                                    mb={2}
                                >
                                    Features available
                                </Typography>
                                <Box
                                    className="scrollable"
                                    sx={{
                                        maxHeight: '325px',
                                        overflow: 'auto',
                                    }}
                                >
                                    {tabData.map((d) => (
                                        <Box mb={2}>
                                            <Box display="flex" mb={1}>
                                                <Checkbox
                                                    size="small"
                                                    sx={{ padding: 0, mr: 2 }}
                                                    disabled={[
                                                        'overview',
                                                        'chat',
                                                    ].includes(d.tabName)}
                                                    onClick={() =>
                                                        updateFilters(d.tabName)
                                                    }
                                                    defaultChecked={
                                                        selectedFilters[
                                                            d.tabName as keyof typeof selectedFilters
                                                        ]
                                                    }
                                                    checked={
                                                        selectedFilters[
                                                            d.tabName as keyof typeof selectedFilters
                                                        ]
                                                    }
                                                />
                                                <Typography>
                                                    {d.tabName
                                                        .split('-')
                                                        .map(
                                                            (word) =>
                                                                word
                                                                    .charAt(0)
                                                                    .toUpperCase() +
                                                                word.slice(1)
                                                        )
                                                        .join(' ')}
                                                </Typography>
                                            </Box>
                                            <Typography variant="caption">
                                                {d.helperText}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                            <Box display="flex" justifyContent="right">
                                <Button
                                    sx={{ color: '#4B4B4C', mr: 3 }}
                                    onClick={() => refreshFilters()}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={() => saveFilters()}
                                >
                                    Save
                                </Button>
                            </Box>
                        </BorderedBox>
                    </Modal>
                    <Box
                        mt={3}
                        display={'flex'}
                        alignItems={'center'}
                        sx={{ borderBottom: 1, borderColor: 'divider' }}
                    >
                        {tabData
                            .filter(
                                (data) =>
                                    selectedFilters[
                                        data.tabName as keyof typeof selectedFilters
                                    ]
                            )
                            .map((t) => (
                                <Box
                                    sx={{
                                        backgroundColor: t.selected
                                            ? '#EBECF6'
                                            : '#fff',
                                        borderBottom: t.selected
                                            ? '2px solid #6e3cfb'
                                            : 'none',
                                        cursor:
                                            t.status == 'PROCESSING'
                                                ? 'progress'
                                                : 'default',
                                        pointerEvents:
                                            t.status == 'PROCESSING'
                                                ? 'none'
                                                : 'auto',
                                        borderTopRightRadius: '8px',
                                        borderTopLeftRadius: '8px',
                                    }}
                                    py={2}
                                    px={2}
                                    onClick={() => setTab(t.tabName)}
                                >
                                    <NavigationLink
                                        style={{
                                            pointerEvents:
                                                t.status == 'PROCESSING'
                                                    ? 'none'
                                                    : 'auto',
                                        }}
                                        to={t.link}
                                    >
                                        <Box display="flex">
                                            <Typography
                                                color={
                                                    query && t.status == 'DONE'
                                                        ? t.selected
                                                            ? '#6e3cfb'
                                                            : '#003'
                                                        : '#A5A6AB'
                                                }
                                                fontWeight={
                                                    t.selected ? 600 : 400
                                                }
                                                mx={1}
                                            >
                                                {t.title}
                                            </Typography>
                                        </Box>
                                    </NavigationLink>
                                </Box>
                            ))}
                        <Edit
                            sx={{ height: '16px', cursor: 'pointer' }}
                            onClick={handleOpen}
                        />
                    </Box>
                    <Box textAlign={'left'}>
                        <Box pt={4}>
                            <Box display={tab == 'overview' ? '' : 'none'}>
                                <ErrorBoundary>
                                    <Overview
                                        query={query}
                                        aiResponse={aiResponse}
                                        sources={sources}
                                        entities={entities}
                                        highlights={highlightIndexes}
                                    />
                                </ErrorBoundary>
                            </Box>

                            <Box
                                display={tab == 'chat' ? '' : 'none'}
                                textAlign={'center'}
                            >
                                <Chat />
                            </Box>
                            <Box
                                display={
                                    tab == 'company-analysis' ? '' : 'none'
                                }
                            >
                                <ErrorBoundary>
                                    {detectedEntities && (
                                        <CompanyAnalysis
                                            entities={detectedEntities}
                                            onFetched={() => {
                                                setFinancialFetched(true);
                                            }}
                                            query={query}
                                        />
                                    )}
                                </ErrorBoundary>
                            </Box>

                            <Box display={tab == 'news-volume' ? '' : 'none'}>
                                <ErrorBoundary>
                                    <VolumeTrends
                                        entities={volumeTrendsEntities['1M']}
                                    />
                                </ErrorBoundary>
                            </Box>

                            <Box display={tab == 'sentiment' ? '' : 'none'}>
                                <ErrorBoundary>
                                    {detectedEntities && (
                                        <Sentiments
                                            onFetched={() => {
                                                setSentimentsFetched(true);
                                            }}
                                            entities={detectedEntities}
                                            query={query}
                                            topicId={props.topicId}
                                        />
                                    )}
                                </ErrorBoundary>
                            </Box>

                            <Box
                                display={
                                    tab == 'recent-headlines' ? '' : 'none'
                                }
                            >
                                <ErrorBoundary>
                                    <RecentHeadlines
                                        headlines={recentHeadlines}
                                    />
                                </ErrorBoundary>
                            </Box>

                            <Box
                                display={
                                    tab == 'employee-sentiment' ? '' : 'none'
                                }
                            >
                                <ErrorBoundary>
                                    {detectedEntities && (
                                        <CorporateCulture
                                            onFetched={() => {
                                                setCorporateCulturFetched(true);
                                            }}
                                            entities={detectedEntities}
                                            query={query}
                                        />
                                    )}
                                </ErrorBoundary>
                            </Box>

                            <Box
                                display={tab == 'research-papers' ? '' : 'none'}
                            >
                                <ErrorBoundary>
                                    {detectedEntities && (
                                        <GoogleScholar
                                            onFetched={() => {
                                                setGoogleScholarFetched(true);
                                            }}
                                            entities={detectedEntities}
                                            query={query}
                                        />
                                    )}
                                </ErrorBoundary>
                            </Box>

                            <Box display={tab == 'patents' ? '' : 'none'}>
                                <ErrorBoundary>
                                    {detectedEntities && (
                                        <GooglePatents
                                            onFetched={() => {
                                                setGooglePatentsFetched(true);
                                            }}
                                            entities={detectedEntities}
                                            query={query}
                                        />
                                    )}
                                </ErrorBoundary>
                            </Box>

                            <Box display={tab == 'web-media' ? '' : 'none'}>
                                <ErrorBoundary>
                                    {detectedEntities && (
                                        <WebMedia
                                            onFetched={() => {
                                                setWebMediaFetched(true);
                                            }}
                                            entities={detectedEntities}
                                            query={query}
                                        />
                                    )}
                                </ErrorBoundary>
                            </Box>

                            {words && tab == 'word-cloud' && (
                                <ErrorBoundary>
                                    <WordCloud data={words} />
                                </ErrorBoundary>
                            )}
                        </Box>
                    </Box>
                </Box>
            )}
        </>
    );
};
