import {
    Box,
    Button,
    Divider,
    IconButton,
    Grid,
    Typography,
    InputAdornment,
    TextField,
    FilterOptionsState,
    Paper,
    styled,
} from '@mui/material';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Autocomplete, {
    AutocompleteRenderOptionState,
} from '@mui/material/Autocomplete';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import List from 'rc-virtual-list';
import { HomeIcon } from '../../../components/icons/home';
import { Input } from '../../../components/core/input';
import { Close } from '../../../components/icons/close';
import { Filters } from '../landing/filters';
import { ResearchTabs } from './tabs';
import AddToWatchList from '../../add-to-watchlist';
import { AppContext } from '../../../providers/app-provider';
import { Topic } from '../../../services/interfaces';
import { UtilsService } from '../../../services/utils.service';
import { FilledButton } from '../../../components/core/button';
import { SearchInsights } from '../../../components/icons/search-insights';
import { KeyboardKeys } from '../../../components/core/enums/keyboard-keys.enum';
import { Header } from '../header';
const { distance, closest } = require('fastest-levenshtein');

export const CustomPaper = (props: any) => {
    return (
        <Paper
            sx={{
                '& > .MuiAutocomplete-listbox': {
                    padding: '0px 0px',
                },
            }}
            elevation={8}
            {...props}
        >
            {props.children}
        </Paper>
    );
};

export const SearchOptionWrapper = styled(Box)(() => ({
    padding: 10,
    borderBottom: '1px solid',
    borderColor: '#D7D9EC',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: '#f9f5ff',
    },
}));

interface IProps {
    minVersion?: boolean;
    value?: string;
    backgroundColor?: string;
    filterOptions?: (topics: Topic[]) => Topic[];
    placeholder?: string;
    onHover?: () => void;
    onBlur?: () => void;
    inputRef?: React.Ref<any> | undefined;
    endAdornment?: React.ReactNode;
    onSelected: (topic: Topic | undefined) => void;
}

interface SearchOptionProps {
    topic: Topic;
    index: number;
    close: Function;
    onSelected: (topic: Topic) => void;
    query: string;
}

export const SearchOption = (props: SearchOptionProps) => {
    return (
        <>
            {!props.topic.noMatch && (
                <>
                    {props.topic.classification.length > 0 && (
                        <Typography
                            onClick={(
                                event: React.MouseEvent<
                                    HTMLSpanElement,
                                    MouseEvent
                                >
                            ) => {
                                event.preventDefault();
                                event.stopPropagation();
                                props.onSelected(props.topic);
                                setTimeout(() => {
                                    props.close();
                                }, 1000);
                            }}
                        >
                            <SearchOptionWrapper>
                                <Grid spacing={3} container>
                                    <Grid item>
                                        <SearchInsights
                                            style={{ verticalAlign: 'middle' }}
                                            width={14}
                                            height={14}
                                            fillColor="#A5A6AB"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle1">
                                            {props.topic.topicName
                                                ? `${props.topic.subscriptionName} (${props.topic.topicName})`
                                                : `${props.topic.subscriptionName}`}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </SearchOptionWrapper>
                        </Typography>
                    )}
                    {props.topic.classification.length == 0 && (
                        <SearchOptionWrapper>
                            <Grid spacing={3} container>
                                <Grid item>
                                    <SearchInsights
                                        style={{ verticalAlign: 'middle' }}
                                        width={14}
                                        height={14}
                                        fillColor="#A5A6AB"
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography
                                        color="#A5A6AB"
                                        variant="subtitle1"
                                    >
                                        {props.topic.topicName
                                            ? `${props.topic.subscriptionName} (${props.topic.topicName})`
                                            : `${props.topic.subscriptionName}`}{' '}
                                        - Processing
                                    </Typography>
                                </Grid>
                            </Grid>
                        </SearchOptionWrapper>
                    )}
                </>
            )}
        </>
    );
};

const ListBoxComponent = (props: any) => {
    return (
        <List
            style={{
                marginTop: 10,
                display:
                    props.children.filter(
                        (c: any) => c.props.topic.subscriptionName
                    ).length > 0
                        ? ''
                        : 'none',
            }}
            onClick={(event: any) => {
                event.preventDefault();
                event.stopPropagation();
            }}
            data={props.children}
            height={Math.min(
                300,
                props.children.filter(
                    (c: any) => c.props.topic.subscriptionName
                ).length * 50
            )}
            itemHeight={45}
            itemKey="key"
        >
            {(option) => option}
        </List>
    );
};

export const Insights = (props: IProps) => {
    let inputRef = useRef();
    const navigate = useNavigate();
    const [filtersFetched, setFiltersfetched] = useState(false);
    const [searchParam] = useSearchParams();
    const [open, setOpen] = useState(false);
    const optionSelected = () => {
        closePopper();
    };
    const keyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key == KeyboardKeys.ENTER) {
            setUserQuery(queryMessage);
            closePopper();
        }
    };
    const appContext = useContext(AppContext);
    const [queryMessage, setQueryMessage] = useState<string>('');
    const [userQuery, setUserQuery] = useState<string>('');
    const openPopper = (meta?: string) => {
        setOpen(true);
    };
    const closePopper = () => {
        setTimeout(() => {
            setOpen(false);
        }, 500);
    };

    useEffect(() => {
        let query = searchParam.get('query');
        if (query != null) {
            setQueryMessage(query);
            setUserQuery(query);
        }
    }, [searchParam.get('query')]);

    const selected = (topic: Topic) => {
        setTimeout(() => {
            props.onSelected(topic);
            setUserQuery(
                topic.topicName
                    ? `${topic.subscriptionName} (${topic.topicName})`
                    : `${topic.subscriptionName}`
            );
            navigate(
                `/research/overview?query=${encodeURIComponent(
                    topic.topicName
                        ? `${topic.subscriptionName} (${topic.topicName})`
                        : `${topic.subscriptionName}`
                )}&id=${topic.topicSubscriptionId}&insights=true`
            );
        }, 0);
    };

    const handleFilterOptions = (
        options: Topic[],
        state: FilterOptionsState<Topic>
    ) => {
        let results = options
            .filter((a: Topic) => {
                let d = distance(
                    a.subscriptionName.toLowerCase(),
                    state.inputValue.toLowerCase()
                );
                if (
                    a.subscriptionName.toLowerCase() ==
                    state.inputValue.toLowerCase()
                ) {
                    d = -1;
                } else if (
                    a.subscriptionName
                        .toLowerCase()
                        .startsWith(state.inputValue.toLowerCase())
                ) {
                    d = 0;
                }
                return (
                    d < 3 ||
                    a.subscriptionName
                        .toLowerCase()
                        .includes(state.inputValue.toLowerCase())
                );
            })
            .sort((a: Topic, b: Topic) => {
                let d1 = distance(
                    a.subscriptionName.toLowerCase(),
                    state.inputValue.toLowerCase()
                );
                let d2 = distance(
                    b.subscriptionName.toLowerCase(),
                    state.inputValue.toLowerCase()
                );
                if (
                    a.subscriptionName
                        .toLocaleLowerCase()
                        .includes(state.inputValue.toLowerCase())
                ) {
                    d1 = Math.min(d1, 1);
                }

                if (
                    b.subscriptionName
                        .toLocaleLowerCase()
                        .includes(state.inputValue.toLowerCase())
                ) {
                    d2 = Math.min(d2, 1);
                }

                if (
                    a.subscriptionName.toLowerCase() ==
                    state.inputValue.toLowerCase()
                ) {
                    d1 = -1;
                } else if (
                    a.subscriptionName
                        .toLowerCase()
                        .startsWith(state.inputValue.toLowerCase())
                ) {
                    d1 = 0;
                }

                if (
                    b.subscriptionName.toLowerCase() ==
                    state.inputValue.toLowerCase()
                ) {
                    d2 = -1;
                } else if (
                    b.subscriptionName
                        .toLowerCase()
                        .startsWith(state.inputValue.toLowerCase())
                ) {
                    d2 = 0;
                }

                return d1 < d2 ? -1 : 1;
            });
        if (
            options.filter(
                (a: Topic) =>
                    a.subscriptionName.toLocaleLowerCase() ==
                    state.inputValue.toLocaleLowerCase()
            ).length == 0
        ) {
            results.push({
                topicName: '',
                subscriptionName: '',
                topicSubscriptionId: '',
                noMatch: true,
                classification: [],
            });
        }
        return results;
    };

    return (
        <Box>
            {appContext?.isMobile === false && <Header />}
            <Box p={7} pb={0}>
                {appContext && (
                    <Box>
                        <Box display={'flex'} alignItems={'center'}>
                            <Button
                                sx={{
                                    mr: 2,
                                    px: 2,
                                    minWidth: 10,
                                }}
                                variant="text"
                                onClick={() => navigate(`/`)}
                            >
                                <HomeIcon />
                            </Button>

                            <Box
                                sx={{
                                    paddingLeft: appContext?.isMobile
                                        ? '0px'
                                        : '0px',
                                    width: `calc(100% - ${
                                        appContext?.isMobile ? 0 : 105
                                    }px)`,
                                }}
                            >
                                <Autocomplete
                                    open={open}
                                    //@ts-ignore
                                    inputValue={queryMessage}
                                    ListboxComponent={ListBoxComponent}
                                    //@ts-ignore
                                    value={queryMessage}
                                    onInputChange={(e, v) => {
                                        //@ts-ignore
                                        setQueryMessage(v);
                                    }}
                                    onKeyDown={keyPress}
                                    //@ts-ignore
                                    onHover={() => {
                                        UtilsService.triggerEvent(
                                            'searchHover'
                                        );
                                    }}
                                    onMouseUp={() => {
                                        UtilsService.triggerEvent(
                                            'searchHover'
                                        );
                                    }}
                                    onMouseOver={() => {
                                        UtilsService.triggerEvent(
                                            'searchHover'
                                        );
                                    }}
                                    onBlur={() => {
                                        UtilsService.triggerEvent('searchBlur');
                                    }}
                                    // onOpen={openPopper}
                                    onClose={closePopper}
                                    id="free-solo-demo"
                                    sx={{
                                        width: '40vw',
                                        '& .MuiFormControl-root': { mt: 3 },
                                        '& .MuiInputBase-root': {
                                            p: '7px',
                                            borderRadius: '8px',
                                            paddingRight: '7px !important',
                                            backgroundColor: props.minVersion
                                                ? props.backgroundColor || ''
                                                : '#fff',
                                        },
                                    }}
                                    PaperComponent={CustomPaper}
                                    options={
                                        props.filterOptions
                                            ? props.filterOptions(
                                                  appContext.topics
                                              )
                                            : appContext.topics
                                    }
                                    //@ts-ignore
                                    getOptionLabel={(option: Topic) => {
                                        if (typeof option == 'string')
                                            return option;
                                        return option.topicName
                                            ? `${option.subscriptionName} (${option.topicName})`
                                            : `${option.subscriptionName}`;
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            margin="normal"
                                            required
                                            onClick={() =>
                                                openPopper('textbox on click')
                                            }
                                            inputRef={props.inputRef}
                                            fullWidth
                                            placeholder={
                                                props.placeholder ||
                                                'Search any topic of your interest'
                                            }
                                            InputProps={{
                                                ...params.InputProps, // this is new
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Box
                                                            sx={{
                                                                height: 20,
                                                                '&>svg': {
                                                                    height: 20,
                                                                },
                                                            }}
                                                        >
                                                            <SearchInsights fillColor="#000033" />
                                                        </Box>
                                                    </InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <Box
                                                        display={'flex'}
                                                        alignItems={'center'}
                                                        sx={{
                                                            pr: 2,
                                                        }}
                                                    >
                                                        {queryMessage && (
                                                            <Box
                                                                display={'flex'}
                                                                alignItems={
                                                                    'center'
                                                                }
                                                            >
                                                                <IconButton
                                                                    sx={{
                                                                        minWidth: 0,
                                                                        padding: 0,
                                                                        marginRight:
                                                                            '8px',
                                                                        width: 'auto',
                                                                    }}
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        setUserQuery(
                                                                            queryMessage
                                                                        );
                                                                        closePopper();
                                                                    }}
                                                                >
                                                                    <ArrowCircleRightIcon
                                                                        color="primary"
                                                                        sx={{
                                                                            fontSize: 28,
                                                                        }}
                                                                    />
                                                                </IconButton>
                                                                <Divider
                                                                    sx={{
                                                                        marginRight:
                                                                            '8px',
                                                                        height: '35px',
                                                                    }}
                                                                    orientation="vertical"
                                                                    flexItem
                                                                />
                                                            </Box>
                                                        )}
                                                        <Filters
                                                            onOpen={() =>
                                                                closePopper()
                                                            }
                                                            min={true}
                                                            setFiltersfetched={
                                                                setFiltersfetched
                                                            }
                                                            filtersFetched={
                                                                filtersFetched
                                                            }
                                                            onFiltersUpdate={() => {}}
                                                        />
                                                    </Box>
                                                ),
                                            }}
                                        />
                                        // <TextField {...params} label="Highlights" variant="outlined" margin="normal" />
                                    )}
                                    renderOption={(
                                        props: React.HTMLAttributes<HTMLLIElement>,
                                        option: Topic,
                                        state: AutocompleteRenderOptionState
                                    ) => {
                                        let index = parseInt(
                                            // @ts-ignore
                                            props['data-option-index']
                                        );
                                        return (
                                            <SearchOption
                                                close={optionSelected}
                                                index={index}
                                                key={index}
                                                // noOptionClick={noOptionClick}
                                                onSelected={selected}
                                                topic={option}
                                                query={queryMessage}
                                            />
                                        );
                                    }}
                                    filterOptions={handleFilterOptions}
                                />
                            </Box>

                            {/* <AddToWatchList query={userQuery} /> */}
                        </Box>

                        <ResearchTabs query={userQuery} />
                    </Box>
                )}
            </Box>
        </Box>
    );
};
