import React, { useContext, useEffect, useState } from 'react';
import {
    DocumentFile,
    IDriveItem,
    IIntegrationAccount,
} from '../../../../../services/interfaces';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CachedIcon from '@mui/icons-material/Cached';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloudSyncOutlinedIcon from '@mui/icons-material/CloudSyncOutlined';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import {
    Badge,
    Box,
    Button,
    Divider,
    Checkbox,
    Menu,
    Tooltip,
    Typography,
    IconButton,
    CircularProgress,
    Stack,
    Pagination,
    PaginationItem,
    FormControlLabel,
    Radio,
    RadioGroup,
} from '@mui/material';
import { DRIVE } from '../drive';
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { AddSource } from './add-source';
import { useDriveService } from '../../../../../services/drive.service';
import { More } from '../../../../../components/icons/more';
import { Doc } from '../../../../../components/icons/doc';
import { FileMenuItem } from '../../file';
import { Share } from '@mui/icons-material';
import { File } from '../../file';
import { useSearchParams } from 'react-router-dom';
import { DocumentInsightsContext } from '../../../../../providers/document-provider';
import { Directory } from '../../../../../components/images/directory';
import Delete from '@mui/icons-material/Delete';
import { DeleteSourceFolder } from './delete-source';
import { UtilsService } from '../../../../../services/utils.service';
import { useInterval } from '../../../../../hooks/use-interval';
import { addMinutes } from 'date-fns';
const ITEM_HEIGHT = 48;

interface IProps {
    integrationAccount: IIntegrationAccount;
    onBackClick: () => void;
}

export const IntegrationAccount = (props: IProps) => {
    const [searchParam] = useSearchParams();
    const [enableRemoveSource, setEnableRemoveSource] = useState(false);
    const [pagesCount, setPagesCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [fileSourcesFilter, setFileSourcesFilter] = useState('');
    const [openWaitDialog, setOpenWaitDialog] = React.useState(false);
    const [sourcesForDeletion, setSourcesForDeletion] = useState<IDriveItem[]>(
        []
    );
    const documentContext = useContext(DocumentInsightsContext);
    const { fetchIntegrationSources, fetchSourceFiles, syncSource } =
        useDriveService();
    const [sources, setSources] = useState<IDriveItem[]>([]);
    const [triggerAddSource, setTriggerAddSource] = useState(0);
    const [files, setFiles] = useState<DocumentFile[]>([]);
    const [moreMenuEl, setMoreMenuEl] = useState<null | HTMLElement>(null);
    const [filterMenuEl, setFilterMenuEl] = useState<null | HTMLElement>(null);
    const { deleteSource } = useDriveService();

    const openMoreMenu = Boolean(moreMenuEl);
    const openFilterMenu = Boolean(filterMenuEl);

    useEffect(() => {
        if (
            searchParam.get('account_id') &&
            searchParam.get('add-source') &&
            props.integrationAccount.account_id == searchParam.get('account_id')
        ) {
            setTriggerAddSource(1);
        }
    }, [searchParam, props.integrationAccount]);

    const refreshSources = async () => {
        const _sources = await fetchIntegrationSources(
            props.integrationAccount.integration_name,
            props.integrationAccount.account_id
        );
        setSources(_sources);
    };

    const refreshSourceFiles = async () => {
        if (!fileSourcesFilter.length) return;
        const res = await fetchSourceFiles(
            props.integrationAccount.account_id,
            fileSourcesFilter.split(','),
            currentPage
        );
        setFiles(res.data);
        setPagesCount(Math.ceil(parseInt(res.count) / 10));
    };

    const startRemoveSource = () => {
        setSourcesForDeletion([]);
        setEnableRemoveSource(true);
    };

    useInterval(() => {
        if (sources.length > 0) {
            let date: Date = UtilsService.getMinDate(
                sources.map((s) =>
                    UtilsService.getUTCDateFromString(s.next_scheduled_sync)
                )
            ) as Date;
            if (date.getTime() < new Date().getTime()) {
                refreshSources();
            }
            if (
                sources.filter((s) => s.sync_status == 'IN-PROGRESS').length > 0
            ) {
                refreshSources();
            }
        }
    }, 10000);

    const toggleSourceDeletion = (id: string) => {
        if (!enableRemoveSource) return;
        if (sourcesForDeletion.map((sd) => sd.item_id).includes(id)) {
            setSourcesForDeletion(
                sourcesForDeletion.filter((s) => s.item_id != id)
            );
        } else {
            setSourcesForDeletion(sources.filter((s) => s.item_id == id));
            setSourcesForDeletion([
                ...sourcesForDeletion,
                ...sources.filter((s) => s.item_id == id),
            ]);
        }
    };
    useEffect(() => {
        setCurrentPage(1);
        refreshSourceFiles();
    }, [fileSourcesFilter]);

    useEffect(() => {
        refreshSourceFiles();
    }, [currentPage, documentContext?.refreshCount]);

    useEffect(() => {
        if (sources.length > 0) {
            setFileSourcesFilter(
                [
                    ...sources
                        .sort((a, b) => a.id.localeCompare(b.id))
                        .map((s) => s.id),
                ].join(',')
            );
        }
    }, [sources, documentContext?.refreshCount]);

    const handleMoreMenuClose = () => {
        setMoreMenuEl(null);
    };
    const handleMoreMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setMoreMenuEl(event.currentTarget);
    };
    const handleFilterMenuClose = () => {
        setFilterMenuEl(null);
    };
    const handleFilterMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setFilterMenuEl(event.currentTarget);
    };

    const handleSyncClick = async (di: IDriveItem) => {
        await syncSource(
            props.integrationAccount.integration_name,
            props.integrationAccount.account_id,
            di.id
        );
        await refreshSources();
        setOpenWaitDialog(true);
    };

    const onSourceAdd = async (di: IDriveItem) => {
        await syncSource(
            props.integrationAccount.integration_name,
            props.integrationAccount.account_id,
            di.id
        );
        await refreshSources();
    };

    const onDeleteSources = () => {
        refreshSources();
        setSourcesForDeletion([]);
    };

    useEffect(() => {
        refreshSources();
    }, [props.integrationAccount]);

    const drive = DRIVE.filter(
        (d) => d.id == props.integrationAccount.integration_id
    )[0];
    return (
        <Box>
            <Box px={4} py={3}>
                <Box display="flex" justifyContent={'space-between'}>
                    <Box display={'flex'}>
                        <KeyboardBackspaceIcon
                            sx={{ cursor: 'pointer', color: '#6e3cfb' }}
                            onClick={props.onBackClick}
                        />
                        <Box
                            sx={{
                                height: '4px',
                                transform: 'scale(.5,.5)',
                            }}
                        >
                            {drive.icon}
                        </Box>
                        <Typography mt={1} fontWeight={600} variant="caption">
                            {drive.title}
                        </Typography>
                        <Typography mt={1} ml={1} variant="caption">
                            ({props.integrationAccount.preferred_username})
                        </Typography>
                    </Box>
                    <Box display={'flex'}>
                        {enableRemoveSource ? (
                            <Tooltip arrow placement="top" title={'Cancel'}>
                                <IconButton
                                    onClick={() => setEnableRemoveSource(false)}
                                    sx={{
                                        color: '#6e3cfb',
                                        width: '30px',
                                        height: 33,
                                        float: 'right',
                                        fontSize: 16,
                                    }}
                                >
                                    <Box component="span" ml={1} pt={'3px'}>
                                        <CloseRoundedIcon
                                            sx={{
                                                color: '#6e3cfb',
                                                fontSize: 24,
                                            }}
                                        />
                                    </Box>
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <Tooltip arrow placement="top" title={'More'}>
                                <Typography
                                    aria-label="more"
                                    id="long-button"
                                    component={Button}
                                    variant="subtitle2"
                                    aria-controls={
                                        openMoreMenu ? 'long-menu' : undefined
                                    }
                                    aria-expanded={
                                        openMoreMenu ? 'true' : undefined
                                    }
                                    aria-haspopup="true"
                                    onClick={handleMoreMenuClick}
                                    sx={{
                                        color: '#6e3cfb',
                                        minWidth: '10px',
                                        float: 'right',
                                        fontSize: 16,
                                    }}
                                >
                                    <Box component="span" ml={1} pt={'3px'}>
                                        <More color={'#6e3cfb'} />
                                    </Box>
                                </Typography>
                            </Tooltip>
                        )}
                        <Menu
                            id="more-menu"
                            MenuListProps={{
                                'aria-labelledby': 'long-button',
                            }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            sx={{
                                py: 0,
                            }}
                            anchorEl={moreMenuEl}
                            open={openMoreMenu}
                            onClose={handleMoreMenuClose}
                            PaperProps={{
                                style: {
                                    maxHeight: ITEM_HEIGHT * 4.5,
                                    width: '23ch',
                                },
                            }}
                        >
                            <FileMenuItem
                                onClick={() => {
                                    handleMoreMenuClose();
                                    setTriggerAddSource(triggerAddSource + 1);
                                }}
                            >
                                <Box display="flex">
                                    <AddRoundedIcon
                                        sx={{ fontSize: 16, mt: 0.5 }}
                                    />
                                    <Box
                                        component="span"
                                        fontSize={14}
                                        sx={{ pl: 2 }}
                                    >
                                        Add Source
                                    </Box>
                                </Box>
                            </FileMenuItem>
                            {sources.length > 0 && (
                                <FileMenuItem
                                    onClick={() => {
                                        startRemoveSource();
                                        handleMoreMenuClose();
                                    }}
                                >
                                    <Box display="flex">
                                        <DeleteOutlineRoundedIcon
                                            sx={{ fontSize: 16, mt: 0.5 }}
                                        />
                                        <Box
                                            component="span"
                                            fontSize={14}
                                            sx={{ pl: 2 }}
                                        >
                                            Remove source folder
                                        </Box>
                                    </Box>
                                </FileMenuItem>
                            )}
                        </Menu>
                    </Box>
                </Box>
                <Box display={'flex'} width={'100%'}>
                    <Box
                        mt={3}
                        flexBasis={'100%'}
                        flexWrap={'wrap'}
                        display="flex"
                    >
                        <Box display={sources.length > 0 ? 'none' : ''}>
                            <AddSource
                                sources={sources}
                                openWaitDialog={openWaitDialog}
                                setOpenWaitDialog={setOpenWaitDialog}
                                onSourceAdd={onSourceAdd}
                                triggerAddSource={triggerAddSource}
                                integrationAccount={props.integrationAccount}
                            />
                        </Box>
                        {sources.map((di) => (
                            <Badge
                                badgeContent={
                                    enableRemoveSource ? (
                                        <Checkbox
                                            onClick={() => {
                                                toggleSourceDeletion(
                                                    di.item_id
                                                );
                                            }}
                                            checked={
                                                enableRemoveSource &&
                                                sourcesForDeletion
                                                    .map((s) => s.item_id)
                                                    .includes(di.item_id)
                                            }
                                            icon={
                                                <RadioButtonUncheckedIcon
                                                    color="error"
                                                    sx={{ fontSize: 18 }}
                                                />
                                            }
                                            checkedIcon={
                                                <RadioButtonCheckedIcon
                                                    color="error"
                                                    sx={{ fontSize: 18 }}
                                                />
                                            }
                                        />
                                    ) : (
                                        <Tooltip
                                            placement="top"
                                            arrow
                                            title={
                                                di.sync_status == 'IN-PROGRESS'
                                                    ? 'Sync In Progress'
                                                    : `${
                                                          di.last_synced
                                                              ? `Last sync ${UtilsService.timeSinceUTC(
                                                                    UtilsService.getUTCDateFromString(
                                                                        di.last_synced
                                                                    )
                                                                )}, n`
                                                              : 'N'
                                                      }${`ext sync in ${UtilsService.timeSinceUTC(
                                                          UtilsService.getUTCDateFromString(
                                                              di.next_scheduled_sync
                                                          )
                                                      )}`}`
                                            }
                                        >
                                            {di.sync_status !==
                                            'IN-PROGRESS' ? (
                                                <CloudSyncOutlinedIcon
                                                    onClick={() => {
                                                        handleSyncClick(di);
                                                    }}
                                                    sx={{
                                                        color: '#6e3cfb',
                                                        fontSize: 16,
                                                        cursor: 'pointer',
                                                    }}
                                                />
                                            ) : (
                                                <CachedIcon
                                                    sx={{
                                                        color: '#24A148',
                                                        fontSize: 16,
                                                        animation:
                                                            'spin 2s linear infinite',
                                                        '@keyframes spin': {
                                                            '0%': {
                                                                transform:
                                                                    'rotate(360deg)',
                                                            },
                                                            '100%': {
                                                                transform:
                                                                    'rotate(0deg)',
                                                            },
                                                        },
                                                    }}
                                                />
                                            )}
                                        </Tooltip>
                                    )
                                }
                                sx={{
                                    '& .MuiBadge-badge': {
                                        right: 20,
                                        top: 0,
                                    },
                                }}
                            >
                                <Box
                                    textAlign={'center'}
                                    onClick={() => {
                                        toggleSourceDeletion(di.item_id);
                                    }}
                                    sx={{
                                        cursor:
                                            di.level == null ? '' : 'pointer',
                                    }}
                                    width={'80px'}
                                >
                                    <Tooltip
                                        placement="top"
                                        arrow
                                        title={di.display_name}
                                    >
                                        <Box
                                            onClick={() => {
                                                toggleSourceDeletion(
                                                    di.item_id
                                                );
                                            }}
                                        >
                                            {di.item_type == 'DIR' ? (
                                                <FolderIcon
                                                    sx={{
                                                        fontSize: 30,
                                                        color:
                                                            enableRemoveSource &&
                                                            sourcesForDeletion
                                                                .map(
                                                                    (s) =>
                                                                        s.item_id
                                                                )
                                                                .includes(
                                                                    di.item_id
                                                                )
                                                                ? '#6778f2'
                                                                : '#95A2FF',
                                                    }}
                                                />
                                            ) : (
                                                <InsertDriveFileIcon
                                                    sx={{
                                                        fontSize: 30,
                                                        color: '#95A2FF',
                                                    }}
                                                />
                                            )}

                                            <Typography
                                                className="clamp-line-1"
                                                variant="caption"
                                                color={
                                                    di.sync_status !==
                                                    'IN-PROGRESS'
                                                        ? '#000033'
                                                        : '#4B4B4C59'
                                                }
                                            >
                                                {di.display_name}
                                            </Typography>
                                        </Box>
                                    </Tooltip>
                                </Box>
                            </Badge>
                        ))}
                    </Box>
                    {enableRemoveSource && sourcesForDeletion.length > 0 && (
                        <Box pt={4}>
                            <DeleteSourceFolder
                                onDelete={onDeleteSources}
                                integrationAccount={props.integrationAccount}
                                sourcesForDeletion={sourcesForDeletion}
                            />
                        </Box>
                    )}
                </Box>
            </Box>
            <Divider />
            <Box py={3}>
                {sources.length > 0 && (
                    <Box>
                        <Box
                            display={'flex'}
                            pb={2}
                            justifyContent={'space-between'}
                        >
                            <Typography px={4} pb={2} variant="h6">
                                Files from the source folders
                            </Typography>
                            {sources.length > 1 && (
                                <Tooltip arrow placement="top" title={'More'}>
                                    <Typography
                                        aria-label="file-filters"
                                        id="long-button"
                                        component={Button}
                                        variant="subtitle2"
                                        aria-controls={
                                            openMoreMenu
                                                ? 'long-menu'
                                                : undefined
                                        }
                                        aria-expanded={
                                            openMoreMenu ? 'true' : undefined
                                        }
                                        aria-haspopup="true"
                                        onClick={handleFilterMenuClick}
                                        sx={{
                                            color: '#6e3cfb',
                                            minWidth: '10px',
                                            float: 'right',
                                            fontSize: 16,
                                            py: 0,
                                            mr: 2,
                                        }}
                                    >
                                        <Box component="span" ml={1} pt={'3px'}>
                                            <FilterAltOutlinedIcon
                                                sx={{
                                                    color: '#6e3cfb',
                                                    fontWeight: 400,
                                                }}
                                            />
                                        </Box>
                                    </Typography>
                                </Tooltip>
                            )}
                            <Menu
                                id="more-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'long-button',
                                }}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                sx={{
                                    py: 0,
                                }}
                                anchorEl={filterMenuEl}
                                open={openFilterMenu}
                                onClose={handleFilterMenuClose}
                                PaperProps={{
                                    style: {
                                        maxHeight: ITEM_HEIGHT * 4.5,
                                        width: '23ch',
                                    },
                                }}
                            >
                                <Typography
                                    pl={4}
                                    fontSize={12}
                                    fontWeight={600}
                                >
                                    Show files of:
                                </Typography>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="female"
                                    onChange={(e) => {
                                        setFileSourcesFilter(e.target.value);
                                        handleFilterMenuClose();
                                    }}
                                    value={fileSourcesFilter}
                                    name="radio-buttons-group"
                                >
                                    {sources.map((s: IDriveItem) => (
                                        <FormControlLabel
                                            sx={{
                                                width: '100%',
                                                py: 1,
                                                pl: 4,
                                            }}
                                            value={s.id}
                                            control={
                                                <Radio
                                                    size="small"
                                                    sx={{ py: 0, px: 2 }}
                                                />
                                            }
                                            label={s.display_name}
                                        />
                                    ))}

                                    <FormControlLabel
                                        sx={{
                                            width: '100%',
                                            py: 1,
                                            pl: 4,
                                        }}
                                        value={sources
                                            .sort((a, b) =>
                                                a.id.localeCompare(b.id)
                                            )
                                            .map((s) => s.id)
                                            .join(',')}
                                        control={
                                            <Radio
                                                size="small"
                                                sx={{ py: 0, px: 2 }}
                                            />
                                        }
                                        label={'All'}
                                    />
                                </RadioGroup>
                            </Menu>
                        </Box>
                        {files?.map((file, id) => (
                            <File
                                accountId={props.integrationAccount.account_id}
                                hideDelete
                                file={file}
                                tab={1}
                                key={file.userFileId}
                            />
                        ))}
                        {pagesCount > 1 && (
                            <Box sx={{ mt: 2 }}>
                                <Stack spacing={2}>
                                    <Pagination
                                        sx={{ mr: 'auto' }}
                                        count={pagesCount}
                                        size="small"
                                        onChange={(e, p) => {
                                            setCurrentPage(p);
                                        }}
                                        renderItem={(item) => (
                                            <PaginationItem
                                                slots={{
                                                    previous: ArrowBackIcon,
                                                    next: ArrowForwardIcon,
                                                }}
                                                {...item}
                                            />
                                        )}
                                    />
                                </Stack>
                            </Box>
                        )}
                    </Box>
                )}
                {files.length == 0 && (
                    <Box pt={20} textAlign={'center'}>
                        {Directory}
                        <Typography mt={2} variant="h4">
                            {sources.length == 0
                                ? 'Add Source Folder'
                                : 'Relax! Wait for some time.'}
                        </Typography>
                        <Typography color="#4B4B4C" mt={1} fontSize={14}>
                            {sources.length == 0
                                ? 'Add Source Folder to view files'
                                : 'Files may take upto an hour for sync. Sit back and see your files after some time.'}
                        </Typography>
                    </Box>
                )}
            </Box>
        </Box>
    );
};
