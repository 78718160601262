import { useState, useContext, useEffect } from 'react';
import { Box, Modal, Button } from '@mui/material';
import { Add } from '../../components/icons/add';
import SelectWatchlist from './select-watchlist';
import { BorderedBox } from '../../components/core/box';
import CreateWatchlist from './create-watchlist';
import ConfirmWatchlist from './confirm-watchlist';
import { Star, StarBorder } from '@mui/icons-material';
import { AppContext } from '../../providers/app-provider';
import cluster from 'cluster';
import RemoveWatchlist from './remove-watchlist';

interface IProps {
    query: string;
    entity?: boolean;
}

const AddToWatchList = (props: IProps) => {
    const appContext = useContext(AppContext);
    const [addStep, setAddStep] = useState<number>(1);
    const [open, setOpen] = useState(false);
    const [clusterName, setClusterName] = useState<string | undefined>('');
    const [clusterId, setClusterId] = useState<string | undefined>('');
    const [isWatchlisted, setIsWatchListed] = useState<boolean>(false);

    useEffect(() => {
        let findQueryInWatchlists = appContext?.clusters?.find((cluster) =>
            cluster.userTopicSubscriptions.find(
                (topic) =>
                    topic.subscriptionName.toLowerCase() ==
                    props.query.toLowerCase()
            )
        );

        if (findQueryInWatchlists) {
            setIsWatchListed(true);
        } else {
            setIsWatchListed(false);
        }
    }, [appContext?.clusters, props.query]);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setAddStep(1);
        setOpen(false);
    };

    return (
        <Box>
            {!props.entity && (
                <Box
                    onClick={handleOpen}
                    sx={{
                        ml: 4,
                        border: '2px solid #6e3cfb',
                        borderRadius: '50%',
                        height: '52px',
                        width: '52px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }}
                >
                    {!isWatchlisted && <StarBorder sx={{ color: '#6e3cfb' }} />}
                    {isWatchlisted && <Star sx={{ color: '#6e3cfb' }} />}
                </Box>
            )}

            {props.entity && (
                <Box
                    onClick={handleOpen}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }}
                >
                    {!isWatchlisted && <StarBorder sx={{ color: '#6e3cfb' }} />}
                    {isWatchlisted && <Star sx={{ color: '#6e3cfb' }} />}
                </Box>
            )}

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ backdropFilter: 'blur(2px)' }}
            >
                <BorderedBox
                    sx={{
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 500,
                        bgcolor: '#FFFFFF',
                        p: 5,
                    }}
                >
                    {!isWatchlisted && (
                        <Box>
                            {addStep === 1 && (
                                <SelectWatchlist
                                    query={props.query}
                                    clusterId={clusterId}
                                    handleComplete={(addStep: number) =>
                                        setAddStep(addStep)
                                    }
                                    setClusterName={(name: string) =>
                                        setClusterName(name)
                                    }
                                    setClusterId={(id: string) =>
                                        setClusterId(id)
                                    }
                                    handleClose={handleClose}
                                />
                            )}
                            {addStep === 2 && (
                                <CreateWatchlist
                                    handleComplete={() => setAddStep(3)}
                                    clusterName={clusterName}
                                    setClusterName={(name: string) =>
                                        setClusterName(name)
                                    }
                                    setClusterId={(id: string) =>
                                        setClusterId(id)
                                    }
                                    handleClose={handleClose}
                                />
                            )}
                            {addStep === 3 && (
                                <ConfirmWatchlist
                                    query={props.query}
                                    clusterName={clusterName}
                                    clusterId={clusterId}
                                    setClusterName={(name: string) =>
                                        setClusterName(name)
                                    }
                                    setClusterId={(id: string) =>
                                        setClusterId(id)
                                    }
                                    handleClose={handleClose}
                                />
                            )}
                        </Box>
                    )}
                    {isWatchlisted && (
                        <RemoveWatchlist
                            query={props.query}
                            handleClose={handleClose}
                        />
                    )}
                </BorderedBox>
            </Modal>
        </Box>
    );
};

export default AddToWatchList;
