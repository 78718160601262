import { Typography } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../../providers/app-provider';
import {
    HighlightIndexes,
    TextHighlightsIndexes,
} from '../../services/interfaces';

interface Props {
    highlight: TextHighlightsIndexes;
    textColor?: string;
    collapsable?: boolean;
    textFontSize?: string;
    text: string;
    textVariant?:
        | 'h1'
        | 'h2'
        | 'h3'
        | 'h4'
        | 'h5'
        | 'h6'
        | 'subtitle1'
        | 'subtitle2'
        | 'body1'
        | 'body2'
        | 'caption'
        | 'button'
        | 'overline'
        | 'inherit'
        | undefined;
}

export const TextHighlight = (props: Props) => {
    const appContext = useContext(AppContext);
    const [text, setText] = useState(props.text);
    const [expanded, setExpanded] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const el = useRef(null);

    const [clamped, setClamped] = useState(false);

    useEffect(() => {
        if (appContext?.showHighlights) {
            formatString();
        } else {
            setText(props.text);
        }
    }, [props.text, appContext?.showHighlights]);
    const formatString = () => {
        if (!props.highlight) {
            return;
        }
        let formatData: { start: number; end: number; cssClass: string }[] = [];

        props.highlight.forwardLookingStatement?.forEach((n: number[]) => {
            formatData.push({
                start: n[0],
                end: n[1],
                cssClass: 'text-forward',
            });
        });

        props.highlight.negative.forEach((n: number[]) => {
            if (
                formatData.filter(
                    (d) =>
                        (n[0] > d.start && n[0] < d.end) ||
                        (n[1] > d.start && n[1] < d.end)
                ).length == 0
            ) {
                formatData.push({
                    start: n[0],
                    end: n[1],
                    cssClass: 'text-negetive',
                });
            }
        });
        props.highlight.positive.forEach((n: number[]) => {
            if (
                formatData.filter(
                    (d) =>
                        (n[0] > d.start && n[0] < d.end) ||
                        (n[1] > d.start && n[1] < d.end)
                ).length == 0
            ) {
                formatData.push({
                    start: n[0],
                    end: n[1],
                    cssClass: 'text-positive',
                });
            }
        });
        formatData.sort(
            (
                a: { start: number; end: number; cssClass: string },
                b: { start: number; end: number; cssClass: string }
            ) => {
                return a.start < b.start ? 1 : -1;
            }
        );
        let _text = props.text;
        formatData.forEach(
            (pos: { start: number; end: number; cssClass: string }) => {
                _text =
                    _text.substring(0, pos.end) +
                    '</span>' +
                    _text.substring(pos.end);
                _text =
                    _text.substring(0, pos.start) +
                    `<span class="${pos.cssClass}">` +
                    _text.substring(pos.start);
            }
        );

        setText(_text);
    };

    useEffect(() => {
        setClamped(hasClamping());
    }, [el.current]);

    const hasClamping = () => {
        if (!el.current) {
            return false;
        }
        //@ts-ignore
        const { clientHeight, scrollHeight } = el.current;
        return clientHeight !== scrollHeight;
    };
    return props.collapsable ? (
        <>
            <span
                ref={el}
                style={{
                    fontSize: props.textFontSize,
                }}
                className={showMore ? '' : 'collapsed'}
                dangerouslySetInnerHTML={{ __html: text }}
            />
            {showMore && <br />}
            {clamped && (
                <span
                    style={{
                        cursor: 'pointer',
                        fontSize: props.textFontSize,
                        color: '#6e3cfb',
                    }}
                    onClick={() => setShowMore(!showMore)}
                >
                    See {showMore ? 'less' : 'more'}
                </span>
            )}
        </>
    ) : (
        <Typography
            color={props.textColor}
            fontSize={props.textFontSize}
            variant={props.textVariant}
        >
            <span ref={el} dangerouslySetInnerHTML={{ __html: text }} />
        </Typography>
    );
};
