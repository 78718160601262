import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Modal,
    Typography,
    Card,
    CardContent,
    CardActions,
    DialogContent,
    Stack,
    CardMedia,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { NavLink } from 'react-router-dom';
import CircleIcon from '@mui/icons-material/Circle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import {
    BootstrapDialogLarge,
    BootstrapDialogMedium,
    BootstrapDialogTitle,
} from '../core/dialog';

import {
    Container,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { MiniLogo } from '../icons/mini-logo';
import { useTour } from '@reactour/tour';
import { useQueryparamServices } from '../../services/query-param.service';
import { Link } from 'react-router-dom';
import { NavigationLink } from '../core/link';

const CustomBox: React.FC<{
    title: string;
    description: string;
    buttonText: string;
    link: string;
    newTab?: boolean;
}> = ({ title, description, buttonText, link, newTab }) => {
    return (
        <Box
            sx={{
                borderRadius: '8px',
                padding: '16px',
                border: '1px solid #e0e0e0',
                backgroundColor: '#f9f9f9',
            }}
        >
            <Typography variant="h6" component="div" gutterBottom>
                {title}
            </Typography>
            <Typography
                variant="body2"
                color="text.secondary"
                sx={{ marginBottom: '16px' }}
            >
                {description}
            </Typography>
            <Button
                component={NavLink}
                to={link}
                variant="outlined"
                target={newTab ? '_blank' : ''}
                sx={{ textTransform: 'none' }}
            >
                {buttonText}
            </Button>
        </Box>
    );
};
export const OnboardDialog = () => {
    const [open, setOpen] = useState(false);
    const { removeQueryParams, getQueryParam } = useQueryparamServices();
    const handleClose = () => setOpen(false);
    const { setIsOpen } = useTour();
    useEffect(() => {
        if (getQueryParam('onboarding')) {
            let success = getQueryParam('onboarding') == 'true';
            if (success) {
                setOpen(true);
            }
            removeQueryParams('onboarding');
        }
    }, [getQueryParam('onboarding')]);

    return (
        <BootstrapDialogLarge
            open={open}
            sx={{
                '& .MuiPaper-root': {
                    maxWidth: '900px',
                },
            }}
            onClose={handleClose}
        >
            <BootstrapDialogTitle
                id="customized-dialog-title"
                onClose={handleClose}
            >
                <Box display="flex">
                    <Box p={1} pl={0}>
                        <MiniLogo width="32" height="32" />
                    </Box>{' '}
                    <Typography variant="h3">Photon Insights</Typography>
                </Box>
            </BootstrapDialogTitle>
            <DialogContent>
                <Box textAlign={'center'}>
                    <Typography fontWeight={600} fontSize={38}>
                        Welcomes You
                    </Typography>
                    <Typography fontSize={28}>
                        You are starting a 7 Day Trial of the Pro Plan
                    </Typography>
                    <Typography mt={2} fontSize={16}>
                        {
                            'Get the best out of Photon Insights by using our best plan'
                        }
                    </Typography>
                </Box>
                <Box py={4}>
                    <Divider />
                </Box>

                <Box>
                    <Box mb={2} display="flex">
                        <StarBorderOutlinedIcon />
                        <Typography ml={1} variant="h6">
                            Features you will get include:
                        </Typography>
                    </Box>

                    <Grid container>
                        {[
                            'Insights Mode (In-depth analytics from many data sources)	',
                            '5000 AI credits monthly',
                            '10M Words of data per month',
                            '10 Chatbots',
                            'Supports Audio/Video files',
                            '5 Diligence Reports',
                            'Sync data from Dropbox, OneDrive, etc.',
                            'Access to GPT 4o',
                            'Up to 100 MB per file',
                        ].map((text, index) => (
                            <Grid item xs={6} key={index}>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    py={1}
                                    key={index}
                                >
                                    <CheckIcon
                                        color="success"
                                        fontSize="small"
                                        sx={{ mr: 1 }}
                                    />
                                    <Typography ml={1} variant="body2">
                                        {text}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                    <Grid mt={4} container>
                        <Grid item xs={12} pr={4} md={6}>
                            <CustomBox
                                title="Explore Other Plans"
                                description="Browse through our catalog of offerings, to find the best subscription for your requirements."
                                buttonText="See Plans"
                                link="/my-account?tab=PLANS_BILLING&subTab=PLANS"
                            />
                        </Grid>
                        <Grid item xs={12} pl={4} md={6}>
                            <CustomBox
                                title="Talk to our product experts"
                                description="Discuss your product goals with our team, and we’ll help you find the perfect plan."
                                buttonText="Contact Us"
                                newTab={true}
                                link="https://www.photoninsights.com/contact"
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    mt={4}
                >
                    <Button
                        onClick={() => handleClose()}
                        variant="text"
                        sx={{ textTransform: 'none', color: '#4B4B4C' }}
                    >
                        Skip
                    </Button>
                    <Button
                        onClick={() => {
                            handleClose();
                            setIsOpen(true);
                        }}
                        variant="outlined"
                        sx={{ textTransform: 'none' }}
                    >
                        Start Tour
                    </Button>
                </Stack>
            </DialogContent>
        </BootstrapDialogLarge>
    );
};
