import { BorderedBox } from '../../../../components/core/box';
import { Box, Typography } from '@mui/material';
import { IDiligenceTemplateConfiguration } from '../../../../services/interfaces';
import { DataSourceOptions } from './common-input';

interface IProps {
    title: string;
    caption: string;
    enabledSource?: 'WEB' | 'DOCUMENTS';
    config: IDiligenceTemplateConfiguration;
    updateConfig: (config: IDiligenceTemplateConfiguration) => void;
    enableDataSourceSelection?: boolean;
}

export const Common = (props: IProps) => {
    return (
        <BorderedBox sx={{ backgroundColor: '#f9f5ff', p: 3 }}>
            <Box py={1} display="flex" justifyContent="space-between">
                <Box>
                    <Typography color="#003" variant="h6">
                        {props.title}
                    </Typography>
                    <Typography color="#8D8D8D" fontSize={12}>
                        {props.caption}
                    </Typography>
                </Box>
                <Box></Box>
            </Box>
            {props.enableDataSourceSelection && (
                <DataSourceOptions
                    updateConfig={props.updateConfig}
                    enabledSource={props.enabledSource}
                    config={props.config}
                />
            )}
        </BorderedBox>
    );
};
