import { useEffect, useState } from 'react';
import { AlertsCluster, Cluster } from '../../../../services/interfaces';
import { useClusterService } from '../../../../services/cluster.service';
import { useSnackbar } from 'notistack';
import {
    Close,
    NotificationsActive,
    NotificationsNone,
} from '@mui/icons-material';
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    Modal,
    Typography,
} from '@mui/material';
import { BorderedBox } from '../../../../components/core/box';

interface IProps {
    cluster: Cluster;
    alertSettings: AlertsCluster[];
    reFetchSettings: () => void;
}
export const SetAlerts = (props: IProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false);
    const [alertCluster, setAlertCluster] = useState<AlertsCluster>();

    const { updateClusterAlert } = useClusterService();
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        setAlertCluster(
            props.alertSettings.find(
                (cluster: AlertsCluster) =>
                    cluster.dashboardClusterId === props.cluster.clusterId
            )
        );
    }, [props.cluster, props.alertSettings]);

    const updateAlertsSetting = async () => {
        if (!alertCluster) return;
        let res = await updateClusterAlert(alertCluster);
        if (res) {
            await props.reFetchSettings();
            handleClose();
            enqueueSnackbar('Alert updated');
        }
    };

    return (
        <>
            {alertCluster && (
                <>
                    {(alertCluster.dailyAlert || alertCluster.weeklyAlert) && (
                        <NotificationsActive
                            onClick={handleOpen}
                            sx={{
                                color: '#6e3cfb',
                                cursor: 'pointer',
                            }}
                        />
                    )}
                    {!alertCluster.dailyAlert && !alertCluster.weeklyAlert && (
                        <NotificationsNone
                            onClick={handleOpen}
                            sx={{
                                color: '#6e3cfb',
                                cursor: 'pointer',
                            }}
                        />
                    )}
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        sx={{ backdropFilter: 'blur(2px)' }}
                    >
                        <BorderedBox
                            sx={{
                                position: 'absolute' as 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: 600,
                                bgcolor: '#FFFFFF',
                                p: 5,
                            }}
                        >
                            <Typography variant="h4">Set Alert</Typography>
                            <Divider sx={{ my: 4 }} />
                            <Typography variant="h6">
                                {alertCluster.dashboardClusterName}{' '}
                            </Typography>
                            <Box
                                sx={{
                                    mt: 2,
                                    width: '70%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={alertCluster.dailyAlert}
                                            onChange={() => {
                                                setAlertCluster({
                                                    ...alertCluster,
                                                    dailyAlert:
                                                        !alertCluster.dailyAlert,
                                                });
                                            }}
                                        />
                                    }
                                    label="Daily"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={alertCluster.weeklyAlert}
                                            onChange={() => {
                                                setAlertCluster({
                                                    ...alertCluster,
                                                    weeklyAlert:
                                                        !alertCluster.weeklyAlert,
                                                });
                                            }}
                                        />
                                    }
                                    label="Weekly"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                !alertCluster.dailyAlert &&
                                                !alertCluster.weeklyAlert
                                            }
                                            onChange={() => {
                                                setAlertCluster({
                                                    ...alertCluster,
                                                    dailyAlert: false,
                                                    weeklyAlert: false,
                                                });
                                            }}
                                        />
                                    }
                                    label="Off"
                                />
                            </Box>
                            <Box display="flex" mt={4} justifyContent="right">
                                <Button
                                    sx={{ color: '#4B4B4C', mr: 3 }}
                                    onClick={() => handleClose()}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={() => updateAlertsSetting()}
                                    sx={{ minWidth: '80px' }}
                                >
                                    Save
                                </Button>
                            </Box>
                            <Close
                                sx={{
                                    position: 'absolute',
                                    top: 24,
                                    right: 24,
                                    color: '#6e3cfb',
                                    cursor: 'pointer',
                                }}
                                onClick={handleClose}
                            />
                        </BorderedBox>
                    </Modal>
                </>
            )}
        </>
    );
};
