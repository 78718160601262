import { Box, Button, Typography } from '@mui/material';
import { BorderedBox } from '../../../components/core/box';
import { SlackIcon } from '../../../components/icons/slack';
import { Link } from 'react-router-dom';
import { Authorize } from '../../../components/authorize';
import { FeatureName, SubFeatureName } from '../../../services/interfaces';
import { usePermissions } from '../../../providers/permissions-provider';

export const SlackIntegration = () => {
    const { isFeatureAuthorized, isSubFeatureAuthorized } = usePermissions();
    return (
        <BorderedBox
            mt={6}
            p={6}
            borderRadius="16px !important"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
        >
            <Box width="70%">
                <SlackIcon size={24} />
                <Typography variant="h4" mt={2}>
                    Slack Integration
                </Typography>
                <Typography
                    mt={3}
                    fontSize="14px"
                    lineHeight="24px"
                    letterSpacing="0.16px"
                >
                    Our Slack integration allows you to bring the power of
                    Photon's AI bot directly into your Slack workspace. This
                    integration enables you to query and interact with your
                    documents without ever leaving Slack.
                </Typography>
            </Box>
            <Authorize
                featureName={FeatureName.LiveQA}
                subFeatureName={SubFeatureName.TeamsLive}
                placement="left"
            >
                <Button
                    disabled={
                        !isSubFeatureAuthorized(
                            FeatureName.LiveQA,
                            SubFeatureName.TeamsDocu
                        )
                    }
                    sx={{
                        border: '1px solid #29319B',
                        borderRadius: 2,
                        width: 160,
                        fontSize: 14,
                        opacity: isSubFeatureAuthorized(
                            FeatureName.LiveQA,
                            SubFeatureName.TeamsDocu
                        )
                            ? 1
                            : 0.5,
                        fontWeight: 600,
                    }}
                    startIcon={<SlackIcon size={20} />}
                    component={Link}
                    to="https://slack.com/oauth/v2/authorize?client_id=3663825352640.6495759560023&scope=chat:write,im:history,users:read,users:read.email&user_scope="
                    target="_blank"
                >
                    Add to Slack
                </Button>
            </Authorize>
        </BorderedBox>
    );
};
