export const VolumeChartPlaceholder = (
    <svg
        width="226"
        height="171"
        viewBox="0 0 226 171"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M207.875 170.25H18.125C13.5516 170.245 9.16695 168.426 5.93306 165.192C2.69917 161.958 0.880139 157.573 0.875 153V17.25C0.880203 12.6766 2.69928 8.29203 5.93315 5.05815C9.16703 1.82428 13.5516 0.00520279 18.125 0H207.875C212.448 0.0052008 216.833 1.82428 220.067 5.05815C223.301 8.29203 225.12 12.6766 225.125 17.25V153C225.12 157.573 223.301 161.958 220.067 165.192C216.833 168.426 212.448 170.245 207.875 170.25ZM18.125 1.5C13.9493 1.50468 9.94592 3.16556 6.99324 6.11824C4.04056 9.07092 2.37968 13.0743 2.375 17.25V153C2.37974 157.176 4.04062 161.179 6.99329 164.132C9.94596 167.084 13.9493 168.745 18.125 168.75H207.875C212.051 168.745 216.054 167.084 219.007 164.132C221.959 161.179 223.62 157.176 223.625 153V17.25C223.62 13.0743 221.959 9.07092 219.007 6.11824C216.054 3.16556 212.051 1.50468 207.875 1.5H18.125Z"
            fill="#9C9FD0"
        />
        <path
            d="M69.7461 20.6689H39.7461C38.9504 20.6689 38.1874 20.3529 37.6248 19.7903C37.0622 19.2277 36.7461 18.4646 36.7461 17.6689C36.7461 16.8733 37.0622 16.1102 37.6248 15.5476C38.1874 14.985 38.9504 14.6689 39.7461 14.6689H69.7461C70.5417 14.6689 71.3048 14.985 71.8674 15.5476C72.43 16.1102 72.7461 16.8733 72.7461 17.6689C72.7461 18.4646 72.43 19.2277 71.8674 19.7903C71.3048 20.3529 70.5417 20.6689 69.7461 20.6689Z"
            fill="#CCCCCC"
        />
        <path
            d="M134.996 88.1689H39.7461C38.9504 88.1689 38.1874 87.8529 37.6248 87.2903C37.0622 86.7277 36.7461 85.9646 36.7461 85.1689C36.7461 84.3733 37.0622 83.6102 37.6248 83.0476C38.1874 82.485 38.9504 82.1689 39.7461 82.1689H134.996C135.792 82.1689 136.555 82.485 137.117 83.0476C137.68 83.6102 137.996 84.3733 137.996 85.1689C137.996 85.9646 137.68 86.7277 137.117 87.2903C136.555 87.8529 135.792 88.1689 134.996 88.1689Z"
            fill="#CCCCCC"
        />
        <path
            d="M56.622 69.2308C67.7307 69.2308 76.7362 60.2254 76.7362 49.1166C76.7362 38.0079 67.7307 29.0024 56.622 29.0024C45.5132 29.0024 36.5078 38.0079 36.5078 49.1166C36.5078 60.2254 45.5132 69.2308 56.622 69.2308Z"
            fill="#6e3cfb"
        />
        <path
            d="M41.2461 145.46H36.7461V146.96H41.2461V145.46Z"
            fill="#3F3D56"
        />
        <path
            d="M173.376 146.96H163.939V145.46H173.376V146.96ZM154.501 146.96H145.063V145.46H154.501V146.96ZM135.626 146.96H126.188V145.46H135.626V146.96ZM116.751 146.96H107.313V145.46H116.751L116.751 146.96ZM97.8756 146.96H88.438V145.46H97.8756V146.96ZM79.0004 146.96H69.5628V145.46H79.0004L79.0004 146.96ZM60.1251 146.96H50.6875V145.46H60.1251V146.96Z"
            fill="#3F3D56"
        />
        <path
            d="M187.312 145.46H182.812V146.96H187.312V145.46Z"
            fill="#3F3D56"
        />
        <path
            d="M113.129 118.919H109.379V144.419H113.129V118.919Z"
            fill="#9C9FD0"
        />
        <path
            d="M151.379 118.919H147.629V144.419H151.379V118.919Z"
            fill="#6e3cfb"
        />
        <path
            d="M170.129 133.169H166.379V144.419H170.129V133.169Z"
            fill="#9C9FD0"
        />
        <path
            d="M131.879 107.669H128.129V144.419H131.879V107.669Z"
            fill="#9C9FD0"
        />
    </svg>
);
