import {
    Autocomplete,
    AutocompleteChangeDetails,
    AutocompleteChangeReason,
    Box,
    Chip,
    CircularProgress,
    Grid,
    IconButton,
    Paper,
    Tooltip,
    Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useContext, useEffect, useState } from 'react';
import { Input } from '../../../components/core/input';
import { useSnackbar } from 'notistack';
import styled from '@emotion/styled';
import {
    DocumentFile,
    FileTag,
    Tag,
    UpdateDocumentName,
    UpdateDocumentTags,
} from '../../../services/interfaces';
import { DocumentInsightsContext } from '../../../providers/document-provider';
import { useDocumentService } from '../../../services/document.service';
import { EditBoxed } from '../../../components/icons/edit-boxed';

const DialogWrapper = styled(Paper)(() => ({
    width: '40%',
}));

interface ITagChipProps {
    title: string;
    onClick: () => void;
}

export const TagBlock = styled(Chip)(() => ({
    border: 'none',
    backgroundColor: '#EBECF6',
    mr: 2,
    fontSize: 16,
    color: '#6e3cfb',
    fontWeight: 600,
    '& .MuiChip-deleteIcon': {
        color: '#6e3cfb !important',
    },
}));

const TagChip = (props: ITagChipProps) => {
    return (
        <Tooltip arrow placement="top" title={`Add tag ${props.title}`}>
            <Box
                onClick={props.onClick}
                sx={{
                    px: 2,
                    py: 1,
                    mr: 2,
                    mt: 2,
                    display: 'flex',
                    borderRadius: 4,
                    color: '#4B4B4C',
                    border: '1px solid #E3E3E3',
                    cursor: 'pointer',
                }}
            >
                <Typography variant="h6">{props.title}</Typography>
            </Box>
        </Tooltip>
    );
};

interface IProps {
    file?: DocumentFile;
    files?: DocumentFile[];
    userFileGroupId?: string;
    open: boolean;
    onUpdate?: () => void;
    onClose: () => void;
}

export const EditTag = (props: IProps) => {
    const [open, setOpen] = React.useState(props.open);
    const documentContext = useContext(DocumentInsightsContext);
    const [fileName, setFileName] = useState<string>('');
    const [fileId, setFileId] = useState<string>('');
    const [editFileName, setEditFileName] = useState<boolean>(false);
    const [tagInputValue, setTagInputValue] = useState('');
    const [docTags, setDocTags] = useState<FileTag[]>([]);
    const [initTags, setInitTags] = useState<FileTag[]>([]);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [updatedFileName, setUpdatedFileName] = useState<string | undefined>(
        ''
    );
    const {
        updateDocumentTags,
        updateDocumentName,
        updateMultipleDocumentTags,
    } = useDocumentService();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (props.file && fileId != props.file.userFileId) {
            setFileName(props.file.fileName);
            setFileId(props.file.userFileId);
            if (props.file.tags) {
                setDocTags([...props.file.tags]);
                setInitTags([...props.file.tags]);
            }
        }
        if (props.files) {
            setFileName('');
            setDocTags([]);
            setInitTags([]);
        }
    }, [props.file, props.files, open]);

    useEffect(() => {
        setOpen(props.open);
        setIsSaving(false);
    }, [props.open]);

    const addTagValue = (value: string) => {
        if (
            documentContext?.filters &&
            value &&
            docTags.filter((t: FileTag) => t.title == value).length == 0
        ) {
            let tagToAdd: FileTag = { title: value };
            let availableTag = documentContext.filters.tags.filter(
                (t: Tag) => t.title == value
            )[0];
            if (availableTag) {
                tagToAdd = { title: value, userTagId: availableTag.userTagId };
            }
            setDocTags([...docTags, tagToAdd]);
        }
    };

    const addTag = () => {
        if (
            tagInputValue &&
            docTags.filter((t: FileTag) => t.title == tagInputValue).length == 0
        ) {
            addTagValue(tagInputValue);
            setTagInputValue('');
        }
    };

    const handleTagInput = (
        event: React.SyntheticEvent<Element, Event>,
        value: string[],
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<string> | undefined
    ) => {
        if (['createOption', 'selectOption'].includes(reason)) {
            if (details) {
                addTagValue(details?.option);
                setTagInputValue('');
            }
        }
        if (reason == 'removeOption') {
            if (details?.option) {
                setDocTags([
                    ...docTags.filter(
                        (t: FileTag) => t.title != details.option
                    ),
                ]);
            }
        }
    };
    const handleFileNameSave = async (name: string) => {
        if (name == fileName || !props.file) {
            return;
        }
        if (documentContext && props.file.userFileId) {
            setIsSaving(true);
            let body: UpdateDocumentName = {
                userFileId: props.file.userFileId,
                title: name,
            };
            let res = await updateDocumentName(body);
            if (res) {
                enqueueSnackbar(`File renamed successfully`);
                documentContext.setRefreshCount(
                    documentContext.refreshCount + 1
                );
            }
            setIsSaving(false);
        }
    };

    const fileNameCTAClick = () => {
        if (updatedFileName) {
            setFileName(updatedFileName);
            if (editFileName) {
                handleFileNameSave(updatedFileName);
                setUpdatedFileName('');
                setEditFileName(false);
            }
        } else {
            setEditFileName(false);
        }
    };

    const editFileNameClick = () => {
        setEditFileName(true);
        setUpdatedFileName(fileName);
    };

    const handleSave = async () => {
        if (props.file && props.file.userFileId && documentContext) {
            setIsSaving(true);
            let body: UpdateDocumentTags = {
                userFileId: props.file.userFileId,
            };
            body.userTags = [];
            if (docTags.length) {
                body.userTags = docTags;
            }
            let res = await updateDocumentTags(body);
            if (res) {
                enqueueSnackbar(`Tags updated successfully`);
                documentContext.setRefreshCount(
                    documentContext.refreshCount + 1
                );
                handleClose();
                props.onUpdate && props.onUpdate();
            }
            setIsSaving(false);
        }
        if (props.files && documentContext) {
            setIsSaving(true);
            let body: UpdateDocumentTags = {
                userFileIds: props.files.map((f: DocumentFile) => f.userFileId),
            };
            body.userTags = [];
            if (docTags.length) {
                body.userTags = docTags;
            }
            let res = await updateMultipleDocumentTags(body);
            if (res) {
                enqueueSnackbar(`Tags updated successfully`);
                documentContext.setRefreshCount(
                    documentContext.refreshCount + 1
                );
                handleClose();
                props.onUpdate && props.onUpdate();
            }
            setIsSaving(false);
        }
    };

    const handleClose = () => {
        setOpen(false);
        props.onClose();
    };
    return (
        <Box>
            <Dialog
                PaperComponent={DialogWrapper}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                    <Box>
                        <Box display="flex">
                            {editFileName && (
                                <Box sx={{ display: 'contents' }}>
                                    <Input
                                        type="text"
                                        mt={0}
                                        containerWidth={'calc(100% - 30px)'}
                                        value={updatedFileName}
                                        onChange={(e) => {
                                            setUpdatedFileName(e.target.value);
                                        }}
                                        endAdornment={
                                            <Button
                                                sx={{ px: 4, fontSize: 16 }}
                                                variant="contained"
                                                onClick={fileNameCTAClick}
                                            >
                                                {updatedFileName &&
                                                updatedFileName != fileName
                                                    ? editFileName
                                                        ? 'UPDATE'
                                                        : 'CREATE'
                                                    : 'CANCEL'}
                                            </Button>
                                        }
                                        placeholder="Enter the group name"
                                    />
                                </Box>
                            )}
                            {!editFileName && fileName && (
                                <Box
                                    sx={{ maxWidth: 'calc(100% - 30px)' }}
                                    display="flex"
                                >
                                    <Typography
                                        variant="h1"
                                        sx={{
                                            wordBreak: 'break-all',
                                        }}
                                    >
                                        {fileName}
                                    </Typography>
                                    <Box
                                        onClick={editFileNameClick}
                                        pt={2}
                                        pl={3}
                                    >
                                        <EditBoxed />
                                    </Box>
                                </Box>
                            )}
                            {!fileName && (
                                <Typography variant="h1">
                                    Add tags to the documents
                                </Typography>
                            )}

                            <IconButton
                                aria-label="close"
                                onClick={handleClose}
                                sx={{
                                    position: 'absolute',
                                    right: 24,
                                    top: 24,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <Tooltip arrow placement="top" title={`Close`}>
                                    <CloseIcon />
                                </Tooltip>
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent sx={{ paddingBottom: 0, zIndex: 9 }}>
                    {props.file && (
                        <DialogContentText>
                            <Typography
                                color="#4B4B4C"
                                mb={2}
                                className="clamp-line-1"
                                variant="caption"
                            >
                                Tags will be added to {fileName}
                            </Typography>
                        </DialogContentText>
                    )}

                    {documentContext?.filters && (
                        <Autocomplete
                            multiple
                            id="tags-filled"
                            inputValue={tagInputValue}
                            options={documentContext.filters.tags
                                .filter(
                                    (t: Tag) =>
                                        docTags.filter(
                                            (dt: FileTag) => dt.title == t.title
                                        ).length == 0
                                )
                                .map((t: Tag) => t.title)}
                            freeSolo
                            defaultValue={[
                                ...docTags.map((t: FileTag) => t.title),
                            ]}
                            value={[...docTags.map((t: FileTag) => t.title)]}
                            onChange={handleTagInput}
                            renderTags={(
                                value: readonly string[],
                                getTagProps
                            ) =>
                                value.map((option: string, index: number) => (
                                    <TagBlock
                                        variant="outlined"
                                        label={option}
                                        deleteIcon={
                                            <Tooltip
                                                arrow
                                                placement="top"
                                                title={`Remove`}
                                            >
                                                <CloseIcon />
                                            </Tooltip>
                                        }
                                        {...getTagProps({ index })}
                                    />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Add tags here..."
                                    sx={{
                                        backgroundColor: '#F9F9F9',
                                    }}
                                    onChange={(e) => {
                                        setTagInputValue(
                                            e.target.value.slice(0, 30)
                                        );
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <Button
                                                sx={{
                                                    p: 0,
                                                    fontSize: 16,
                                                }}
                                                variant="contained"
                                                disabled={!tagInputValue}
                                                onClick={addTag}
                                            >
                                                Add
                                            </Button>
                                        ),
                                    }}
                                />
                            )}
                        />
                    )}
                    {/* <Input
                        type="text"
                        mt={4}
                        autoFocus
                        value={tagInputValue}
                        backgroundColor="#EBECF6"
                        fontSize={14}
                        onChange={(e) => {
                            setTagInputValue(e.target.value.slice(0, 30));
                        }}
                        onEnterPress={addTag}
                        endAdornment={
                            <Button
                                sx={{
                                    p: 0,
                                    fontSize: 16,
                                }}
                                variant="contained"
                                disabled={!tagInputValue}
                                onClick={addTag}
                            >
                                Add
                            </Button>
                        }
                        placeholder="Add a tags here..."
                    /> */}
                    <Typography pt={2} variant="h6">
                        Suggested Tags:
                    </Typography>
                    <Box mt={2} display="flex" flexWrap="wrap">
                        {documentContext?.filters?.tags
                            .filter(
                                (t: Tag) =>
                                    docTags.filter(
                                        (dt: FileTag) => dt.title == t.title
                                    ).length == 0 &&
                                    t.title.toLowerCase() !== 'untagged'
                            )
                            .map((key) => (
                                <TagChip
                                    key={key.userTagId}
                                    onClick={() => {
                                        addTagValue(key.title);
                                    }}
                                    title={key.title}
                                />
                            ))}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Grid px={6} py={4} spacing={6} container>
                        <Grid xs={6} item>
                            <Button
                                fullWidth
                                variant="outlined"
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid xs={6} item>
                            <Button
                                fullWidth
                                variant="contained"
                                disabled={
                                    isSaving ||
                                    JSON.stringify(initTags.sort()) ==
                                        JSON.stringify(docTags.sort())
                                }
                                onClick={handleSave}
                            >
                                {isSaving ? (
                                    <CircularProgress
                                        size={25}
                                        sx={{
                                            color: '#ffffff',
                                            ml: 2,
                                        }}
                                    />
                                ) : (
                                    'Save'
                                )}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </Box>
    );
};
