import { ChangeEvent, useState } from 'react';
import { BorderedBox } from '../../../../components/core/box';
import { Box, Typography, Tabs, Tab, Switch } from '@mui/material';
import { TabPanel } from '../../../../components/core/tab-panel';
import { IOSSwitch } from '../../../../components/core/ios-switch';
import { IDiligenceTemplateConfiguration } from '../../../../services/interfaces';
interface IProps {
    config: IDiligenceTemplateConfiguration;
    updateConfig: (config: IDiligenceTemplateConfiguration) => void;
}

export const Financials = (props: IProps) => {
    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    return (
        <BorderedBox
            className="scrollable"
            sx={{
                backgroundColor: '#f9f5ff',
                p: 3,
                maxHeight: 'calc(100vh - 300px)',
                overflow: 'auto',
            }}
        >
            <Box py={1} display="flex" justifyContent="space-between">
                <Box>
                    <Typography color="#003" variant="h6">
                        Financials
                    </Typography>
                    <Typography color="#8D8D8D" fontSize={12}>
                        Comprehensive financial analysis of the company
                    </Typography>
                </Box>
                <Box></Box>
            </Box>
            <Box>
                <Box pb={1} display="flex" justifyContent="space-between">
                    <Box>
                        <Typography color="#003" variant="h6">
                            Price chart
                        </Typography>
                        <Typography color="#8D8D8D" fontSize={12}>
                            Stock price history
                        </Typography>
                    </Box>
                    <Box pt={2}>
                        <IOSSwitch
                            onChange={(
                                event: ChangeEvent<HTMLInputElement>,
                                checked: boolean
                            ) => {
                                props.updateConfig({
                                    ...props.config,
                                    sectionTemplate: {
                                        ...props.config.sectionTemplate,
                                        enablePriceChart: checked,
                                    },
                                });
                            }}
                            checked={
                                !!props.config.sectionTemplate.enablePriceChart
                            }
                            color="success"
                        />
                    </Box>
                </Box>
                <Box pb={1} display="flex" justifyContent="space-between">
                    <Box>
                        <Typography color="#003" variant="h6">
                            Financial Statements
                        </Typography>
                        <Typography color="#8D8D8D" fontSize={12}>
                            Financial reports, such as the Income Statement,
                            Balance Sheet, and Cash Flow Statement
                        </Typography>
                    </Box>
                    <Box pt={2}>
                        <IOSSwitch
                            onChange={(
                                event: ChangeEvent<HTMLInputElement>,
                                checked: boolean
                            ) => {
                                props.updateConfig({
                                    ...props.config,
                                    sectionTemplate: {
                                        ...props.config.sectionTemplate,
                                        enableFinancialStatements: checked,
                                    },
                                });
                            }}
                            checked={
                                !!props.config.sectionTemplate
                                    .enableFinancialStatements
                            }
                            color="success"
                        />
                    </Box>
                </Box>

                <Box pb={1} display="flex" justifyContent="space-between">
                    <Box>
                        <Typography color="#003" variant="h6">
                            Analyst Estimates
                        </Typography>
                        <Typography color="#8D8D8D" fontSize={12}>
                            Comprehensive Insights from Analyst Projections and
                            Market Performance Trends
                        </Typography>
                    </Box>
                    <Box pt={2}>
                        <IOSSwitch
                            onChange={(
                                event: ChangeEvent<HTMLInputElement>,
                                checked: boolean
                            ) => {
                                props.updateConfig({
                                    ...props.config,
                                    sectionTemplate: {
                                        ...props.config.sectionTemplate,
                                        enableAnalystEstimates: checked,
                                    },
                                });
                            }}
                            checked={
                                !!props.config.sectionTemplate
                                    .enableAnalystEstimates
                            }
                            color="success"
                        />
                    </Box>
                </Box>
                <Box pb={1} display="flex" justifyContent="space-between">
                    <Box>
                        <Typography color="#003" variant="h6">
                            Financials trends
                        </Typography>
                        <Typography color="#8D8D8D" fontSize={12}>
                            Revenue and earnings trends
                        </Typography>
                    </Box>
                    <Box pt={2}>
                        <IOSSwitch
                            onChange={(
                                event: ChangeEvent<HTMLInputElement>,
                                checked: boolean
                            ) => {
                                props.updateConfig({
                                    ...props.config,
                                    sectionTemplate: {
                                        ...props.config.sectionTemplate,
                                        enableFinancialTrends: checked,
                                    },
                                });
                            }}
                            checked={
                                !!props.config.sectionTemplate
                                    .enableFinancialTrends
                            }
                            color="success"
                        />
                    </Box>
                </Box>
                <Box pb={1} display="flex" justifyContent="space-between">
                    <Box>
                        <Typography color="#003" variant="h6">
                            Key ratios
                        </Typography>
                        <Typography color="#8D8D8D" fontSize={12}>
                            Key financial metrics
                        </Typography>
                    </Box>
                    <Box pt={2}>
                        <IOSSwitch
                            onChange={(
                                event: ChangeEvent<HTMLInputElement>,
                                checked: boolean
                            ) => {
                                props.updateConfig({
                                    ...props.config,
                                    sectionTemplate: {
                                        ...props.config.sectionTemplate,
                                        enableKeyRatios: checked,
                                    },
                                });
                            }}
                            checked={
                                !!props.config.sectionTemplate.enableKeyRatios
                            }
                            color="success"
                        />
                    </Box>
                </Box>
                <Box pb={1} display="flex" justifyContent="space-between">
                    <Box>
                        <Typography color="#003" variant="h6">
                            Institutional ownership
                        </Typography>
                        <Typography color="#8D8D8D" fontSize={12}>
                            Which institutions have stakes in the company
                        </Typography>
                    </Box>
                    <Box pt={2}>
                        <IOSSwitch
                            onChange={(
                                event: ChangeEvent<HTMLInputElement>,
                                checked: boolean
                            ) => {
                                props.updateConfig({
                                    ...props.config,
                                    sectionTemplate: {
                                        ...props.config.sectionTemplate,
                                        enableInstituteOwnership: checked,
                                    },
                                });
                            }}
                            checked={
                                !!props.config.sectionTemplate
                                    .enableInstituteOwnership
                            }
                            color="success"
                        />
                    </Box>
                </Box>
                <Box pb={1} display="flex" justifyContent="space-between">
                    <Box>
                        <Typography color="#003" variant="h6">
                            Insider trading
                        </Typography>
                        <Typography color="#8D8D8D" fontSize={12}>
                            Insider trading history
                        </Typography>
                    </Box>
                    <Box pt={2}>
                        <IOSSwitch
                            onChange={(
                                event: ChangeEvent<HTMLInputElement>,
                                checked: boolean
                            ) => {
                                props.updateConfig({
                                    ...props.config,
                                    sectionTemplate: {
                                        ...props.config.sectionTemplate,
                                        enableInsiderTrading: checked,
                                    },
                                });
                            }}
                            checked={
                                !!props.config.sectionTemplate
                                    .enableInsiderTrading
                            }
                            color="success"
                        />
                    </Box>
                </Box>
                <Box pb={1} display="flex" justifyContent="space-between">
                    <Box>
                        <Typography color="#003" variant="h6">
                            Investments
                        </Typography>
                        <Typography color="#8D8D8D" fontSize={12}>
                            Who has the company invested in, and how much
                        </Typography>
                    </Box>
                    <Box pt={2}>
                        <IOSSwitch
                            onChange={(
                                event: ChangeEvent<HTMLInputElement>,
                                checked: boolean
                            ) => {
                                props.updateConfig({
                                    ...props.config,
                                    sectionTemplate: {
                                        ...props.config.sectionTemplate,
                                        enableInvestments: checked,
                                    },
                                });
                            }}
                            checked={
                                !!props.config.sectionTemplate.enableInvestments
                            }
                            color="success"
                        />
                    </Box>
                </Box>
                <Box pb={1} display="flex" justifyContent="space-between">
                    <Box>
                        <Typography color="#003" variant="h6">
                            Acquisitions
                        </Typography>
                        <Typography color="#8D8D8D" fontSize={12}>
                            Who has the company acquired
                        </Typography>
                    </Box>
                    <Box pt={2}>
                        <IOSSwitch
                            onChange={(
                                event: ChangeEvent<HTMLInputElement>,
                                checked: boolean
                            ) => {
                                props.updateConfig({
                                    ...props.config,
                                    sectionTemplate: {
                                        ...props.config.sectionTemplate,
                                        enableAcquisitions: checked,
                                    },
                                });
                            }}
                            checked={
                                !!props.config.sectionTemplate
                                    .enableAcquisitions
                            }
                            color="success"
                        />
                    </Box>
                </Box>
                <Box pb={1} display="flex" justifyContent="space-between">
                    <Box>
                        <Typography color="#003" variant="h6">
                            Funding rounds
                        </Typography>
                        <Typography color="#8D8D8D" fontSize={12}>
                            Fundraising history
                        </Typography>
                    </Box>
                    <Box pt={2}>
                        <IOSSwitch
                            onChange={(
                                event: ChangeEvent<HTMLInputElement>,
                                checked: boolean
                            ) => {
                                props.updateConfig({
                                    ...props.config,
                                    sectionTemplate: {
                                        ...props.config.sectionTemplate,
                                        enableFundingRounds: checked,
                                    },
                                });
                            }}
                            checked={
                                !!props.config.sectionTemplate
                                    .enableFundingRounds
                            }
                            color="success"
                        />
                    </Box>
                </Box>
                <Box pb={1} display="flex" justifyContent="space-between">
                    <Box>
                        <Typography color="#003" variant="h6">
                            Investors
                        </Typography>
                        <Typography color="#8D8D8D" fontSize={12}>
                            Who are their investors
                        </Typography>
                    </Box>
                    <Box pt={2}>
                        <IOSSwitch
                            onChange={(
                                event: ChangeEvent<HTMLInputElement>,
                                checked: boolean
                            ) => {
                                props.updateConfig({
                                    ...props.config,
                                    sectionTemplate: {
                                        ...props.config.sectionTemplate,
                                        enableInvestors: checked,
                                    },
                                });
                            }}
                            checked={
                                !!props.config.sectionTemplate.enableInvestors
                            }
                            color="success"
                        />
                    </Box>
                </Box>
            </Box>
        </BorderedBox>
    );
};
