import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import ThemeCustomization from './themes';
import AppProvider from './providers/app-provider';
import { SnackbarProvider } from 'notistack';
import { StepType, TourProvider, useTour } from '@reactour/tour';
import { MaterialDesignContent } from 'notistack';
import { styled } from '@mui/material';
import * as Sentry from '@sentry/react';
import { init } from './analytics';
import { BrowserTracing } from '@sentry/tracing';
import OneSignal from 'react-onesignal';
//@ts-ignore
import { beamerInitialize } from 'react-beamer';
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from 'react-router-dom';

import { PermissionsProvider } from './providers/permissions-provider';
import Mask from '@reactour/mask';
import { SupportChat } from './components/support-chat';
const { REACT_APP_BEAMER_PRODUCT_ID, REACT_APP_ONESIGNAL_APP_ID } = process.env;

OneSignal.init({
    appId: REACT_APP_ONESIGNAL_APP_ID as string,
    allowLocalhostAsSecureOrigin: true,
});

beamerInitialize(REACT_APP_BEAMER_PRODUCT_ID, undefined, {
    selector: '.beamer',
    callback: (unreadCount: number) => {
        console.log(unreadCount);
        //@ts-ignore
        window.unreadCount = unreadCount;
    },
});
// markerSDK.loadWidget({
//     project: '6421c40d004294272a5fd1c4',
// });

init();

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    '&.notistack-MuiContent-default': {
        backgroundColor: '#6e3cfb',
    },
}));

const steps: StepType[] = [
    {
        selector: '#app-menu', // Assuming the sidebar has a class 'sidebar'
        content:
            'This is the sidebar where you can navigate between different sections such as  Photon Live, DocuInsights, Photon Chat and Diligence Suite.',
        padding: 0,
        position: 'right',
    },
    {
        selector: '#updates', // Assuming the account section has a class 'account-section'
        content: 'Here you can check for product updates',
        padding: 0,
        position: 'right',
    },
    {
        selector: '#account', // Assuming the account section has a class 'account-section'
        content: 'Here you can manage your account settings.',
        padding: 0,
        position: 'right',
    },
    {
        selector: '#search-box', // Assuming the search box has an ID 'search-box'
        content:
            'Use this search box to ask any question or search for a topic to obtain relevant insights.',
        padding: 0,
        position: 'right',
    },
    {
        selector: '#settings-button', // Assuming the settings button has an ID 'settings-button'
        content:
            'Click here to adjust your search settings like data sources, timeframes, and AI models.',
        padding: 0,
        position: 'right',
    },
    {
        selector: '#questions-section', // Assuming the questions section has an ID 'questions-section'
        content:
            'In this section, you can see some suggested questions you might want to ask.',
        padding: 0,
        position: 'right',
    },
    {
        selector: '#topics-section', // Assuming the high-level topics section has an ID 'topics-section'
        content:
            'This section contains high-level topics that you can explore.',
        padding: 0,
        position: 'right',
    },
    {
        selector: '#recent-searches', // Assuming the recent searches section has an ID 'recent-searches'
        content: 'Here you can find your recent searches for quick access.',
        padding: 0,
        position: 'right',
    },
    {
        selector: '#help-enter', // Assuming the account avatar has an ID 'account-avatar'
        content: 'Click here to view tutorials on the feature',
        padding: 0,
        position: 'left',
        //@ts-ignore
        action: ({ close }) => (
            <button onClick={close} style={{ marginTop: '10px' }}>
                End Tour
            </button>
        ),
    },
];

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
        new BrowserTracing({
            tracingOrigins: [
                'api.photoninsights.com',
                'dev-api.photoninsights.com',
            ],
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes
            ),
        }),
    ],

    tracesSampleRate: 1.0,
});
const sizes = {
    width: 100,
    height: 100,
    top: 100,
    left: 100,
};

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <ThemeCustomization>
        <TourProvider
            styles={{
                badge: (base) => ({
                    ...base,
                    right: '-13px', // Adjust as needed
                    left: 'auto', // Override the default left positioning
                }),
            }}
            scrollSmooth
            steps={steps}
        >
            <SnackbarProvider
                Components={{
                    default: StyledMaterialDesignContent,
                }}
                maxSnack={3}
                autoHideDuration={3000}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <AppProvider>
                    <App />
                </AppProvider>
            </SnackbarProvider>
        </TourProvider>
    </ThemeCustomization>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
