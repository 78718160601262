interface IProps {
    size?: number;
    color?: string;
}

export const Tag = (props: IProps) => (
    <svg
        width={props.size || 12}
        height={props.size || 12}
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M11.5383 6.24282L6.88266 1.58719C6.79586 1.49982 6.69257 1.43055 6.57879 1.38339C6.46502 1.33624 6.34301 1.31214 6.21985 1.3125H1.875C1.72582 1.3125 1.58275 1.37177 1.47726 1.47726C1.37177 1.58275 1.3125 1.72582 1.3125 1.875V6.21985C1.31214 6.34301 1.33624 6.46502 1.38339 6.57879C1.43055 6.69257 1.49982 6.79586 1.58719 6.88266L6.24282 11.5383C6.41862 11.714 6.65704 11.8128 6.90563 11.8128C7.15422 11.8128 7.39263 11.714 7.56844 11.5383L11.5383 7.56844C11.714 7.39263 11.8128 7.15422 11.8128 6.90563C11.8128 6.65704 11.714 6.41862 11.5383 6.24282ZM6.90563 10.6102L2.4375 6.14063V2.4375H6.14063L10.6088 6.90563L6.90563 10.6102ZM4.6875 3.9375C4.6875 4.08584 4.64352 4.23084 4.56111 4.35418C4.47869 4.47752 4.36156 4.57365 4.22452 4.63041C4.08747 4.68718 3.93667 4.70203 3.79119 4.67309C3.6457 4.64415 3.51206 4.57272 3.40717 4.46783C3.30228 4.36294 3.23085 4.22931 3.20191 4.08382C3.17298 3.93834 3.18783 3.78754 3.24459 3.65049C3.30136 3.51345 3.39749 3.39631 3.52083 3.3139C3.64416 3.23149 3.78917 3.1875 3.9375 3.1875C4.13642 3.1875 4.32718 3.26652 4.46783 3.40717C4.60849 3.54783 4.6875 3.73859 4.6875 3.9375Z"
            fill={props.color || '#6e3cfb'}
        />
    </svg>
);
