import {
    Box,
    Table,
    TableBody,
    Button,
    Divider,
    Grid,
    Tooltip,
    Typography,
    TableRow,
    TableCell,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import GoogleTrends from '../../../components/widgets/google-trends';
import {
    FinancialInfoData,
    FinancialInfoDataResults,
    GooglePatentsData,
    GooglePatentsDataResult,
    GoogleScholarData,
    GoogleScholarDataResult,
    IQueryEntitiesesponseData,
    LiveInsightsQueryResponse,
    LiveQueryResponseDataTrends,
} from '../../../services/interfaces';
import { LocationIcon } from '../../../components/icons/location';
import { OrganizationIcon } from '../../../components/icons/organization';
import { Info } from '../../../components/icons/info';
import { useSearchParams } from 'react-router-dom';
import { useChatbotService } from '../../../services/chatbot.service';
import {
    BorderedBox,
    BorderedContainedBox,
} from '../../../components/core/box';
import { Financialtrends } from '../../company-analysis/company-details/financial-trends';
import { UtilsService } from '../../../services/utils.service';
import { Matrix } from '../../company-analysis/company-details/matrix';
import { Ownership } from '../../company-analysis/company-details/ownership';
import { NoDataImage } from '../../../components/images/no-data';
import { NavigationLink } from '../../../components/core/link';
import { Circle } from '../../../components/icons/circle';
import { AppContext } from '../../../providers/app-provider';

interface IProps {
    entities: IQueryEntitiesesponseData[];
    onFetched: () => void;
    query?: string;
}

export const GooglePatents = (props: IProps) => {
    const appContext = useContext(AppContext);
    const [googlePatentData, setGooglepatentData] = useState<
        GooglePatentsDataResult[]
    >([]);
    const [streamFinished, setStreamFinished] = useState(true);
    const [selectedEntity, setSelectedEntity] =
        useState<FinancialInfoDataResults | null>(null);
    const [searchParam] = useSearchParams();
    const { fetchChatbotGooglePatents } = useChatbotService();
    useEffect(() => {
        refreshScholarData();
    }, [props.query, searchParam.get('query'), props.entities]);

    const refreshScholarData = async () => {
        let message = props.query || searchParam.get('query');
        if (!message) return;
        await fetchChatbotGooglePatents(
            message,
            props.entities,
            (data: string) => {
                if (data) {
                    processQueryResponse(data.split('\n'));
                }
            },
            true
        );
    };

    const processQueryResponse = (responses: string[]) => {
        setStreamFinished(false);
        for (let res of responses) {
            try {
                let parsedRes: LiveInsightsQueryResponse = JSON.parse(res);
                switch (parsedRes.chunk) {
                    case 'GOOGLE_PATENTS':
                        let data = (parsedRes.data as GooglePatentsData)
                            .results;
                        setGooglepatentData(data);
                        break;
                    case 'TERMINATION':
                        setStreamFinished(true);
                        props.onFetched();
                        break;
                }
            } catch (error) {}
        }
    };

    return (
        <Box>
            <Box
                px={6}
                sx={{
                    maxHeight: `calc(100vh - ${
                        appContext?.accountActionBannerVisible ? '22px' : '0px'
                    } - 255px)`,
                    overflowY: 'auto',
                }}
            >
                {googlePatentData.length == 0 && (
                    <Box textAlign={'center'} px={4} py={20}>
                        <Box>{NoDataImage}</Box>
                        <br />
                        <Typography variant="h4">
                            Sorry, No data available
                        </Typography>
                        <Typography fontSize={14} fontWeight={400} mt={2}>
                            No data available for this entities. Please try with
                            some different query
                        </Typography>
                    </Box>
                )}
                <Box py={6}>
                    <Grid spacing={4} container>
                        {googlePatentData.map((d) => (
                            <Grid item sm={3}>
                                <BorderedBox
                                    p={3}
                                    sx={{
                                        background:
                                            'linear-gradient(127deg, #f9f5ff 0.74%, rgba(249, 249, 255, 0.00) 99.23%)',
                                        '&:hover': {
                                            boxShadow:
                                                '0px 2px 4px 0px rgba(0, 0, 0, 0.15)',
                                        },
                                    }}
                                >
                                    <Typography variant="h6">
                                        {d.publicationNumber}
                                    </Typography>
                                    <Typography
                                        mt={3}
                                        component={NavigationLink}
                                        to={d.url}
                                        lineHeight="normal"
                                        target="_blank"
                                        color="#6e3cfb"
                                        variant="subtitle2"
                                    >
                                        {d.title}
                                    </Typography>
                                    <Typography
                                        mt={2}
                                        fontSize={14}
                                        fontWeight={600}
                                    >
                                        Inventor
                                    </Typography>
                                    <Typography
                                        color="#4B4B4C"
                                        variant="subtitle2"
                                    >
                                        {d.inventor}
                                    </Typography>
                                    <Typography
                                        mt={2}
                                        fontSize={14}
                                        fontWeight={600}
                                    >
                                        Worldwide Applications
                                    </Typography>
                                    <Box display="flex" flexWrap="wrap">
                                        {d.countryStatus.map((c, index) => (
                                            <Box
                                                display="flex"
                                                component="span"
                                            >
                                                {index > 0 && (
                                                    <Circle
                                                        mt={5}
                                                        h={8}
                                                        mx={4}
                                                        w={8}
                                                    />
                                                )}
                                                <Typography
                                                    variant="subtitle2"
                                                    color="#4B4B4C"
                                                >
                                                    {c.countryCode.toUpperCase()}
                                                </Typography>
                                            </Box>
                                        ))}
                                    </Box>
                                    <Typography
                                        mt={2}
                                        fontSize={14}
                                        fontWeight={600}
                                    >
                                        Application Events
                                    </Typography>
                                    <Box mt={1}>
                                        <Table size="small">
                                            <TableBody>
                                                {[
                                                    {
                                                        date: d.priorityDate,
                                                        event: 'Application Prioritized',
                                                    },
                                                    {
                                                        date: d.filingDate,
                                                        event: 'Application Filed',
                                                    },
                                                    {
                                                        date: d.publicationDate,
                                                        event: 'Patent Published',
                                                    },
                                                ].map((row) => (
                                                    <TableRow
                                                        sx={{
                                                            marginTop: '4px',
                                                            '& td, & th': {
                                                                border: 0,
                                                                padding: 0,
                                                            },
                                                        }}
                                                    >
                                                        <TableCell
                                                            sx={{
                                                                width: '95px',
                                                            }}
                                                        >
                                                            <Typography
                                                                variant="subtitle2"
                                                                color="#4B4B4C"
                                                            >
                                                                {UtilsService.formatStringDate(
                                                                    row.date
                                                                )}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Circle
                                                                mt={5}
                                                                h={8}
                                                                mx={4}
                                                                w={8}
                                                            />{' '}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography
                                                                variant="subtitle2"
                                                                color="#4B4B4C"
                                                            >
                                                                {row.event}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Box>
                                </BorderedBox>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
};
