import { Box, Divider, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BorderedBox } from '../../../../components/core/box';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useParams } from 'react-router-dom';
import { useFinancialsService } from '../../../../services/financials.service';
import {
    FinancialOwnershipResponseData,
    InstituteOwnerShip,
    InstitutionOwnership,
    ReportModule,
} from '../../../../services/interfaces';
import { UtilsService } from '../../../../services/utils.service';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        padding: '10px 16px',
        borderBottom: 'none',
        textAlign: 'center',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        border: 'none',
        padding: '10px 16px',
        textAlign: 'center',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: '#f9f5ff',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

interface IProps {
    data: ReportModule;
}

export const InstitutionalOwnership = (props: IProps) => {
    let { topicSubscriptionId } = useParams();
    const { fetchFinancialOwnership } = useFinancialsService();
    const [data, setData] = useState<InstituteOwnerShip[] | null>(null);

    useEffect(() => {
        if (props.data.data?.yahoo?.coreFinancials?.instituteOwnerShip) {
            setData(props.data.data?.yahoo.coreFinancials.instituteOwnerShip);
        }
    }, [data]);

    return (
        <BorderedBox sx={{ backgroundColor: '#FFFFFF' }} pt={3}>
            <Box>
                <Typography px={4} variant="h6">
                    Institution Ownership
                </Typography>
                <Box pt={2}>
                    <Divider />
                </Box>
                <Box pb={3}>
                    {' '}
                    <TableContainer>
                        <Table
                            sx={{ minWidth: 700 }}
                            aria-label="customized table"
                        >
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell
                                        sx={{ textAlign: 'left !important' }}
                                    >
                                        Organization
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        % Held
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        Shares
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        Value
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        Report Date
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data?.map(
                                    (row: InstitutionOwnership, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell
                                                component="th"
                                                scope="row"
                                                sx={{
                                                    textAlign:
                                                        'left !important',
                                                }}
                                            >
                                                {row.organization}
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                {(row.pctHeld * 100).toFixed(2)}
                                                %
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                {row.position.toLocaleString(
                                                    'en',
                                                    {
                                                        minimumFractionDigits: 0,
                                                    }
                                                )}
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                {row.value.toLocaleString(
                                                    'en',
                                                    {
                                                        minimumFractionDigits: 0,
                                                    }
                                                )}
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                {UtilsService.formatStringDate(
                                                    row.reportDate
                                                )}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </BorderedBox>
    );
};
