interface IProps {
    size?: number;
    color?: string;
}

export const Close = (props: IProps) => {
    return (
        <svg
            width={props.size ? props.size : '24'}
            height={props.size ? props.size : '24'}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5 5L19 19M5.00003 19L12 12L19 5"
                stroke={props.color || '#6e3cfb'}
                stroke-width="1.5"
                stroke-linecap="round"
            />
        </svg>
    );
};
