import { Box, Divider, Skeleton, Typography } from '@mui/material';
import { LiveQueryResponseDataSourcesResult } from '../../../services/interfaces';
import { UtilsService } from '../../../services/utils.service';
import { NavigationLink } from '../../../components/core/link';
import { useSearchParams } from 'react-router-dom';

interface IProps {
    headlines: LiveQueryResponseDataSourcesResult[] | null;
}

export const RelatedHeadlines = (props: IProps) => {
    const [searchParam] = useSearchParams();
    return (
        <Box
            sx={{
                backgroundColor: '#FFFFFF',
            }}
            pb={6}
            pl={2}
        >
            <Box pl={2} pr={1} justifyContent="space-between" display={'flex'}>
                <Typography variant="h6">Related Sources</Typography>
            </Box>
            <Box mt={3}>
                {!props.headlines &&
                    Array.from(Array(5).keys()).map((index) => (
                        <Box>
                            <Box px={2} display="flex">
                                <Box>
                                    <Typography
                                        lineHeight={'16px'}
                                        className="clamp-line-2"
                                        variant="subtitle2"
                                        sx={{
                                            width: 'max-content',
                                        }}
                                    >
                                        {`${index + 1}.`}
                                    </Typography>
                                </Box>
                                <Box width={'calc(100% - 10px)'} pl={1}>
                                    <Typography
                                        lineHeight={'16px'}
                                        className="clamp-line-2"
                                        variant="subtitle2"
                                    >
                                        <Skeleton variant="text" />
                                    </Typography>

                                    <Box pr={1} width={'100%'} display="flex">
                                        <Box width={'100%'}>
                                            <Typography
                                                fontSize={12}
                                                fontWeight={400}
                                                className="clamp-line-2"
                                            >
                                                <Skeleton variant="text" />
                                                <Skeleton variant="text" />
                                            </Typography>
                                            <Box
                                                mt={1}
                                                justifyContent={'space-between'}
                                                display="flex"
                                            >
                                                <Box display="flex">
                                                    <Box mr={1} width={20}>
                                                        <Skeleton variant="rectangular" />
                                                    </Box>

                                                    <Typography
                                                        color="#4B4B4C"
                                                        variant="caption"
                                                    >
                                                        <Skeleton
                                                            width={120}
                                                            variant="text"
                                                        />
                                                    </Typography>
                                                </Box>
                                                <Typography
                                                    color="#4B4B4C"
                                                    variant="caption"
                                                >
                                                    <Skeleton
                                                        width={120}
                                                        variant="text"
                                                    />
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box py={2}>
                                <Divider />
                            </Box>
                        </Box>
                    ))}
                {props.headlines?.map(
                    (d: LiveQueryResponseDataSourcesResult, index: number) => {
                        return (
                            <Box
                                width={'100%'}
                                id={`related-headlines-${index}`}
                                px={2}
                                mt={3}
                                sx={{
                                    '&:hover': {
                                        backgroundColor: '#f9f5ff',
                                    },
                                }}
                            >
                                <Box display="flex" textAlign={'left'}>
                                    <Box>
                                        <Typography
                                            lineHeight={'20px'}
                                            className="clamp-line-2"
                                            variant="h6"
                                            sx={{
                                                width: 'max-content',
                                            }}
                                        >
                                            {`${index + 1}.`}
                                        </Typography>
                                    </Box>
                                    <Box pl={1}>
                                        <NavigationLink
                                            to={d.url}
                                            target="_blank"
                                        >
                                            <Typography
                                                lineHeight={'20px'}
                                                className="clamp-line-2"
                                                variant="h6"
                                                color={'#6e3cfb'}
                                            >
                                                {d.title}
                                            </Typography>
                                        </NavigationLink>
                                        <Box
                                            mt={2}
                                            pr={1}
                                            width={'100%'}
                                            display="flex"
                                        >
                                            <Box width={'100%'}>
                                                {d.article ? (
                                                    <Typography
                                                        fontSize={14}
                                                        fontWeight={400}
                                                        className="clamp-line-2"
                                                    >
                                                        {d.article}
                                                    </Typography>
                                                ) : (
                                                    <Typography
                                                        fontSize={12}
                                                        fontWeight={400}
                                                        className="clamp-line-3"
                                                    >
                                                        <Skeleton variant="text" />
                                                        <Skeleton variant="text" />
                                                    </Typography>
                                                )}
                                                <Box
                                                    mt={2}
                                                    justifyContent={
                                                        'space-between'
                                                    }
                                                    display="flex"
                                                >
                                                    <Box
                                                        display="flex"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        lineHeight="12px"
                                                    >
                                                        <Box mr={2}>
                                                            <img
                                                                width={12}
                                                                src={d.icon}
                                                                alt="source"
                                                            />
                                                        </Box>
                                                        <Typography
                                                            color="#4B4B4C"
                                                            variant="caption"
                                                        >
                                                            {d.source}
                                                        </Typography>
                                                    </Box>
                                                    <Typography
                                                        color="#4B4B4C"
                                                        variant="caption"
                                                    >
                                                        {UtilsService.formatStringDateInMDYFormat(
                                                            d.datetime
                                                        )}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Divider sx={{ marginTop: '8px' }} />
                            </Box>
                        );
                    }
                )}
            </Box>
        </Box>
    );
};
