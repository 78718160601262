import {
    Box,
    Button,
    Divider,
    Grid,
    Skeleton,
    Tab,
    TableBody,
    TableContainer,
    TableHead,
    Tabs,
    Tooltip,
    Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import {
    IQueryEntitiesesponseData,
    LiveSentimentsResponse,
    IRecentHeadlines,
    PotentialSentiment,
} from '../../../services/interfaces';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useSearchParams } from 'react-router-dom';
import { useChatbotService } from '../../../services/chatbot.service';
import { BorderedBox, ContainedBox } from '../../../components/core/box';
import { UtilsService } from '../../../services/utils.service';
import {
    BarLineChart,
    BarLineChartProps,
} from '../../../components/charts/bar-line-chart';
import { Circle } from '../../../components/icons/circle';
import { NavigationLink } from '../../../components/core/link';
import { TableBodyRow } from '../../../components/core/table/common/row';
import { TableHeadCell } from '../../../components/core/table/comparison/head-cell';
import { TableHeadRow } from '../../../components/core/table/comparison/head-row';
import { TableNegativeCell } from '../../../components/core/table/comparison/negative-cell';
import { TablePositiveCell } from '../../../components/core/table/comparison/positive-cell';
import { ComparisonTableWrapper } from '../../../components/core/table/comparison/table';
import { TextHighlight } from '../../../components/sentiment-highlights';
import { data } from './search-trends';
import { Info } from '../../../components/icons/info';
import { NewTab } from '../../../components/icons/new-tab';
import { Subscribe } from '../../../utils/subscribe';
import { BankOutlined } from '../../../components/icons/bank-outlined';
import { SourceSelection } from '../../../components/core/source-selection';
import { DateInput } from '../../../components/date-range-input';
import { TopicSourceDistribution } from '../../research/insights/sentiment-analysis/source-distribution';
import { RadialChart } from '../../../components/charts/radial';
import { SearchImage } from '../../../components/icons/search-image';
import { TabPanel } from '../../../components/core/tab-panel';
import { SectionPercentage } from '../../topic/overview';
import TopicSentiments from '../insights/sentiment-analysis';
import { AppContext } from '../../../providers/app-provider';

interface IEntityArticleDataProps {
    d: IRecentHeadlines | null;
}

export const EntityArticleData = (props: IEntityArticleDataProps) => {
    const [expand, setExpand] = useState(false);
    const { d } = props;
    return (
        <Box width={'100%'}>
            {d && (
                <Typography
                    fontSize={14}
                    fontWeight={400}
                    className={expand ? '' : ''}
                >
                    {d.article || d.text}
                </Typography>
            )}
            {!(d?.article || d?.text) && (
                <Box>
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                </Box>
            )}
            <Box mt={1} justifyContent={'space-between'} display="flex">
                {
                    <Box height={16} display="flex">
                        {d && (
                            <Typography
                                className=""
                                fontSize={12}
                                fontWeight={400}
                            >
                                <img
                                    style={{
                                        maxWidth: 14,
                                        maxHeight: 14,
                                        marginRight: 4,
                                        paddingTop: 2,
                                    }}
                                    src={d.icon}
                                />{' '}
                                {d.source}
                                <Circle mt={8} mx={8} h={8} w={8} />{' '}
                                {UtilsService.formatStringDateInMDYFormat(
                                    d.datetime
                                )}
                            </Typography>
                        )}
                    </Box>
                }
                {/* {(d?.article || d?.text) && (
                    <Button
                        sx={{ py: 0 }}
                        onClick={() => {
                            setExpand(!expand);
                        }}
                        variant="text"
                    >
                        See {expand ? 'Less' : 'More'}{' '}
                        {expand ? <ExpandLess /> : <ExpandMore />}
                    </Button>
                )} */}
            </Box>
        </Box>
    );
};

interface IProps {
    entities: IQueryEntitiesesponseData[];
    onFetched: () => void;
    topicId?: string;
    query?: string;
}

export const Sentiments = (props: IProps) => {
    const appContext = useContext(AppContext);
    const [sentimentsData, setSentimentssData] =
        useState<LiveSentimentsResponse | null>(null);
    const [chartData, setChartData] = useState<BarLineChartProps | null>(null);
    const [selectedDate, setSelectedDate] = useState<string>('');
    const [value, setValue] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if (typeof newValue != 'number') return;
        setValue(newValue);
    };
    const [potentialIndicators, setPotentialIndicators] = useState<
        IRecentHeadlines[][]
    >([]);
    const [searchParam] = useSearchParams();
    const { fetchLiveInsightsSentiments } = useChatbotService();
    useEffect(() => {
        if (!props.topicId) {
            refreshSentimentsData();
        } else {
            props.onFetched();
        }
    }, [props.entities, props.query, props.topicId]);

    const refreshSentimentsData = async () => {
        let message = props.query || searchParam.get('query');
        if (!message) return;
        let response = await fetchLiveInsightsSentiments(
            message,
            props.entities,
            true
        );
        setSentimentssData(response);
        props.onFetched();
    };

    useEffect(() => {
        if (!sentimentsData) return;
        const positiveSeries =
            sentimentsData?.results.map((dateDetails) =>
                UtilsService.average(
                    dateDetails.searchResults.map(
                        (d) => d.sentiments?.positive || 0
                    )
                )
            ) || [];
        const negativeSeries =
            sentimentsData?.results.map(
                (dateDetails) =>
                    -1 *
                    UtilsService.average(
                        dateDetails.searchResults.map(
                            (d) => d.sentiments?.negative || 0
                        )
                    )
            ) || [];

        const labels =
            sentimentsData?.results.map((dateDetails) => dateDetails.date) ||
            [];

        let sentimentData: BarLineChartProps = {
            series: [
                {
                    name: 'Positive',
                    data: positiveSeries,
                },
                {
                    name: 'Negative',
                    data: negativeSeries,
                },
            ],
            height: 400,
            labels: labels,
        };
        setChartData({ ...sentimentData });
        let pData: IRecentHeadlines[][] = [];
        let flatHeadlines = sentimentsData.results
            .map((d) => d.searchResults)
            .reduce<IRecentHeadlines[]>(
                (accumulator, value) => accumulator.concat(value),
                []
            );
        flatHeadlines
            .sort((a, b) =>
                a.sentiments!.positive < b.sentiments!.positive ? 1 : -1
            )
            .forEach((element, index) => {
                if (index < 3) {
                    pData.push([element]);
                }
            });
        flatHeadlines
            .sort((a, b) =>
                a.sentiments!.negative < b.sentiments!.negative ? 1 : -1
            )
            .forEach((element, index) => {
                if (index < 3) {
                    if (!pData[index]) {
                        pData.push([]);
                    }
                    pData[index][1] = element;
                }
            });
        setPotentialIndicators(pData);
        if (labels.length) setSelectedDate(labels[labels.length - 1]);
        let datesWithData = sentimentsData.results.filter(
            (r) => r.searchResults.length > 0
        );
        if (datesWithData.length) {
            setSelectedDate(datesWithData.slice(-1)[0].date);
        }
    }, [sentimentsData]);

    const sentimentChartBarClick = (date: string) => {
        setSelectedDate(date);
    };

    return (
        <Box>
            <Box
                sx={{
                    maxHeight: `calc(100vh - ${
                        appContext?.accountActionBannerVisible ? '22px' : '0px'
                    } - 255px)`,
                    overflowY: 'auto',
                }}
            >
                {!(
                    sentimentsData?.topicSubscription?.topicDetails
                        ?.topicSubscriptionId || props.topicId
                ) && (
                    <Box>
                        {/* <Box sx={{ pb: 4 }}>
                            <Grid justifyContent="space-between" container>
                                <Grid textAlign="left" item>
                                    <Typography variant="h1">
                                        Overall Assessment
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        {UtilsService.formatDate(
                                            UtilsService.getDateWeeksAgo(2)
                                        )}{' '}
                                        - {UtilsService.formatDate(new Date())}{' '}
                                    </Typography>
                                </Grid>
                                <Grid
                                    sx={{ pt: 4 }}
                                    spacing={2}
                                    display="flex"
                                    item
                                >
                                    <Box mt={-3.5} mr={2}>
                                        <DateInput disabled />
                                    </Box>
                                    <Box mt={-3.5} mr={2}>
                                        <SourceSelection
                                            lockedCategories={[
                                                'CAT961178861612681037',
                                                'CAT381498563573732878',
                                                'CAT630686379050514716',
                                            ]}
                                        />
                                    </Box>
                                    <Box>
                                        <TopicSourceDistribution
                                            largeButton={true}
                                            query={props.query}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box position="relative">
                            {props.query && (
                                <Subscribe
                                    wideBox={true}
                                    message="Subscribe to view all the assessment and analysis"
                                    query={props.query}
                                />
                            )}
                            <BorderedBox>
                                <Grid
                                    sx={{
                                        backgroundColor: '#f9f5ff',
                                        borderTopLeftRadius: 8,
                                        borderTopRightRadius: 8,
                                    }}
                                    justifyContent="space-between"
                                    textAlign="left"
                                    container
                                >
                                    <Grid p={4} pb={2} xs={12} sm={4} item>
                                        <Grid container>
                                            <Grid item>
                                                <Typography variant="h4">
                                                    Potential Positive
                                                </Typography>

                                                <SectionPercentage
                                                    sx={{ color: '#19AF55' }}
                                                >
                                                    {UtilsService.convertSentimentToPercentage(
                                                        0.58
                                                    )}
                                                    %
                                                </SectionPercentage>

                                                <Typography
                                                    color="#A5A6AB"
                                                    variant="h6"
                                                >
                                                    {0.58} potential positives
                                                </Typography>
                                            </Grid>
                                            <Grid xs={4} item>
                                                <RadialChart
                                                    color="#1ACB7F"
                                                    value={UtilsService.convertSentimentToPercentage(
                                                        0.58
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid p={4} pb={2} xs={12} sm={4} item>
                                        <Grid container>
                                            <Grid item>
                                                <Typography variant="h4">
                                                    Potential Negative
                                                </Typography>

                                                <SectionPercentage
                                                    sx={{ color: '#D82F44' }}
                                                >
                                                    {UtilsService.convertSentimentToPercentage(
                                                        0.58
                                                    )}
                                                    %
                                                </SectionPercentage>

                                                <Typography
                                                    color="#A5A6AB"
                                                    variant="h6"
                                                >
                                                    {0.58} potential negatives
                                                </Typography>
                                            </Grid>
                                            <Grid xs={4} item>
                                                <RadialChart
                                                    color="#D82F44"
                                                    value={UtilsService.convertSentimentToPercentage(
                                                        0.58
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid p={4} pb={2} xs={12} sm={4} item>
                                        <Grid container>
                                            <Grid xs={7} item>
                                                <Typography variant="h4">
                                                    Articles Crunched
                                                </Typography>

                                                <SectionPercentage>
                                                    328
                                                </SectionPercentage>
                                            </Grid>
                                            <Grid xs={5} item>
                                                {SearchImage}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </BorderedBox>
                        </Box> */}

                        <Box
                            sx={{
                                borderBottom: 1,
                                borderColor: 'divider',
                                mt: 5,
                            }}
                        >
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label="basic tabs example"
                            >
                                <Tab label="Historical Sentiment Plot" />
                                <Tab label="Potential Indicators" />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <BorderedBox py={4} my={6}>
                                <Grid container>
                                    <Grid item sm={7}>
                                        <Box
                                            pr={4}
                                            borderRight="1px solid #ccc"
                                        >
                                            <Typography px={5} variant="h4">
                                                Sentiment Snapshot - Biweekly
                                            </Typography>
                                            {chartData && (
                                                <BarLineChart
                                                    onBarClick={
                                                        sentimentChartBarClick
                                                    }
                                                    showLinesOnly={false}
                                                    {...chartData}
                                                />
                                            )}
                                        </Box>
                                    </Grid>
                                    <Grid item sm={5}>
                                        <Box px={4}>
                                            <Box display="flex">
                                                <Typography variant="h6">
                                                    Top Articles
                                                </Typography>
                                                <Circle
                                                    mt={8}
                                                    mx={8}
                                                    h={8}
                                                    w={8}
                                                />{' '}
                                                {selectedDate && (
                                                    <Typography
                                                        lineHeight={'24px'}
                                                        variant="body2"
                                                    >
                                                        {UtilsService.formatStringDateInMDYFormat(
                                                            selectedDate
                                                        )}
                                                    </Typography>
                                                )}
                                            </Box>
                                            <Box
                                                mt={3}
                                                sx={{
                                                    height: '408px',
                                                    overflowY: 'auto',
                                                }}
                                            >
                                                {sentimentsData?.results.filter(
                                                    (d) =>
                                                        d.date == selectedDate
                                                )[0] &&
                                                    sentimentsData?.results
                                                        .filter(
                                                            (d) =>
                                                                d.date ==
                                                                selectedDate
                                                        )[0]
                                                        .searchResults?.map(
                                                            (
                                                                d: IRecentHeadlines,
                                                                index: number
                                                            ) => {
                                                                return (
                                                                    <Box
                                                                        width={
                                                                            '100%'
                                                                        }
                                                                        px={1}
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <NavigationLink
                                                                            to={
                                                                                d.url
                                                                            }
                                                                            target="_blank"
                                                                        >
                                                                            <Typography
                                                                                className=""
                                                                                fontSize={
                                                                                    14
                                                                                }
                                                                                color="#6e3cfb"
                                                                                fontWeight={
                                                                                    600
                                                                                }
                                                                            >
                                                                                {
                                                                                    d.title
                                                                                }
                                                                            </Typography>
                                                                        </NavigationLink>
                                                                        <Box
                                                                            pt={
                                                                                2
                                                                            }
                                                                            width={
                                                                                '100%'
                                                                            }
                                                                            display="flex"
                                                                        >
                                                                            <EntityArticleData
                                                                                d={
                                                                                    d
                                                                                }
                                                                            />
                                                                        </Box>
                                                                        <Box
                                                                            py={
                                                                                3
                                                                            }
                                                                        >
                                                                            <Divider />
                                                                        </Box>
                                                                    </Box>
                                                                );
                                                            }
                                                        )}
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </BorderedBox>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <Box pt={4}>
                                <TableContainer>
                                    <ComparisonTableWrapper aria-label="simple table">
                                        <TableHead>
                                            <TableHeadRow>
                                                <TableHeadCell>
                                                    <Typography
                                                        sx={{
                                                            color: '#19AF55',
                                                            fontWeight: '500',
                                                        }}
                                                        variant="subtitle1"
                                                    >
                                                        Positive
                                                    </Typography>
                                                </TableHeadCell>
                                                <TableHeadCell>
                                                    <Typography
                                                        sx={{
                                                            color: '#D82F44',
                                                            fontWeight: '500',
                                                        }}
                                                        variant="subtitle1"
                                                    >
                                                        Negative
                                                    </Typography>
                                                </TableHeadCell>
                                            </TableHeadRow>
                                        </TableHead>
                                        <TableBody>
                                            {potentialIndicators?.map(
                                                (
                                                    row: (IRecentHeadlines | null)[],
                                                    index
                                                ) => (
                                                    <TableBodyRow key={index}>
                                                        <TablePositiveCell
                                                            sx={{
                                                                borderLeftWidth:
                                                                    '2px !important',
                                                                padding: '12px',
                                                                paddingBottom:
                                                                    '4px',
                                                            }}
                                                        >
                                                            {row[0] && (
                                                                <Box
                                                                    sx={
                                                                        index >
                                                                        0
                                                                            ? {
                                                                                  borderTop:
                                                                                      '1px solid #ccc',
                                                                                  pt: 2,
                                                                              }
                                                                            : {}
                                                                    }
                                                                    width={
                                                                        '100%'
                                                                    }
                                                                    px={1}
                                                                >
                                                                    <Box display="flex">
                                                                        <NavigationLink
                                                                            to={
                                                                                row[0]
                                                                                    .url
                                                                            }
                                                                            target="_blank"
                                                                        >
                                                                            <Typography
                                                                                className=""
                                                                                fontSize={
                                                                                    14
                                                                                }
                                                                                color="#6e3cfb"
                                                                                fontWeight={
                                                                                    600
                                                                                }
                                                                            >
                                                                                {
                                                                                    row[0]
                                                                                        .title
                                                                                }
                                                                            </Typography>
                                                                        </NavigationLink>
                                                                    </Box>
                                                                    <Box
                                                                        pt={2}
                                                                        width={
                                                                            '100%'
                                                                        }
                                                                        display="flex"
                                                                    >
                                                                        <EntityArticleData
                                                                            d={
                                                                                row[0]
                                                                            }
                                                                        />
                                                                    </Box>
                                                                    {/* <Box py={2}>
                                                            <Divider />
                                                        </Box> */}
                                                                </Box>
                                                            )}
                                                        </TablePositiveCell>
                                                        <TableNegativeCell
                                                            sx={{
                                                                borderLeft:
                                                                    '2px !important',
                                                                padding:
                                                                    '12px 10px',
                                                            }}
                                                        >
                                                            {row[1] && (
                                                                <Box
                                                                    sx={
                                                                        index >
                                                                        0
                                                                            ? {
                                                                                  borderTop:
                                                                                      '1px solid #ccc',
                                                                                  pt: 2,
                                                                              }
                                                                            : {}
                                                                    }
                                                                    width={
                                                                        '100%'
                                                                    }
                                                                    px={1}
                                                                >
                                                                    <Box display="flex">
                                                                        <NavigationLink
                                                                            to={
                                                                                row[1]
                                                                                    .url
                                                                            }
                                                                            target="_blank"
                                                                        >
                                                                            <Typography
                                                                                className=""
                                                                                fontSize={
                                                                                    14
                                                                                }
                                                                                color="#6e3cfb"
                                                                                fontWeight={
                                                                                    600
                                                                                }
                                                                            >
                                                                                {
                                                                                    row[1]
                                                                                        .title
                                                                                }
                                                                            </Typography>
                                                                        </NavigationLink>
                                                                    </Box>
                                                                    <Box
                                                                        pt={2}
                                                                        width={
                                                                            '100%'
                                                                        }
                                                                        display="flex"
                                                                    >
                                                                        <EntityArticleData
                                                                            d={
                                                                                row[1]
                                                                            }
                                                                        />
                                                                    </Box>
                                                                    {/* <Box py={2}>
                                                            <Divider />
                                                        </Box> */}
                                                                </Box>
                                                            )}
                                                        </TableNegativeCell>
                                                    </TableBodyRow>
                                                )
                                            )}
                                        </TableBody>
                                    </ComparisonTableWrapper>
                                </TableContainer>
                            </Box>
                        </TabPanel>
                    </Box>
                )}
                {sentimentsData?.topicSubscription?.topicDetails
                    ?.topicSubscriptionId ? (
                    <TopicSentiments
                        disablePx={true}
                        topicSubscriptionId={
                            sentimentsData?.topicSubscription.topicDetails
                                .topicSubscriptionId
                        }
                    />
                ) : (
                    props.topicId && (
                        <TopicSentiments
                            disablePx={true}
                            topicSubscriptionId={props.topicId}
                        />
                    )
                )}
            </Box>
        </Box>
    );
};
