import {
    Box,
    Button,
    CircularProgress,
    Grid,
    Tab,
    Tabs,
    Typography,
} from '@mui/material';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { BorderedContainedBox } from '../../../../components/core/box';
import { ChatBotDesignSettings } from './chatBotDesignSettings';
import { ChatBotTextSettings } from './chatBotTextSettings';
import { useNavigate } from 'react-router-dom';
import { ChatBotConfigurationSettings } from './chatBotConfigurationSettings';
import {
    IChatbotConfig,
    IChatbotConfigData,
    IUpsertChatbotResponse,
} from '../../../../services/interfaces';
import { useSnackbar } from 'notistack';
import { DEFAULT_CHATBOT_CONFIG } from './default';
import { ChatbotDemo } from '../chatbot-demo';
import { useChatWidgetService } from '../../../../services/chat-widget.service';
import { useParams } from 'react-router-dom';

interface CustomTabProps {
    label: string;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ pt: 0 }}>{children}</Box>}
        </div>
    );
}

export function CustomTab(props: CustomTabProps) {
    const { label, ...other } = props;

    return (
        <Tab
            sx={{
                borderRadius: '0px !important',
                backgroundColor: '#fff',
                fontSize: 14,
                fontWeight: 600,
                padding: '8px 16px',
                minHeight: 30,
                border: '1px solid #D7D9EC',
                '&.Mui-selected': {
                    backgroundColor: '#6e3cfb',
                    color: '#fff',
                },
            }}
            label={label}
            {...other}
        />
    );
}

interface IProps {
    chatbotConfig: IChatbotConfig | undefined;
    setChatbotName: (title: string) => void;
    onConfigUpdate: () => void;
    setUnsavedChanges: (value: boolean) => void;
}

export const ChatBotSettings = React.forwardRef((props: IProps, ref) => {
    const [value, setValue] = React.useState(0);
    const { chatbotId } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { getSourcesCount } = useChatWidgetService();
    const [ignoreChatbotRefresh, setIgnoreChatbotRefresh] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isFetched, setIsFetched] = useState(false);
    const [dataSourcesCount, setDataSourcesCount] = useState<number | null>(
        null
    );
    const [chatbotConfig, setChatbotConfig] = useState<
        IChatbotConfig | undefined
    >(props.chatbotConfig);
    const [configUpdated, setConfigUpdated] = useState(false);

    useEffect(() => {
        if (props.chatbotConfig) {
            setChatbotConfig(props.chatbotConfig);
        }
    }, [props.chatbotConfig]);

    useImperativeHandle(ref, () => ({
        onSaveClick: async () => {
            await onSaveClick();
        },
    }));

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if (newValue !== undefined) {
            if (value == 0 && newValue != value) {
                if (chatbotConfig && chatbotConfig.title.length > 0) {
                    setValue(newValue);
                } else {
                    enqueueSnackbar('Provide valid chatbot name', {
                        variant: 'error',
                    });
                }
            } else {
                setValue(newValue);
            }
        }
    };

    useEffect(() => {
        props.setUnsavedChanges(configUpdated && !isSaving);
    }, [configUpdated, isSaving]);

    const { upsertChatWidget } = useChatWidgetService();

    const onSaveClick = async () => {
        if (!chatbotConfig) return;
        setIsSaving(true);
        let resp: IUpsertChatbotResponse = await upsertChatWidget(
            chatbotConfig
        );

        if (resp.success) {
            props.setChatbotName(chatbotConfig.title);
            enqueueSnackbar('Widget updated successfully');
            setConfigUpdated(false);
            if (chatbotId == 'new') {
                navigate('/chat-widget/' + resp.data.widgetId);
            }
            props.onConfigUpdate();
            setIsSaving(false);
        } else {
            enqueueSnackbar(resp.error.description, {
                variant: 'error',
            });
            setIsSaving(false);
        }
    };

    const refreshFilesCount = async () => {
        if (!dataSourcesCount) {
            let count = await getSourcesCount([], []);
            setDataSourcesCount(count.totalFileCount);
        }
    };

    useEffect(() => {
        if (
            //@ts-ignore
            window.photon &&
            //@ts-ignore
            window.photon.updateChatbotConfig &&
            !ignoreChatbotRefresh
        ) {
            if (chatbotConfig) {
                //@ts-ignore
                let config = JSON.parse(JSON.stringify(chatbotConfig));
                if (value != 2) config.visitorInfo = undefined;
                //@ts-ignore
                window.photon.updateChatbotConfig(config);
            }
        }
        refreshFilesCount();
    }, [chatbotConfig, value]);

    const updateConfig = (config: IChatbotConfigData) => {
        if (!chatbotConfig) return;
        setConfigUpdated(true);
        setIgnoreChatbotRefresh(false);
        setChatbotConfig({
            ...chatbotConfig,
            designConfig: {
                ...chatbotConfig.designConfig,
                data: {
                    ...chatbotConfig.designConfig.data,
                },
            },
        });
    };

    const updateWelcomeMessage = (config: IChatbotConfig) => {
        if (!chatbotConfig) return;
        //@ts-ignore
        if (window.photon && window.photon.updateChatbotConfig) {
            let c = JSON.parse(JSON.stringify(config));
            if (value != 2) c.visitorInfo = undefined;
            //@ts-ignore
            window.photon.updateChatbotConfig(c);
        }
    };

    const updateChatbotConfig = (
        config: IChatbotConfig,
        skipPush?: boolean
    ) => {
        if (!chatbotConfig) return;
        setConfigUpdated(true);
        setIgnoreChatbotRefresh(!!skipPush);
        setChatbotConfig(config);
    };

    return (
        <Grid mt={1} spacing={4} container>
            <Grid item sm={6}>
                <BorderedContainedBox>
                    <Tabs
                        value={value}
                        variant="fullWidth"
                        onChange={handleChange}
                        sx={{
                            '& .MuiTabs-indicator': { display: 'none' },
                            '& .MuiTab-root:first-of-type': {
                                borderTopLeftRadius: '100px !important',
                                borderBottomLeftRadius: '100px !important',
                            },
                            '& .MuiTab-root:last-of-type': {
                                borderTopRightRadius: '100px !important',
                                borderBottomRightRadius: '100px !important',
                            },
                        }}
                        aria-label="basic tabs example"
                    >
                        <CustomTab label="Design" />
                        <CustomTab label="Text" />
                        <CustomTab label="Configure" />
                    </Tabs>
                    {chatbotConfig && (
                        <TabPanel value={value} index={0}>
                            <ChatBotDesignSettings
                                config={chatbotConfig}
                                updateConfig={updateChatbotConfig}
                            />
                        </TabPanel>
                    )}
                    {chatbotConfig && (
                        <TabPanel value={value} index={1}>
                            <ChatBotTextSettings
                                config={chatbotConfig}
                                updateConfig={updateChatbotConfig}
                            />
                        </TabPanel>
                    )}
                    {chatbotConfig && (
                        <TabPanel value={value} index={2}>
                            <ChatBotConfigurationSettings
                                config={chatbotConfig}
                                dataSourcesCount={dataSourcesCount}
                                updateConfig={updateChatbotConfig}
                                updateWelcomeMessage={updateWelcomeMessage}
                            />
                        </TabPanel>
                    )}
                    <TabPanel value={value} index={3}></TabPanel>
                </BorderedContainedBox>
            </Grid>
            <Grid item sm={6}>
                <Box>
                    <Box display="flex" justifyContent="space-between">
                        <Typography variant="h4">Chatbot Preview</Typography>
                        {chatbotConfig && (
                            <Button
                                disabled={
                                    !configUpdated ||
                                    isSaving ||
                                    chatbotConfig.title.trim().length == 0
                                }
                                onClick={onSaveClick}
                                variant="contained"
                            >
                                {isSaving ? (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: '#ffffff',
                                        }}
                                    />
                                ) : (
                                    'Save'
                                )}
                            </Button>
                        )}
                    </Box>
                    <ChatbotDemo />
                </Box>
            </Grid>
        </Grid>
    );
});
