interface IProps {
    size?: number;
}

export const HighlighterFilled = (props: IProps) => (
    <svg
        width={props.size || 24}
        height={props.size || 24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <mask
            id="mask0_4869_29565"
            style={{ maskType: 'alpha' }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
        >
            <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_4869_29565)">
            <path
                d="M2 24V20H22V24H2ZM10.6 8L16 13.425L12 17.425C11.6 17.825 11.1292 18.025 10.5875 18.025C10.0458 18.025 9.575 17.825 9.175 17.425L8.5 18H3.5L6.65 14.875C6.25 14.475 6.04167 13.9958 6.025 13.4375C6.00833 12.8792 6.2 12.4 6.6 12L10.6 8ZM12 6.575L16 2.6C16.4 2.2 16.8708 2 17.4125 2C17.9542 2 18.425 2.2 18.825 2.6L21.425 5.175C21.825 5.575 22.025 6.04583 22.025 6.5875C22.025 7.12917 21.825 7.6 21.425 8L17.425 12L12 6.575Z"
                fill="#6e3cfb"
            />
        </g>
    </svg>
);
