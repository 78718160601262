import {
    Box,
    Grid,
    Button,
    Typography,
    Divider,
    Skeleton,
    Tooltip,
    TooltipProps,
    styled,
    tooltipClasses,
} from '@mui/material';
import Switch from '@mui/material/Switch';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { useContext, useEffect, useRef, useState } from 'react';
import FadeIn from 'react-fade-in';
import SearchIcon from '@mui/icons-material/Search';
import { Input } from '../../../components/core/input';
import { MiniLogo } from '../../../components/icons/mini-logo';
import { Arrow } from '../../../components/icons/arrow';
import { Filters } from './filters';
import { useLiveServices } from '../../../services/live.service';
import {
    ILiveQAFiltersResponseData,
    RecentSearchesResponseData,
    Search,
} from '../../../services/interfaces';
import { Close, History } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { AppContext } from '../../../providers/app-provider';
import { useNavigate } from 'react-router-dom';
import { Header } from '../header';
import { Logo } from '../../../components/icons/logo';
import { Authorize } from '../../../components/authorize';
import { FeatureName, SubFeatureName } from '../../../services/interfaces';
import { usePermissions } from '../../../providers/permissions-provider';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#fff',
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: 12,
        border: '1px solid #dadde9',
        maxWidth: 800,
    },
}));

export const Landing = () => {
    let inputRef = useRef();
    const { isFeatureAuthorized, isSubFeatureAuthorized } = usePermissions();
    const appContext = useContext(AppContext);
    const navigate = useNavigate();
    const [queryMessage, setQueryMessage] = useState<string>('');
    const [filtersFetched, setFiltersfetched] = useState(false);
    const [recentSearches, setRecentSearches] =
        useState<RecentSearchesResponseData | null>(null);
    const [enableResearch, setEnableResearch] = useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();

    const { fetchRecentSearches, deleteSelectedSearch } = useLiveServices();

    const refreshRecentSearches = async () => {
        const data = await fetchRecentSearches();
        setRecentSearches(data);
    };

    const onFiltersUpdate = (filters: ILiveQAFiltersResponseData) => {
        setEnableResearch(filters.insightsMode ? filters.insightsMode : false);
    };

    const handleDeleteSearch = async (s: Search) => {
        if (recentSearches) {
            setRecentSearches({
                searches: recentSearches.searches.filter(
                    (x) => x.query !== s.query
                ),
            });
        }
        const data = await deleteSelectedSearch(s);
        if (data.rowsUpdated >= 1) {
            if (
                recentSearches &&
                recentSearches.searches.filter((x) => x.query !== s.query)
                    .length == 0
            ) {
                refreshRecentSearches();
            }
        } else {
            enqueueSnackbar('Server Error! Please try again later.', {
                variant: 'error',
            });
        }
    };

    const sendMessage = (query: string) => {
        enableResearch &&
        isSubFeatureAuthorized(
            FeatureName.InstantInsights,
            SubFeatureName.InsightsMode
        )
            ? navigate(
                  `/research/overview?query=${encodeURIComponent(
                      query
                  )}&id=&insights=${enableResearch}`
              )
            : navigate(
                  `/live?query=${encodeURIComponent(
                      query
                  )}&id=&insights=${enableResearch}`
              );
    };

    useEffect(() => {
        refreshRecentSearches();
    }, []);

    return (
        <Box>
            {appContext?.isMobile === false && <Header />}
            <Box p={15} pb={0}>
                <Box mt={8} textAlign="center">
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Logo />
                    </Box>
                    <Typography variant="body2">
                        Ask any question or search a topic to obtain relevant
                        insights
                    </Typography>
                    <Grid mt={6} container>
                        <Grid
                            mx="auto"
                            sx={{
                                minWidth:
                                    appContext?.isMobile === false
                                        ? 780
                                        : '100%',
                            }}
                            xs={12}
                            sm={6}
                            item
                        >
                            <Box
                                id="search-box"
                                sx={{
                                    border: '1px solid #ccc',
                                    borderRadius: 4,
                                    pb: 1,
                                    boxShadow:
                                        '0px 11px 20px 0px rgba(5, 16, 55, 0.10)',
                                }}
                            >
                                <Input
                                    type="text"
                                    mt={0}
                                    inputRef={inputRef}
                                    multiline
                                    noBorder
                                    sx={{
                                        pl: 2,
                                        '&:hover': {
                                            '& fieldset': {
                                                border: '0px solid #6e3cfb !important',
                                            },
                                        },
                                    }}
                                    value={queryMessage}
                                    onChange={(e) => {
                                        setQueryMessage(e.target.value);
                                    }}
                                    onEnterPress={() =>
                                        sendMessage(queryMessage)
                                    }
                                    inputPadding={'10.5px 14px 10.5px 0px'}
                                    startAdornment={
                                        <Button
                                            sx={{
                                                px: 2,
                                                // py: 1,
                                                fontSize: 18,
                                                borderRadius: 10,
                                                minWidth: 10,
                                                marginBottom: 'auto',
                                                marginTop: 2,
                                            }}
                                            variant="text"
                                            disabled={!queryMessage}
                                            onClick={() =>
                                                sendMessage(queryMessage)
                                            }
                                        >
                                            <SearchIcon sx={{ fontSize: 28 }} />
                                        </Button>
                                    }
                                    endAdornment={
                                        <Button
                                            sx={{
                                                p: 0,
                                                fontSize: 18,
                                                borderRadius: 10,
                                                minWidth: 10,
                                                marginBottom: 'auto',
                                                marginTop: 2,
                                            }}
                                            variant="text"
                                            disabled={!queryMessage}
                                            onClick={() =>
                                                sendMessage(queryMessage)
                                            }
                                        >
                                            <ArrowCircleRightIcon
                                                color="primary"
                                                sx={{ fontSize: 32 }}
                                            />
                                        </Button>
                                    }
                                    placeholder="Ask me anything..."
                                />
                                <Box
                                    justifyContent={'space-between'}
                                    display="flex"
                                    pl={5}
                                    pr={2}
                                    pb={2}
                                    pt={3}
                                >
                                    <Box display="flex">
                                        {appContext?.isMobile === false ? (
                                            <Filters
                                                min={false}
                                                setFiltersfetched={
                                                    setFiltersfetched
                                                }
                                                filtersFetched={filtersFetched}
                                                onFiltersUpdate={
                                                    onFiltersUpdate
                                                }
                                            />
                                        ) : null}
                                    </Box>
                                    {appContext?.isMobile === false ? (
                                        filtersFetched ? (
                                            <Authorize
                                                featureName={
                                                    FeatureName.InstantInsights
                                                }
                                                subFeatureName={
                                                    SubFeatureName.InsightsMode
                                                }
                                            >
                                                <Box
                                                    display="flex"
                                                    justifyContent="end"
                                                    alignItems="center"
                                                >
                                                    <HtmlTooltip
                                                        open={
                                                            isSubFeatureAuthorized(
                                                                FeatureName.InstantInsights,
                                                                SubFeatureName.InsightsMode
                                                            )
                                                                ? undefined
                                                                : false
                                                        }
                                                        title={
                                                            <Box py={4} px={3}>
                                                                <Typography variant="h5">
                                                                    Unlock
                                                                    Advanced
                                                                    Insights
                                                                </Typography>
                                                                <Box
                                                                    display={
                                                                        'flex'
                                                                    }
                                                                >
                                                                    <ul
                                                                        style={{
                                                                            paddingInlineStart: 16,
                                                                        }}
                                                                    >
                                                                        {[
                                                                            [
                                                                                'Company Financials',
                                                                                'Sentiment Analysis',
                                                                                'Corporate Culture',
                                                                                'Volume/Search Trends',
                                                                            ].map(
                                                                                (
                                                                                    summary: string
                                                                                ) => (
                                                                                    <li>
                                                                                        <Typography
                                                                                            fontSize={
                                                                                                14
                                                                                            }
                                                                                            fontWeight={
                                                                                                400
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                summary
                                                                                            }
                                                                                        </Typography>
                                                                                    </li>
                                                                                )
                                                                            ),
                                                                        ]}
                                                                    </ul>
                                                                    <ul
                                                                        style={{
                                                                            paddingInlineStart: 16,
                                                                            marginLeft: 16,
                                                                        }}
                                                                    >
                                                                        {[
                                                                            [
                                                                                'Patents/Research papers',
                                                                                'Web Media',
                                                                                'Conversational Web Search',
                                                                                'Filings Analysis',
                                                                            ].map(
                                                                                (
                                                                                    summary: string
                                                                                ) => (
                                                                                    <li>
                                                                                        <Typography
                                                                                            fontSize={
                                                                                                14
                                                                                            }
                                                                                            fontWeight={
                                                                                                400
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                summary
                                                                                            }
                                                                                        </Typography>
                                                                                    </li>
                                                                                )
                                                                            ),
                                                                        ]}
                                                                    </ul>
                                                                </Box>
                                                            </Box>
                                                        }
                                                    >
                                                        <Box
                                                            display="flex"
                                                            justifyContent="end"
                                                            alignItems="center"
                                                        >
                                                            <Box>
                                                                <Typography
                                                                    color={
                                                                        isSubFeatureAuthorized(
                                                                            FeatureName.InstantInsights,
                                                                            SubFeatureName.InsightsMode
                                                                        )
                                                                            ? '#003'
                                                                            : '#ccc'
                                                                    }
                                                                    fontSize={
                                                                        14
                                                                    }
                                                                    fontWeight={
                                                                        600
                                                                    }
                                                                >
                                                                    Insights
                                                                </Typography>
                                                            </Box>
                                                            <Box>
                                                                <Switch
                                                                    size="small"
                                                                    disabled={
                                                                        !isSubFeatureAuthorized(
                                                                            FeatureName.InstantInsights,
                                                                            SubFeatureName.InsightsMode
                                                                        )
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        setEnableResearch(
                                                                            e
                                                                                .target
                                                                                .checked
                                                                        );
                                                                    }}
                                                                    checked={
                                                                        enableResearch
                                                                    }
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </HtmlTooltip>
                                                </Box>
                                            </Authorize>
                                        ) : (
                                            <Box
                                                display="flex"
                                                justifyContent="end"
                                                alignItems="center"
                                            >
                                                <Box
                                                    display="flex"
                                                    justifyContent="end"
                                                    alignItems="center"
                                                >
                                                    <Box>
                                                        <Typography
                                                            color="#003"
                                                            fontSize={14}
                                                        >
                                                            Insights
                                                        </Typography>
                                                    </Box>
                                                    <Box visibility="hidden">
                                                        <Switch
                                                            size="small"
                                                            onChange={(e) => {
                                                                setEnableResearch(
                                                                    e.target
                                                                        .checked
                                                                );
                                                            }}
                                                            checked={
                                                                enableResearch
                                                            }
                                                        />
                                                    </Box>
                                                </Box>
                                            </Box>
                                        )
                                    ) : null}
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                <Box
                    sx={{
                        height: `calc(100vh - ${
                            appContext?.accountActionBannerVisible
                                ? '22px'
                                : '0px'
                        } - 380px)`,
                        overflowY: 'auto',
                    }}
                >
                    <Typography textAlign={'center'} variant="h4" mt={6}>
                        You can ask
                    </Typography>
                    <Grid px={{ sm: 40 }} spacing={0} container>
                        {[
                            {
                                title: 'Questions',
                                id: 'questions-section',
                                questions: [
                                    'Who will win the presidential election?',
                                    'What is the impact of AI in education?',
                                    'Will we have a recession?',
                                ],
                            },
                            {
                                title: 'High-Level Topics',
                                id: 'topics-section',
                                questions: [
                                    'Nvidia',
                                    'Elon Musk',
                                    'Autonomous vehicles',
                                ],
                            },
                        ].map(
                            (data: {
                                title: string;
                                questions: string[];
                                id: string;
                            }) => (
                                <Grid px={3} mt={4} item xs={12} sm={6}>
                                    <Box
                                        id={data.id}
                                        sx={{
                                            p: 2,
                                            textAlign: 'left',
                                            borderRadius: 2,
                                            pb: 4,
                                            border: '1px solid #D7D9EC',
                                            background:
                                                'linear-gradient(315deg, rgba(249, 249, 255, 0.10) 0%, rgba(249, 249, 255, 0.00) 100%)',
                                        }}
                                    >
                                        <Typography variant="h6">
                                            {data.title}
                                        </Typography>
                                        <Box>
                                            {[...data.questions].map((q) => (
                                                <Box
                                                    onClick={() =>
                                                        sendMessage(q)
                                                    }
                                                    sx={{
                                                        display: 'flex',
                                                        px: 2,
                                                        justifyContent:
                                                            'space-between',
                                                        py: 1,
                                                        mt: 2,
                                                        cursor: 'pointer',
                                                        borderRadius: 2,
                                                        boxShadow:
                                                            '0px 1px 5px 0px rgba(0, 0, 0, 0.15)',
                                                        '&:hover': {
                                                            background:
                                                                'linear-gradient(134deg, #E0E3FF 0%, rgba(215, 217, 236, 0.00) 100%)',
                                                        },
                                                    }}
                                                >
                                                    <Typography
                                                        fontSize={14}
                                                        lineHeight={'24px'}
                                                    >
                                                        {q}
                                                    </Typography>
                                                    <Box pt={0.5} pl={1}>
                                                        {Arrow}
                                                    </Box>
                                                </Box>
                                            ))}
                                        </Box>
                                    </Box>
                                </Grid>
                            )
                        )}
                    </Grid>

                    <Box
                        id="recent-searches"
                        mx={{ sm: 43 }}
                        sx={{
                            p: 2,
                            pb: 3,
                            textAlign: 'left',

                            background:
                                'linear-gradient(315deg, rgba(249, 249, 255, 0.10) 0%, rgba(249, 249, 255, 0.00) 100%)',
                        }}
                    >
                        <Typography variant="h6">Recent Searches</Typography>
                        <Box>
                            {recentSearches &&
                                recentSearches.searches
                                    .slice(0, 3)
                                    .map((q, index) => (
                                        <FadeIn delay={200}>
                                            <Box
                                                key={index}
                                                onClick={() =>
                                                    sendMessage(q.query)
                                                }
                                                sx={{
                                                    position: 'relative',
                                                    mt: 3,
                                                    px: 2,
                                                    py: 1,
                                                    cursor: 'pointer',
                                                    borderRadius: 2,
                                                    boxShadow:
                                                        '0px 1px 5px 0px rgba(0, 0, 0, 0.15)',
                                                    '&:hover': {
                                                        background:
                                                            'linear-gradient(134deg, #E0E3FF 0%, rgba(215, 217, 236, 0.00) 100%)',
                                                        '& #remove': {
                                                            display: 'block',
                                                        },
                                                    },
                                                }}
                                            >
                                                <Box
                                                    display="flex"
                                                    alignItems="center"
                                                >
                                                    <History
                                                        sx={{
                                                            fontSize: '16px',
                                                            mr: 1,
                                                        }}
                                                    />
                                                    <Typography
                                                        fontSize={14}
                                                        width="90%"
                                                        lineHeight={'24px'}
                                                    >
                                                        {q.query}
                                                    </Typography>
                                                </Box>

                                                <Close
                                                    id="remove"
                                                    sx={{
                                                        position: 'absolute',
                                                        top: 5,
                                                        right: 8,
                                                        height: '24px',
                                                        cursor: 'pointer',
                                                        p: 1,
                                                        display: 'none',
                                                        '&:hover': {
                                                            backgroundColor:
                                                                '#D3D3D3',
                                                            borderRadius: '50%',
                                                        },
                                                    }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleDeleteSearch(q);
                                                    }}
                                                />
                                            </Box>
                                        </FadeIn>
                                    ))}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
