import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Add } from '../../../components/icons/add';
import { BootstrapDialogMedium } from '../../../components/core/dialog';
import { Input } from '../../../components/core/input';

interface IProps {
    addCategory: (name: string, explaination: string) => void;
}

export const CustomRequest = (props: IProps) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [name, setName] = useState('');
    const [explaination, setExplaination] = useState('');
    const closeDialog = () => {
        setOpenDialog(false);
    };
    return (
        <Box>
            <Button
                startIcon={<Add size={14} color="#6e3cfb" />}
                onClick={() => setOpenDialog(true)}
                variant="text"
                sx={{ fontSize: 16, fontWeight: 600 }}
            >
                Custom Request
                <Box pl={0.5} fontSize={15} color="#0F62FE" component="sup">
                    *
                </Box>
            </Button>
            <BootstrapDialogMedium
                onClose={closeDialog}
                aria-labelledby="customized-dialog-title"
                open={openDialog}
                PaperProps={{
                    sx: {
                        p: '12px !important',
                    },
                }}
                sx={{
                    textAlign: 'left',
                }}
            >
                <DialogContent sx={{ p: '0px !important' }}>
                    <Typography variant="h3">Custom request</Typography>
                    <Typography mt={4} mb={2} fontSize={14} fontWeight={600}>
                        Please submit the feature you need us to add
                    </Typography>
                    <Input
                        mt={0}
                        pt={0}
                        pb={0}
                        fontSize={14}
                        autoFocus={true}
                        value={name}
                        noShadow
                        backgroundColor="#fff"
                        type="text"
                        onChange={(e) => {
                            setName(e.target.value.slice(0, 100));
                        }}
                        placeholder="E.g., Industry Analysis"
                    />
                    <Typography mt={2} mb={2} fontSize={14} fontWeight={600}>
                        Explanation
                    </Typography>
                    <Input
                        mt={0}
                        pt={2}
                        pb={0}
                        fontSize={14}
                        multiline
                        rows={6}
                        noShadow
                        value={explaination}
                        backgroundColor="#fff"
                        type="text"
                        onChange={(e) => {
                            setExplaination(e.target.value);
                        }}
                        placeholder="Provide an analysis on the industry pertaining to the company, include facets such as TAM and Growth."
                    />
                </DialogContent>
                <DialogActions sx={{ mt: 4 }}>
                    <Button variant="text" sx={{ mr: 4 }} onClick={closeDialog}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        disabled={!name || !explaination}
                        onClick={() => {
                            props.addCategory(name, explaination);
                            setName('');
                            setExplaination('');
                            closeDialog();
                        }}
                    >
                        Save
                    </Button>
                </DialogActions>
            </BootstrapDialogMedium>
        </Box>
    );
};
