import React, { useEffect, useRef, useState } from 'react';
import { BorderedBox } from '../../../components/core/box';
import {
    Box,
    Button,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    InputAdornment,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { IconButton } from '@mui/material';
import { Add, Remove, SmsOutlined } from '@mui/icons-material';
import { styled } from '@mui/system';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { UtilsService } from '../../../services/utils.service';
import { CustomIntelligence } from '../../../components/icons/custom-intelligence';
import { Live } from '../../../components/icons/live';
import loadingAnimation from '../../../components/animations/circular-loader.json';
import Lottie from 'lottie-react';
import { ColumnChart } from '../../../components/charts/column-chart';
import { useQuotaService } from '../../../services/quota.service';
import {
    IUsageOverview,
    IUsageOverviewResponseData,
    IUserFeatureUsage,
    IUsersUsageList,
} from '../../../services/interfaces';
import SingleSelect from '../../../components/core/input/single-select';
import YearMonthPicker from '../../../components/core/input/year-month-picker';
import { useSnackbar } from 'notistack';
import SortableTable from '../../../components/table/sortable-table';
import { features } from 'process';
import { Input } from '../../../components/core/input';
import { SearchInsights } from '../../../components/icons/search-insights';
import {
    BootstrapDialogMedium,
    BootstrapDialogSmall,
    BootstrapDialogTitle,
} from '../../../components/core/dialog';
import { Dashboard } from '../../../components/icons/dashboard';

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
    '& .MuiCircularProgress-circle': {
        strokeLinecap: 'round',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: '#F9F9FF',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

enum EFeatureEnum {
    DILIGENCE = 'Reports',
    DOCUMENTS = 'Word Count',
    QUERIES = 'Message Credits',
    TOPICS = 'Topics',
}

enum EFeatureBreakdownEnum {
    DOCU_INSIGHTS_QUERY_QUOTA = 'Document Insights',
    INSTANT_INSIGHTS_QUERY_QUOTA = 'Instant Insights',
    LIVE_QA_QUERY_QUOTA = 'Photon Live',
    PHOTON_CHAT_QUERY_QUOTA = 'Photon Chat',
    DILIGENCE_REPORTS_QUOTA = 'Reports',
    TOPICS_SUBSCRIPTION_QUOTA = 'Topics',
    DOCU_INSIGHTS_WORD_QUOTA = 'Document Insights Words',
}

const CircularProgressWithLabel: React.FC<{
    value: number;
    size: number;
    color: string;
}> = ({ value, size, color }) => {
    return (
        <Box
            position="relative"
            display="inline-flex"
            justifyContent="center"
            alignItems="center"
        >
            {/* Background Circular Progress (for remaining part) */}
            <StyledCircularProgress
                variant="determinate"
                value={100}
                thickness={3}
                size={size}
                sx={{
                    color: value >= 100 ? 'transparent' : '#E2E2E2', // Color of the unfilled part
                    position: 'absolute',
                }}
            />
            {/* Foreground Circular Progress (for completed part) */}
            <StyledCircularProgress
                variant="determinate"
                value={Math.min(100, value)}
                thickness={3}
                size={size}
                sx={{
                    color,
                }}
            />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="h6" component="div" color="textSecondary">
                    {`${Math.round(value)}%`}
                </Typography>
            </Box>
        </Box>
    );
};

const NumberInput: React.FC<{
    threshold: number;
    onChange: (value: number) => void;
}> = ({ threshold, onChange }) => {
    const [value, setValue] = useState<number>(threshold);
    const debounceRef = useRef<NodeJS.Timeout | null>(null);

    const handleIncrease = () => {
        setValue((prev) => Math.min(prev + 1, 100));
    };

    const handleDecrease = () => {
        setValue((prev) => (prev > 0 ? prev - 1 : 0));
    };

    useEffect(() => {
        if (debounceRef.current) {
            clearTimeout(debounceRef.current);
        }

        debounceRef.current = setTimeout(() => {
            onChange(value);
        }, 1000);

        return () => {
            if (debounceRef.current) {
                clearTimeout(debounceRef.current);
            }
        };
    }, [value, onChange]);

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                border: '1px solid #002D9C',
                borderRadius: 1,
                px: 2,
                overflow: 'hidden',
                maxWidth: 150,
            }}
        >
            <IconButton
                onClick={handleDecrease}
                sx={{
                    borderRadius: 0,
                    color: 'black',
                }}
            >
                <Remove />
            </IconButton>
            <Typography
                textAlign={'center'}
                minWidth={'53px !important'}
                px={2}
                py={1}
            >
                {value}%
            </Typography>
            <IconButton
                onClick={handleIncrease}
                sx={{
                    borderRadius: 0,
                    color: 'black',
                }}
            >
                <Add />
            </IconButton>
        </Box>
    );
};

interface IFeatureUsageProps {
    feature: string;
    value: number;
    total: number;
}

const FeatureUsage = (props: IFeatureUsageProps) => {
    const getColor = () => {
        if (props.value / props.total > 0.7) return '#ff6692';
        else if (props.value / props.total > 0.4) return '#FF832B';
        else return '#24A148';
    };
    return (
        <Box
            display="flex"
            sx={{
                backgroundColor:
                    props.value / props.total >= 1 ? '#FBE8E9' : '',
                borderRadius: 2,
            }}
            p={3}
            mx="auto"
        >
            <CircularProgressWithLabel
                size={100}
                color={getColor()}
                value={Math.floor((props.value * 100) / props.total)}
            />
            <Box ml={4}>
                <Typography variant="h6">
                    {EFeatureEnum[
                        props.feature as keyof typeof EFeatureEnum
                    ].toString()}
                </Typography>
                <Typography fontSize={32} fontWeight={600}>
                    {UtilsService.convertToInternationalCurrencySystem(
                        props.value
                    )}
                </Typography>
                <Typography fontSize={12}>
                    /
                    {UtilsService.convertToInternationalCurrencySystem(
                        props.total
                    )}{' '}
                    Used
                </Typography>
            </Box>
        </Box>
    );
};

const QuotaConsumptionWarning: React.FC<{
    features: IUsageOverview[];
}> = ({ features }) => {
    const [consumedFeatures, setConsumesFeatures] = useState<string[]>([]);
    useEffect(() => {
        setConsumesFeatures(
            features
                .filter((f) => f.quotaConsumed / f.quotaAllocated >= 1)
                .map((f) => f.quotaCategoryGroup)
        );
    }, [features]);
    return consumedFeatures.length > 0 ? (
        <Box mt={4} display="flex">
            <Typography
                py={2}
                px={4}
                borderRadius={2}
                fontSize={12}
                sx={{ backgroundColor: '#DA1E281A' }}
                textTransform={'uppercase'}
            >
                you have consumed all the{' '}
                {UtilsService.joinWithAnd(
                    consumedFeatures.map((f) =>
                        EFeatureEnum[f as keyof typeof EFeatureEnum].toString()
                    )
                )}
                . Please{' '}
                <Box
                    component="a"
                    sx={{ textDecoration: 'none', fontWeight: 700 }}
                    href="mailto:support@photoninsights.com"
                    target="_blank"
                >
                    Contact Photon Insights
                </Box>{' '}
                to upgrade plan or top up Message credit{' '}
            </Typography>
            <Button
                component="a"
                sx={{
                    ml: 4,
                    borderRadius: 2,
                    px: 10,
                    py: 2,
                    fontSize: 12,
                    fontWeight: 600,
                }}
                href={'mailto:support@photoninsights.com'}
                target="_blank" // Optional: to open the link in a new tab
                rel="noopener noreferrer" // Recommended for security when using target="_blank"
                variant="contained" // You can choose the variant you like (contained, outlined, text)
                color="primary" // You can choose the color you like (primary, secondary, etc.)
            >
                Contact Us
            </Button>
        </Box>
    ) : null;
};

export const QuotaManagement = () => {
    const {
        fetchUsageOverview,
        fetchUsageHistory,
        fetchUsersUsage,
        updateThreshold,
    } = useQuotaService();
    const { enqueueSnackbar } = useSnackbar();
    const [selectedView, setSelectedView] = useState<
        'QUERIES' | 'DOCUMENTS' | 'DILIGENCE' | 'TOPICS'
    >('QUERIES');
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [
        showThresholdConfigurationDialog,
        setShowThresholdConfigurationDialog,
    ] = useState(false);
    const [overview, setOverview] = useState<IUsageOverviewResponseData | null>(
        null
    );

    const [searchValue, setSearchValue] = useState('');
    const [usersUsage, setUsersUsage] = useState<IUsersUsageList[] | null>(
        null
    );
    const [monthYear, setMonthYear] = useState<string>(
        `${new Date().getMonth() + 1}-${new Date().getFullYear()}`
    );
    const [labels, setLables] = useState<string[]>([]);
    const [series, setSeries] = useState<
        {
            name: string;
            data: number[];
        }[]
    >([]);

    const initialize = async () => {
        const usageOverviewRes = await fetchUsageOverview();
        if (usageOverviewRes) {
            setOverview(usageOverviewRes);
        }
        if (!usersUsage) {
            const usersUsageRes = await fetchUsersUsage();
            if (usersUsageRes) {
                setUsersUsage(
                    usersUsageRes.map((user) => {
                        const transformedUser: IUsersUsageList = {
                            userId: user.userId,
                            id: user.userId,
                            firstName: user.firstName,
                            elements: user.elements,
                            DILIGENCE: 0,
                            QUERIES: 0,
                            DOCUMENTS: 0,
                            TOPICS: 0,
                        };

                        user.elements.forEach((element: IUserFeatureUsage) => {
                            transformedUser[element.quotaCategoryGroup] =
                                element.quotaConsumed;
                        });

                        return transformedUser;
                    })
                );
            }
        }
    };

    useEffect(() => {
        initialize();
    }, []);

    const refreshData = async () => {
        setIsRefreshing(true);
        await Promise.all([initialize(), refreshUsageHistory()]);
        setIsRefreshing(false);
    };

    const handleUpdateThreshold = async (value: number) => {
        if (overview && overview.notificationAlertThreshold != value) {
            let res = await updateThreshold(value);
            if (res) {
                await initialize();
                enqueueSnackbar('Threshold updated successfully', {
                    variant: 'success',
                });
            }
        }
    };

    const refreshUsageHistory = async () => {
        const usageHistoryRes = await fetchUsageHistory(
            selectedView,
            parseInt(monthYear.split('-')[1]),
            parseInt(monthYear.split('-')[0])
        );
        if (usageHistoryRes) {
            const dates = Array.from(
                new Set(usageHistoryRes.elements.map((item) => item.date))
            ).sort();
            let dataPoints: string[] = [];
            if (selectedView == 'QUERIES') {
                dataPoints = [
                    'DOCU_INSIGHTS_QUERY_QUOTA',
                    'INSTANT_INSIGHTS_QUERY_QUOTA',
                    'LIVE_QA_QUERY_QUOTA',
                    'PHOTON_CHAT_QUERY_QUOTA',
                ];
            } else if (selectedView == 'DILIGENCE') {
                dataPoints = ['DILIGENCE_REPORTS_QUOTA'];
            } else if (selectedView == 'TOPICS') {
                dataPoints = ['TOPICS_SUBSCRIPTION_QUOTA'];
            } else if (selectedView == 'DOCUMENTS') {
                dataPoints = ['DOCU_INSIGHTS_WORD_QUOTA'];
            }

            const chartSeries = dataPoints.map((category) => ({
                name: EFeatureBreakdownEnum[
                    category as keyof typeof EFeatureBreakdownEnum
                ].toString(),
                data: dates.map((date) => {
                    const item = usageHistoryRes.elements.find(
                        (d) => d.date === date
                    );
                    if (item) {
                        const categoryItem = item.categories.find(
                            (c) => c.quotaCategory === category
                        );
                        return categoryItem ? categoryItem.quotaConsumed : 0;
                    }
                    return 0;
                }),
            }));
            setSeries(chartSeries);
            setLables(
                dates.map((d) => UtilsService.formatStringDateWithoutYear(d))
            );
        }
    };

    useEffect(() => {
        refreshUsageHistory();
    }, [monthYear, selectedView]);

    return overview ? (
        <Box>
            <BorderedBox p={4}>
                <Box display="flex" justifyContent={'space-between'}>
                    <Box display="flex">
                        <Typography variant="h4">Usage overview</Typography>
                        <Tooltip placement="top" arrow title="Refresh Usage">
                            <IconButton
                                onClick={refreshData}
                                sx={{ mt: 2, ml: 2 }}
                                color="success"
                                disabled={isRefreshing}
                                className={isRefreshing ? 'rotate' : ''}
                            >
                                <AutorenewIcon sx={{ fontSize: 20 }} />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    {overview && (
                        <Box display="flex">
                            <Typography
                                sx={{
                                    py: 2,
                                    px: 3,
                                    borderRadius: 6,
                                    backgroundColor: '#EBECF6',
                                }}
                                color={'#4a4b4c'}
                                fontSize={14}
                            >
                                Your quota renews on the 1st of every month
                            </Typography>
                            <Button
                                startIcon={<EmailOutlinedIcon />}
                                onClick={() => {
                                    setShowThresholdConfigurationDialog(true);
                                }}
                                sx={{
                                    borderRadius: 2,
                                    ml: 4,
                                }}
                                variant="outlined"
                            >
                                Set Alerts
                            </Button>
                        </Box>
                    )}
                </Box>
                <Grid spacing={4} container>
                    {overview?.usageOverviews.map((usage, index) => (
                        <Grid key={index} item xs={12} mt={3} sm={6} md={3}>
                            <FeatureUsage
                                feature={usage.quotaCategoryGroup}
                                value={usage.quotaConsumed}
                                total={usage.quotaAllocated}
                            />
                        </Grid>
                    ))}
                </Grid>
            </BorderedBox>
            <QuotaConsumptionWarning features={overview.usageOverviews} />
            <BorderedBox mt={4} p={4}>
                <Typography variant="h5">Message Credit Breakdown</Typography>
                <Grid mt={0} container spacing={3}>
                    {overview?.featureWiseBreakdowns
                        .filter((o) =>
                            [
                                'DOCU_INSIGHTS_QUERY_QUOTA',
                                'INSTANT_INSIGHTS_QUERY_QUOTA',
                                'LIVE_QA_QUERY_QUOTA',
                                'PHOTON_CHAT_QUERY_QUOTA',
                            ].includes(o.quotaCategory)
                        )
                        .map((breakdown, index) => (
                            <Grid item sm={6} md>
                                <BorderedBox
                                    p={4}
                                    textAlign={'center'}
                                    sx={{
                                        backgroundColor: '#F9F9FF',
                                    }}
                                >
                                    <Box
                                        mb={2}
                                        justifyContent={'center'}
                                        display="flex"
                                    >
                                        {breakdown.quotaCategory ==
                                            'LIVE_QA_QUERY_QUOTA' && (
                                            <Live fillColor="#000" />
                                        )}
                                        {breakdown.quotaCategory ==
                                            'DOCU_INSIGHTS_QUERY_QUOTA' && (
                                            <CustomIntelligence
                                                fillColor={'#000'}
                                            />
                                        )}
                                        {breakdown.quotaCategory ==
                                            'INSTANT_INSIGHTS_QUERY_QUOTA' && (
                                            <Live fillColor="#000" />
                                        )}

                                        {breakdown.quotaCategory ==
                                            'PHOTON_CHAT_QUERY_QUOTA' && (
                                            <SmsOutlined
                                                sx={{
                                                    color: '#000',
                                                }}
                                            />
                                        )}

                                        <Typography ml={2} mb={1} variant="h6">
                                            {EFeatureBreakdownEnum[
                                                breakdown.quotaCategory as keyof typeof EFeatureBreakdownEnum
                                            ].toString()}
                                        </Typography>
                                    </Box>
                                    <Divider />
                                    <Typography mt={2} variant="h1">
                                        {UtilsService.convertToInternationalCurrencySystem(
                                            breakdown.quotaConsumed
                                        )}
                                    </Typography>
                                </BorderedBox>
                            </Grid>
                        ))}
                </Grid>
            </BorderedBox>
            <BorderedBox mt={4} p={4}>
                <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography variant="h4">Historical Usage</Typography>
                    <Box display="flex">
                        <YearMonthPicker
                            selectedMonth={parseInt(monthYear.split('-')[0])}
                            selectedYear={parseInt(monthYear.split('-')[1])}
                            onChange={(y, m) => {
                                setMonthYear(`${m}-${y}`);
                            }}
                        />
                        <SingleSelect
                            getOptionText={(v: any) => {
                                return v
                                    ? EFeatureEnum[
                                          v as keyof typeof EFeatureEnum
                                      ].toString()
                                    : '';
                            }}
                            disableCheckbox
                            placeholder="Auto Open Behaviour"
                            pt={1.5}
                            ptContainer={0}
                            pb={2.5}
                            width={200}
                            backgroundColor="#fff"
                            mt={0}
                            options={['QUERIES', 'DOCUMENTS', 'DILIGENCE']}
                            selectedValue={selectedView}
                            onChange={(v: any) => {
                                setSelectedView(v);
                            }}
                        />
                    </Box>
                </Box>
                {labels.length > 0 && series.length > 0 && (
                    <ColumnChart labels={labels} series={series} />
                )}
            </BorderedBox>
            {usersUsage && usersUsage.length > 0 && (
                <BorderedBox py={3} mt={4}>
                    <Box
                        px={6}
                        pb={3}
                        display={'flex'}
                        justifyContent={'space-between'}
                    >
                        <Typography mt={1} variant="h4">
                            Individual Usage Statistics
                        </Typography>
                        <Input
                            mt={0}
                            pt={0}
                            pb={0}
                            containerWidth="350px"
                            sx={{
                                pl: '4px !important',
                            }}
                            fontSize={12}
                            value={searchValue}
                            backgroundColor="#fff"
                            type="text"
                            noShadow
                            startAdornment={
                                <InputAdornment position="end">
                                    <SearchInsights
                                        height={15}
                                        width={15}
                                        fillColor="#A5A6AB"
                                    />
                                </InputAdornment>
                            }
                            onChange={(e) => {
                                setSearchValue(e.target.value.slice(0, 100));
                            }}
                            placeholder="Search by user’s name"
                        />
                    </Box>

                    {usersUsage && (
                        <SortableTable
                            convertToInternationalCurrencySystem={true}
                            columns={[
                                { id: 'firstName', label: 'User Name' },
                                { id: 'QUERIES', label: 'Message Credit' },
                                { id: 'DOCUMENTS', label: 'Word Count' },
                                { id: 'DILIGENCE', label: 'Reports' },
                                { id: 'TOPICS', label: 'Topics' },
                            ]}
                            data={usersUsage.filter((u) =>
                                u.firstName
                                    .toLowerCase()
                                    .includes(searchValue.toLowerCase())
                            )}
                        />
                    )}
                </BorderedBox>
            )}
            <BootstrapDialogSmall
                onClose={() => {
                    setShowThresholdConfigurationDialog(false);
                }}
                PaperProps={{
                    sx: {
                        p: '0px !important',
                        backgroundColor: '#F9F9FF',
                    },
                }}
                aria-labelledby="customized-dialog-title"
                open={showThresholdConfigurationDialog}
                sx={{
                    p: 0,
                }}
            >
                <BootstrapDialogTitle
                    id="test"
                    onClose={() => {
                        setShowThresholdConfigurationDialog(false);
                    }}
                ></BootstrapDialogTitle>
                <DialogContent sx={{ p: '0px !important' }}>
                    <BorderedBox p={4}>
                        <EmailOutlinedIcon />
                        <Typography mt={4} variant="h6">
                            Notification Alert
                        </Typography>
                        <Typography color={'#4a4b4c'} mt={2} fontSize={14}>
                            Get notified on email when usage exceeds your set
                            threshold.
                        </Typography>
                        <Box
                            mt={6}
                            pb={3}
                            display="flex"
                            justifyContent={'space-between'}
                        >
                            <Typography variant="h6">
                                Set Alert Threshold
                            </Typography>
                            <Typography variant="h6"></Typography>
                            {overview && (
                                <NumberInput
                                    threshold={
                                        overview.notificationAlertThreshold
                                    }
                                    onChange={handleUpdateThreshold}
                                />
                            )}
                        </Box>
                    </BorderedBox>
                </DialogContent>
            </BootstrapDialogSmall>
        </Box>
    ) : (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 'calc(100vh - 150px)',
            }}
        >
            <Lottie animationData={loadingAnimation} loop={true} />
        </Box>
    );
};
