import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Button,
    Grid,
    IconButton,
    Paper,
    Tooltip,
    Typography,
    CircularProgress,
} from '@mui/material';
import React, { useState } from 'react';
import { BorderedBox } from '../../../components/core/box';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import {
    ReportModule,
    ReportSectionSource,
} from '../../../services/interfaces';
import { Pointer } from '../../../components/icons/pointer';
import FadeIn from 'react-fade-in';
import { NavigationLink } from '../../../components/core/link';
import { UtilsService } from '../../../services/utils.service';
import CloseIcon from '@mui/icons-material/Close';
import { NoData } from './no-data';
import { ViewFile } from '../../document-insights/drive/view-file';
import { LiveResponse } from '../../../components/live/live-response';

interface ISWOTSourceProps {
    sources: ReportSectionSource[];
}

const SWOTSources = (props: ISWOTSourceProps) => {
    const [open, setOpen] = useState(false);
    const { sources } = props;
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <Box>
            <Button
                aria-label="close"
                onClick={() => {
                    setOpen(true);
                }}
                sx={{
                    color: '#003',
                    mx: 3,
                    mt: 5,
                }}
                startIcon={
                    <FormatListBulletedIcon
                        sx={{
                            fontSize: 20,
                            color: '#6e3cfb',
                        }}
                    />
                }
            >
                View Sources
            </Button>
            <Dialog
                PaperComponent={Paper}
                PaperProps={{
                    sx: {
                        width: '100%',
                    },
                }}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle pb={0}>
                    <Box>
                        <Box display="flex">
                            <Typography variant="h4">Sources</Typography>

                            <IconButton
                                aria-label="close"
                                onClick={handleClose}
                                sx={{
                                    position: 'absolute',
                                    right: 24,
                                    top: 24,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <Tooltip arrow placement="top" title={`Close`}>
                                    <CloseIcon />
                                </Tooltip>
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box textAlign="left">
                        <FadeIn delay={200}>
                            {sources?.map((s: ReportSectionSource, index) => (
                                <Box py={2}>
                                    <Box id={'citation-' + (index + 1)}>
                                        <Box minHeight={30} display="flex">
                                            <Typography
                                                mt={0.5}
                                                lineHeight={'20px'}
                                                variant="h6"
                                            >
                                                {index + 1}.
                                            </Typography>
                                            <Typography
                                                pl={1}
                                                fontWeight={600}
                                                variant="h6"
                                            >
                                                {s.answerSource == 'ARTICLE'
                                                    ? s.sourceName
                                                    : 'Document'}
                                            </Typography>

                                            <Typography
                                                ml="auto"
                                                mt={1}
                                                fontSize={14}
                                                color="#4B4B4C"
                                            >
                                                {UtilsService.formatStringDate(
                                                    s.answerSource == 'ARTICLE'
                                                        ? s.timestamp
                                                        : s.uploadedOn
                                                )}
                                            </Typography>
                                        </Box>
                                        {s.answerSource == 'ARTICLE' ? (
                                            <Typography
                                                component={NavigationLink}
                                                to={s.url}
                                                target="_blank"
                                                className="clamp-line-1"
                                                variant="h6"
                                                color="#6e3cfb"
                                            >
                                                {s?.title}
                                            </Typography>
                                        ) : (
                                            <ViewFile
                                                fileName={s.title}
                                                pageNo={s.pageNo}
                                                fileId={s.userFileId}
                                                loader={
                                                    <Box display={'flex'}>
                                                        <Typography
                                                            display={'flex'}
                                                            className="clamp-line-1"
                                                            variant="h6"
                                                            color="#6e3cfb"
                                                        >
                                                            {s.title}{' '}
                                                        </Typography>
                                                        <Button
                                                            sx={{
                                                                px: 2,
                                                                py: 0,
                                                                minWidth: 30,
                                                            }}
                                                        >
                                                            <CircularProgress
                                                                size={14}
                                                            />
                                                        </Button>
                                                    </Box>
                                                }
                                            >
                                                <Typography
                                                    display={'flex'}
                                                    className="clamp-line-1"
                                                    variant="h6"
                                                    color="#6e3cfb"
                                                >
                                                    {s.title}{' '}
                                                </Typography>
                                            </ViewFile>
                                        )}

                                        {s.summary && (
                                            <Typography
                                                mt={2}
                                                className="clamp-line-4"
                                                fontSize={14}
                                                variant="subtitle1"
                                            >
                                                {s.summary}{' '}
                                            </Typography>
                                        )}
                                    </Box>
                                    <Box pt={3}>
                                        <Divider />
                                    </Box>
                                </Box>
                            ))}
                        </FadeIn>
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

interface IProps {
    data: ReportModule;
}
export const SWOT = (props: IProps) => {
    const { data } = props;

    const handleCitationClick = (
        index: number,
        sources?: ReportSectionSource[]
    ) => {
        if (!sources) return;
        let s = sources[index - 1];
        if (s) {
            if (s.answerSource == 'ARTICLE') {
                window.open(s.url, '_blank');
            }
            if (s.answerSource == 'USER_DATA') {
                window.open(`/document-insight/file/${s.userFileId}`, '_blank');
            }
        }
    };
    return (
        <BorderedBox
            sx={{
                backgroundColor: '#f9f5ff',
            }}
            pb={4}
            my={4}
        >
            <Typography px={4} pt={4} variant="h4">
                SWOT Analysis
            </Typography>
            <Box
                className="scrollable"
                sx={{
                    maxHeight: 'calc(100vh - 220px)',
                    overflow: 'auto',
                }}
                px={4}
            >
                {data.strengths?.points?.length ||
                data.weaknesses?.points?.length ||
                data.opportunities?.points?.length ||
                data.threats?.points?.length ? (
                    <Grid container>
                        <Grid
                            height={300}
                            sx={{
                                borderRight: '10px solid #24A148',
                                borderBottom: '10px solid #24A148',
                                position: 'relative',
                                '&:before': {
                                    content: '""',
                                    position: 'absolute',
                                    margin: '-1px',
                                    right: -5,
                                    top: -14,
                                    width: 10,
                                    height: 305,
                                    transform: 'rotate(1.5deg)',
                                    backgroundColor: '#f9f5ff',
                                },
                                '&:after': {
                                    content: '""',
                                    position: 'absolute',
                                    margin: '-1px',
                                    right: 1,
                                    bottom: -3,
                                    width: '100%',
                                    height: 10,
                                    transform: 'rotate(-1deg)',
                                    backgroundColor: '#f9f5ff',
                                },
                            }}
                            item
                            sm={6}
                        >
                            <Box>
                                <Typography
                                    variant="h4"
                                    textAlign={'center'}
                                    color="#24A148"
                                >
                                    STRENGTHS
                                </Typography>
                                <Box
                                    className="scrollable"
                                    sx={{
                                        maxHeight: 250,
                                        marginRight: 2.5,
                                        overflow: 'auto',
                                    }}
                                >
                                    {data.strengths?.points?.map((s, i) => (
                                        <Box display="flex" key={i}>
                                            <Box pt={1.5} pr={2}>
                                                {Pointer}
                                            </Box>
                                            <Box
                                                sx={{
                                                    py: 1,
                                                    '& p': {
                                                        margin: '0px !important',
                                                    },
                                                }}
                                            >
                                                <LiveResponse
                                                    onCitationClick={(
                                                        index
                                                    ) => {
                                                        handleCitationClick(
                                                            index,
                                                            data.strengths
                                                                ?.sources
                                                        );
                                                    }}
                                                    text={s}
                                                />
                                            </Box>
                                        </Box>
                                    ))}
                                    {data.strengths && (
                                        <SWOTSources
                                            sources={data.strengths?.sources}
                                        />
                                    )}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid
                            sx={{
                                borderLeft: '10px solid #F1C21B',
                                borderBottom: '10px solid #F1C21B',
                                position: 'relative',
                                '&:before': {
                                    content: '""',
                                    position: 'absolute',
                                    margin: '-1px',
                                    left: -4,
                                    top: -14,
                                    width: 10,
                                    height: 305,
                                    transform: 'rotate(-1.5deg)',
                                    backgroundColor: '#f9f5ff',
                                },
                                '&:after': {
                                    content: '""',
                                    position: 'absolute',
                                    margin: '-1px',
                                    right: 1,
                                    bottom: -3,
                                    width: '100%',
                                    height: 10,
                                    transform: 'rotate(1deg)',
                                    backgroundColor: '#f9f5ff',
                                },
                            }}
                            height={300}
                            item
                            sm={6}
                        >
                            <Box>
                                <Typography
                                    variant="h4"
                                    textAlign={'center'}
                                    color="#F1C21B"
                                >
                                    WEAKNESS
                                </Typography>
                                <Box
                                    className="scrollable"
                                    sx={{
                                        maxHeight: 250,
                                        overflow: 'auto',
                                        pl: 2.5,
                                    }}
                                >
                                    {data.weaknesses?.points?.map((s, i) => (
                                        <Box display="flex" key={i}>
                                            <Box pt={1.5} pr={2}>
                                                {Pointer}
                                            </Box>
                                            <Box
                                                sx={{
                                                    py: 1,
                                                    '& p': {
                                                        margin: '0px !important',
                                                    },
                                                }}
                                            >
                                                <LiveResponse
                                                    onCitationClick={(
                                                        index
                                                    ) => {
                                                        handleCitationClick(
                                                            index,
                                                            data.weaknesses
                                                                ?.sources
                                                        );
                                                    }}
                                                    text={s}
                                                />
                                            </Box>
                                        </Box>
                                    ))}
                                    {data.weaknesses && (
                                        <SWOTSources
                                            sources={data.weaknesses?.sources}
                                        />
                                    )}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid
                            height={300}
                            sx={{
                                borderRight: '10px solid #FF832B',
                                borderTop: '10px solid #FF832B',
                                position: 'relative',
                                '&:before': {
                                    content: '""',
                                    position: 'absolute',
                                    margin: '-1px',
                                    right: -5,
                                    top: 1,
                                    width: 10,
                                    height: 305,
                                    transform: 'rotate(-1.5deg)',
                                    backgroundColor: '#f9f5ff',
                                },
                                '&:after': {
                                    content: '""',
                                    position: 'absolute',
                                    margin: '-1px',
                                    right: 1,
                                    top: -3,
                                    width: '100%',
                                    height: 10,
                                    transform: 'rotate(1deg)',
                                    backgroundColor: '#f9f5ff',
                                },
                            }}
                            item
                            sm={6}
                        >
                            <Box>
                                <Typography
                                    variant="h4"
                                    textAlign={'center'}
                                    color="#FF832B"
                                >
                                    OPPORTUNITIES
                                </Typography>
                                <Box
                                    className="scrollable"
                                    sx={{
                                        maxHeight: 250,
                                        marginRight: 2.5,
                                        overflow: 'auto',
                                    }}
                                >
                                    {data.opportunities?.points?.map((s, i) => (
                                        <Box display="flex" key={i}>
                                            <Box pt={1.5} pr={2}>
                                                {Pointer}
                                            </Box>
                                            <Box
                                                sx={{
                                                    py: 1,
                                                    '& p': {
                                                        margin: '0px !important',
                                                    },
                                                }}
                                            >
                                                <LiveResponse
                                                    onCitationClick={(
                                                        index
                                                    ) => {
                                                        handleCitationClick(
                                                            index,
                                                            data.opportunities
                                                                ?.sources
                                                        );
                                                    }}
                                                    text={s}
                                                />
                                            </Box>
                                        </Box>
                                    ))}
                                    {data.opportunities && (
                                        <SWOTSources
                                            sources={
                                                data.opportunities?.sources
                                            }
                                        />
                                    )}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid
                            sx={{
                                borderLeft: '10px solid #DA1E28',
                                borderTop: '10px solid #DA1E28',
                                position: 'relative',
                                '&:before': {
                                    content: '""',
                                    position: 'absolute',
                                    margin: '-1px',
                                    left: -4,
                                    top: 2,
                                    width: 10,
                                    height: 305,
                                    transform: 'rotate(1.5deg)',
                                    backgroundColor: '#f9f5ff',
                                },
                                '&:after': {
                                    content: '""',
                                    position: 'absolute',
                                    margin: '-1px',
                                    right: 1,
                                    top: -3,
                                    width: '100%',
                                    height: 10,
                                    transform: 'rotate(-1deg)',
                                    backgroundColor: '#f9f5ff',
                                },
                            }}
                            height={300}
                            item
                            sm={6}
                        >
                            <Box>
                                <Typography
                                    variant="h4"
                                    textAlign={'center'}
                                    color="#DA1E28"
                                >
                                    THREATS
                                </Typography>
                                <Box
                                    className="scrollable"
                                    sx={{
                                        maxHeight: 250,
                                        overflow: 'auto',
                                        pl: 2.5,
                                    }}
                                >
                                    {data.threats?.points?.map((s, i) => (
                                        <Box display="flex" key={i}>
                                            <Box pt={1.5} pr={2}>
                                                {Pointer}
                                            </Box>
                                            <Box
                                                sx={{
                                                    py: 1,
                                                    '& p': {
                                                        margin: '0px !important',
                                                    },
                                                }}
                                            >
                                                <LiveResponse
                                                    onCitationClick={(
                                                        index
                                                    ) => {
                                                        handleCitationClick(
                                                            index,
                                                            data.threats
                                                                ?.sources
                                                        );
                                                    }}
                                                    text={s}
                                                />
                                            </Box>
                                        </Box>
                                    ))}
                                    {data.threats && (
                                        <SWOTSources
                                            sources={data.threats?.sources}
                                        />
                                    )}
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                ) : (
                    <NoData />
                )}
            </Box>
        </BorderedBox>
    );
};
