export enum SortOrder {
    ASC = 'ASC',
    DESC = 'DESC',
    NONE = 'NONE',
}

interface IProps {
    sortOrder: SortOrder;
}
export const Sort = (props: IProps) => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        style={{ verticalAlign: 'middle' }}
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="16" height="16" rx="3" fill="#F5F5FA" />
        <path
            d="M10.8503 2.70923C10.4484 2.66848 10.1665 2.99884 10.1665 3.33349V12.6668C10.1665 12.943 10.3904 13.1668 10.6665 13.1668C10.9426 13.1668 11.1665 12.943 11.1665 12.6668L11.1665 3.90913C11.176 3.91652 11.1856 3.92417 11.1955 3.93209C11.4106 4.10482 11.6663 4.36173 12.0461 4.74499L12.978 5.68524C13.1724 5.88137 13.489 5.88279 13.6851 5.6884C13.8813 5.49401 13.8827 5.17743 13.6883 4.9813L12.7361 4.02054C12.3818 3.66309 12.0864 3.36496 11.8217 3.1524C11.5441 2.92945 11.2523 2.75986 10.8968 2.71456C10.8813 2.71258 10.8658 2.71081 10.8503 2.70923Z"
            fill={
                props.sortOrder == SortOrder.ASC
                    ? '#6e3cfb'
                    : props.sortOrder == SortOrder.DESC
                    ? '#e1e2f0'
                    : '#585960'
            }
        />
        <path
            d="M5.83317 3.3335C5.83317 3.05736 5.60931 2.8335 5.33317 2.8335C5.05703 2.8335 4.83317 3.05736 4.83317 3.3335L4.83317 11.9577C4.82369 11.9503 4.81403 11.9427 4.80416 11.9347C4.58908 11.762 4.33339 11.5051 3.95353 11.1218L3.02163 10.1816C2.82724 9.98546 2.51066 9.98405 2.31453 10.1784C2.1184 10.3728 2.11699 10.6894 2.31138 10.8855L3.26359 11.8463C3.61785 12.2037 3.91332 12.5019 4.178 12.7144C4.45561 12.9374 4.74738 13.107 5.10284 13.1523C5.53658 13.2076 5.83317 12.8479 5.83317 12.4993L5.83317 3.3335Z"
            fill={
                props.sortOrder == SortOrder.ASC
                    ? '#e1e2f0'
                    : props.sortOrder == SortOrder.DESC
                    ? '#6e3cfb'
                    : '#585960'
            }
        />
    </svg>
);
