import React from 'react';

interface IProps {
    height?: number;
    width?: number;
}

export const Logo = (props: IProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width || '159'}
            height={props.height || '24'}
            viewBox="0 0 159 24"
            fill="none"
        >
            <g clip-path="url(#clip0_2644_5979)">
                <path
                    d="M5.8229 9.53809L0 19.0575H8.63677L14.4597 9.53809H5.8229Z"
                    fill="#6E3CFA"
                />
                <path
                    d="M25.9268 8.03613H6.73463L3.00781 14.1289H22.2L25.9268 8.03613Z"
                    fill="#6E3CFA"
                />
                <path
                    d="M11.4065 0L7.67969 6.09226H26.8724L30.5987 0H11.4065Z"
                    fill="#6E3CFA"
                />
            </g>
            <path
                d="M153.617 1.36719H156.009V4.91593H158.691V6.83488H156.009V15.2204C156.009 16.6925 156.114 17.0605 157.481 17.0605H158.691V19.0583H156.956C154.248 19.0583 153.617 18.4537 153.617 15.5096V6.83488H151.383V4.91593H153.617V1.36719Z"
                fill="black"
            />
            <path
                d="M140.919 7.04454C141.734 5.7039 143.101 4.67871 145.493 4.67871C148.911 4.67871 150.146 6.88682 150.146 10.1201V19.0577H147.754V10.8299C147.754 8.64804 147.386 6.75538 144.652 6.75538C142.339 6.75538 140.919 8.35889 140.919 11.3819V19.0577H138.527V4.91529H140.919V7.04454Z"
                fill="black"
            />
            <path
                d="M135.521 0.525391V2.89122H133.129V0.525391H135.521ZM135.521 4.91531V19.0577H133.129V4.91531H135.521Z"
                fill="black"
            />
            <path
                d="M131.056 12.0391C131.056 17.165 127.56 19.3994 124.274 19.3994C120.988 19.3994 117.492 17.165 117.492 12.0391C117.492 6.9131 120.988 4.67871 124.274 4.67871C127.56 4.67871 131.056 6.9131 131.056 12.0391ZM124.274 17.2965C126.43 17.2965 128.506 15.6667 128.506 12.0391C128.506 8.28003 126.43 6.78167 124.274 6.78167C122.119 6.78167 120.042 8.17488 120.042 12.0391C120.042 15.7981 122.119 17.2965 124.274 17.2965Z"
                fill="black"
            />
            <path
                d="M105.523 4.91529V7.14969C106.39 5.65133 107.889 4.67871 110.149 4.67871C113.383 4.67871 116.195 7.38627 116.195 12.0391C116.195 16.7181 113.383 19.3994 110.149 19.3994C107.889 19.3994 106.39 18.4268 105.523 16.9284V23.6579H103.131V4.91529H105.523ZM109.545 17.349C111.99 17.349 113.593 15.4038 113.593 12.0391C113.593 8.67433 111.99 6.7291 109.545 6.7291C107.416 6.7291 105.418 8.30631 105.418 12.0391C105.418 15.7718 107.416 17.349 109.545 17.349Z"
                fill="black"
            />
            <path
                d="M90.248 12.7751C90.3531 15.8244 92.5087 17.2965 94.6116 17.2965C96.7146 17.2965 97.8712 16.3501 98.4758 14.9569H100.947C100.29 17.4016 98.0552 19.3994 94.6116 19.3994C90.1429 19.3994 87.6719 16.1924 87.6719 12.0128C87.6719 7.54399 90.6686 4.67871 94.5328 4.67871C98.8438 4.67871 101.446 8.30631 101.131 12.7751H90.248ZM90.2743 10.7247H98.6073C98.5547 8.7269 97.0563 6.7291 94.5328 6.7291C92.4035 6.7291 90.4846 7.88572 90.2743 10.7247Z"
                fill="black"
            />
            <path
                d="M83.2639 4.91529H85.656V17.165C85.656 20.0566 85.1303 21.2395 84.3154 22.1333C83.2902 23.3162 81.529 23.9996 79.426 23.9996C76.219 23.9996 74.0635 22.5276 73.6166 19.6623H76.1664C76.4819 20.9241 77.2442 21.8967 79.4523 21.8967C80.7403 21.8967 81.713 21.5287 82.3438 20.8452C82.8696 20.2932 83.2639 19.636 83.2639 17.717V16.3764C82.3964 17.8748 81.0295 18.8211 78.7688 18.8211C75.6407 18.8211 72.7754 16.2976 72.7754 11.7499C72.7754 7.22855 75.6407 4.67871 78.7688 4.67871C81.0295 4.67871 82.3964 5.65133 83.2639 7.14969V4.91529ZM79.3734 6.7291C77.0339 6.7291 75.3778 8.5429 75.3778 11.7499C75.3778 14.9832 77.0339 16.797 79.3734 16.797C81.5027 16.797 83.369 15.3512 83.369 11.7499C83.369 8.17488 81.5027 6.7291 79.3734 6.7291Z"
                fill="black"
            />
            <path
                d="M72.4941 7.41304C71.9947 7.36046 71.5478 7.30789 71.1535 7.30789C68.9454 7.30789 67.8413 8.54338 67.8413 11.6452V19.0582H65.4492V4.91577H67.7888V7.38675C68.4985 5.78325 69.9443 4.88949 71.7844 4.83691C72.021 4.83691 72.2313 4.83691 72.4941 4.8632V7.41304Z"
                fill="black"
            />
            <path
                d="M63.3766 12.0391C63.3766 17.165 59.8804 19.3994 56.5945 19.3994C53.3087 19.3994 49.8125 17.165 49.8125 12.0391C49.8125 6.9131 53.3087 4.67871 56.5945 4.67871C59.8804 4.67871 63.3766 6.9131 63.3766 12.0391ZM56.5945 17.2965C58.7501 17.2965 60.8267 15.6667 60.8267 12.0391C60.8267 8.28003 58.7501 6.78167 56.5945 6.78167C54.439 6.78167 52.3623 8.17488 52.3623 12.0391C52.3623 15.7981 54.439 17.2965 56.5945 17.2965Z"
                fill="black"
            />
            <path
                d="M36.6543 0H49.0355V2.31325H39.3356V8.09639H48.4834V10.4096H39.3356V19.0581H36.6543V0Z"
                fill="black"
            />
            <defs>
                <clipPath id="clip0_2644_5979">
                    <rect width="30.6003" height="19.0581" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
