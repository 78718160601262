interface IProps {
    color?: string;
    size?: number;
}
export const Eye = (props: IProps) => (
    <svg
        width={props.size || '20'}
        height={props.size || '20'}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.99789 6.87467C8.272 6.87467 6.87289 8.27378 6.87289 9.99967C6.87289 11.7256 8.272 13.1247 9.99789 13.1247C11.7238 13.1247 13.1229 11.7256 13.1229 9.99967C13.1229 8.27378 11.7238 6.87467 9.99789 6.87467ZM8.12289 9.99967C8.12289 8.96414 8.96236 8.12467 9.99789 8.12467C11.0334 8.12467 11.8729 8.96414 11.8729 9.99967C11.8729 11.0352 11.0334 11.8747 9.99789 11.8747C8.96236 11.8747 8.12289 11.0352 8.12289 9.99967Z"
            fill={props.color || '#6e3cfb'}
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.99789 2.70801C8.21376 2.70801 6.60059 3.5905 5.29099 4.66277C3.97425 5.74089 2.89757 7.06522 2.17406 8.07302L2.1144 8.156C1.67884 8.7612 1.32031 9.25939 1.32031 9.99967C1.32031 10.74 1.67884 11.2381 2.1144 11.8434L2.17407 11.9263C2.89757 12.9341 3.97425 14.2585 5.291 15.3366C6.60059 16.4088 8.21376 17.2913 9.99789 17.2913C11.782 17.2913 13.3952 16.4088 14.7048 15.3366C16.0215 14.2585 17.0982 12.9341 17.8217 11.9263L17.8814 11.8434C18.3169 11.2381 18.6755 10.74 18.6755 9.99967C18.6755 9.25939 18.3169 8.76121 17.8814 8.156L17.8217 8.07302C17.0982 7.06522 16.0215 5.74089 14.7048 4.66277C13.3952 3.5905 11.782 2.70801 9.99789 2.70801ZM3.18949 8.80199C3.88055 7.83939 4.88412 6.61146 6.08289 5.62993C7.2888 4.64256 8.62641 3.95801 9.99789 3.95801C11.3694 3.95801 12.707 4.64256 13.9129 5.62993C15.1117 6.61146 16.1152 7.83939 16.8063 8.80199C17.3263 9.52629 17.4255 9.7009 17.4255 9.99967C17.4255 10.2984 17.3263 10.4731 16.8063 11.1974C16.1152 12.16 15.1117 13.3879 13.9129 14.3694C12.707 15.3568 11.3694 16.0413 9.99789 16.0413C8.62641 16.0413 7.2888 15.3568 6.08289 14.3694C4.88412 13.3879 3.88055 12.16 3.18949 11.1974C2.66952 10.4731 2.57031 10.2984 2.57031 9.99967C2.57031 9.7009 2.66952 9.52629 3.18949 8.80199Z"
            fill={props.color || '#6e3cfb'}
        />
    </svg>
);
