import React, { useContext, useEffect, useState } from 'react';
import { BorderedBox } from '../../../../components/core/box';
import { Box, Typography, Tabs, Divider, Switch } from '@mui/material';
import { CustomTab } from '../../../chat-widget/chatbot/settings';
import SingleSelect from '../../../../components/core/input/single-select';
import { DriveFilters } from '../../../document-insights/drive/drive-filters';
import {
    FileFilters,
    IDiligenceTemplateConfiguration,
} from '../../../../services/interfaces';
import { DocumentInsightsContext } from '../../../../providers/document-provider';
import { useDocumentService } from '../../../../services/document.service';
import { IOSSwitch } from '../../../../components/core/ios-switch';
import {
    AnswerLength,
    DataSourceOptions,
    DateRangeSelect,
} from './common-input';

interface IProps {
    config: IDiligenceTemplateConfiguration;
    updateConfig: (config: IDiligenceTemplateConfiguration) => void;
}

export const RecentHappening = (props: IProps) => {
    return (
        <BorderedBox sx={{ backgroundColor: '#f9f5ff', p: 3 }}>
            <Box py={1} display="flex" justifyContent="space-between">
                <Box>
                    <Typography color="#003" variant="h6">
                        Recent Information
                    </Typography>
                    <Typography color="#8D8D8D" fontSize={12}>
                        Recent events corresponding to the company
                    </Typography>
                </Box>
                <Box></Box>
            </Box>

            <AnswerLength
                updateConfig={props.updateConfig}
                config={props.config}
            />
            <DataSourceOptions
                updateConfig={props.updateConfig}
                config={props.config}
            />
        </BorderedBox>
    );
};
