import {
    Box,
    Button,
    DialogContent,
    Divider,
    SxProps,
    Theme,
} from '@mui/material';
import React, { useState } from 'react';
import {
    BootstrapDialog,
    BootstrapDialogLarge,
    BootstrapDialogMedium,
    BootstrapDialogTitle,
} from '../../../components/core/dialog';
import { Filter } from '../../../components/icons/filter';
import { UploadWidget } from './upload-widget';
import { Upload as UploadIcon } from '../../../components/icons/upload';
interface IProps {
    min?: boolean;
    mt?: number;
    containerSx?: SxProps<Theme>;
}

export const Upload = (props: IProps) => {
    const [openDialog, setOpenDialog] = useState<boolean>(false);

    const closeDialog = () => {
        setOpenDialog(false);
    };
    return (
        <Box sx={{ ...props.containerSx }}>
            {props.min ? (
                <Button
                    startIcon={<UploadIcon size={14} color="#fff" />}
                    onClick={() => {
                        setOpenDialog(true);
                    }}
                    variant="contained"
                    sx={{
                        ml: 2,
                        padding: '6px 7px 2px 7px',
                        textAlign: 'center',
                        mt: props.mt || 3,
                        fontSize: 12,
                        fontWeight: 600,
                        minWidth: 0,
                        display: 'table-cell',
                        border: '1px solid #D7D9EC',
                        borderRadius: 2,
                        backgroundColor: '#6e3cfb',
                        color: '#fff',
                        '&:hover': {
                            backgroundColor: '#343ebe',
                            boxShadow:
                                '0px 0px 0px 1px rgba(41, 49, 155, 0.50) inset, 0px 0px 2px 1px rgba(180, 197, 255, 0.50)',
                            color: '#fff',
                        },
                        '& svg': {
                            transform: 'scale(1.3)',
                            ml: 0,
                            mr: 0,
                        },
                    }}
                ></Button>
            ) : (
                <Button
                    startIcon={
                        <Box mr={1}>
                            <UploadIcon color="#fff" />
                        </Box>
                    }
                    onClick={() => {
                        setOpenDialog(true);
                    }}
                    variant="contained"
                    sx={{
                        ml: 2,
                        padding: '6px 12px 6px 12px',
                        textAlign: 'center',
                        mt: 3,
                        fontSize: 16,
                        fontWeight: 400,
                        height: 50,
                        maxWidth: 120,
                        minWidth: 10,
                        width: 120,
                        border: '1px solid #D7D9EC',
                        borderRadius: 2,
                        backgroundColor: '#6e3cfb',
                        color: '#fff',
                        '&:hover': {
                            backgroundColor: '#343ebe',
                            boxShadow:
                                '0px 0px 0px 1px rgba(41, 49, 155, 0.50) inset, 0px 0px 2px 1px rgba(180, 197, 255, 0.50)',
                            color: '#fff',
                        },
                        '& svg': {
                            transform: 'scale(1.3)',
                        },
                    }}
                >
                    Upload
                </Button>
            )}
            <BootstrapDialogMedium
                onClose={closeDialog}
                aria-labelledby="customized-dialog-title"
                open={true}
                sx={{
                    p: 4,
                    textAlign: 'center',
                    display: openDialog ? '' : 'none',
                }}
            >
                <DialogContent>
                    <UploadWidget
                        onClose={() => {
                            setOpenDialog(false);
                        }}
                    />
                </DialogContent>
            </BootstrapDialogMedium>
        </Box>
    );
};
