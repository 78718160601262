import Box from '@mui/material/Box';
import { useContext, useState } from 'react';
import { FileUploadSection } from './file-upload';

import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import {
    Button,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    Tab,
    Tabs,
    Typography,
} from '@mui/material';
import { UtilsService } from '../../../services/utils.service';
import { useDocumentService } from '../../../services/document.service';
import { useSnackbar } from 'notistack';
import { DocumentInsightsContext } from '../../../providers/document-provider';
import { TextUpload } from './text-upload';
import { UrlUploadSection } from './url-upload';
import { WebCrawl } from './web-crawl';
import { usePermissions } from '../../../providers/permissions-provider';
import { Authorize, HtmlTooltip } from '../../../components/authorize';
import { FeatureName, SubFeatureName } from '../../../services/interfaces';
import { CloneProps } from '../drive';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    adjustHeight?: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, adjustHeight, ...other } = props;

    return (
        <div
            className="scrollable"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{
                overflow: 'auto',
                position: 'relative',
            }}
            {...other}
        >
            <Box sx={{ pt: 0, display: value === index ? 'block' : 'none' }}>
                {children}
            </Box>
        </div>
    );
}

interface IProps {
    onClose: () => void;
}

export const UploadWidget = (props: IProps) => {
    const { isFeatureAuthorized, isSubFeatureAuthorized } = usePermissions();
    const [value, setTabValue] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if ([0, 1, 2, 3].includes(newValue)) {
            setTabValue(newValue);
        }
    };

    const onClose = (tab?: number) => {
        props.onClose();
        if (tab != undefined) {
            setTabValue(tab);
        }
    };
    return (
        <Box>
            <Box pb={2}>
                <Tabs
                    sx={{
                        backgroundColor: '#EBECF6',
                        borderRadius: 8,
                        p: 1,
                        '& .MuiTabs-flexContainer': {
                            flexWrap: 'wrap',
                            justifyContent: 'space-between',
                        },
                        '& .Mui-selected': {
                            backgroundColor: '#fff !important',
                            borderRadius: '24px !important',
                        },
                        '& .MuiTabs-indicator': {
                            display: 'none',
                        },
                        '& .MuiTab-root': {
                            color: '#003',
                            minWidth: 120,
                        },
                    }}
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                >
                    <Tab label="Files" />
                    <Tab label="Text" />
                    <Tab label="URL" />
                    <CloneProps>
                        {(tabProps: any) => (
                            <HtmlTooltip
                                arrow
                                placement={'top'}
                                open={
                                    isSubFeatureAuthorized(
                                        FeatureName.DocumentInsights,
                                        SubFeatureName.WebCrawl
                                    )
                                        ? false
                                        : undefined
                                }
                                title={
                                    <Box py={2} textAlign={'center'} px={1}>
                                        <Typography variant="h5">
                                            {'Feature Unavailable'}
                                        </Typography>
                                        <Typography mt={2}>
                                            {`This feature is not included in your current plan. Upgrade to access this functionality.`}
                                        </Typography>
                                    </Box>
                                }
                            >
                                <span>
                                    <Tab
                                        {...tabProps}
                                        disabled={
                                            !isSubFeatureAuthorized(
                                                FeatureName.DocumentInsights,
                                                SubFeatureName.WebCrawl
                                            )
                                        }
                                        label={'Web Crawl'}
                                    />
                                </span>
                            </HtmlTooltip>
                        )}
                    </CloneProps>
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <FileUploadSection />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <TextUpload onClose={onClose} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <UrlUploadSection />
            </TabPanel>

            <TabPanel value={value} index={3}>
                <WebCrawl onClose={onClose} />
            </TabPanel>

            {[0, 2].includes(value) && (
                <Box pt={4}>
                    <Button
                        sx={{
                            minWidth: 100,
                            borderColor: '#D7D9EC',
                            color: '#6e3cfb',
                            borderRadius: 2,
                            fontSize: 16,
                            float: 'right',
                            height: 36,
                            border: '2px solid #6e3cfb',
                            fontWeight: 600,
                            '&:hover': {
                                border: '2px solid #6e3cfb',
                            },
                        }}
                        onClick={() => {
                            onClose();
                        }}
                        variant="outlined"
                    >
                        Close
                    </Button>
                </Box>
            )}
        </Box>
    );
};
