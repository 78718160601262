interface IProps {
    size?: number;
}

export const PILogo = (props: IProps) => (
    <svg
        width={props.size || '20'}
        height={props.size || '20'}
        viewBox="0 0 147 217"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M27 203.416L27 15.5843C27 8.08188 20.9558 2 13.5 2C6.04416 2 -1.90735e-06 8.08188 -1.90735e-06 15.5843L-1.90735e-06 203.416C-1.90735e-06 210.918 6.04416 217 13.5 217C20.9558 217 27 210.918 27 203.416Z"
            fill="#fff"
        />
        <path
            d="M27 203.416L27 15.5843C27 8.08188 20.9558 2 13.5 2C6.04416 2 -1.90735e-06 8.08188 -1.90735e-06 15.5843L-1.90735e-06 203.416C-1.90735e-06 210.918 6.04416 217 13.5 217C20.9558 217 27 210.918 27 203.416Z"
            fill="#fff"
        />
        <path
            d="M67 127.241V38.6673C67 31.0685 60.9558 32.0404 53.5 32.0404C46.0442 32.0404 40 31.0685 40 38.6673L40 127.241C40 134.84 46.0442 141 53.5 141C60.9558 141 67 134.84 67 127.241Z"
            fill="#fff"
        />
        <path
            d="M147 99.5217V44.4301C147 38.8793 140.956 32 133.5 32C126.044 32 120 38.8793 120 44.4301V99.5217C120 105.073 126.044 111 133.5 111C140.956 111 147 105.073 147 99.5217Z"
            fill="#fff"
        />
        <path
            d="M80 15.588V103.062C80 110.566 86.1471 111 93.6029 111C101.059 111 107 110.566 107 103.062V15.588C107 8.08353 100.956 2 93.5 2C86.0442 2 80 8.08353 80 15.588Z"
            fill="#fff"
        />
        <path
            d="M50.9393 4.19555C52.1088 2.27944 54.8912 2.27944 56.0607 4.19555L66.7365 21.6871C67.9567 23.6862 66.5178 26.25 64.1758 26.25H42.8242C40.4821 26.25 39.0433 23.6862 40.2635 21.6871L50.9393 4.19555Z"
            fill="#fff"
        />
        <path
            d="M96.0607 138.804C94.8912 140.721 92.1088 140.721 90.9393 138.804L80.2635 121.313C79.0433 119.314 80.4822 116.75 82.8242 116.75L104.176 116.75C106.518 116.75 107.957 119.314 106.737 121.313L96.0607 138.804Z"
            fill="#fff"
        />
    </svg>
);
