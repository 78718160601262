import {
    Autocomplete,
    AutocompleteChangeDetails,
    AutocompleteChangeReason,
    Box,
    Button,
    CircularProgress,
    Divider,
    FormControlLabel,
    IconButton,
    InputBase,
    Paper,
    Switch,
    TextField,
    Tooltip,
    Typography,
    styled,
} from '@mui/material';
import React, { useContext, useRef, useState } from 'react';
import { UtilsService } from '../../../services/utils.service';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
//@ts-ignore
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import CachedIcon from '@mui/icons-material/Cached';
import { useDocumentService } from '../../../services/document.service';
import { DocumentInsightsContext } from '../../../providers/document-provider';
import {
    EFileUploadType,
    FileTag,
    FileUploadRequest,
    IFileUploadResponseChunkStreamError,
    IFileUploadResponseChunkUploadProgressDetails,
    Tag,
} from '../../../services/interfaces';
import { Delete } from '../../../components/icons/delete';
import { TagBlock } from '../tags/edit-tags';
import { KeyboardKeys } from '../../../components/core/enums/keyboard-keys.enum';
import loadingAnimation from '../../../components/animations/circular-loader.json';
import Lottie from 'lottie-react';
import { Info } from '../../../components/icons/info';

interface IProps {
    onClose: (tab: number) => void;
}

const StyledLottie = styled(Lottie)(({ theme }: any) => ({
    '& rect': {
        fill: '#F5F5F5',
    },
}));

export const WebCrawl = (props: IProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const myRef = useRef(null);
    const [addTags, setAddTags] = useState(false);
    const documentContext = useContext(DocumentInsightsContext);
    const [docTags, setDocTags] = useState<FileTag[]>([]);
    const [error, setError] = useState<string>('');
    const [processing, setProcessing] = useState<boolean>(false);
    const [urlInput, setUrlInput] = useState<string>('');
    const [xhrRef, setXHRRef] = useState<XMLHttpRequest | null>(null);
    const [tagInputValue, setTagInputValue] = useState('');
    const [crawledFiles, setCrawledFiles] = useState<
        IFileUploadResponseChunkUploadProgressDetails[]
    >([]);
    const [isUploadingUrl, setIsUploadingUrl] = useState<boolean>(false);
    const { uploadUrl, fetchUploadFileResponse, submitFile } =
        useDocumentService();
    const [fileStatus, setFileStatus] = useState<
        'IDLE' | 'IN_PROGRESS' | 'SUCCESS' | 'FAILED'
    >('IDLE');

    const addTag = () => {
        if (
            tagInputValue &&
            docTags.filter((t: FileTag) => t.title == tagInputValue).length == 0
        ) {
            addTagValue(tagInputValue);
            setTagInputValue('');
        }
    };

    const handleFileUploadResponse = async (res: string) => {
        let files: IFileUploadResponseChunkUploadProgressDetails[] = [];
        let error = false;
        for (let v of res.split('\n')) {
            try {
                let data = JSON.parse(v);
                switch (data.chunkType) {
                    case 'UPLOAD_COMPLETE': {
                        let f =
                            data.data as IFileUploadResponseChunkUploadProgressDetails;
                        files.push(f);
                        setCrawledFiles([...files]);
                        break;
                    }
                    case 'STREAM_ERROR': {
                        error = true;
                        setError(
                            (data.data as IFileUploadResponseChunkStreamError)
                                .message
                        );
                        break;
                    }
                    case 'TERMINATION': {
                        setIsUploadingUrl(false);
                        if (error || files.length == 0) {
                            setFileStatus('FAILED');
                        } else {
                            setFileStatus('SUCCESS');
                        }
                        break;
                    }
                }
            } catch (error) {}
        }
    };

    const onSubmitClick = async () => {
        setProcessing(true);
        let res = await submitFile(
            crawledFiles.map((f) => f.userFileId),
            docTags
        );
        setProcessing(false);
        if (res && documentContext) {
            documentContext.setRefreshCount(documentContext.refreshCount + 1);
            enqueueSnackbar(`Uploaded successfully!`);
            setCrawledFiles([]);
            setFileStatus('IDLE');
            setUrlInput('');
            props.onClose(0);
        }
    };

    const abort = () => {
        if (xhrRef) {
            xhrRef.abort();
        }
        setCrawledFiles([]);
        setDocTags([]);
        setAddTags(false);
        setIsUploadingUrl(false);
        setFileStatus('IDLE');
    };

    const handleTagInput = (
        event: React.SyntheticEvent<Element, Event>,
        value: string[],
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<string> | undefined
    ) => {
        if (['createOption', 'selectOption'].includes(reason)) {
            if (details) {
                addTagValue(details?.option);
                setTagInputValue('');
            }
        }
        if (reason == 'removeOption') {
            if (details?.option) {
                setDocTags([
                    ...docTags.filter(
                        (t: FileTag) => t.title != details.option
                    ),
                ]);
            }
        }
    };
    const addTagValue = (value: string) => {
        if (
            documentContext?.filters &&
            value &&
            docTags.filter((t: FileTag) => t.title == value).length == 0
        ) {
            let tagToAdd: FileTag = { title: value };
            let availableTag = documentContext.filters.tags.filter(
                (t: Tag) => t.title == value
            )[0];
            if (availableTag) {
                tagToAdd = { title: value, userTagId: availableTag.userTagId };
            }
            setDocTags([...docTags, tagToAdd]);
        }
    };

    const uploadUrlClick = async () => {
        setIsUploadingUrl(true);
        setCrawledFiles([]);
        setDocTags([]);
        setError('');
        setAddTags(false);
        setFileStatus('IN_PROGRESS');
        let data: FileUploadRequest = {
            url: urlInput,
            upload: EFileUploadType.WEB_CRAWL,
        };
        let xhr = await fetchUploadFileResponse(data, handleFileUploadResponse);
        setXHRRef(xhr);
    };
    return (
        <Box>
            <Box minHeight={406}>
                <Box textAlign={'left'}>
                    <Typography fontSize={14} fontWeight={400}>
                        Input URL of a website or a sitemap, and Forgepoint will
                        navigate through and retrieve all pages.
                    </Typography>
                    <Typography fontSize={14} fontWeight={400}>
                        <b>Note:</b> Maximum number of pages that can be crawled
                        is 250. For scraping more pages plus pdfs from your
                        urls, contact us at{' '}
                        <Box
                            fontWeight={600}
                            sx={{ textDecoration: 'none' }}
                            component="a"
                            href="mailto:support@photoninsights.com"
                        >
                            support@photoninsights.com
                        </Box>
                    </Typography>
                </Box>
                <Paper
                    elevation={0}
                    component="form"
                    sx={{
                        p: '8px !important',
                        mt: 4,
                        width: '100% !important',
                        display: 'flex',
                        alignItems: 'center',
                        border: '1px solid #D7D9EC',
                        borderRadius: 2,
                    }}
                >
                    <InputBase
                        sx={{ ml: 1, flex: 1, width: '100%' }}
                        placeholder={
                            'https://www.example.com  (or)  https://www.example.com/sitemap.xml'
                        }
                        value={urlInput}
                        onChange={(e) => {
                            setUrlInput(e.target.value);
                        }}
                        onKeyDown={(
                            event: React.KeyboardEvent<
                                HTMLTextAreaElement | HTMLInputElement
                            >
                        ) => {
                            if (event.key == KeyboardKeys.ENTER) {
                                uploadUrlClick();
                                event.preventDefault();
                            }
                        }}
                        inputProps={{ 'aria-label': 'Enter an URL here' }}
                    />
                    <Button
                        sx={{
                            px: 2,
                            fontSize: 12,
                            borderRadius: 2,
                            py: 1,
                        }}
                        onClick={uploadUrlClick}
                        disabled={
                            !UtilsService.isUrlValid(urlInput) || isUploadingUrl
                        }
                        variant="contained"
                    >
                        {isUploadingUrl ? (
                            <CircularProgress
                                size={20}
                                sx={{
                                    color: '#ffffff',
                                    ml: 2,
                                }}
                            />
                        ) : (
                            'Crawl'
                        )}
                    </Button>
                </Paper>

                {fileStatus !== 'IDLE' && (
                    <Box py={2}>
                        <Divider />
                    </Box>
                )}

                {fileStatus == 'FAILED' && (
                    <Box pt={15}>
                        <ReportProblemIcon
                            sx={{ fontSize: 70 }}
                            color="warning"
                        />
                        <Typography
                            fontSize={14}
                            mt={2}
                            fontWeight={400}
                            color="#4B4B4C"
                        >
                            {error}{' '}
                        </Typography>
                        <Button
                            variant="text"
                            sx={{ p: 0, ml: 2, fontWeight: 600 }}
                            onClick={uploadUrlClick}
                        >
                            Retry
                        </Button>
                    </Box>
                )}

                {fileStatus !== 'IDLE' && fileStatus !== 'FAILED' && (
                    <Box display={'flex'} justifyContent={'space-between'}>
                        <Box display={'flex'}>
                            <Typography
                                fontSize={14}
                                width={72}
                                fontWeight={600}
                                textAlign={'left'}
                            >
                                Links: {crawledFiles.length}
                            </Typography>
                            {fileStatus == 'IN_PROGRESS' && (
                                <Box
                                    sx={{
                                        height: 22,
                                        width: 22,
                                        ml: 2,
                                        display: 'flex',
                                    }}
                                >
                                    <Lottie
                                        animationData={loadingAnimation}
                                        loop={true}
                                    />
                                </Box>
                            )}
                            <Box ml={2}>
                                {fileStatus == 'SUCCESS' &&
                                    crawledFiles.length < 250 && (
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M9.9974 18.3337C14.5998 18.3337 18.3307 14.6027 18.3307 10.0003C18.3307 5.39795 14.5998 1.66699 9.9974 1.66699C5.39502 1.66699 1.66406 5.39795 1.66406 10.0003C1.66406 14.6027 5.39502 18.3337 9.9974 18.3337ZM14.6297 7.91722C14.86 7.66005 14.8381 7.26492 14.581 7.03468C14.3238 6.80444 13.9287 6.82626 13.6984 7.08343L10.8908 10.2194C10.3219 10.8549 9.93873 11.2806 9.61116 11.5559C9.29912 11.8182 9.11584 11.8753 8.95573 11.8753C8.79562 11.8753 8.61234 11.8182 8.3003 11.5559C7.97273 11.2806 7.5896 10.8549 7.02068 10.2194L6.29637 9.4104C6.06613 9.15323 5.67101 9.1314 5.41384 9.36165C5.15667 9.59189 5.13484 9.98702 5.36508 10.2442L6.12034 11.0878C6.65021 11.6796 7.09218 12.1733 7.49602 12.5128C7.92322 12.8718 8.384 13.1253 8.95573 13.1253C9.52746 13.1253 9.98824 12.8718 10.4154 12.5128C10.8193 12.1733 11.2612 11.6797 11.7911 11.0878L14.6297 7.91722Z"
                                                fill="#19AF55"
                                            />
                                        </svg>
                                    )}
                                {fileStatus == 'SUCCESS' &&
                                    crawledFiles.length >= 250 && (
                                        <Tooltip
                                            arrow
                                            placement="top"
                                            title={'Reached Crawl Limit'}
                                        >
                                            <Box
                                                sx={{
                                                    position: 'relative',
                                                    top: 3,
                                                }}
                                                component="span"
                                            >
                                                <Info color="#FF832B" />
                                            </Box>
                                        </Tooltip>
                                    )}
                            </Box>
                        </Box>
                        {fileStatus == 'SUCCESS' && (
                            <Button
                                variant="text"
                                sx={{ py: 0, mr: 3 }}
                                onClick={() => {
                                    setCrawledFiles([]);
                                    setAddTags(false);
                                    setFileStatus('IDLE');
                                }}
                                color="error"
                            >
                                Delete All
                            </Button>
                        )}
                        {fileStatus == 'IN_PROGRESS' && (
                            <Button
                                variant="text"
                                sx={{ mr: 3, p: 1 }}
                                onClick={abort}
                                color="error"
                            >
                                Abort
                            </Button>
                        )}
                    </Box>
                )}
                <Box
                    sx={{
                        maxHeight: 270,
                        overflowY: 'auto',
                    }}
                >
                    {fileStatus == 'IN_PROGRESS' && (
                        <Box
                            px={4}
                            mt={2}
                            justifyContent={'space-between'}
                            display={'flex'}
                            sx={{
                                backgroundColor: '#F5F5F5',
                                border: '1px solid #D7D9EC',
                                borderRadius: 1,
                            }}
                        >
                            <Typography
                                py={1.25}
                                textAlign={'left'}
                                className="clamp-line-1"
                                variant="caption"
                            >
                                Loading...
                            </Typography>
                            <Box
                                sx={{
                                    height: 26,
                                    width: 26,
                                    p: 1,
                                    display: 'flex',
                                    backgroundColor: '#F5F5F5',
                                }}
                            >
                                <StyledLottie
                                    animationData={loadingAnimation}
                                    loop={true}
                                />
                            </Box>
                        </Box>
                    )}
                    {crawledFiles.map((file) => (
                        <Box
                            py={1.25}
                            px={4}
                            mt={2}
                            justifyContent={'space-between'}
                            display={'flex'}
                            sx={{
                                backgroundColor: '#F5F5F5',
                                border: '1px solid #D7D9EC',
                                borderRadius: 1,
                            }}
                        >
                            <Typography
                                textAlign={'left'}
                                className="clamp-line-1"
                                variant="caption"
                            >
                                {file.url}
                            </Typography>
                            <IconButton
                                onClick={() =>
                                    setCrawledFiles([
                                        ...crawledFiles.filter(
                                            (c) => c.url != file.url
                                        ),
                                    ])
                                }
                                sx={{
                                    p: 0,
                                    ml: 3,
                                    '&.Mui-disabled': {
                                        fillOpacity: 0.35,
                                    },
                                }}
                            >
                                <Delete color="#D82F44" size={18} />
                            </IconButton>
                        </Box>
                    ))}
                </Box>
                {fileStatus == 'SUCCESS' && (
                    <Box pt={4}>
                        <Box
                            pr={3}
                            display="flex"
                            justifyContent={'space-between'}
                        >
                            <Typography fontSize={14} fontWeight={600}>
                                Add Tag
                            </Typography>
                            <FormControlLabel
                                labelPlacement="start"
                                control={
                                    <Switch
                                        color="success"
                                        onChange={(e) => {
                                            if (addTags) {
                                                setDocTags([]);
                                            }
                                            setAddTags(!addTags);
                                        }}
                                        checked={addTags}
                                    />
                                }
                                label={
                                    <Typography
                                        sx={{
                                            fontWeight: 600,
                                        }}
                                    ></Typography>
                                }
                            />
                        </Box>
                        {documentContext?.filters && addTags && (
                            <Autocomplete
                                multiple
                                id="tags-filled"
                                sx={{
                                    '& .MuiInputBase-root': {
                                        boxShadow: 'none !important',
                                    },
                                }}
                                inputValue={tagInputValue}
                                options={documentContext.filters.tags
                                    .filter(
                                        (t: Tag) =>
                                            docTags.filter(
                                                (dt: FileTag) =>
                                                    dt.title == t.title
                                            ).length == 0 &&
                                            t.title != 'UNTAGGED'
                                    )
                                    .map((t: Tag) => t.title)}
                                freeSolo
                                defaultValue={[
                                    ...docTags.map((t: FileTag) => t.title),
                                ]}
                                value={[
                                    ...docTags.map((t: FileTag) => t.title),
                                ]}
                                onChange={handleTagInput}
                                renderTags={(
                                    value: readonly string[],
                                    getTagProps
                                ) =>
                                    value.map(
                                        (option: string, index: number) => (
                                            <TagBlock
                                                variant="outlined"
                                                label={option}
                                                sx={{ height: 24 }}
                                                deleteIcon={
                                                    <Tooltip
                                                        arrow
                                                        placement="top"
                                                        title={`Remove`}
                                                    >
                                                        <CloseIcon
                                                            sx={{
                                                                fontSize: 10,
                                                            }}
                                                        />
                                                    </Tooltip>
                                                }
                                                {...getTagProps({ index })}
                                            />
                                        )
                                    )
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder="Add tags here..."
                                        sx={{
                                            backgroundColor: '#FFFFFF',
                                        }}
                                        onChange={(e) => {
                                            setTagInputValue(
                                                e.target.value.slice(0, 30)
                                            );
                                        }}
                                        InputProps={{
                                            ...params.InputProps,
                                            sx: { p: '8px !important' },
                                            endAdornment: (
                                                <Button
                                                    sx={{
                                                        p: 0,
                                                        fontSize: 16,
                                                    }}
                                                    variant="contained"
                                                    disabled={!tagInputValue}
                                                    onClick={addTag}
                                                >
                                                    Add
                                                </Button>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        )}
                    </Box>
                )}
            </Box>
            <Box display="flex" mt={4} justifyContent="right">
                <Button
                    sx={{
                        color: '#4B4B4C',
                        mr: 3,
                        fontSize: 16,
                        fontWeight: 600,
                        height: 36,
                    }}
                    onClick={() => props.onClose(0)}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    disabled={
                        fileStatus !== 'SUCCESS' ||
                        processing ||
                        crawledFiles.length == 0
                    }
                    onClick={onSubmitClick}
                    sx={{
                        minWidth: '80px',
                        fontSize: 16,
                        fontWeight: 600,
                        height: 36,
                    }}
                >
                    {processing ? (
                        <CircularProgress
                            size={25}
                            sx={{
                                color: '#ffffff',
                                ml: 2,
                            }}
                        />
                    ) : (
                        'Submit'
                    )}
                </Button>
            </Box>
        </Box>
    );
};
