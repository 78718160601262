export const DocumentImageLight = (
    <svg
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="80" height="80" rx="4" fill="#f9f5ff" />
        <path
            d="M22.4865 49.9541H12.9383C12.4451 49.9535 11.9723 49.7556 11.6236 49.4036C11.2748 49.0517 11.0787 48.5745 11.0781 48.0768V9.53743C11.0787 9.03972 11.2748 8.56255 11.6236 8.21062C11.9723 7.85868 12.4451 7.66072 12.9383 7.66016H38.8365L43.5766 11.9313V19.6968H43.3577V12.0302L38.753 7.88101H12.9383C12.5031 7.88151 12.086 8.05618 11.7782 8.36672C11.4705 8.67725 11.2975 9.09828 11.297 9.53743V48.0768C11.2975 48.516 11.4705 48.937 11.7782 49.2475C12.086 49.5581 12.5031 49.7328 12.9383 49.7332H22.4865V49.9541Z"
            fill="#A5A6AB"
        />
        <path
            d="M43.4679 12.0773H40.5135C40.0203 12.0767 39.5475 11.8788 39.1988 11.5268C38.85 11.1749 38.6539 10.6977 38.6533 10.2V7.77058C38.6533 7.7413 38.6648 7.71321 38.6854 7.6925C38.7059 7.67179 38.7337 7.66016 38.7627 7.66016C38.7918 7.66016 38.8196 7.67179 38.8401 7.6925C38.8606 7.71321 38.8722 7.7413 38.8722 7.77058V10.2C38.8727 10.6392 39.0457 11.0602 39.3534 11.3707C39.6611 11.6813 40.0783 11.8559 40.5135 11.8564H43.4679C43.4969 11.8564 43.5248 11.8681 43.5453 11.8888C43.5658 11.9095 43.5773 11.9376 43.5773 11.9669C43.5773 11.9961 43.5658 12.0242 43.5453 12.0449C43.5248 12.0656 43.4969 12.0773 43.4679 12.0773Z"
            fill="#A5A6AB"
        />
        <path
            d="M39.8066 17.6523H14.8583C14.6261 17.6523 14.4035 17.7454 14.2393 17.9111C14.0751 18.0768 13.9829 18.3015 13.9829 18.5358C13.9829 18.7701 14.0751 18.9948 14.2393 19.1604C14.4035 19.3261 14.6261 19.4192 14.8583 19.4192H39.8066C40.0388 19.4192 40.2614 19.3261 40.4256 19.1604C40.5898 18.9948 40.682 18.7701 40.682 18.5358C40.682 18.3015 40.5898 18.0768 40.4256 17.9111C40.2614 17.7454 40.0388 17.6523 39.8066 17.6523Z"
            fill="#D7D9EC"
        />
        <path
            d="M27.223 13.8984H14.8583C14.6261 13.8984 14.4035 13.9915 14.2393 14.1572C14.0751 14.3229 13.9829 14.5476 13.9829 14.7819C13.9829 15.0162 14.0751 15.2409 14.2393 15.4065C14.4035 15.5722 14.6261 15.6653 14.8583 15.6653H27.223C27.4552 15.6653 27.6778 15.5722 27.842 15.4065C28.0062 15.2409 28.0984 15.0162 28.0984 14.7819C28.0984 14.5476 28.0062 14.3229 27.842 14.1572C27.6778 13.9915 27.4552 13.8984 27.223 13.8984Z"
            fill="#D7D9EC"
        />
        <path
            d="M14.8583 25.0508C14.6261 25.0508 14.4035 25.1439 14.2393 25.3095C14.0751 25.4752 13.9829 25.6999 13.9829 25.9342C13.9829 26.1685 14.0751 26.3932 14.2393 26.5589C14.4035 26.7246 14.6261 26.8176 14.8583 26.8176H22.6776V25.0508H14.8583Z"
            fill="#D7D9EC"
        />
        <path
            d="M14.8583 21.2969C14.7433 21.2969 14.6295 21.3197 14.5233 21.3641C14.4171 21.4085 14.3206 21.4736 14.2393 21.5556C14.158 21.6377 14.0935 21.735 14.0495 21.8422C14.0056 21.9494 13.9829 22.0643 13.9829 22.1803C13.9829 22.2963 14.0056 22.4112 14.0495 22.5184C14.0935 22.6256 14.158 22.7229 14.2393 22.805C14.3206 22.887 14.4171 22.9521 14.5233 22.9965C14.6295 23.0409 14.7433 23.0637 14.8583 23.0637H22.6776V21.2969H14.8583Z"
            fill="#D7D9EC"
        />
        <path
            d="M22.7318 43.7677H15.7244C15.2457 43.7672 14.7868 43.5751 14.4484 43.2335C14.1099 42.8919 13.9195 42.4288 13.9189 41.9457V32.6697C13.9195 32.1866 14.1099 31.7235 14.4484 31.3819C14.7868 31.0403 15.2457 30.8482 15.7244 30.8477H22.7318V43.7677Z"
            fill="#D7D9EC"
        />
        <path
            d="M34.0319 61.8252H24.4837C23.9905 61.8246 23.5177 61.6267 23.169 61.2747C22.8202 60.9228 22.6241 60.4456 22.6235 59.9479V21.4085C22.6241 20.9108 22.8202 20.4336 23.169 20.0817C23.5177 19.7298 23.9905 19.5318 24.4837 19.5312H50.3819L55.122 23.8024V31.1262H54.9031V23.9013L50.2984 19.7521H24.4837C24.0486 19.7526 23.6314 19.9273 23.3237 20.2378C23.016 20.5483 22.8429 20.9694 22.8424 21.4085V59.9479C22.8429 60.3871 23.016 60.8081 23.3237 61.1186C23.6314 61.4292 24.0486 61.6038 24.4837 61.6043H34.0319V61.8252Z"
            fill="#A5A6AB"
        />
        <path
            d="M55.0114 23.9484H52.057C51.5638 23.9478 51.091 23.7499 50.7422 23.3979C50.3935 23.046 50.1973 22.5688 50.1968 22.0711V19.6417C50.1968 19.6124 50.2083 19.5843 50.2288 19.5636C50.2493 19.5429 50.2772 19.5312 50.3062 19.5312C50.3352 19.5312 50.3631 19.5429 50.3836 19.5636C50.4041 19.5843 50.4156 19.6124 50.4156 19.6417V22.0711C50.4161 22.5103 50.5892 22.9313 50.8969 23.2418C51.2046 23.5523 51.6218 23.727 52.057 23.7275H55.0114C55.0404 23.7275 55.0682 23.7392 55.0887 23.7599C55.1093 23.7806 55.1208 23.8087 55.1208 23.8379C55.1208 23.8672 55.1093 23.8953 55.0887 23.916C55.0682 23.9367 55.0404 23.9484 55.0114 23.9484Z"
            fill="#A5A6AB"
        />
        <path
            d="M51.3501 29.5234H26.4017C26.1696 29.5234 25.9469 29.6165 25.7828 29.7822C25.6186 29.9479 25.5264 30.1726 25.5264 30.4069C25.5264 30.6412 25.6186 30.8659 25.7828 31.0315C25.9469 31.1972 26.1696 31.2903 26.4017 31.2903H51.3501C51.5822 31.2903 51.8049 31.1972 51.969 31.0315C52.1332 30.8659 52.2254 30.6412 52.2254 30.4069C52.2254 30.1726 52.1332 29.9479 51.969 29.7822C51.8049 29.6165 51.5822 29.5234 51.3501 29.5234Z"
            fill="#D7D9EC"
        />
        <path
            d="M38.7665 25.7695H26.4017C26.1696 25.7695 25.9469 25.8626 25.7828 26.0283C25.6186 26.194 25.5264 26.4187 25.5264 26.653C25.5264 26.8873 25.6186 27.112 25.7828 27.2776C25.9469 27.4433 26.1696 27.5364 26.4017 27.5364H38.7665C38.9986 27.5364 39.2213 27.4433 39.3855 27.2776C39.5496 27.112 39.6419 26.8873 39.6419 26.653C39.6419 26.4187 39.5496 26.194 39.3855 26.0283C39.2213 25.8626 38.9986 25.7695 38.7665 25.7695Z"
            fill="#D7D9EC"
        />
        <path
            d="M51.3501 29.5234H26.4017C26.1696 29.5234 25.9469 29.6165 25.7828 29.7822C25.6186 29.9479 25.5264 30.1726 25.5264 30.4069C25.5264 30.6412 25.6186 30.8659 25.7828 31.0315C25.9469 31.1972 26.1696 31.2903 26.4017 31.2903H51.3501C51.5822 31.2903 51.8049 31.1972 51.969 31.0315C52.1332 30.8659 52.2254 30.6412 52.2254 30.4069C52.2254 30.1726 52.1332 29.9479 51.969 29.7822C51.8049 29.6165 51.5822 29.5234 51.3501 29.5234Z"
            fill="#D7D9EC"
        />
        <path
            d="M38.7665 25.7695H26.4017C26.1696 25.7695 25.9469 25.8626 25.7828 26.0283C25.6186 26.194 25.5264 26.4187 25.5264 26.653C25.5264 26.8873 25.6186 27.112 25.7828 27.2776C25.9469 27.4433 26.1696 27.5364 26.4017 27.5364H38.7665C38.9986 27.5364 39.2213 27.4433 39.3855 27.2776C39.5496 27.112 39.6419 26.8873 39.6419 26.653C39.6419 26.4187 39.5496 26.194 39.3855 26.0283C39.2213 25.8626 38.9986 25.7695 38.7665 25.7695Z"
            fill="#D7D9EC"
        />
        <path
            d="M26.4017 36.9219C26.1696 36.9219 25.9469 37.015 25.7828 37.1806C25.6186 37.3463 25.5264 37.571 25.5264 37.8053C25.5264 38.0396 25.6186 38.2643 25.7828 38.43C25.9469 38.5956 26.1696 38.6887 26.4017 38.6887H34.2211V36.9219H26.4017Z"
            fill="#D7D9EC"
        />
        <path
            d="M26.4017 33.168C26.1696 33.168 25.9469 33.261 25.7828 33.4267C25.6186 33.5924 25.5264 33.8171 25.5264 34.0514C25.5264 34.2857 25.6186 34.5104 25.7828 34.6761C25.9469 34.8417 26.1696 34.9348 26.4017 34.9348H34.2211V33.168H26.4017Z"
            fill="#D7D9EC"
        />
        <path
            d="M26.4017 36.9219C26.1696 36.9219 25.9469 37.015 25.7828 37.1806C25.6186 37.3463 25.5264 37.571 25.5264 37.8053C25.5264 38.0396 25.6186 38.2643 25.7828 38.43C25.9469 38.5956 26.1696 38.6887 26.4017 38.6887H34.2211V36.9219H26.4017Z"
            fill="#D7D9EC"
        />
        <path
            d="M26.4017 33.168C26.1696 33.168 25.9469 33.261 25.7828 33.4267C25.6186 33.5924 25.5264 33.8171 25.5264 34.0514C25.5264 34.2857 25.6186 34.5104 25.7828 34.6761C25.9469 34.8417 26.1696 34.9348 26.4017 34.9348H34.2211V33.168H26.4017Z"
            fill="#D7D9EC"
        />
        <path
            d="M34.2768 55.6428H27.2693C26.7907 55.6422 26.3317 55.4501 25.9933 55.1085C25.6548 54.7669 25.4644 54.3038 25.4639 53.8207V44.5447C25.4644 44.0616 25.6548 43.5985 25.9933 43.2569C26.3317 42.9153 26.7907 42.7232 27.2693 42.7227H34.2768V55.6428Z"
            fill="#D7D9EC"
        />
        <path
            d="M34.8936 31.5104C34.6217 31.678 34.3969 31.9132 34.241 32.1936C34.085 32.474 34.003 32.7901 34.0029 33.1117V33.3877H34.2218V33.1117C34.2222 32.7465 34.342 32.3916 34.5626 32.1021C34.7832 31.8126 35.0922 31.6047 35.4418 31.5104H34.8936ZM61.7612 31.2344H35.8631C35.3699 31.235 34.8971 31.4329 34.5484 31.7849C34.1997 32.1368 34.0035 32.6139 34.0029 33.1117V71.6511C34.0036 72.1487 34.1998 72.6258 34.5485 72.9777C34.8972 73.3296 35.37 73.5276 35.8631 73.5283H64.6412C65.1343 73.5276 65.6071 73.3296 65.9558 72.9777C66.3045 72.6258 66.5007 72.1487 66.5014 71.6511V35.5057L61.7612 31.2344ZM66.2825 71.6511C66.282 72.0902 66.1089 72.5112 65.8012 72.8217C65.4935 73.1323 65.0763 73.3069 64.6412 73.3075H35.8631C35.428 73.3069 35.0108 73.1323 34.7031 72.8217C34.3954 72.5112 34.2223 72.0902 34.2218 71.6511L34.2218 33.1117C34.2222 32.7465 34.342 32.3916 34.5626 32.1021C34.7832 31.8126 35.0922 31.6047 35.4418 31.5104C35.5793 31.4735 35.7209 31.4549 35.8631 31.4552H61.678L66.2825 35.604V71.6511ZM34.8936 31.5104C34.6217 31.678 34.3969 31.9132 34.241 32.1936C34.085 32.474 34.003 32.7901 34.0029 33.1117V33.3877H34.2218V33.1117C34.2222 32.7465 34.342 32.3916 34.5626 32.1021C34.7832 31.8126 35.0922 31.6047 35.4418 31.5104H34.8936Z"
            fill="#D7D9EC"
        />
        <path
            d="M66.3912 35.6515H63.4368C62.9437 35.6509 62.4708 35.453 62.1221 35.101C61.7734 34.7491 61.5772 34.2719 61.5767 33.7742V31.3448C61.5767 31.3155 61.5882 31.2874 61.6087 31.2667C61.6292 31.246 61.6571 31.2344 61.6861 31.2344C61.7151 31.2344 61.7429 31.246 61.7635 31.2667C61.784 31.2874 61.7955 31.3155 61.7955 31.3448V33.7742C61.796 34.2134 61.9691 34.6344 62.2768 34.9449C62.5845 35.2555 63.0017 35.4301 63.4368 35.4306H66.3912C66.4203 35.4306 66.4481 35.4423 66.4686 35.463C66.4891 35.4837 66.5007 35.5118 66.5007 35.5411C66.5007 35.5704 66.4891 35.5984 66.4686 35.6192C66.4481 35.6399 66.4203 35.6515 66.3912 35.6515Z"
            fill="#D7D9EC"
        />
        <path
            d="M62.7314 42.9973H37.7831C37.5509 42.9973 37.3283 42.9042 37.1641 42.7386C36.9999 42.5729 36.9077 42.3482 36.9077 42.1139C36.9077 41.8796 36.9999 41.6549 37.1641 41.4892C37.3283 41.3235 37.5509 41.2305 37.7831 41.2305H62.7314C62.9636 41.2305 63.1862 41.3235 63.3504 41.4892C63.5146 41.6549 63.6068 41.8796 63.6068 42.1139C63.6068 42.3482 63.5146 42.5729 63.3504 42.7386C63.1862 42.9042 62.9636 42.9973 62.7314 42.9973Z"
            fill="#A5A6AB"
        />
        <path
            d="M50.1478 39.2434H37.7831C37.5509 39.2434 37.3283 39.1503 37.1641 38.9847C36.9999 38.819 36.9077 38.5943 36.9077 38.36C36.9077 38.1257 36.9999 37.901 37.1641 37.7353C37.3283 37.5696 37.5509 37.4766 37.7831 37.4766H50.1478C50.38 37.4766 50.6026 37.5696 50.7668 37.7353C50.931 37.901 51.0232 38.1257 51.0232 38.36C51.0232 38.5943 50.931 38.819 50.7668 38.9847C50.6026 39.1503 50.38 39.2434 50.1478 39.2434Z"
            fill="#A5A6AB"
        />
        <path
            d="M62.7314 50.3958H37.7831C37.5509 50.3958 37.3283 50.3027 37.1641 50.137C36.9999 49.9713 36.9077 49.7466 36.9077 49.5123C36.9077 49.278 36.9999 49.0533 37.1641 48.8877C37.3283 48.722 37.5509 48.6289 37.7831 48.6289H62.7314C62.9636 48.6289 63.1862 48.722 63.3504 48.8877C63.5146 49.0533 63.6068 49.278 63.6068 49.5123C63.6068 49.7466 63.5146 49.9713 63.3504 50.137C63.1862 50.3027 62.9636 50.3958 62.7314 50.3958Z"
            fill="#A5A6AB"
        />
        <path
            d="M50.1478 46.6418H37.7831C37.5509 46.6418 37.3283 46.5488 37.1641 46.3831C36.9999 46.2174 36.9077 45.9927 36.9077 45.7584C36.9077 45.5241 36.9999 45.2994 37.1641 45.1338C37.3283 44.9681 37.5509 44.875 37.7831 44.875H50.1478C50.38 44.875 50.6026 44.9681 50.7668 45.1338C50.931 45.2994 51.0232 45.5241 51.0232 45.7584C51.0232 45.9927 50.931 46.2174 50.7668 46.3831C50.6026 46.5488 50.38 46.6418 50.1478 46.6418Z"
            fill="#A5A6AB"
        />
        <path
            d="M61.8468 67.3459H38.6492C38.1705 67.3453 37.7116 67.1532 37.3732 66.8116C37.0347 66.47 36.8443 66.0069 36.8438 65.5238V56.2478C36.8443 55.7648 37.0347 55.3016 37.3732 54.9601C37.7116 54.6185 38.1705 54.4263 38.6492 54.4258H61.8468C62.3254 54.4263 62.7844 54.6185 63.1228 54.9601C63.4613 55.3016 63.6517 55.7648 63.6522 56.2478V65.5238C63.6517 66.0069 63.4613 66.47 63.1228 66.8116C62.7844 67.1532 62.3254 67.3453 61.8468 67.3459Z"
            fill="#9C9FD0"
        />
    </svg>
);
