interface IProps {
    size?: number;
    color?: string;
}

export const RemoveTag = (props: IProps) => (
    <svg
        width={props.size || 12}
        height={props.size || 12}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M5.8401 5.68477C5.8401 5.83282 5.86751 5.9699 5.92235 6.09602C5.97718 6.22214 6.05121 6.3318 6.14442 6.42502C6.23764 6.51823 6.35004 6.595 6.48164 6.65532C6.61324 6.71563 6.75033 6.74305 6.89289 6.73756C7.04094 6.73756 7.17802 6.71015 7.30414 6.65532C7.43025 6.60048 7.53992 6.52646 7.63313 6.43324C7.72635 6.34003 7.80312 6.22762 7.86343 6.09602C7.92375 5.96442 7.95117 5.82734 7.94568 5.68477C7.94568 5.53672 7.91827 5.39964 7.86343 5.27353C7.8086 5.14741 7.73458 5.03774 7.64136 4.94453C7.54814 4.85131 7.43574 4.77455 7.30414 4.71423C7.17254 4.65391 7.03546 4.6265 6.89289 4.63198C6.74484 4.63198 6.60776 4.6594 6.48164 4.71423C6.35553 4.76906 6.24586 4.84309 6.15265 4.9363C6.05943 5.02952 5.98266 5.14193 5.92235 5.27353C5.86203 5.40512 5.83461 5.54221 5.8401 5.68477ZM11.6305 16.3032L9.83743 14.5101C9.63268 14.3054 9.301 14.3045 9.09513 14.5081C8.88764 14.7133 8.88671 15.0481 9.09307 15.2545L10.9233 17.0848C11.3139 17.4753 11.947 17.4753 12.3376 17.0848L18.2929 11.1294C18.6834 10.7389 18.6834 10.1058 18.2929 9.71523L10.8706 2.29289C10.683 2.10536 10.4287 2 10.1634 2H4.20812C3.65583 2 3.20812 2.44772 3.20812 3V8.95533C3.20812 9.22055 3.31347 9.4749 3.50101 9.66244L5.74139 11.9028C5.94614 12.1076 6.27781 12.1085 6.48369 11.9049C6.69118 11.6997 6.6921 11.3648 6.48575 11.1585L4.26091 8.93362V3.05279H10.1417L17.5113 10.4223L11.6305 16.3032ZM2.54972 13.9759C2.3454 14.1802 2.34503 14.5114 2.5489 14.7162L4.03884 16.2127L2.5489 17.7092C2.34503 17.914 2.3454 18.2451 2.54972 18.4495C2.75435 18.6541 3.08614 18.6541 3.29078 18.4495L4.78731 16.9529L6.28425 18.4499C6.48866 18.6543 6.82008 18.6543 7.02449 18.4499C7.2289 18.2455 7.2289 17.914 7.02449 17.7096L5.52755 16.2127L7.02449 14.7158C7.2289 14.5113 7.2289 14.1799 7.02449 13.9755C6.82008 13.7711 6.48866 13.7711 6.28425 13.9755L4.78731 15.4724L3.29078 13.9759C3.08614 13.7713 2.75435 13.7713 2.54972 13.9759Z"
            fill="#6e3cfb"
        />
    </svg>
);
