export const Reset = (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <mask
            id="mask0_6925_61301"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
        >
            <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_6925_61301)">
            <path
                d="M10.1231 20.248C8.47051 19.8288 7.12019 18.9535 6.07212 17.6221C5.02404 16.2907 4.5 14.75 4.5 13C4.5 12.1013 4.65673 11.242 4.9702 10.4221C5.28367 9.60225 5.72245 8.85001 6.28655 8.16539C6.425 8.01028 6.5923 7.93015 6.78845 7.92502C6.9846 7.91988 7.16537 8.00001 7.33075 8.16539C7.46922 8.30386 7.54101 8.47277 7.54613 8.67212C7.55126 8.87147 7.48459 9.05896 7.34613 9.23459C6.90768 9.75768 6.5737 10.3404 6.3442 10.9827C6.11472 11.625 5.99997 12.2974 5.99997 13C5.99997 14.3756 6.41504 15.599 7.24518 16.6702C8.07531 17.7413 9.13781 18.4526 10.4327 18.8038C10.5981 18.8513 10.734 18.9442 10.8404 19.0827C10.9468 19.2211 11 19.3731 11 19.5384C11 19.7884 10.9138 19.9852 10.7413 20.1288C10.5689 20.2724 10.3628 20.3121 10.1231 20.248ZM13.8769 20.2673C13.6371 20.3314 13.4311 20.2891 13.2586 20.1403C13.0862 19.9916 13 19.7923 13 19.5423C13 19.3872 13.0532 19.2404 13.1596 19.1019C13.266 18.9634 13.4019 18.8673 13.5673 18.8135C14.8557 18.4199 15.9166 17.6949 16.75 16.6385C17.5833 15.5821 18 14.3692 18 13C18 11.3333 17.4166 9.91666 16.25 8.74999C15.0833 7.58333 13.6666 6.99999 12 6.99999H11.6461L12.4692 7.82309C12.6141 7.96796 12.6865 8.1436 12.6865 8.35002C12.6865 8.55642 12.6141 8.73205 12.4692 8.87692C12.3244 9.02178 12.1487 9.09422 11.9423 9.09422C11.7359 9.09422 11.5603 9.02178 11.4154 8.87692L9.42117 6.88269C9.32759 6.78911 9.26157 6.69039 9.2231 6.58654C9.18463 6.48269 9.1654 6.37052 9.1654 6.25002C9.1654 6.1295 9.18463 6.01732 9.2231 5.91347C9.26157 5.80963 9.32759 5.71092 9.42117 5.61732L11.4154 3.62309C11.5603 3.47823 11.7359 3.40579 11.9423 3.40579C12.1487 3.40579 12.3244 3.47823 12.4692 3.62309C12.6141 3.76796 12.6865 3.9436 12.6865 4.15002C12.6865 4.35642 12.6141 4.53205 12.4692 4.67692L11.6461 5.50002H12C14.0923 5.50002 15.8653 6.22694 17.3192 7.68079C18.773 9.13463 19.5 10.9077 19.5 13C19.5 14.7333 18.9743 16.2667 17.923 17.6C16.8717 18.9333 15.523 19.8224 13.8769 20.2673Z"
                fill="#f9f5ff"
            />
        </g>
    </svg>
);
