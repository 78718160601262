import { Box, Button, Divider, Grid, Tooltip, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import GoogleTrends from '../../../components/widgets/google-trends';
import {
    FinancialInfoData,
    FinancialInfoDataResults,
    GoogleScholarData,
    GoogleScholarDataResult,
    IQueryEntitiesesponseData,
    LiveInsightsQueryResponse,
    LiveQueryResponseDataTrends,
} from '../../../services/interfaces';
import { LocationIcon } from '../../../components/icons/location';
import { OrganizationIcon } from '../../../components/icons/organization';
import { Info } from '../../../components/icons/info';
import { useSearchParams } from 'react-router-dom';
import { useChatbotService } from '../../../services/chatbot.service';
import {
    BorderedBox,
    BorderedContainedBox,
} from '../../../components/core/box';
import { Financialtrends } from '../../company-analysis/company-details/financial-trends';
import { UtilsService } from '../../../services/utils.service';
import { Matrix } from '../../company-analysis/company-details/matrix';
import { Ownership } from '../../company-analysis/company-details/ownership';
import { NoDataImage } from '../../../components/images/no-data';
import { NavigationLink } from '../../../components/core/link';
import { Circle } from '../../../components/icons/circle';
import { AppContext } from '../../../providers/app-provider';

interface IProps {
    entities: IQueryEntitiesesponseData[];
    onFetched: () => void;
    query?: string;
}

export const GoogleScholar = (props: IProps) => {
    const appContext = useContext(AppContext);
    const [googleScholarData, setGoogleScholarData] = useState<
        GoogleScholarDataResult[]
    >([]);
    const [streamFinished, setStreamFinished] = useState(true);
    const [selectedEntity, setSelectedEntity] =
        useState<FinancialInfoDataResults | null>(null);
    const [searchParam] = useSearchParams();
    const { fetchChatbotGoogleScholar } = useChatbotService();
    useEffect(() => {
        refreshScholarData();
    }, [props.query, searchParam.get('query'), props.entities]);

    const refreshScholarData = async () => {
        let message = props.query || searchParam.get('query');
        if (!message) return;
        await fetchChatbotGoogleScholar(
            message,
            props.entities,
            (data: string) => {
                if (data) {
                    processQueryResponse(data.split('\n'));
                }
            },
            true
        );
    };

    const processQueryResponse = (responses: string[]) => {
        setStreamFinished(false);
        for (let res of responses) {
            try {
                let parsedRes: LiveInsightsQueryResponse = JSON.parse(res);
                switch (parsedRes.chunk) {
                    case 'GOOGLE_SCHOLAR':
                        let data = (parsedRes.data as GoogleScholarData)
                            .results;
                        setGoogleScholarData(data);
                        break;
                    case 'TERMINATION':
                        setStreamFinished(true);
                        props.onFetched();
                        break;
                }
            } catch (error) {}
        }
    };

    return (
        <Box>
            <Box
                px={6}
                sx={{
                    maxHeight: `calc(100vh - ${
                        appContext?.accountActionBannerVisible ? '22px' : '0px'
                    } - 255px)`,
                    overflowY: 'auto',
                }}
            >
                {googleScholarData.length == 0 && (
                    <Box textAlign={'center'} px={4} py={20}>
                        <Box>{NoDataImage}</Box>
                        <br />
                        <Typography variant="h4">
                            Sorry, No data available
                        </Typography>
                        <Typography fontSize={14} fontWeight={400} mt={2}>
                            No data available for this entities. Please try with
                            some different query
                        </Typography>
                    </Box>
                )}
                <Box pb={6}>
                    {googleScholarData.map((d) => (
                        <Box>
                            <Typography
                                component={NavigationLink}
                                to={d.url}
                                fontSize={14}
                                fontWeight={600}
                                color="#6e3cfb"
                                target="_blank"
                            >
                                {d.title}
                            </Typography>
                            <Typography fontSize={14} fontWeight={400} mt={2}>
                                {d.paperAbstract}
                            </Typography>
                            <Box mt={1} display="flex">
                                <Typography variant="subtitle2" color="#4B4B4C">
                                    Author: {d.authors}
                                </Typography>
                                {d.citationCount && (
                                    <Box display="flex" component="span">
                                        <Circle mt={5} h={8} mx={4} w={8} />{' '}
                                        <Typography
                                            variant="subtitle2"
                                            color="#4B4B4C"
                                        >
                                            Cited By: {d.citationCount}
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                            <Box py={4}>
                                <Divider />
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    );
};
