import {
    Box,
    Grid,
    Skeleton,
    Tab,
    TableBody,
    TableContainer,
    TableHead,
    Tabs,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BorderedBox } from '../../../../components/core/box';
import {
    PotentialIndicator,
    PotentialSentiment,
    ReportModule,
} from '../../../../services/interfaces';
import { UtilsService } from '../../../../services/utils.service';
import { RadialChart } from '../../../../components/charts/radial';
import { SearchImage } from '../../../../components/icons/search-image';
import { SectionPercentage } from '../../../topic/overview';
import SingleSelect from '../../../../components/core/input/single-select';
import { SortOrder } from '../../../../components/icons/sort';
import { ToggleHighLight } from '../../../../components/toggle-highlight';
import { TabPanel } from '../../../../components/core/tab-panel';
import { Sentiment } from './sentiment';
import { IndicatorsTable } from '../../../../components/insights/indicators-table';
import { potentialIndicatorTitleClick } from '../../../../analytics/analytics';
import { NavigationLink } from '../../../../components/core/link';
import { TableBodyRow } from '../../../../components/core/table/common/row';
import { TableHeadCell } from '../../../../components/core/table/comparison/head-cell';
import { TableHeadRow } from '../../../../components/core/table/comparison/head-row';
import { TableNegativeCell } from '../../../../components/core/table/comparison/negative-cell';
import { TablePositiveCell } from '../../../../components/core/table/comparison/positive-cell';
import { ComparisonTableWrapper } from '../../../../components/core/table/comparison/table';
import { TextHighlight } from '../../../../components/sentiment-highlights';
import { NoData } from '../no-data';

interface IProps {
    data: ReportModule;
    compact?: boolean;
}

export const SentimentAnalysis = (inputProps: IProps) => {
    const { data } = inputProps;
    const props = {
        context: 'Topic',
        compact: false,
        data,
    };
    const [value, setValue] = React.useState(0);
    const [indicators, setIndicators] = useState<
        (PotentialIndicator | null)[][] | null
    >(null);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if (typeof newValue != 'number') return;
        setValue(newValue);
    };
    useEffect(() => {
        let formattedIndicators: (PotentialIndicator | null)[][] | null = [];
        for (
            let index = 0;
            index <
            Math.max(
                data.potentialPositives ? data.potentialPositives.length : 0,
                data.potentialNegatives ? data.potentialNegatives.length : 0
            );
            index++
        ) {
            formattedIndicators?.push([
                data.potentialPositives[index]
                    ? data.potentialPositives[index]
                    : null,
                data.potentialNegatives[index]
                    ? data.potentialNegatives[index]
                    : null,
            ]);
        }
        setIndicators(formattedIndicators);
    }, [data]);
    return (
        <BorderedBox
            sx={{
                backgroundColor: '#f9f5ff',
            }}
            pb={4}
            my={4}
        >
            <Typography px={4} pt={4} variant="h4">
                Sentiment Analysis
            </Typography>
            <Box
                className="scrollable"
                sx={{
                    maxHeight: 'calc(100vh - 220px)',
                    overflow: 'auto',
                }}
                px={4}
            >
                {!data.sentimentData?.data?.totalArticles && <NoData />}
                {data.sentimentData?.data?.totalArticles !== undefined &&
                    data.sentimentData?.data?.totalArticles > 0 && (
                        <Box>
                            <Typography variant="h6">
                                Overall Assessment
                            </Typography>
                            <Typography color="#4B4B4C" variant="subtitle1">
                                {UtilsService.formatStringDate(data.startDate)}{' '}
                                - {UtilsService.formatStringDate(data.endDate)}{' '}
                            </Typography>
                            {data.sentimentData && (
                                <BorderedBox mt={4}>
                                    <Grid
                                        sx={{
                                            backgroundColor: '#f9f5ff',
                                            borderTopLeftRadius: 8,
                                            borderTopRightRadius: 8,
                                        }}
                                        justifyContent="space-between"
                                        textAlign="left"
                                        container
                                    >
                                        <Grid p={4} pb={2} xs={12} sm={4} item>
                                            <Grid container>
                                                <Grid item>
                                                    <Typography variant="h4">
                                                        Potential Positive
                                                    </Typography>
                                                    {data.sentimentData?.data
                                                        .averagePositive && (
                                                        <SectionPercentage
                                                            sx={{
                                                                color: '#19AF55',
                                                            }}
                                                        >
                                                            {UtilsService.convertSentimentToPercentage(
                                                                data
                                                                    .sentimentData
                                                                    ?.data
                                                                    .averagePositive
                                                            )}
                                                            %
                                                        </SectionPercentage>
                                                    )}

                                                    {data.sentimentData.data
                                                        .averagePositive && (
                                                        <Typography
                                                            color="#A5A6AB"
                                                            variant="h6"
                                                        >
                                                            {data.potentialPositives
                                                                ? data
                                                                      .potentialPositives
                                                                      .length
                                                                : 0}{' '}
                                                            potential positives
                                                        </Typography>
                                                    )}
                                                </Grid>
                                                <Grid xs={4} item>
                                                    {data.sentimentData.data
                                                        .averagePositive !=
                                                        null && (
                                                        <RadialChart
                                                            color="#1ACB7F"
                                                            value={UtilsService.convertSentimentToPercentage(
                                                                data
                                                                    .sentimentData
                                                                    .data
                                                                    .averagePositive
                                                            )}
                                                        />
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid p={4} pb={2} xs={12} sm={4} item>
                                            <Grid container>
                                                <Grid item>
                                                    <Typography variant="h4">
                                                        Potential Negative
                                                    </Typography>
                                                    {data.sentimentData.data
                                                        .averageNegative && (
                                                        <SectionPercentage
                                                            sx={{
                                                                color: '#D82F44',
                                                            }}
                                                        >
                                                            {UtilsService.convertSentimentToPercentage(
                                                                data
                                                                    .sentimentData
                                                                    .data
                                                                    .averageNegative
                                                            )}
                                                            %
                                                        </SectionPercentage>
                                                    )}

                                                    {data.sentimentData.data
                                                        .averageNegative !=
                                                        null && (
                                                        <Typography
                                                            color="#A5A6AB"
                                                            variant="h6"
                                                        >
                                                            {data.potentialNegatives
                                                                ? data
                                                                      .potentialNegatives
                                                                      .length
                                                                : 0}{' '}
                                                            potential negatives
                                                        </Typography>
                                                    )}
                                                </Grid>
                                                <Grid xs={4} item>
                                                    {data.sentimentData.data
                                                        .averageNegative && (
                                                        <RadialChart
                                                            color="#D82F44"
                                                            value={UtilsService.convertSentimentToPercentage(
                                                                data
                                                                    .sentimentData
                                                                    .data
                                                                    .averageNegative
                                                            )}
                                                        />
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid p={4} pb={2} xs={12} sm={4} item>
                                            <Grid container>
                                                <Grid xs={7} item>
                                                    <Typography variant="h4">
                                                        Articles Crunched
                                                    </Typography>
                                                    {data.sentimentData.data
                                                        .totalArticles && (
                                                        <SectionPercentage>
                                                            {
                                                                data
                                                                    .sentimentData
                                                                    .data
                                                                    .totalArticles
                                                            }
                                                        </SectionPercentage>
                                                    )}
                                                </Grid>
                                                <Grid xs={5} item>
                                                    {SearchImage}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </BorderedBox>
                            )}
                            <Box
                                sx={{
                                    borderBottom: 1,
                                    borderColor: 'divider',
                                    mb: 2,
                                }}
                            >
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="basic tabs example"
                                >
                                    <Tab label="Historical Sentiment Plot" />
                                    <Tab label="Potential Indicators" />
                                </Tabs>
                            </Box>
                            <TabPanel value={value} index={0}>
                                <Sentiment data={props.data} />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <Box>
                                    <TableContainer>
                                        <ComparisonTableWrapper
                                            sx={{
                                                border: props.compact
                                                    ? 'none !important'
                                                    : '',
                                            }}
                                            aria-label="simple table"
                                        >
                                            <TableHead
                                                sx={{
                                                    borderTop: props.compact
                                                        ? '1px solid #e0e0e0'
                                                        : '',
                                                    backgroundColor:
                                                        props.compact
                                                            ? '#f9f5ff'
                                                            : '',
                                                }}
                                            >
                                                <TableHeadRow>
                                                    <TableHeadCell>
                                                        <Typography
                                                            sx={{
                                                                color: '#19AF55',
                                                            }}
                                                            variant="subtitle1"
                                                        >
                                                            Positive Indicators
                                                        </Typography>
                                                    </TableHeadCell>
                                                    <TableHeadCell>
                                                        <Typography
                                                            sx={{
                                                                color: '#D82F44',
                                                            }}
                                                            variant="subtitle1"
                                                        >
                                                            Negative Indicators
                                                        </Typography>
                                                    </TableHeadCell>
                                                </TableHeadRow>
                                            </TableHead>
                                            <TableBody>
                                                {indicators &&
                                                    indicators.map(
                                                        (
                                                            row: (PotentialIndicator | null)[],
                                                            index
                                                        ) => (
                                                            <TableBodyRow
                                                                key={index}
                                                            >
                                                                <TablePositiveCell
                                                                    sx={{
                                                                        borderLeftWidth:
                                                                            props.compact
                                                                                ? '2px !important'
                                                                                : '10px !important',
                                                                        padding:
                                                                            '8px 10px',
                                                                    }}
                                                                >
                                                                    {row[0] && (
                                                                        <Box
                                                                            pb={
                                                                                1
                                                                            }
                                                                        >
                                                                            <Box
                                                                                pb={
                                                                                    2
                                                                                }
                                                                            >
                                                                                <Box display="flex">
                                                                                    <Box
                                                                                        sx={{
                                                                                            maxWidth: 40,
                                                                                            maxHeight: 40,
                                                                                            mr: 2,
                                                                                        }}
                                                                                        component="img"
                                                                                        src={
                                                                                            row[0]
                                                                                                .icon
                                                                                        }
                                                                                    ></Box>
                                                                                    <Typography
                                                                                        to={
                                                                                            row[0]
                                                                                                .url
                                                                                        }
                                                                                        component={
                                                                                            NavigationLink
                                                                                        }
                                                                                        sx={{
                                                                                            cursor: 'pointer',
                                                                                        }}
                                                                                        color="#6e3cfb"
                                                                                        fontSize={
                                                                                            props.compact
                                                                                                ? 12
                                                                                                : 14
                                                                                        }
                                                                                        target="_blank"
                                                                                        variant="subtitle2"
                                                                                    >
                                                                                        {
                                                                                            row[0]
                                                                                                .title
                                                                                        }
                                                                                    </Typography>
                                                                                </Box>
                                                                            </Box>
                                                                            <Typography
                                                                                text={
                                                                                    row[0]
                                                                                        .summary
                                                                                }
                                                                                textFontSize={
                                                                                    props.compact
                                                                                        ? '12px'
                                                                                        : '14px'
                                                                                }
                                                                                highlight={{
                                                                                    positive:
                                                                                        [],
                                                                                    negative:
                                                                                        [],
                                                                                }}
                                                                                collapsable={
                                                                                    props.compact
                                                                                }
                                                                                component={
                                                                                    TextHighlight
                                                                                }
                                                                                textVariant="caption"
                                                                            ></Typography>
                                                                            <Box
                                                                                mt={
                                                                                    1
                                                                                }
                                                                                display="flex"
                                                                            >
                                                                                <Typography
                                                                                    color="#4B4B4C"
                                                                                    variant="caption"
                                                                                >
                                                                                    Sources:{' '}
                                                                                    {
                                                                                        row[0]
                                                                                            .sourceName
                                                                                    }
                                                                                </Typography>

                                                                                <Typography
                                                                                    sx={{
                                                                                        color: '#8D8D8D',
                                                                                    }}
                                                                                    ml={
                                                                                        2
                                                                                    }
                                                                                    fontSize={
                                                                                        12
                                                                                    }
                                                                                    variant="subtitle2"
                                                                                >
                                                                                    {UtilsService.formatStringDateForPotentialIndicators(
                                                                                        row[0]
                                                                                            .timestamp
                                                                                    )}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    )}
                                                                </TablePositiveCell>
                                                                <TableNegativeCell
                                                                    sx={{
                                                                        borderLeftWidth:
                                                                            props.compact
                                                                                ? '2px !important'
                                                                                : '10px !important',
                                                                        padding:
                                                                            '8px 10px',
                                                                    }}
                                                                >
                                                                    {row[1] && (
                                                                        <Box
                                                                            pb={
                                                                                1
                                                                            }
                                                                        >
                                                                            <Box
                                                                                pb={
                                                                                    2
                                                                                }
                                                                            >
                                                                                <Box display="flex">
                                                                                    <Box
                                                                                        sx={{
                                                                                            maxWidth: 40,
                                                                                            maxHeight: 40,
                                                                                            mr: 2,
                                                                                        }}
                                                                                        component="img"
                                                                                        src={
                                                                                            row[1]
                                                                                                .icon
                                                                                        }
                                                                                    ></Box>
                                                                                    <Typography
                                                                                        to={
                                                                                            row[1]
                                                                                                .url
                                                                                        }
                                                                                        component={
                                                                                            NavigationLink
                                                                                        }
                                                                                        sx={{
                                                                                            cursor: 'pointer',
                                                                                        }}
                                                                                        color="#6e3cfb"
                                                                                        fontSize={
                                                                                            props.compact
                                                                                                ? 12
                                                                                                : 14
                                                                                        }
                                                                                        target="_blank"
                                                                                        variant="subtitle2"
                                                                                    >
                                                                                        {
                                                                                            row[1]
                                                                                                .title
                                                                                        }
                                                                                    </Typography>
                                                                                </Box>
                                                                            </Box>
                                                                            <Typography
                                                                                text={
                                                                                    row[1]
                                                                                        .summary
                                                                                }
                                                                                textFontSize={
                                                                                    props.compact
                                                                                        ? '12px'
                                                                                        : '14px'
                                                                                }
                                                                                highlight={{
                                                                                    positive:
                                                                                        [],
                                                                                    negative:
                                                                                        [],
                                                                                }}
                                                                                collapsable={
                                                                                    props.compact
                                                                                }
                                                                                component={
                                                                                    TextHighlight
                                                                                }
                                                                                textVariant="caption"
                                                                            ></Typography>
                                                                            <Box
                                                                                mt={
                                                                                    1
                                                                                }
                                                                                display="flex"
                                                                            >
                                                                                <Typography
                                                                                    color="#4B4B4C"
                                                                                    variant="caption"
                                                                                >
                                                                                    Sources:{' '}
                                                                                    {
                                                                                        row[1]
                                                                                            .sourceName
                                                                                    }
                                                                                </Typography>

                                                                                <Typography
                                                                                    sx={{
                                                                                        color: '#8D8D8D',
                                                                                    }}
                                                                                    ml={
                                                                                        2
                                                                                    }
                                                                                    fontSize={
                                                                                        12
                                                                                    }
                                                                                    variant="subtitle2"
                                                                                >
                                                                                    {UtilsService.formatStringDateForPotentialIndicators(
                                                                                        row[1]
                                                                                            .timestamp
                                                                                    )}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    )}
                                                                </TableNegativeCell>
                                                            </TableBodyRow>
                                                        )
                                                    )}
                                                {!data &&
                                                    [1, 2, 3, 4, 5, 6].map(
                                                        () => (
                                                            <TableBodyRow>
                                                                <TablePositiveCell
                                                                    sx={{
                                                                        borderLeftWidth:
                                                                            props.compact
                                                                                ? '2px !important'
                                                                                : '10px !important',
                                                                    }}
                                                                >
                                                                    <Skeleton
                                                                        variant="text"
                                                                        sx={{
                                                                            fontSize:
                                                                                '1rem',
                                                                        }}
                                                                    />
                                                                    <Skeleton
                                                                        variant="text"
                                                                        sx={{
                                                                            fontSize:
                                                                                '1rem',
                                                                        }}
                                                                    />
                                                                    <Skeleton
                                                                        variant="text"
                                                                        sx={{
                                                                            fontSize:
                                                                                '1rem',
                                                                        }}
                                                                    />
                                                                    <Skeleton
                                                                        variant="text"
                                                                        sx={{
                                                                            fontSize:
                                                                                '1rem',
                                                                        }}
                                                                    />
                                                                </TablePositiveCell>
                                                                <TableNegativeCell
                                                                    sx={{
                                                                        borderLeftWidth:
                                                                            props.compact
                                                                                ? '2px !important'
                                                                                : '10px !important',
                                                                    }}
                                                                >
                                                                    <Skeleton
                                                                        variant="text"
                                                                        sx={{
                                                                            fontSize:
                                                                                '1rem',
                                                                        }}
                                                                    />
                                                                    <Skeleton
                                                                        variant="text"
                                                                        sx={{
                                                                            fontSize:
                                                                                '1rem',
                                                                        }}
                                                                    />
                                                                    <Skeleton
                                                                        variant="text"
                                                                        sx={{
                                                                            fontSize:
                                                                                '1rem',
                                                                        }}
                                                                    />
                                                                    <Skeleton
                                                                        variant="text"
                                                                        sx={{
                                                                            fontSize:
                                                                                '1rem',
                                                                        }}
                                                                    />
                                                                </TableNegativeCell>
                                                            </TableBodyRow>
                                                        )
                                                    )}
                                            </TableBody>
                                        </ComparisonTableWrapper>
                                    </TableContainer>
                                </Box>
                            </TabPanel>
                        </Box>
                    )}
            </Box>
        </BorderedBox>
    );
};
