export const Stop = (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <mask
            id="mask0_91_74442"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
        >
            <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_91_74442)">
            <path
                d="M9.24998 14.7491V9.24911H14.7499V14.7491H9.24998ZM11.9999 22.4991C10.1294 22.4991 8.40828 22.0433 6.83647 21.1317C5.26469 20.2202 3.98585 18.9394 2.99995 17.2894V20.4991H1.5V14.8068H7.18265V16.3067H4.17492C4.98646 17.7619 6.08325 18.9077 7.4653 19.7443C8.84737 20.5808 10.3589 20.9991 11.9999 20.9991C13.9487 20.9991 15.707 20.4283 17.275 19.2866C18.8429 18.1449 19.9307 16.6465 20.5384 14.7914L22.0076 15.1164C21.3217 17.3253 20.0589 19.108 18.2192 20.4644C16.3794 21.8209 14.3063 22.4991 11.9999 22.4991ZM1.55 10.9991C1.67307 9.92088 1.9237 8.91897 2.3019 7.99334C2.6801 7.0677 3.21664 6.17475 3.91153 5.31449L4.99033 6.37408C4.45699 7.06383 4.03328 7.77858 3.71918 8.51831C3.40508 9.25806 3.18712 10.085 3.06533 10.9991H1.55ZM6.39033 4.98371L5.33075 3.90488C6.17562 3.22283 7.07593 2.68373 8.0317 2.28758C8.98747 1.89143 9.97688 1.65169 10.9999 1.56836V3.06831C10.1692 3.15164 9.36566 3.35838 8.58938 3.68851C7.81309 4.01864 7.08008 4.45038 6.39033 4.98371ZM17.6057 4.98371C16.9609 4.46319 16.2349 4.03306 15.4278 3.69331C14.6208 3.35358 13.8115 3.14524 12.9999 3.06831V1.56836C14.0333 1.66194 15.0304 1.90585 15.9913 2.30008C16.9522 2.69432 17.8467 3.23245 18.6749 3.91449L17.6057 4.98371ZM20.9307 10.9991C20.8346 10.1106 20.6214 9.28209 20.2913 8.51351C19.9612 7.74491 19.5294 7.03498 18.9961 6.38371L20.0653 5.31449C20.7538 6.12987 21.2951 7.01801 21.6894 7.97891C22.0836 8.93979 22.3307 9.94652 22.4307 10.9991H20.9307Z"
                fill="#6e3cfb"
            />
        </g>
    </svg>
);
