import {
    Box,
    Button,
    Divider,
    Grid,
    Skeleton,
    Tooltip,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import {
    IQueryEntitiesesponseData,
    LiveInsightsQueryResponse,
    TVCaptionsData,
    TVCaptionsDataResult,
    YoutubeMediaData,
} from '../../../services/interfaces';
import { useSearchParams } from 'react-router-dom';
import { BorderedBox } from '../../../components/core/box';
import SingleSelectTab from '../../../components/core/input/single-select-tab';
import { NavigationLink } from '../../../components/core/link';
import { Eye } from '../../../components/icons/eye';
import { NoDataImage } from '../../../components/images/no-data';
import { useChatbotService } from '../../../services/chatbot.service';
import { UtilsService } from '../../../services/utils.service';
import { TVCaptions } from '../../../components/icons/tv-captions';
import { YouTube } from '../../../components/icons/youtube';
import { Comment } from '../../../components/icons/comment';
import { Circle } from '../../../components/icons/circle';
import { ThumbUp } from '../../../components/icons/thumb-up';

interface IProps {
    entities: IQueryEntitiesesponseData[];
    onFetched: () => void;
    query?: string;
}

export const WebMedia = (props: IProps) => {
    const [youtubeMediaData, setYoutubeMediaData] = useState<
        YoutubeMediaData[] | null
    >([]);
    const [tvCaptionsData, setTvCaptionsData] = useState<
        TVCaptionsDataResult[] | null
    >(null);
    const [selectedtab, setSelectedtab] = useState('TV Captions');
    const [selectedEntity, setSelectedEntity] =
        useState<YoutubeMediaData | null>(null);
    const [searchParam] = useSearchParams();
    const {
        fetchLiveInsightsYoutubeMediaResponse,
        fetchLiveInsightsTvCaptionsResponse,
    } = useChatbotService();
    useEffect(() => {
        refreshYoutubeMedia();
        refreshTVCaptions();
    }, [props.query, searchParam.get('query'), props.entities]);

    const refreshTVCaptions = async () => {
        let message = props.query || searchParam.get('query');
        if (!message) return;
        setTvCaptionsData(null);
        await fetchLiveInsightsTvCaptionsResponse(
            message,
            props.entities,
            (data: string) => {
                if (data) {
                    processTVCaptionsQueryResponse(data.split('\n'));
                }
            },
            true
        );
    };

    const refreshYoutubeMedia = async () => {
        let message = props.query || searchParam.get('query');
        if (!message) return;
        setYoutubeMediaData(null);
        await fetchLiveInsightsYoutubeMediaResponse(
            message,
            props.entities,
            (data: string) => {
                if (data) {
                    processYoutubeQueryResponse(data.split('\n'));
                }
            },
            true
        );
    };

    const processYoutubeQueryResponse = (responses: string[]) => {
        let yMediaData: YoutubeMediaData[] | null = null;
        for (let res of responses) {
            try {
                let parsedRes: LiveInsightsQueryResponse = JSON.parse(res);
                switch (parsedRes.chunk) {
                    case 'YOUTUBE_DATA':
                        let youtubeData = parsedRes.data as YoutubeMediaData;
                        if (yMediaData) {
                            yMediaData = [...yMediaData, youtubeData];
                        } else {
                            yMediaData = [youtubeData];
                        }
                        break;

                    case 'TERMINATION':
                        setYoutubeMediaData(yMediaData);
                        props.onFetched();
                        break;
                }
            } catch (error) {}
        }
    };

    const processTVCaptionsQueryResponse = (responses: string[]) => {
        let tvCaptionsMediaData: TVCaptionsDataResult[] | null = null;
        for (let res of responses) {
            try {
                let parsedRes: LiveInsightsQueryResponse = JSON.parse(res);
                switch (parsedRes.chunk) {
                    case 'TV_CAPTIONS':
                        let tvCaptionsData = (parsedRes.data as TVCaptionsData)
                            .results;
                        if (tvCaptionsMediaData) {
                            tvCaptionsMediaData = [
                                ...tvCaptionsMediaData,
                                ...tvCaptionsData,
                            ];
                        } else {
                            tvCaptionsMediaData = [...tvCaptionsData];
                        }
                        break;
                    case 'TERMINATION':
                        setTvCaptionsData(tvCaptionsMediaData);
                        props.onFetched();
                        break;
                }
            } catch (error) {}
        }
    };

    useEffect(() => {
        if (youtubeMediaData && youtubeMediaData.length > 0) {
            setSelectedEntity(youtubeMediaData[0]);
        }
    }, [youtubeMediaData]);

    return (
        <Box>
            {youtubeMediaData && (
                <Box
                    px={6}
                    justifyContent="space-between"
                    // sx={{
                    //     boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.05)',
                    //     borderBottom: '1px solid #D7D9EC',
                    // }}
                >
                    {youtubeMediaData.filter((e) => !!e.query).length > 0 && (
                        <Typography mt={2} variant="subtitle2">
                            Detected Entities from “
                            {props.query || searchParam.get('query')}” are
                            below.
                        </Typography>
                    )}

                    {youtubeMediaData.filter((e) => !!e.query).length > 0 && (
                        <Box mt={2} display="flex">
                            {youtubeMediaData.map((e) => (
                                <Button
                                    sx={{
                                        backgroundColor:
                                            selectedEntity?.query == e.query
                                                ? '#EBECF6'
                                                : '#fff',
                                        border: `1px solid ${
                                            selectedEntity?.query == e.query
                                                ? '#6e3cfb'
                                                : '#D7D9EC'
                                        }`,
                                        borderRadius: 1,
                                        mr: 2,
                                        color:
                                            selectedEntity?.query == e.query
                                                ? '#6e3cfb'
                                                : '#4B4B4C',
                                        display: 'flex',
                                        py: 0,
                                        px: 2,
                                    }}
                                    onClick={() => {
                                        setSelectedEntity(e);
                                    }}
                                >
                                    <Typography variant="h6">
                                        {e.query}
                                    </Typography>
                                </Button>
                            ))}
                        </Box>
                    )}
                </Box>
            )}
            <Box
                px={6}
                sx={{ maxHeight: 'calc(100vh - 305px)', overflowY: 'auto' }}
            >
                <Box py={4} display="flex" justifyContent="space-between">
                    <Box pt={1} display="flex">
                        <Box pt={0.5}>
                            {selectedtab == 'TV Captions' ? (
                                TVCaptions
                            ) : (
                                <YouTube />
                            )}
                        </Box>
                        <Typography ml={1} variant="h6">
                            {selectedtab == 'TV Captions'
                                ? 'TV Captions'
                                : 'YouTube'}
                        </Typography>
                    </Box>
                    <SingleSelectTab
                        width={136}
                        getOptionText={(v: string) => v}
                        placeholder="Select source"
                        pb={3}
                        options={['TV Captions', 'Youtube']}
                        selectedValue={selectedtab}
                        onChange={setSelectedtab}
                    />
                </Box>
                {((selectedtab == 'TV Captions' &&
                    tvCaptionsData &&
                    tvCaptionsData.length == 0) ||
                    (selectedEntity &&
                        selectedtab == 'Youtube' &&
                        selectedEntity.videos.length == 0)) && (
                    <Box textAlign={'center'} px={4} py={20}>
                        <Box>{NoDataImage}</Box>
                        <br />
                        <Typography variant="h4">
                            Sorry, No data available
                        </Typography>
                        <Typography fontSize={14} fontWeight={400} mt={2}>
                            No data available for this entities. Please try with
                            some different query
                        </Typography>
                    </Box>
                )}
                {((selectedtab == 'TV Captions' && !tvCaptionsData) ||
                    (selectedtab == 'Youtube' && !selectedEntity)) && (
                    <Box my={2}>
                        <Grid spacing={4} container>
                            {Array.from(Array(8).keys()).map((v) => (
                                <Grid item sm={2}>
                                    <BorderedBox>
                                        <Box p={1}>
                                            <Skeleton
                                                variant="rectangular"
                                                sx={{
                                                    width: '100%',
                                                    height: 125,
                                                }}
                                            />

                                            <Typography
                                                className="clamp-line-2"
                                                variant="subtitle2"
                                            >
                                                <Skeleton variant="text" />
                                            </Typography>
                                        </Box>
                                        <Divider />
                                        <Box p={1}>
                                            <Typography
                                                className="clamp-line-3"
                                                fontWeight={400}
                                                fontSize={10}
                                            >
                                                <Skeleton variant="text" />
                                            </Typography>
                                            <Box
                                                mt={1}
                                                display="flex"
                                                justifyContent="space-between"
                                            >
                                                <Typography
                                                    fontWeight={600}
                                                    fontSize={10}
                                                >
                                                    <Skeleton
                                                        width={50}
                                                        variant="text"
                                                    />
                                                </Typography>
                                                <Typography
                                                    fontWeight={600}
                                                    fontSize={10}
                                                >
                                                    <Skeleton
                                                        width={50}
                                                        variant="text"
                                                    />
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </BorderedBox>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                )}
                {selectedEntity && (
                    <Box
                        display={selectedtab == 'Youtube' ? '' : 'none'}
                        my={2}
                    >
                        <Grid spacing={4} container>
                            {selectedEntity.videos.map((v) => (
                                <Grid item sm={2}>
                                    <BorderedBox pb={2}>
                                        <Box p={1}>
                                            <img
                                                style={{
                                                    width: '100%',
                                                    maxHeight: '150px',
                                                    borderRadius: '4px',
                                                    overflow: 'hidden',
                                                }}
                                                src={`https://i.ytimg.com/vi/${v.videoId}/0.jpg`}
                                            />
                                            <NavigationLink
                                                to={v.videoUrl}
                                                target="_blank"
                                            >
                                                <Typography
                                                    className="clamp-line-1"
                                                    variant="subtitle2"
                                                >
                                                    {v.title}
                                                </Typography>
                                            </NavigationLink>
                                            <Box
                                                display="flex"
                                                mt={1}
                                                justifyContent="space-between"
                                            >
                                                <Typography
                                                    fontSize={10}
                                                    fontWeight={400}
                                                    color="#A5A6AB"
                                                >
                                                    {v.channelName}
                                                </Typography>
                                                <Typography
                                                    fontSize={10}
                                                    fontWeight={400}
                                                    color="#A5A6AB"
                                                >
                                                    {UtilsService.formatStringDate(
                                                        v.publishedAt
                                                    )}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Divider />
                                        <Box p={1}>
                                            <Typography
                                                className="clamp-line-3"
                                                fontWeight={400}
                                                fontSize={10}
                                            >
                                                {v.description}
                                            </Typography>
                                        </Box>
                                        <Divider />
                                        <Box display="flex" px={1} mt={1}>
                                            <Box display={'flex'}>
                                                {Comment}
                                                <Typography
                                                    color="#A5A6AB"
                                                    fontWeight={400}
                                                    lineHeight={'11px'}
                                                    fontSize={10}
                                                    ml={1}
                                                >
                                                    {UtilsService.convertToInternationalCurrencySystem(
                                                        v.comments
                                                    )}
                                                </Typography>
                                            </Box>
                                            <Circle
                                                color="#D9D9D9"
                                                mt={3}
                                                h={4}
                                                mx={8}
                                                w={4}
                                            />
                                            <Box display={'flex'}>
                                                <Eye
                                                    size={12}
                                                    color="#A5A6AB"
                                                />
                                                <Typography
                                                    ml={1}
                                                    color="#A5A6AB"
                                                    lineHeight={'11px'}
                                                    fontWeight={400}
                                                    fontSize={10}
                                                >
                                                    {UtilsService.convertToInternationalCurrencySystem(
                                                        v.views
                                                    )}
                                                </Typography>
                                            </Box>
                                            <Circle
                                                color="#D9D9D9"
                                                mt={3}
                                                h={4}
                                                mx={8}
                                                w={4}
                                            />
                                            <Box display={'flex'}>
                                                <ThumbUp size={12} />
                                                <Typography
                                                    ml={1}
                                                    color="#A5A6AB"
                                                    fontWeight={400}
                                                    lineHeight={'11px'}
                                                    fontSize={10}
                                                >
                                                    {UtilsService.convertToInternationalCurrencySystem(
                                                        v.likes
                                                    )}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </BorderedBox>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                )}
                <Box
                    display={selectedtab == 'TV Captions' ? '' : 'none'}
                    my={2}
                >
                    <Grid spacing={4} container>
                        {tvCaptionsData?.map((v) => (
                            <Grid item sm={2}>
                                <BorderedBox>
                                    <Box p={1}>
                                        <img
                                            style={{
                                                width: '100%',
                                                maxHeight: '120px',
                                                overflow: 'hidden',
                                                borderRadius: '4px',
                                            }}
                                            src={v.thumbnailUrl}
                                        />
                                        <NavigationLink
                                            to={v.videoUrl}
                                            target="_blank"
                                        >
                                            <Typography
                                                className="clamp-line-1"
                                                variant="subtitle2"
                                            >
                                                {v.title}
                                            </Typography>
                                        </NavigationLink>
                                    </Box>
                                    <Divider />
                                    <Box p={1}>
                                        <Typography
                                            className="clamp-line-3"
                                            fontWeight={400}
                                            fontSize={10}
                                        >
                                            {v.captionText}
                                        </Typography>
                                        <Box
                                            mt={1}
                                            display="flex"
                                            justifyContent="space-between"
                                        >
                                            <Typography
                                                fontWeight={600}
                                                fontSize={10}
                                            >
                                                {UtilsService.formatStringDate(
                                                    v.timestamp
                                                )}
                                            </Typography>
                                            <Typography
                                                fontWeight={600}
                                                fontSize={10}
                                            >
                                                {new Date(
                                                    v.timestamp
                                                ).toLocaleString('en-US', {
                                                    hour: 'numeric',
                                                    minute: 'numeric',
                                                    hour12: true,
                                                })}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </BorderedBox>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
};
