import {
    Avatar,
    Button,
    Divider,
    IconButton,
    Menu,
    MenuItem,
    Pagination,
    PaginationItem,
    Skeleton,
    Stack,
    Tooltip,
} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import React, { useContext, useEffect, useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { tabSwitchDocumentInsight } from '../../../analytics/analytics';
import { Add } from '../../../components/icons/add';
import { Circle } from '../../../components/icons/circle';
import { useInterval } from '../../../hooks/use-interval';
import { DocumentInsightsContext } from '../../../providers/document-provider';
import { useDocumentService } from '../../../services/document.service';
import {
    DocumentFile,
    FileFilters,
    IDocumentQueryRequest,
    IProximityFilters,
} from '../../../services/interfaces';
import { File, FileInfo } from './file';
import { EmptyBox } from '../../../components/images/empty-box';
import { NoFileResult } from '../../../components/images/no-file-result';
import { Upload } from '../upload';
import { FilesWrapper } from './files-wrapper';
import { Integration } from './integrations';
import { FileSelectionMenu } from './file-selection-menu';
import { BackArrowCircle } from '../../../components/icons/back-arrow-circle';
import { Authorize, HtmlTooltip } from '../../../components/authorize';
import { FeatureName, SubFeatureName } from '../../../services/interfaces';
import { usePermissions } from '../../../providers/permissions-provider';
import { AppContext } from '../../../providers/app-provider';
import { UtilsService } from '../../../services/utils.service';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    adjustHeight?: number;
}

export enum ECURRENT_TAB {
    FILES = 'files',
    INTEGRATIONS = 'integrations',
    SHARED = 'shared',
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, adjustHeight, ...other } = props;
    const appContext = useContext(AppContext);
    return (
        <div
            className="scrollable"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{
                height: `calc(100vh - ${
                    appContext?.accountActionBannerVisible ? '22px' : '0px'
                } - ${adjustHeight ? 142 + adjustHeight : 142}px)`,
                overflow: 'auto',
                position: 'relative',
            }}
            {...other}
        >
            {value === index && <Box sx={{ pt: 0 }}>{children}</Box>}
        </div>
    );
}

export function CloneProps(props: any) {
    const { children, ...other } = props;
    return children(other);
}

export const Drive = () => {
    const navigate = useNavigate();
    const { isFeatureAuthorized, isSubFeatureAuthorized } = usePermissions();
    const {
        fetchFiles,
        fetchSharedFiles,
        fetchFilesFilters,
        fetchFilterQueryFiles,
    } = useDocumentService();
    const [value, setTabValue] = useState(0);
    const [filesCount, setFilesCount] = useState('');
    const [searchParams] = useSearchParams();
    const [currentPage, updateCurrentPage] = useState(1);
    const { type, tab } = useParams();
    const [files, setFiles] = useState<DocumentFile[] | null>(null);
    const [selectedFiles, setSelectedFiles] = useState<DocumentFile[] | null>(
        null
    );
    const appContext = useContext(AppContext);
    const documentContext = useContext(DocumentInsightsContext);
    const [isFileListsFetched, setIsFilesListFetcehd] = useState(false);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const refreshDocuments = async (isBackgroundRefresh?: boolean) => {
        if (value == 0) {
            await refreshMyFiles(isBackgroundRefresh);
        } else if (value == 2) {
            await refreshSharedFiles(isBackgroundRefresh);
        }
        await refreshFilters();
    };

    const refreshFilters = async () => {
        let filters = await fetchFilesFilters();
        documentContext?.setFilters({ ...filters });
    };

    const refreshMyFiles = async (isBackgroundRefresh?: boolean) => {
        if (searchParams.get('filters') || searchParams.get('query')) return;
        if (!isBackgroundRefresh) {
            setFiles(null);
        }
        let res = await fetchFiles(currentPage);
        setFiles([...res.files]);
        setFilesCount(res.count);
        documentContext?.setActiveContextFileCount(parseInt(res.count));
    };

    const refreshSharedFiles = async (isBackgroundRefresh?: boolean) => {
        if (searchParams.get('filters') || searchParams.get('query')) return;
        if (!isBackgroundRefresh) {
            setFiles(null);
        }
        let res = await fetchSharedFiles(currentPage);
        setFiles([...res.files]);
        setFilesCount(res.count);
        documentContext?.setActiveContextFileCount(parseInt(res.count));
    };

    useInterval(() => {
        refreshDocuments(true);
    }, 10 * 1000);

    const setCurrentPage = (page: number) => {
        updateCurrentPage(page);
    };

    useEffect(() => {
        if (currentPage != 1) {
            setCurrentPage(1);
        } else {
            // setSelectedFiles(null);
            refreshDocuments();
        }
    }, [documentContext?.refreshCount, value]);

    useEffect(() => {
        // setSelectedFiles(null);
        refreshDocuments();
    }, [currentPage]);

    const refreshFiles = async () => {
        if (!(searchParams.get('filters') || searchParams.get('query'))) return;
        setFiles(null);
        setFilesCount('');
        documentContext?.setActiveContextFileCount(0);
        let availableFilters = await fetchFilesFilters();
        documentContext?.setFilters({ ...availableFilters });
        let filters = searchParams.get('filters');
        let proximity = searchParams.get('proximityFilters');
        let query = searchParams.get('query');
        if (query) {
            let fileQueryRequest: IDocumentQueryRequest = {
                userQuery: query,
                fuzziness: 1,
                proximity: 10,
                searchableField: 'EVERYWHERE',
            };
            if (filters) {
                let fileFilters: FileFilters = JSON.parse(filters);
                fileQueryRequest.filters = fileFilters;
                if (fileFilters.includeSharedFiles) {
                }
            }
            if (!filters) {
                fileQueryRequest.filters = { includeSharedFiles: true };
            }
            if (proximity) {
                let proximityFilters: IProximityFilters = JSON.parse(proximity);
                fileQueryRequest.fuzziness = proximityFilters.fuzziness;
                fileQueryRequest.proximity = proximityFilters.proximity;
                fileQueryRequest.searchableField =
                    proximityFilters.searchableFields;
            }
            setFilesCount('');
            documentContext?.setActiveContextFileCount(0);
            setFiles(null);
            let res = await fetchFilterQueryFiles(fileQueryRequest);
            setFiles([...res.files]);
            setFilesCount(res.count);
            documentContext?.setActiveContextFileCount(parseInt(res.count));
        } else if (filters) {
            let value: FileFilters = JSON.parse(filters);
            setFilesCount('');
            documentContext?.setActiveContextFileCount(0);
            setFiles(null);
            let res = await fetchFiles(currentPage, value);
            setFiles([...res.files]);
            setFilesCount(res.count);
            documentContext?.setActiveContextFileCount(parseInt(res.count));
        } else {
            setFilesCount('');
            setFiles(null);
            refreshDocuments();
        }
    };

    const removeFilters = () => {
        navigate(`/document-insight`);
        if (documentContext) {
            documentContext.setRefreshCount(documentContext.refreshCount + 1);
        }
    };

    useEffect(() => {
        refreshFiles();
    }, [currentPage]);

    useEffect(() => {
        if (searchParams.get('filters') || searchParams.get('query')) {
            setCurrentPage(1);
            refreshFiles();
        }

        setSelectedFiles(null);
    }, [searchParams]);

    useEffect(() => {
        if (searchParams.get('filters') || searchParams.get('query')) {
            setCurrentPage(1);
            refreshFiles();
        } else {
            refreshDocuments();
        }

        setSelectedFiles(null);
    }, [documentContext?.refreshCount]);

    const setValue = (v: number) => {
        setTabValue(v);
    };

    useEffect(() => {
        if (searchParams.get('filters') || searchParams.get('query')) {
            setValue(0);
        } else if (
            (tab == 'integration' || type == 'integration') &&
            isSubFeatureAuthorized(
                FeatureName.DocumentInsights,
                SubFeatureName.Integrations
            )
        ) {
            setValue(1);
        } else if (tab == 'shared' && value != 2) {
            setValue(2);
        } else if (
            type == 'file' &&
            value != 0 &&
            !(tab == 'shared' && value == 2 && type == 'file')
        ) {
            setValue(0);
        } else if (searchParams.get('currentTab') == 'file') {
            setValue(0);
        } else if (searchParams.get('currentTab')) {
            switch (searchParams.get('currentTab')) {
                case ECURRENT_TAB.FILES:
                    setValue(0);
                    break;
                case ECURRENT_TAB.INTEGRATIONS:
                    setValue(
                        isSubFeatureAuthorized(
                            FeatureName.DocumentInsights,
                            SubFeatureName.Integrations
                        )
                            ? 1
                            : 0
                    );
                    break;
                case ECURRENT_TAB.SHARED:
                    setValue(2);
                    break;
            }
        }
    }, [type, tab, searchParams]);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if ([0, 1, 2].includes(newValue)) {
            tabSwitchDocumentInsight(newValue.toString());
            setValue(newValue);
        }
    };
    const getUploadLimit = () => {
        if (appContext?.subscriptionInfo) {
            let plan = appContext.subscriptionInfo.plan_type;
            switch (plan) {
                case 'FREE':
                    return UtilsService.mbToBytes(10);
                case 'BASIC':
                    return UtilsService.mbToBytes(50);
                case 'PRO':
                    return UtilsService.mbToBytes(100);
            }
        }
        return UtilsService.mbToBytes(50);
    };

    const getAllowedFormats = () => {
        if (appContext?.subscriptionInfo) {
            let plan = appContext.subscriptionInfo.plan_type;
            switch (plan) {
                case 'FREE':
                    return 'pdf, doc, docx, ppt, txt, images';
                case 'BASIC':
                    return 'pdf, doc, docx, ppt, txt, images';
                case 'PRO':
                    return 'pdf, doc, docx, ppt, txt, images, audio/video files';
            }
        }
        return 'pdf, doc, docx, ppt, txt, images, audio/video files';
    };
    return (
        <Box
            pt={4}
            className="max-window-available"
            sx={{
                textAlign: 'left',
                borderRight: '1px solid #D7D9EC',
                boxShadow: '1px 0px 4px rgb(0 0 0 / 8%)',
            }}
        >
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, pl: 5, borderColor: 'divider' }}>
                    {!searchParams.get('filters') &&
                        !searchParams.get('query') && (
                            <Tabs
                                sx={{
                                    pt: 2,
                                    '& .MuiTabs-flexContainer': {
                                        flexWrap: 'wrap',
                                    },
                                }}
                                value={value}
                                onChange={handleChange}
                                aria-label="basic tabs example"
                            >
                                <Tab label="My Files" />
                                <CloneProps>
                                    {(tabProps: any) => (
                                        <HtmlTooltip
                                            arrow
                                            placement={'top'}
                                            open={
                                                isSubFeatureAuthorized(
                                                    FeatureName.DocumentInsights,
                                                    SubFeatureName.Integrations
                                                )
                                                    ? false
                                                    : undefined
                                            }
                                            title={
                                                <Box
                                                    py={2}
                                                    textAlign={'center'}
                                                    px={1}
                                                >
                                                    <Typography variant="h5">
                                                        {'Feature Unavailable'}
                                                    </Typography>
                                                    <Typography mt={2}>
                                                        {`This feature is not included in your current plan. Upgrade to access this functionality.`}
                                                    </Typography>
                                                </Box>
                                            }
                                        >
                                            <span>
                                                <Tab
                                                    {...tabProps}
                                                    disabled={
                                                        !isSubFeatureAuthorized(
                                                            FeatureName.DocumentInsights,
                                                            SubFeatureName.Integrations
                                                        )
                                                    }
                                                    label={'Integrations'}
                                                />
                                            </span>
                                        </HtmlTooltip>
                                    )}
                                </CloneProps>
                                <Tab label="Shared" />
                                {value == 0 && (
                                    <Box ml={'auto'} width={70}>
                                        <FileSelectionMenu
                                            files={files}
                                            selectedFiles={selectedFiles}
                                            setSelectedFiles={setSelectedFiles}
                                        />
                                    </Box>
                                )}
                            </Tabs>
                        )}
                    {(searchParams.get('filters') ||
                        searchParams.get('query')) && (
                        <Box
                            display={'flex'}
                            justifyContent={'space-between'}
                            pb={2}
                        >
                            <Box display={'flex'}>
                                <Tooltip
                                    placement="top"
                                    arrow
                                    title="Reset Filters"
                                >
                                    <IconButton
                                        onClick={removeFilters}
                                        sx={{
                                            p: 0,
                                            width: 24,
                                            mt: 3,
                                        }}
                                    >
                                        {BackArrowCircle}
                                    </IconButton>
                                </Tooltip>
                                <Box ml={2}>
                                    <Typography fontSize={16} fontWeight={700}>
                                        Results
                                    </Typography>
                                    <Typography
                                        fontSize={12}
                                        color="#585960"
                                        fontWeight={600}
                                    >
                                        Total result found - {filesCount}
                                    </Typography>
                                </Box>
                            </Box>
                            <FileSelectionMenu
                                files={files}
                                selectedFiles={selectedFiles}
                                setSelectedFiles={setSelectedFiles}
                            />
                        </Box>
                    )}
                </Box>
                <TabPanel
                    adjustHeight={
                        searchParams.get('filters') || searchParams.get('query')
                            ? 12
                            : 0
                    }
                    value={value}
                    index={0}
                >
                    {/* {documentContext?.uploadFiles.map((file) => (
                        <FileUpload skipUpload={true} fileData={file} />
                    ))} */}

                    {files && files.length > 0 && (
                        <Box>
                            <FilesWrapper
                                selectedFiles={selectedFiles}
                                setSelectedFiles={setSelectedFiles}
                                files={files}
                                tab={value}
                            />
                            {!searchParams.get('query') && (
                                <Box
                                    sx={{
                                        my: 2,
                                        mr: 5,
                                        display: 'flex',
                                        justifyContent: 'right',
                                    }}
                                >
                                    <Stack spacing={2}>
                                        <Pagination
                                            sx={{
                                                mr: 'auto',
                                                '& .Mui-selected': {
                                                    backgroundColor:
                                                        '#6e3cfb !important',
                                                    color: '#FFFFFF !important',
                                                    '&:hover': {
                                                        backgroundColor:
                                                            '#6e3cfb !important',
                                                        color: '#FFFFFF !important',
                                                    },
                                                },
                                            }}
                                            count={Math.ceil(
                                                parseInt(filesCount) / 50
                                            )}
                                            siblingCount={0}
                                            boundaryCount={2}
                                            size="small"
                                            onChange={(e, p) => {
                                                setCurrentPage(p);
                                            }}
                                            page={currentPage}
                                        />
                                    </Stack>
                                </Box>
                            )}
                        </Box>
                    )}
                    {files?.length == 0 &&
                        (searchParams.get('filters') ||
                        searchParams.get('query') ? (
                            <Box pt={30} textAlign="center">
                                {NoFileResult}
                                <Typography variant="h4">
                                    No Results Found!
                                </Typography>
                                <Typography fontSize={14} fontWeight={400}>
                                    No related results found. Try searching
                                    something else.
                                </Typography>
                            </Box>
                        ) : (
                            <Box pt={30} textAlign="center">
                                {EmptyBox}
                                <Typography variant="h4">No files!</Typography>
                                <Box display="inline-flex">
                                    <Typography
                                        fontSize={14}
                                        pt={4}
                                        fontWeight={400}
                                    >
                                        Click{' '}
                                    </Typography>{' '}
                                    <Upload min />{' '}
                                    <Typography
                                        fontSize={14}
                                        pl={1}
                                        pt={4}
                                        fontWeight={400}
                                    >
                                        to upload any document to generate its
                                        insights
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography
                                        color="#A5A6AB"
                                        mt={1}
                                        variant="caption"
                                    >
                                        {getAllowedFormats()}
                                        <Circle mt={0} h={8} w={8} /> Max file
                                        size upto{' '}
                                        {UtilsService.getFileSize(
                                            getUploadLimit()
                                        )}
                                    </Typography>
                                </Box>
                            </Box>
                        ))}
                    {!files &&
                        [1, 2, 3, 4, 5].map(() => (
                            <Box display="flow-root">
                                <Box px={10} my={4}>
                                    <Grid
                                        justifyContent="space-between"
                                        container
                                    >
                                        <Grid item>
                                            <Grid spacing={3} container>
                                                <Grid item>
                                                    <Skeleton
                                                        width={40}
                                                        height={40}
                                                        variant="rectangular"
                                                    />
                                                </Grid>

                                                <Grid item>
                                                    <Box display="flex">
                                                        <Skeleton
                                                            width={350}
                                                            variant="text"
                                                        />
                                                    </Box>
                                                    <FileInfo
                                                        display="flex"
                                                        mb="8px !important"
                                                    >
                                                        <Skeleton
                                                            width={100}
                                                            sx={{ mr: 2 }}
                                                            variant="text"
                                                        />
                                                        <Circle
                                                            mt={5}
                                                            h={8}
                                                            w={8}
                                                        />{' '}
                                                        <Skeleton
                                                            sx={{ ml: 2 }}
                                                            width={50}
                                                            variant="text"
                                                        />
                                                    </FileInfo>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item>
                                            <Box sx={{ cursor: 'pointer' }}>
                                                <Skeleton
                                                    width={20}
                                                    height={20}
                                                    variant="rectangular"
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Divider />
                            </Box>
                        ))}
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Integration />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    {files && files.length > 0 && (
                        <FilesWrapper
                            selectedFiles={null}
                            setSelectedFiles={() => {}}
                            files={files}
                            hideDelete={true}
                            tab={value}
                        />
                    )}
                    {!files &&
                        [1, 2, 3, 4, 5].map(() => (
                            <Box display="flow-root">
                                <Box px={10} my={4}>
                                    <Grid
                                        justifyContent="space-between"
                                        container
                                    >
                                        <Grid item>
                                            <Grid spacing={3} container>
                                                <Grid item>
                                                    <Skeleton
                                                        width={40}
                                                        height={40}
                                                        variant="rectangular"
                                                    />
                                                </Grid>

                                                <Grid item>
                                                    <Box display="flex">
                                                        <Skeleton
                                                            width={350}
                                                            variant="text"
                                                        />
                                                    </Box>
                                                    <FileInfo
                                                        display="flex"
                                                        mb="8px !important"
                                                    >
                                                        <Skeleton
                                                            width={100}
                                                            sx={{ mr: 2 }}
                                                            variant="text"
                                                        />
                                                        <Circle
                                                            mt={5}
                                                            h={8}
                                                            w={8}
                                                        />{' '}
                                                        <Skeleton
                                                            sx={{ ml: 2 }}
                                                            width={50}
                                                            variant="text"
                                                        />
                                                    </FileInfo>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item>
                                            <Box sx={{ cursor: 'pointer' }}>
                                                <Skeleton
                                                    width={20}
                                                    height={20}
                                                    variant="rectangular"
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Divider />
                            </Box>
                        ))}

                    {files && (
                        <Box
                            sx={{
                                my: 2,
                                mr: 5,
                                display: 'flex',
                                justifyContent: 'right',
                            }}
                        >
                            <Stack spacing={2}>
                                <Pagination
                                    sx={{
                                        mr: 'auto',
                                        '& .Mui-selected': {
                                            backgroundColor:
                                                '#6e3cfb !important',
                                            color: '#FFFFFF !important',
                                            '&:hover': {
                                                backgroundColor:
                                                    '#6e3cfb !important',
                                                color: '#FFFFFF !important',
                                            },
                                        },
                                    }}
                                    count={Math.ceil(parseInt(filesCount) / 50)}
                                    size="small"
                                    onChange={(e, p) => {
                                        setCurrentPage(p);
                                    }}
                                    page={currentPage}
                                />
                            </Stack>
                        </Box>
                    )}
                </TabPanel>
            </Box>
        </Box>
    );
};
