export const FilterUnchecked = (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect x="1" y="1" width="14" height="14" rx="1" fill="white" />
        <path
            d="M6 12.4L2 8.4L3.4 7L6 9.6L12.6 3L14 4.4L6 12.4Z"
            fill="white"
        />
        <rect
            x="1"
            y="1"
            width="14"
            height="14"
            rx="1"
            stroke="#6e3cfb"
            stroke-width="2"
        />
    </svg>
);
