import { useNavigate } from 'react-router-dom';
import { useAuthService } from './auth.service';
import { UtilsService } from './utils.service';
import { useSnackbar } from 'notistack';

export enum NetworkRequestMethod {
    GET = 'GET',
    PUT = 'PUT',
    POST = 'POST',
    PATCH = 'PATCH',
    DELETE = 'DELETE',
}

export interface INetworkRequestProps {
    method: NetworkRequestMethod;
    headers?: { [key: string]: string };
    url: string;
    body?: any;
    retryCount?: number;
    callback?: (data: string) => void;
}

export interface INetworkResponse {
    status: number;
    success: boolean;
    error: any;
}

export const useNetworkAPI = () => {
    const { getAccessToken, generateAccessToken } = useAuthService();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const triggerRequest = async (
        requestProps: INetworkRequestProps,
        headers?: string[],
        skipAuthHeaders?: boolean,
        redirectToLoginOnFailure?: boolean
    ): Promise<any> => {
        try {
            if (requestProps.retryCount === undefined) {
                requestProps.retryCount = 3;
            }
            if (!requestProps.headers) {
                requestProps.headers = {};
            }
            requestProps.headers['x-pi-request-id'] = UtilsService.uuidv4();
            if (!skipAuthHeaders) {
                requestProps.headers['x-pi-bearer'] = await getAccessToken(
                    triggerRequest
                );
            }
            let res: Response = await fetch(requestProps.url, {
                method: requestProps.method.toString(),
                headers: requestProps.headers,
                body: requestProps.body,
            });
            let respHeaders: { [key: string]: string } = {};
            headers?.forEach((h: string) => {
                respHeaders[h] = res.headers.get(h) as string;
            });
            if ([429].includes(res.status)) {
                enqueueSnackbar('Rate Limit Exceeded', {
                    variant: 'error',
                });
                return { body: { success: false }, headers: respHeaders };
            }

            let body = await res.json();

            if (res.status == 401 && skipAuthHeaders) {
                return { body, headers: respHeaders };
            }

            if ([401].includes(res.status) && redirectToLoginOnFailure) {
                if (window.location.pathname != '/signup') {
                    if (window.location.pathname != '/signup') {
                        navigate('/login');
                    }
                }
                return { body, headers: respHeaders };
            }

            if (res.status == 401) {
                let tokenGenerated = await generateAccessToken(triggerRequest);
                if (tokenGenerated) {
                    return await triggerRequest(
                        requestProps,
                        headers,
                        skipAuthHeaders,
                        redirectToLoginOnFailure
                    );
                } else {
                    return { body, headers: respHeaders };
                }
            }
            if (
                [400].includes(res.status) &&
                body.error &&
                body.error.code &&
                body.error.code == 'QUOTA_EXCEEDED'
            ) {
                enqueueSnackbar(body.error.title, {
                    variant: 'error',
                });
                return { body, headers: respHeaders };
            }

            if (res.status === 500) {
                return { body };
            }
            return { body, headers: respHeaders, status: res.status };
        } catch (error) {
            if (
                requestProps.retryCount !== undefined &&
                requestProps.retryCount > 0
            ) {
                requestProps.retryCount--;
                return await triggerRequest(
                    requestProps,
                    headers,
                    skipAuthHeaders,
                    redirectToLoginOnFailure
                );
            }
            throw error;
        }
    };

    const triggerUploadRequest = async (
        requestProps: INetworkRequestProps,
        skipAuthHeaders?: boolean,
        redirectToLoginOnFailure?: boolean
    ): Promise<any> => {
        try {
            if (requestProps.retryCount === undefined) {
                requestProps.retryCount = 3;
            }
            if (!requestProps.headers) {
                requestProps.headers = {};
            }
            if (!skipAuthHeaders) {
                requestProps.headers['x-pi-bearer'] = await getAccessToken(
                    triggerRequest
                );
            }

            let res: Response = await fetch(requestProps.url, {
                method: requestProps.method.toString(),
                headers: requestProps.headers,
                body: requestProps.body,
            });

            return { status: res.status };
        } catch (error) {
            throw error;
        }
    };

    const triggerRequestWithChunkedResponse = async (
        requestProps: INetworkRequestProps,
        headers?: string[],
        skipAuthHeaders?: boolean,
        redirectToLoginOnFailure?: boolean
    ): Promise<any> => {
        try {
            // WARNING: For POST requests, body is set to null by browsers.
            let response = '';
            var xhr = new XMLHttpRequest();
            // xhr.withCredentials = true;

            xhr.addEventListener('readystatechange', async function () {
                if (requestProps.callback && response != this.responseText) {
                    response = this.responseText;
                    requestProps.callback(this.responseText);
                }
                if (this.readyState === 4) {
                    return this.responseText;
                }
                const status = this.status;
                if (!(status === 0 || (status >= 200 && status < 400))) {
                    console.error(this.responseText);
                }
                if (status == 401) {
                    let tokenGenerated = await generateAccessToken(
                        triggerRequest
                    );
                    if (tokenGenerated) {
                        return await triggerRequestWithChunkedResponse(
                            requestProps,
                            headers,
                            skipAuthHeaders,
                            redirectToLoginOnFailure
                        );
                    }
                }
            });

            xhr.open('POST', requestProps.url);
            xhr.setRequestHeader(
                'x-pi-bearer',
                await getAccessToken(triggerRequest)
            );
            xhr.setRequestHeader('Content-Type', 'application/json');

            xhr.send(requestProps.body);
        } catch (error) {
            if (
                requestProps.retryCount !== undefined &&
                requestProps.retryCount > 0
            ) {
                requestProps.retryCount--;
                return await triggerRequestWithChunkedResponse(
                    requestProps,
                    headers
                );
            }
            throw error;
        }
    };

    const triggerRequestWithChunkedResponseWithRef = async (
        requestProps: INetworkRequestProps,
        headers?: string[],
        skipAuthHeaders?: boolean,
        redirectToLoginOnFailure?: boolean
    ): Promise<XMLHttpRequest> => {
        try {
            // WARNING: For POST requests, body is set to null by browsers.
            let response = '';
            var xhr = new XMLHttpRequest();
            // xhr.withCredentials = true;

            xhr.addEventListener('readystatechange', async function () {
                if (requestProps.callback && response != this.responseText) {
                    response = this.responseText;
                    requestProps.callback(this.responseText);
                }
                if (this.readyState === 4) {
                    return this.responseText;
                }
                const status = this.status;
                if (!(status === 0 || (status >= 200 && status < 400))) {
                    console.error(this.responseText);
                }
                if (status == 401) {
                    let tokenGenerated = await generateAccessToken(
                        triggerRequest
                    );
                    if (tokenGenerated) {
                        return await triggerRequestWithChunkedResponse(
                            requestProps,
                            headers,
                            skipAuthHeaders,
                            redirectToLoginOnFailure
                        );
                    }
                }
                if (status === 429) {
                    enqueueSnackbar(
                        'Error: Too Many Requests. Please try again later.',
                        {
                            variant: 'error',
                        }
                    );
                    // Optionally, you can handle retry logic here
                }
            });

            xhr.open('POST', requestProps.url);
            xhr.setRequestHeader(
                'x-pi-bearer',
                await getAccessToken(triggerRequest)
            );
            xhr.setRequestHeader('Content-Type', 'application/json');

            xhr.send(requestProps.body);
            return xhr;
        } catch (error) {
            console.log(error);
            if (
                requestProps.retryCount !== undefined &&
                requestProps.retryCount > 0
            ) {
                requestProps.retryCount--;
                return await triggerRequestWithChunkedResponse(
                    requestProps,
                    headers
                );
            }
            throw error;
        }
    };
    return {
        triggerRequest,
        triggerRequestWithChunkedResponse,
        triggerUploadRequest,
        triggerRequestWithChunkedResponseWithRef,
    };
};
