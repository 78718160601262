export const WebMediaIcon = (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <mask
            id="mask0_152_117619"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="20"
            height="20"
        >
            <rect width="20" height="20" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_152_117619)">
            <path
                d="M7.08398 17.0826V15.8327H8.75065V14.166H3.5904C3.16946 14.166 2.81315 14.0202 2.52148 13.7285C2.22982 13.4368 2.08398 13.0805 2.08398 12.6596V4.42243C2.08398 4.00149 2.22982 3.64518 2.52148 3.35352C2.81315 3.06185 3.16946 2.91602 3.5904 2.91602H16.4109C16.8318 2.91602 17.1881 3.06185 17.4798 3.35352C17.7714 3.64518 17.9173 4.00149 17.9173 4.42243V12.6596C17.9173 13.0805 17.7714 13.4368 17.4798 13.7285C17.1881 14.0202 16.8318 14.166 16.4109 14.166H11.2506V15.8327H12.9173V17.0826H7.08398ZM3.5904 12.916H16.4109C16.475 12.916 16.5337 12.8893 16.5872 12.8359C16.6406 12.7825 16.6673 12.7237 16.6673 12.6596V4.42243C16.6673 4.35832 16.6406 4.29955 16.5872 4.24612C16.5337 4.1927 16.475 4.166 16.4109 4.166H3.5904C3.52629 4.166 3.46752 4.1927 3.41409 4.24612C3.36067 4.29955 3.33396 4.35832 3.33396 4.42243V12.6596C3.33396 12.7237 3.36067 12.7825 3.41409 12.8359C3.46752 12.8893 3.52629 12.916 3.5904 12.916Z"
                fill="#6e3cfb"
            />
        </g>
    </svg>
);
