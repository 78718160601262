import { Box, Typography, Tabs, Tab, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ReportModule } from '../../../../services/interfaces';
import { BorderedBox } from '../../../../components/core/box';
import { PriceChart } from './price-chart';
import { FinancialTrendsPlot } from './financial-trend';
import { KeyRatio } from './key-ratio';
import { InstitutionalOwnership } from './institutional-ownership';
import { InsiderTrading } from './insider-trading';
import { TabPanel } from '../../../../components/core/tab-panel';
import { EarningCall } from './earning-call';
import { Acqusitions } from './acqusitions';
import { FundingRounds } from './funding-rounds';
import { Investments } from './investments';
import { Investors } from './investors';
import { NoData } from '../no-data';
import { AnalystEstimate } from './analyst-estimate';
import { FiancialStatement } from './financial-statements';
import { BorderBottom } from '@mui/icons-material';

interface IProps {
    data: ReportModule;
    reportTitle: string;
}

const CustomTabs = styled(Tabs)({
    overflow: 'visible',
    '& .MuiTabs-indicator': {
        display: 'none', // Ensure the underline is at the bottom of the tab
    },
});

const CustomTab = styled(Tab)({
    minWidth: 'auto', // Adjust as needed
    '&.Mui-selected': {
        borderBottom: '2px solid', // Ensure the underline is at the bottom of the tab
        backgroundColor: 'transparent',
    },
});

export const Financials = (props: IProps) => {
    const { data } = props;
    const [value, setValue] = useState('');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };
    useEffect(() => {
        if (props.data?.data?.yahoo?.stockPrices) {
            setValue('PRICE_CHART');
            return;
        }
        if (props.data?.data?.yahoo?.coreFinancials.analystRecommendations) {
            setValue('ANALYST_ESTIMATE');
            return;
        }
        if (props.data?.data?.yahoo?.coreFinancials.incomeStatement) {
            setValue('FINANCIAL_STATEMENTS');
            return;
        }
        if (props.data?.data?.yahoo?.coreFinancials) {
            setValue('KEY_RATIO');
            return;
        }
        if (props.data.data?.yahoo?.coreFinancials?.instituteOwnerShip) {
            setValue('INSTITUTIONAL_OWNERSHIP');
            return;
        }
        if (props.data.data?.yahoo?.insiderTransactions) {
            setValue('INSIDER_TRADING');
            return;
        }
        if (data.data?.earningsCalls) {
            setValue('EARNING_CALL');
            return;
        }
        if (data.data?.investments?.length) {
            setValue('INVESTMENTS');
            return;
        }
        if (data.data?.investors?.length) {
            setValue('INVESTORS');
            return;
        }
        if (props.data?.data?.acquisitions?.length) {
            setValue('ACQUISITIONS');
            return;
        }
        if (props.data?.data?.fundingRounds?.length) {
            setValue('FUNDING_ROUNDS');
            return;
        }
    }, [props.data]);
    return (
        <BorderedBox
            sx={{
                backgroundColor: '#f9f5ff',
            }}
            pb={4}
            my={4}
        >
            <Typography px={4} pt={4} pb={4} variant="h4">
                Financials
            </Typography>
            <Box
                sx={{
                    maxHeight: 'calc(100vh - 280px)',
                    overflowY: 'auto',
                }}
                px={4}
            >
                {!value && <NoData />}
                {value && (
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <CustomTabs
                            sx={{
                                '& .MuiTabs-flexContainer': {
                                    flexWrap: 'wrap',
                                },
                            }}
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                        >
                            {data.data?.yahoo?.stockPrices && (
                                <CustomTab
                                    sx={{
                                        fontWeight:
                                            value == 'PRICE_CHART' ? 600 : 400,
                                    }}
                                    label="Price Chart"
                                    value="PRICE_CHART"
                                />
                            )}
                            {props.data?.data?.yahoo?.coreFinancials
                                .analystRecommendations && (
                                <CustomTab
                                    sx={{
                                        fontWeight:
                                            value == 'ANALYST_ESTIMATE'
                                                ? 600
                                                : 400,
                                    }}
                                    label="Analyst Estimate"
                                    value="ANALYST_ESTIMATE"
                                />
                            )}
                            {props.data?.data?.yahoo?.coreFinancials
                                .incomeStatement && (
                                <CustomTab
                                    sx={{
                                        fontWeight:
                                            value == 'FINANCIAL_STATEMENTS'
                                                ? 600
                                                : 400,
                                    }}
                                    label="Financial Statements"
                                    value="FINANCIAL_STATEMENTS"
                                />
                            )}
                            {props.data?.data?.yahoo?.coreFinancials && (
                                <CustomTab
                                    sx={{
                                        fontWeight:
                                            value == 'KEY_RATIO' ? 600 : 400,
                                    }}
                                    label="Key Ratios"
                                    value="KEY_RATIO"
                                />
                            )}
                            {props.data.data?.yahoo?.coreFinancials
                                ?.instituteOwnerShip && (
                                <CustomTab
                                    sx={{
                                        fontWeight:
                                            value == 'INSTITUTIONAL_OWNERSHIP'
                                                ? 600
                                                : 400,
                                    }}
                                    label="Institution ownership"
                                    value="INSTITUTIONAL_OWNERSHIP"
                                />
                            )}
                            {props.data.data?.yahoo?.insiderTransactions && (
                                <CustomTab
                                    sx={{
                                        fontWeight:
                                            value == 'INSIDER_TRADING'
                                                ? 600
                                                : 400,
                                    }}
                                    label="Insider trading"
                                    value="INSIDER_TRADING"
                                />
                            )}
                            {data.data?.earningsCalls && (
                                <CustomTab
                                    sx={{
                                        fontWeight:
                                            value == 'EARNING_CALL' ? 600 : 400,
                                    }}
                                    label="Earnings Calls"
                                    value="EARNING_CALL"
                                />
                            )}
                            {data.data?.investments?.length && (
                                <CustomTab
                                    sx={{
                                        fontWeight:
                                            value == 'INVESTMENTS' ? 600 : 400,
                                    }}
                                    label="Investments"
                                    value="INVESTMENTS"
                                />
                            )}
                            {data.data?.investors?.length && (
                                <CustomTab
                                    sx={{
                                        fontWeight:
                                            value == 'INVESTORS' ? 600 : 400,
                                    }}
                                    label="Investors"
                                    value="INVESTORS"
                                />
                            )}
                            {data.data?.acquisitions?.length && (
                                <CustomTab
                                    sx={{
                                        fontWeight:
                                            value == 'ACQUISITIONS' ? 600 : 400,
                                    }}
                                    label="Acquisitions"
                                    value="ACQUISITIONS"
                                />
                            )}
                            {data.data?.fundingRounds?.length && (
                                <CustomTab
                                    sx={{
                                        fontWeight:
                                            value == 'FUNDING_ROUNDS'
                                                ? 600
                                                : 400,
                                    }}
                                    label="Funding Rounds"
                                    value="FUNDING_ROUNDS"
                                />
                            )}
                        </CustomTabs>
                    </Box>
                )}
                <Box pt={4}>
                    {data.data?.yahoo?.stockPrices && (
                        <TabPanel value={value} index={'PRICE_CHART'}>
                            <PriceChart data={data} />
                        </TabPanel>
                    )}
                    {props.data?.data?.yahoo?.coreFinancials && (
                        <TabPanel value={value} index={'KEY_RATIO'}>
                            <KeyRatio data={data} />
                        </TabPanel>
                    )}
                    {props.data.data?.yahoo?.coreFinancials
                        ?.instituteOwnerShip && (
                        <TabPanel
                            value={value}
                            index={'INSTITUTIONAL_OWNERSHIP'}
                        >
                            <InstitutionalOwnership data={data} />
                        </TabPanel>
                    )}
                    <TabPanel value={value} index={'ANALYST_ESTIMATE'}>
                        <AnalystEstimate
                            reportTitle={props.reportTitle}
                            data={data}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={'FINANCIAL_STATEMENTS'}>
                        <FiancialStatement data={data} />
                    </TabPanel>
                    {props.data.data?.yahoo?.insiderTransactions && (
                        <TabPanel value={value} index={'INSIDER_TRADING'}>
                            <InsiderTrading data={data} />
                        </TabPanel>
                    )}
                    {data.data?.earningsCalls && (
                        <TabPanel value={value} index={'EARNING_CALL'}>
                            <EarningCall data={data} />
                        </TabPanel>
                    )}
                    {data.data?.investors && (
                        <TabPanel value={value} index={'INVESTORS'}>
                            <Investors data={data} />
                        </TabPanel>
                    )}
                    {data.data?.investments && (
                        <TabPanel value={value} index={'INVESTMENTS'}>
                            <Investments data={data} />
                        </TabPanel>
                    )}
                    {data.data?.fundingRounds && (
                        <TabPanel value={value} index={'FUNDING_ROUNDS'}>
                            <FundingRounds data={data} />
                        </TabPanel>
                    )}
                    {data.data?.acquisitions && (
                        <TabPanel value={value} index={'ACQUISITIONS'}>
                            <Acqusitions data={data} />
                        </TabPanel>
                    )}
                </Box>
                <Typography
                    mt={3}
                    mr={1}
                    textAlign={'right'}
                    fontSize={8}
                    color="#A5A6AB"
                >
                    *Data reference taken from Yahoo finance
                </Typography>
            </Box>
        </BorderedBox>
    );
};
